<template>
  <div class="foot img-bg" :style="defaultStyle">
  </div>
</template>

<script>

export default {
  name: 'STopicFoot',
  props: {
    defaultBg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    defaultStyle () {
      return {
        width: this.defaultBg.style.width,
        height: this.defaultBg.style.height,
        'background-image': `url(${this.defaultBg.src})`
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
</style>
