import _mixin from './_mixin'
export default {
  name: 'BasePage',
  cnName: '页面',
  // 属性相关
  props: {
    // src: ''
  },
  propsDesc: [],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1920px',
    'min-height': '100vh',
    'background-color': '#000',
    'background-size': '100% 100%'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
