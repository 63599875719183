<template>
    <div v-if="recordModelShow" class="lay">
        <div class="record-model" :style="modelStyle">
            <div class="close img-bg" :style="closeStyle" @click="close"></div>
            <img :src="recordTitle" alt="" class="record-title" :style="recordTitleStyle"/>
            <div v-if="!recordData || recordData.length <= 0" class="empty-record">暂无记录</div>
            <div v-else>
                <div v-if="modelType===1" class="record-list">
                    <div v-for="(item,index) in recordData" :key="index" class="record-item">
                        <div>{{item.created_at}}</div>
                        <div>{{item.summary.split(' ')[0]}}</div>
                        <div class="summary">{{item.summary.split(' ')[1]}}</div>
                    </div>
                </div>
                <div v-else-if="modelType===0" class="record-list">
                  <div v-for="(item,index) in recordData" :key="index" class="record-item">
                    <div>{{item.created_at}}</div>
                    <div class="summary">抽中了{{item.award}}</div>
                  </div>
                </div>
                <div v-else class="record-list">
                  <div v-for="(item,index) in recordData" :key="index" class="record-item">
                    <div>{{item.created_at}}</div>
                    <div class="summary">兑换了{{item.award}}</div>
                  </div>
                </div>
                <div class="page">
                    <div :class="['up-page','img-bg', currentPage === 1 ? '': 'btn-hover']" :style="upStyle" @click="upAndDown(1)">上一页</div>
                    <div class="page-desc">{{currentPage}}/{{totalPage}}</div>
                    <div :class="['down-page','img-bg',currentPage === totalPage ? '': 'btn-hover']" :style="downStyle" @click="upAndDown(2)">下一页</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SRecordModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    // 0: 抽奖记录  1： 邀请记录  2： 兑换记录
    modelType: {
      type: Number,
      default: 0
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#AD272D'
    },
    modelClose: {
      type: String,
      default: ''
    },
    recordBgSize: {
      type: String,
      default: ''
    },
    recordTitle: {
      type: String,
      default: ''
    },
    recordTitleSize: {
      type: String,
      default: '419px 29px'
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    btnStatusTwo: {
      type: String,
      default: ''
    },
    recordBtnColor: {
      type: String,
      default: ''
    },
    recordBtnSize: {
      type: String,
      default: ''
    },
    recordData: {
      type: Array,
      default: () => []
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalPageSize: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      recordModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.recordModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
    totalPage () {
      return Math.ceil(this.totalPageSize / this.pageSize)
    },
    modelStyle () {
      const arr = this.recordBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '450px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    recordTitleStyle () {
      const arr = this.recordTitleSize.split(' ')
      const width = arr[0] || '419px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    upStyle () {
      const arr = this.recordBtnSize.split(' ')
      const width = arr[0] || '184px'
      const height = arr[1] || '55px'
      const backImg = this.currentPage === 1 ? this.btnStatusTwo : this.btnStatusOne
      return {
        width,
        height,
        'line-height': `${height}`,
        color: `${this.recordBtnColor}`,
        'background-image': `url(${backImg})`
      }
    },
    downStyle () {
      const arr = this.recordBtnSize.split(' ')
      const width = arr[0] || '184px'
      const height = arr[1] || '55px'
      const backImg = this.currentPage === this.totalPage ? this.btnStatusTwo : this.btnStatusOne
      return {
        width,
        height,
        'line-height': `${height}`,
        color: `${this.recordBtnColor}`,
        'background-image': `url(${backImg})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.recordModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.recordModelShow = true
    },
    upAndDown (type) {
      if (type === 1) {
        // 上一页
        if (this.currentPage === 1) {
          return
        }
        this.$emit('upAndDown', type)
      } else {
        // 下一页
        if (this.totalPage === this.currentPage) {
          return
        }
        this.$emit('upAndDown', type)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .record-model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .record-title{
    margin: 35px 0 10px;
  }
  .record-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    overflow-y: auto;
    padding: 0 60px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .record-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
     /* .summary{
        width: 250px;
      }*/
    }
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    .page-desc{
      margin: 0 20px;
    }
    .up-page,.down-page{
      color: #ffffff;
      font-size: 24px;
      font-weight: bold;
    }
    .btn-hover{
      cursor: pointer;
      &:hover{
        filter: brightness(120%)
      }
    }
  }
  .empty-record{
    height: 300px;
    line-height: 300px;
    font-weight: bold;
    font-size: 24px;
  }
</style>
