import _mixin from '../_mixin'

export default {
  name: 'STipModel',
  cnName: '提示弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    tipBgSize: '750px 450px',
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    tipBtnColor: '#FEEACB',
    tipBtnText: '关闭',
    tipBtnSize: '184px 55px'
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'tipBgSize',
      label: '提示弹窗背景大小'
    },
    {
      target: 'tipTitle',
      label: '提示标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    },
    {
      target: 'btnStatusOne',
      label: '提示按钮背景'
    },
    {
      target: 'tipBtnColor',
      label: '提示按钮颜色'
    },
    {
      target: 'tipBtnText',
      label: '提示按钮文案'
    },
    {
      target: 'tipBtnSize',
      label: '提示按钮大小'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
