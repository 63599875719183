<template>
  <div class="s-component" :style="{height: mainHeight, 'background-image': `url(${bg.src})`}">
    <div class="s-part-1" :id="part1Id" :style="{top: `${part1Top}`}">
      <div class="s-part-1-gift">
        <img :src="part1Info.icon" :style="{width: part1Obj[0].bgWidth, height: part1Obj[0].bgHeight}" alt="">
      </div>
      <div class="s-part-1-btn" @click="receiveAward(part1Info)">
        <img :class="['s-btn']" v-if="part1Info.status === 0" :src="receiveBtn0.src" :style="{width: part1Btn[0].btnWidth, height: part1Btn[0].btnHeight}" alt=""/>
        <img :class="['s-btn']" v-if="part1Info.status === 1" :src="receiveBtn1.src" :style="{width: part1Btn[0].btnWidth, height: part1Btn[0].btnHeight}" alt=""/>
        <img :class="['s-btn hv-highlight']" v-if="part1Info.status === 2" :src="receiveBtn2.src" :style="{width: part1Btn[0].btnWidth, height: part1Btn[0].btnHeight}" alt=""/>
        <img :class="['s-btn']" v-if="part1Info.status === 3" :src="receiveBtn3.src" :style="{width: part1Btn[0].btnWidth, height: part1Btn[0].btnHeight}" alt=""/>
        <img :class="['s-btn']" v-if="part1Info.status === 4" :src="receiveBtn4.src" :style="{width: part1Btn[0].btnWidth, height: part1Btn[0].btnHeight}" alt=""/>
<!--        <img :src="part1Btn[0].btnSrc" alt="">-->
      </div>
    </div>
    <div class="s-part-2" :id="part2Id" :style="{top: `${part2Top}`}">
      <div class="s-part-2-gift" :style="{'background-image': `url(${part2Obj[0].bgSrc})`, width: part2Obj[0].bgWidth, height: part2Obj[0].bgHeight}">
        <div class="s-part-2-time" :style="{color: textColorLevel3}">已游戏：<span :style="{color: miniBtnColor}">{{ sliderData.weeks }}</span>周</div>
        <div class="s-part-2-list" :style="{padding: part2Obj[0].padding}">
          <div v-for="gift in part2Info" :key="gift.id" class="s-part-2-item">
            <img :src="gift.icon" :style="{width: part2Obj[0].itemWidth, height: part2Obj[0].itemHeight}" alt="">
            <div class="s-part-2-btn" @click="receiveWeekAward(gift)">
              <img :class="['s-btn']" v-if="gift.status === 0" :src="receiveBtn0.src" :style="{width: part2Btn[0].btnWidth, height: part2Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 1" :src="receiveBtn1.src" :style="{width: part2Btn[0].btnWidth, height: part2Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn hv-highlight']" v-if="gift.status === 2" :src="receiveBtn2.src" :style="{width: part2Btn[0].btnWidth, height: part2Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 3" :src="receiveBtn3.src" :style="{width: part2Btn[0].btnWidth, height: part2Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 4" :src="receiveBtn4.src" :style="{width: part2Btn[0].btnWidth, height: part2Btn[0].btnHeight}" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-part-3" :id="part3Id" :style="{top: `${part3Top}`}">
      <div class="s-part-3-gift" :style="{'background-image': `url(${part3Obj[0].bgSrc})`, width: part3Obj[0].bgWidth, height: part3Obj[0].bgHeight}">
        <div class="s-part-3-list" :style="{padding: part3Obj[0].padding}">
          <div v-for="gift in part3Info" :key="gift.id" class="s-part-3-item">
            <img :src="gift.icon" :style="{width: part3Obj[0].itemWidth, height: part3Obj[0].itemHeight}" alt="">
            <div class="s-part-3-btn" @click="receiveAllChannelExtraInfo(gift)">
              <img :class="['s-btn']" v-if="gift.status === 0" :src="receiveBtn0.src" :style="{width: part3Btn[0].btnWidth, height: part3Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 1" :src="receiveBtn1.src" :style="{width: part3Btn[0].btnWidth, height: part3Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn hv-highlight']" v-if="gift.status === 2" :src="receiveBtn2.src" :style="{width: part3Btn[0].btnWidth, height: part3Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 3" :src="receiveBtn3.src" :style="{width: part3Btn[0].btnWidth, height: part3Btn[0].btnHeight}" alt=""/>
              <img :class="['s-btn']" v-if="gift.status === 4" :src="receiveBtn4.src" :style="{width: part3Btn[0].btnWidth, height: part3Btn[0].btnHeight}" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-part-4" :id="part4Id" :style="{top: `${part4Top}`}">
      <div class="top" :style="{...part4RecordStyle}">
        <div class="mini-color-btn hv-highlight" @click="openLog(4)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">抽奖记录</div>
      </div>
      <div class="part4-box">
        <div class="swiper-box" :style="{padding: part4Obj[0].padding,width: part4Obj[0].bgWidth, height: part4Obj[0].bgHeight, 'line-height': part4Obj[0].bgHeight, 'background-image': `url(${part4Obj[0].bgSrc})`}">
          <swiper :options="swiperOption" ref="swiper" class="swiper">
            <swiper-slide v-for="item in lotteryAct.award.groups" :key="item.id" class="part4-item" :style="{width: part4Obj[0].itemWidth, height: part4Obj[0].itemHeight}">
              <img :src="item.icon" alt="" class="part4-img"/>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev swiper_btn_l" :style="{width: part4Obj[0].arrowWidth, height: part4Obj[0].arrowHeight, 'background-image': `url(${part4Obj[0].leftSrc})`}"></div>
          <div class="swiper-button-next swiper_btn_r" :style="{width: part4Obj[0].arrowWidth, height: part4Obj[0].arrowHeight, 'background-image': `url(${part4Obj[0].rightSrc})`}"></div>
        </div>
        <div class="part4-get-more iphone-text">
          <div :style="{'background-image': `url(${part4Btn[0].textBgSrc})`, color: `${part4Btn[0].textColor}`}">{{getLimitTimes(lotteryAct.total)}}</div>
        </div>
        <div class="part4-btn" :style="{width: part4Btn[0].btnWidth, height: part4Btn[0].btnHeight, 'background-image': `url(${part4Btn[0].bgSrc})`}" @click="reciveLotteryInfo">
          <div class="part4-btn-box" :style="{color: part4Btn[0].btnTextColor}">
            <div class="part4-btn-chance iphone-text">可用机会：{{ lotteryAct.available }}次</div>
            <div class="part4-btn-use-chance iphone-text">累计已抽：{{ lotteryAct.total }}次</div>
          </div>
        </div>
      </div>
    </div>
    <ModalReceive ref="ModalReceive"/>
    <ModalConfirm ref="ModalConfirm"/>
    <ModalReceiveLog ref="ModalReceiveLog"/>
<!--    <ModalPointLog ref="ModalPointLog"/>-->
<!--    <ModalAddress ref="ModalAddress"/>-->
    <AllChannelSliderBar ref="AllChannelSliderBar"/>
    <slot></slot>
  </div>
</template>

<script>
import ModalReceive from '../_components/ModalReceive.vue'
import ModalConfirm from '../_components/ModalConfirm.vue'
import ModalReceiveLog from '../_components/ModalReceiveLog.vue'
// import ModalPointLog from '../_components/ModalPointLog.vue'
// import ModalAddress from '../_components/ModalAddress.vue'
import AllChannelSliderBar from './_components/AllChannelSliderBar.vue'
// import SButtonShare from "@/components/BuilderComponents/ButtonShare.vue";

export default {
  name: 'SAllChannelActivity',
  components: {
    // SButtonShare,
    AllChannelSliderBar,
    ModalReceiveLog,
    // ModalPointLog,
    ModalReceive,
    // ModalAddress
    ModalConfirm
  },
  props: {
    part1Id: {
      type: String,
      required: true
    },
    part2Id: {
      type: String,
      required: true
    },
    part3Id: {
      type: String,
      required: true
    },
    part4Id: {
      type: String,
      required: true
    },
    // 一键领取活动
    part1Top: {
      type: String,
      required: true
    },
    part1Obj: {
      type: Array,
      required: true,
      default: () => []
    },
    part1Btn: {
      type: Array,
      required: true,
      default: () => []
    },
    // 战斗领活动
    part2Top: {
      type: String,
      required: true
    },
    part2Obj: {
      type: Array,
      required: true,
      default: () => []
    },
    part2Btn: {
      type: Array,
      required: true,
      default: () => []
    },
    // 登录领活动
    part3Top: {
      type: String,
      required: true
    },
    part3Obj: {
      type: Array,
      required: true,
      default: () => []
    },
    part3Btn: {
      type: Array,
      required: true,
      default: () => []
    },
    // 免费抽活动
    part4Top: {
      type: String,
      required: true
    },
    // 免费抽活动
    part4Record: {
      type: String,
      default: '{}'
    },
    part4SpaceBetween: {
      type: String
    },
    part4SlidesPerView: {
      type: String
    },
    part4Obj: {
      type: Array,
      required: true,
      default: () => []
    },
    part4Btn: {
      type: Array,
      required: true,
      default: () => []
    },

    bg: {
      type: Object,
      required: true
    },
    mainHeight: {
      type: String,
      required: true
    },
    // ===== 任务 ID 配置 =====
    // 一键领
    activityId1: {
      type: String,
      default: ''
    },
    // 战斗领
    activityId2: {
      type: String,
      default: ''
    },
    // 额外福利
    activityId3: {
      type: String,
      default: ''
    },
    // 免费抽
    activityId4: {
      type: String,
      default: ''
    },
    // ===== text color =====
    textColorLevel1: {
      type: String,
      default: '#333'
    },
    textColorLevel2: {
      type: String,
      default: '#666'
    },
    textColorLevel3: {
      type: String,
      default: '#999'
    },
    textColorActive: {
      type: String,
      default: '#fff'
    },
    miniBtnColor: {
      type: String,
      default: '#608351'
    },

    // ===== 抽奖Btn =====
    // drawActiveItem: {
    //   type: String,
    //   default: ''
    // },
    drawBgColor: {
      type: String,
      default: '#FFBE57'
    },
    drawBgColorActive: {
      type: String,
      default: '#FFE8BB'
    },

    // ===== 登录领取Btn =====
    receiveBtn0: {
      type: Object,
      required: true
    },
    receiveBtn1: {
      type: Object,
      required: true
    },
    receiveBtn2: {
      type: Object,
      required: true
    },
    receiveBtn3: {
      type: Object,
      required: true
    },
    receiveBtn4: {
      type: Object,
      required: true
    },

    // ===== 侧边栏 =====
    sideBarBg: {
      type: Array,
      required: true,
      default: () => []
    },
    textColor: {
      type: String,
      default: '#FDEDC2'
    },
    textHoverColor: {
      type: String,
      default: '#FDEDC2'
    },
    sideBarNavText1: {
      type: String,
      default: '回归一键领'
    },
    sideBarNavText2: {
      type: String,
      default: '战斗免费领'
    },
    sideBarNavText3: {
      type: String,
      default: '豪礼天天领'
    },
    sideBarNavText4: {
      type: String,
      default: '大奖免费抽'
    },
    // ===== modal =====
    modalBg: {
      type: Object,
      default: () => {
      }
    },
    modalCloseIcon: {
      type: Object,
      default: () => {
      }
    },
    modalTitleStyle: {
      type: String,
      default: '{}'
    },
    modalTextStyle: {
      type: String,
      default: '{}'
    },
    modalConfirmBtnStyle: {
      type: String,
      default: '{}'
    },
    modalCancelBtnStyle: {
      type: String,
      default: '{}'
    }

  },
  data () {
    return {
      swiperOption: {
        slidesPerView: Number(this.part4SlidesPerView),
        spaceBetween: Number(this.part4SpaceBetween),
        autoplay: {
          disableOnInteraction: false,
          delay: 3000 // 3秒切换一次
        },
        // centeredSlides: true,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      wdFrom: '',
      from: '',
      part1Info: {
        id: '',
        icon: '',
        status: 0
      },
      sliderData: {},
      part2Info: [],
      part3Info: [],

      point: 0,
      pageInfo: {
        status: false
      },
      shopAct: {
        groups: []
      },
      // 登录活动
      loginAct: {
        groups: [{ status: 0 }]
      },
      lotteryAct: {
        award: {
          groups: []
        }
      },
      taskAct: {
        groups: []
      },
      lotteryTimes: 0
    }
  },
  provide () {
    return {
      $MainWrap: this
    }
  },

  inject: {
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    part4RecordStyle () {
      try {
        return JSON.parse(this.part4Record)
      } catch (e) {
        console.error('part4Record 格式错误')
        return {}
      }
    }
    // drawItemBgColorActive () {
    //   if (!this.drawBgColorActive) return
    //   const arr = this.drawBgColorActive.split(',')
    //   return arr.length > 1 ? `linear-gradient(${arr[0]}, ${arr[1]})` : this.drawBgColorActive
    // },
    // drawActiveItemIndexList () {
    //   if (!this.drawBgColorActive) return []
    //   return this.drawActiveItem.split(',').map(item => parseInt(item))
    // }
  },
  mounted () {
    const { from, wdFrom } = this.$route.query
    this.from = from
    this.wdFrom = wdFrom
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.init)
      // 登出操作添加
      this.eventBus.on('signOut', this.init)
    }
    this.init()
    // this.openLog(4)
    console.log(this.bg)
  },
  methods: {
    getLimitTimes (day) {
      const arr = {}
      this.lotteryAct.award.groups.forEach(item => {
        if (item.lottery_times > 0) {
          arr[item.lottery_times] = item.name
        }
      })
      if (day < 10) {
        return ('再抽' + (10 - day) + '次必得：' + arr[10])
      } else if (day >= 10 && day < 15) {
        return ('再抽' + (15 - day) + '次必得：' + arr[15])
      } else if (day >= 15 && day < 30) {
        return ('再抽' + (30 - day) + '次必得：' + arr[30])
      } else if (day >= 30 && day < 50) {
        return ('再抽' + (50 - day) + '次必得：' + arr[50])
      } else {
        return ''
      }
    },
    init () {
      this.getGift1Info()
      this.getGift2Info()
      this.getGift3Info()
      this.getGift4Info()
    },
    // 一键领活动
    getGift1Info () {
      this.requestAPI({
        url: '/v1/back/gift/info',
        method: 'get',
        params: { a_id: this.activityId1 }
      }).then(res => {
        console.log('----一键领取活动----', res)
        this.part1Info = {
          status: res.award.groups[0].status,
          icon: res.award.groups[0].icon,
          id: res.award.groups[0].id
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 战斗领活动
    getGift2Info () {
      this.requestAPI({
        url: '/v1/back/week/info',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('----战斗领取活动----', res)
        this.sliderData = {
          date: res.date, // 回流时间
          expire_left: res.expire_left, // 剩余天数
          weeks: res.weeks, // 累计周数
          hour: res.hour // 累计小时
        }
        this.part2Info = res.award.groups || []
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 额外福利活动
    getGift3Info () {
      this.requestAPI({
        url: '/v1/back/login/info',
        method: 'get',
        params: { a_id: this.activityId3 }
      }).then(res => {
        console.log('----额外福利活动----', res)
        this.part3Info = res.award.groups || []
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 获取抽奖任务
    getGift4Info () {
      this.requestAPI({
        url: '/v1/back/lottery/info',
        method: 'get',
        params: { a_id: this.activityId4 }
      }).then(res => {
        console.log('----抽奖活动----', res)
        this.lotteryAct = res
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 一键领取活动奖励
     * @param item
     */
    receiveAward (item) {
      console.log('---receiveAward---', item)
      // 判断登录
      if (!window.$checkLogin()) return

      if (item.status !== 2) return
      const params = {
        a_id: this.activityId1,
        g_id: item.id
      }
      return this.requestAPI({
        url: '/v1/back/gift/receive',
        method: 'get',
        params
      }).then((data) => {
        console.log(item.id)
        if (data.success) {
          // if (data.gift && data.gift.materials) {
          //   this.openReceiveModal(data.gift.materials)
          // } else {
          //   this.openReceiveModal(item.materials)
          // }
          this.openReceiveModal([
            {
              name: '',
              icon: 'https://rescdn.ssjj.cn/website/2024/07/29/dab64a3aa25d482c20763a2eed057ccd.png'
            }
          ])
          this.getGift1Info()
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 战斗领活动奖励
     * @param item
     */
    receiveWeekAward (item) {
      // 判断登录
      if (!window.$checkLogin()) return
      if (item.status !== 2) return
      const params = {
        a_id: this.activityId2,
        g_id: item.id
      }
      return this.requestAPI({
        url: '/v1/back/week/receive',
        method: 'get',
        params
      }).then((data) => {
        console.log(item.id)
        if (data.success) {
          if (data.gift && data.gift.materials) {
            this.openReceiveModal(data.gift.materials)
          } else {
            this.openReceiveModal(item.materials)
          }
          this.getGift2Info()
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 额外活动奖励
     * @param item
     */
    receiveAllChannelExtraInfo (item) {
      // 判断登录
      if (!window.$checkLogin()) return
      if (item.status !== 2) return
      const params = {
        a_id: this.activityId3,
        g_id: item.id
      }
      return this.requestAPI({
        url: '/v1/back/login/receive',
        method: 'get',
        params
      }).then((data) => {
        console.log(data, '--data---')
        if (data.success) {
          if (data.gift && data.gift.materials) {
            this.openReceiveModal(data.gift.materials)
          } else {
            this.openReceiveModal(item.materials)
          }
          this.getGift3Info()
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 免费抽活动奖励
     * @param item
     */
    reciveLotteryInfo () {
      if (!window.$checkLogin()) return
      if (!this.lotteryAct.available) {
        this.$message.error('抽奖次数不足')
        // this.$message.warnning('抽奖次数不足')
        // this._handleTips({msg: '您当前暂未获得抽奖次数'})
        return
      }
      this.requestAPI({
        url: '/v1/back/lottery/receive',
        method: 'get',
        params: { a_id: this.activityId4 }
      }).then(data => {
        console.log(data, '---data---')
        if (data.id) {
          this.openReceiveModal([
            {
              name: data.name,
              icon: data.icon
            }
          ])
        }
        this.getGift4Info()
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 打开领取弹窗
     * @param awardsList 奖励列表 [{name: '', icon: ''}]
     * @param showAddress 是否显示地址
     */
    openReceiveModal (awardsList, showAddress = false) {
      this.$refs.ModalReceive.open(awardsList, showAddress)
    },
    confirm (options) {
      this.$refs.ModalConfirm.open(options)
    },
    /**
     * 打开记录弹窗
     * @param type 2: 抽奖记录 3: 领取记录 4: 兑换记录
     */
    openLog (type) {
      if (!window.$checkLogin()) return
      const titleMap = {
        // 1: '购买记录',
        // 2: '抽奖记录',
        4: '抽奖记录'
      }
      const title = titleMap[type]
      // console.log('---openLog---', type, title)
      this.$refs.ModalReceiveLog.open({
        title,
        activityId: this[`activityId${type}`],
        needAddress: type === 3
      })
    },
    /**
     * 打开积分记录弹窗
     */
    // openPointLog () {
    //   if (!window.$checkLogin()) return
    //   const options = {
    //     activityId: this.activityId4
    //   }
    //   this.$refs.ModalPointLog.open(options)
    // },
    openAddress () {
      this.$refs.ModalAddress.open(this.activityId3)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  background-color: rgba(0, 0, 0, .1);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 5000px;
}
.mini-color-btn{
  text-decoration: underline;
  display: inline-block;
  width: 95px;
  height: 30px;
  border: 1PX solid;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.s-part-1, .s-part-2, .s-part-3, .s-part-4 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.s-part-1 {
  .s-part-1-btn, .s-part-1-gift {
    display: flex;
    justify-content: center;
  }
  .s-part-1-btn {
    margin-top: 20px;
  }
}
.s-part-2 {
  .s-part-2-gift {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding: 30px;
  }
  .s-part-2-time {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: HarmonyOS Sans SC;
    font-weight: 400;
    font-size: 32px;
  }
  .s-part-2-list {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .s-part-2-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.s-part-3 {
  .s-part-3-gift {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //position: absolute;
    margin: 0 auto;
    //left: 50%;
    //transform: translateX(-50%);
    padding: 20px;
  }
  .s-part-3-list {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .s-part-3-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
}
.s-part-4 {
  .top{
    position: absolute;
    z-index: 100;
    .mini-color-btn{
      margin: 0 10px;
    }
  }

  .part4-btn {
    width: 648px;
    height: 196px;
    margin: 0 auto;
    //background-image: url("https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/lottery-now.1686d3cb.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
    .part4-btn-box {
      position: absolute;
      bottom: 20px;
      width: 400px;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .swiper-box {
    position: relative;
    //background-image: url("https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/swiper-bg.6c9cdc6e.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    margin: 0 auto;
    align-items: center;
    .swiper_btn_r {
      position: absolute;
      width: 116px;
      height: 116px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      bottom: 50%;
      transform: translateY(-25%);
      right: -50px;
    }

    .swiper_btn_l {
      position: absolute;
      left: -50px;
      bottom: 50%;
      transform: translateY(-25%);
      width: 116px;
      height: 116px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .part4-box {
    //height: 820px;
    padding-top: 500px;
    margin: 0 auto;
    position: relative;
  }

  .part4-get-more {
    width: 420px;
    height: 32px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    margin: 40px auto;
    > div {
      position: absolute;
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-weight: 400;
      font-size: 21px;
      text-align: center;
    }
  }

  .part4-item {
    position: relative;

    .part4-img {
      width: 100%;
      height: 100%;
    }
    .part4-item-tip {
      position: absolute;
      top: 6px;
      right: 10px;
      font-weight: 400;
      font-size: 19px;
      color: #62231A;
    }

    .part4-name {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      color: #FFFFFF;
      height: 35px;
      line-height: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: normal;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .part4-gift-level {
      position: absolute;
      width: 100%;
      bottom: 34px;
      height: 5px;
      background: transparent;
    }
  }
}
</style>
