import _mixin from '../_mixin'

export default {
  name: 'STipComModel',
  cnName: '公共提示弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/66ff37c9751d3b7b50f31fa4a5097b04.png',
      style: {
        width: '750px',
        height: '450px'
      }
    },
    modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/9d4318b1f4af8a822f0bda055005e4c7.png',
      style: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '-20px',
        right: '-30px',
        left: '-',
        bottom: '_',
        cursor: 'pointer'
      }
    },
    modelColor: '#C09248',
    tipTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/0791485f27905518fbcfa8c0cf09a5f0.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    // 关闭按钮
    btnClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/ff207bb79bb6dbb97c47300b993283bd.png',
      style: {
        width: '181px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'modelClose',
      label: '关闭icon',
      formType: 'image'
    },
    {
      target: 'modelColor',
      label: '弹窗文案颜色'
    },
    {
      target: 'tipTitle',
      label: '提示标题',
      formType: 'image'
    },
    {
      target: 'btnClose',
      label: '提示关闭按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
