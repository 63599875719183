import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopic2Empty',
  cnName: '公共空白',
  type: 'activity_topic2',
  preview: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/fifth-bg.9ef1d6a2.png',
  // 属性相关
  props: {
    ..._mixinWrap.props
  },
  propsDesc: [
    ..._mixinWrap.propsDesc
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
