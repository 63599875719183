<template>
  <a v-if="computedLink"
     :href="computedLink"
     target="_blank"
     class="ss-button"
     v-show="visible"
     :style="style"
     @mouseover="isHover = true"
     @mouseout="isHover = false">
    {{ text }}
  </a>
  <div v-else
       class="ss-button"
       v-show="visible"
       :style="style"
       @click="handleClick"
       @mouseover="isHover = true"
       @mouseout="isHover = false">
    {{ text }}
  </div>

</template>

<script>
export default {
  name: 'SButton',
  props: {
    defaultBg: {
      type: String,
      default: ''
    },
    hoverBg: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '200px 150px' // 宽高
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    channelLinks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  computed: {
    style () {
      const arr = this.size.split(' ')
      return {
        width: arr[0] || '200px',
        height: arr[1] || '50px',
        'background-image': `url(${this.isHover ? (this.hoverBg || this.defaultBg) : this.defaultBg})`
      }
    },
    computedLink () {
      let wdFromStr
      const { wdFrom, from } = this.$route.query
      if (wdFrom) {
        wdFromStr = wdFrom
      } else if (from) {
        wdFromStr = 'lenovo'
      } else {
        wdFromStr = ''
      }
      if (wdFromStr) {
        const channel = this.channelLinks.filter(item => item.value === wdFromStr)[0]
        console.log('> wdFrom:', this.channelLinks)
        console.log('> channel:', channel)
        return channel ? channel.link : this.link
      } else {
        return this.link
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      if (this.$listeners.click) {
        this.$emit('click')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: .3s;
  text-decoration: none;
  color: inherit;
  user-select: none;
  &:hover{
    filter: brightness(110%)
  }
}
</style>
