<template>
  <STopic2Wrapper v-bind="$attrs" v-show="visible">
    <div class="s-components">
      <div class="s-box">
        <div class="s-conner-group">
          <i class="s-conner-top-left"></i>
          <i class="s-conner-top-right"></i>
          <i class="s-conner-bottom-left"></i>
          <i class="s-conner-bottom-right"></i>
        </div>
        <div class="s-box-content">
          <ul class="s-left">
            <li v-for="(item,index) in list" :key="item.label" :class="{active: curIndex === index}" @click="curIndex = index">
              <img :src="item.thumb" alt="">
              <p>{{item.label}}</p>
            </li>
          </ul>
          <div class="s-right">
            <div class="s-media-wrap">
              <video v-if="curItem.video" :src="curItem.video" muted autoplay loop></video>
              <img v-else :src="curItem.img" alt="">
              <div class="s-play-btn" v-if="curItem.video">
                <i @click="showVideo" class="el-icon-video-play"></i>
              </div>
            </div>
            <p class="s-label">{{curItem.label}}</p>
            <div class="s-pve-award-btn">{{ curItem.type }}</div>
<!--            <div class="s-mini-title">{{ curItem.desTitle }} </div>-->
            <p class="s-pve-desc">{{curItem.desc}}</p>
          </div>
        </div>
      </div>
    </div>
    <s-dialog :visible.sync="videoVisible" v-if="videoVisible" class="s-video-dialog" background-color="transparent">
      <video class="s-video--main" controls autoplay :src="curItem.video"></video>
    </s-dialog>
  </STopic2Wrapper>
</template>

<script>
export default {
  name: 'STopic2PveAward',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    prevImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png'
    },
    nextImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/33b1279f41a2b1a9d796e076f996d306.png'
    },
    thumbItemStyle: {
      type: String,
      default: '{}'
    },
    thumbWrapStyle: {
      type: String,
      default: '{}'
    }
  },
  data () {
    return {
      visible: true,
      curIndex: 0,
      videoVisible: false
    }
  },
  computed: {
    curItem () {
      return this.list[this.curIndex] || {}
    },
    thumbWrapStyleObj () {
      try {
        return JSON.parse(this.thumbWrapStyle)
      } catch (e) {
        console.error('thumbWrapStyle 格式错误')
        return {}
      }
    },
    thumbItemStyleObj () {
      try {
        return JSON.parse(this.thumbItemStyle)
      } catch (e) {
        console.error('thumbItemStyle 格式错误')
        return {}
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    showVideo () {
      this.videoVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.s-components {
  position: relative;
  width: 1920px;
  height: 1000px;
  padding-top: 290px;
  .s-box{
    position: relative;
    margin: 0 auto;
    width: 1220px;
    padding: 20px;
    background-color: rgba(0,0,0,.5);
    .s-conner-group{
      i{
        position: absolute;
        width: 20px;
        height: 20px;
      }
      .s-conner-top-left{
        top: 0;
        left: 0;
        border-top: 3px solid #996DFF;
        border-left: 3px solid #996DFF;
      }
      .s-conner-top-right{
        top: 0;
        right: 0;
        border-top: 3px solid #996DFF;
        border-right: 3px solid #996DFF;
      }
      .s-conner-bottom-left{
        bottom: 0;
        left: 0;
        border-bottom: 3px solid #996DFF;
        border-left: 3px solid #996DFF;
      }
      .s-conner-bottom-right{
        bottom: 0;
        right: 0;
        border-bottom: 3px solid #996DFF;
        border-right: 3px solid #996DFF;
      }
    }
    .s-box-content{
      display: flex;
      height: 530px;
      .s-left{
        padding: 20px 0 0;
        width: 800px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li{
          margin:0 35px 20px;
          cursor: pointer;
          img{
            display: block;
            width: 190px;
            height: 190px;
            border: 1px solid transparent;
            object-fit: cover;
          }
          p{
            margin-top: 10px;
            font-size: 20px;
            text-align: center;
            line-height: 1;
            color: #fff;
          }
          &.active{
            img{
              border: 1px solid #996DFF;
            }
          }
        }
      }
      .s-right{
        border-left: 1PX solid rgba(144,144,144,.5);
        flex: 1;
        padding: 20px 30px;
        color: #fff;
        .s-media-wrap{
          position: relative;
          width: 230px;
          height: 230px;
          margin: 0 auto ;
          .s-play-btn{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            transition: .3s;
            font-size: 60px;
            color: #996DFF;
            opacity: 0;
            background-color: rgba(0,0,0,.5);
            &:hover{
              opacity: 1;
            }
          }
        }
        img,
        video{
          background-color: #000;
          width: 230px;
          height: 230px;
          object-fit: cover;
          display: block;
          box-shadow: 0 0 10px #996DFF;
          border-radius: 4px;
        }
        .s-label{
          text-align: center;
          font-size: 24px;
          margin-top: 20px;
        }
        .s-pve-award-btn{
          display: block;
          width: 185px;
          height: 52px;
          text-align: center;
          margin: 30px auto 0;
          border: 1PX solid #BD1317;
          background-color: rgba(189, 20, 24,.2);
          font-size: 20px;
          line-height: 50px;
        }
        .s-mini-title{
          font-size: 25px;
          margin-top: 30px;
          font-weight: 700;
          i{
            margin-left: 10px;
            color: #996DFF;
            cursor: pointer;
          }
        }
        .s-pve-desc{
          margin-top: 20px;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}
.s-video-dialog{
  .s-video--main{
    width: 100%;
    display: block;
    object-fit: contain;
  }
}
</style>
<style lang="scss">
</style>
