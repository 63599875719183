import _ from 'lodash'

/**
 * 深拷贝
 * @param data
 * @returns {{}|any}
 */
export function deepClone (data) {
  return _.cloneDeep(data)
}

/**
 * 导出JSON文件
 * @param data
 * @param filename
 */
export function saveJSON (data, filename) {
  if (!data) {
    alert('保存的数据为空')
    return
  }
  if (!filename) filename = 'json.json'
  if (typeof data === 'object') {
    data = JSON.stringify(data, undefined, 4)
  }
  const blob = new Blob([data], { type: 'text/json' })
  const e = document.createEvent('MouseEvents')
  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
  e.initMouseEvent(
    'click',
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  a.dispatchEvent(e)
}

/**
 * 获取本地存储空间
 * @param type
 * @returns {number}
 */
export function getCacheSize (type) {
  let obj = ''
  if (type === 'localStorage') {
    if (!window.localStorage) {
      console.log('浏览器不支持localStorage')
    } else {
      obj = window.localStorage
    }
  } else {
    if (!window.sessionStorage) {
      console.log('浏览器不支持sessionStorage')
    } else {
      obj = window.sessionStorage
    }
  }
  let size = 0

  if (obj !== '') {
    for (const item in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(item)) {
        size += obj.getItem(item).length
      }
    }
  }
  return size
}

/**
 * 随机字符串
 * @param length
 * @returns {string}
 */
export function randomString (length = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'
  const maxPos = chars.length
  let s = ''
  for (let i = 0; i < length; i++) {
    s += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return s
}

/**
 * 转换字符串中的px为vw
 * @param value {string} 要转换的字符串
 * @param designSize {number} 设计稿尺寸 目标尺寸
 * @returns {string}
 */
export function px2vw (value, designSize) {
  if (!value) return value
  // ==== 转换目标 ===
  // '100px'
  // '10px auto'
  // '10px 0 10px'
  // '10px 5px 15px 20px'
  // '10px _ _ 20px'
  // ==== 不转换 =====
  // '20PX'
  // '20Px'
  // '再往左边移20px'
  let newValue = ''
  const arr = value.split(' ')
  const newArr = arr.map(item => {
    const num = Number(item.split('px')[0])
    if (!isNaN(num)) { // 如果px前的字符是数字
      item = (num / designSize) + 'vw'
    }
    return item
  })
  newValue = newArr.join(' ')
  console.log(newValue)
  return newValue
}

const BASE_TITLE = 'BattleTeams2'
export function setTitle (title) {
  // title = title ? title + ' - ' + BASE_TITLE : BASE_TITLE
  if (title) window.document.title = title
}
export function setKeywords (keywords) {
  keywords = keywords || BASE_TITLE
  window.document.querySelector('meta[name=keywords]').setAttribute('content', keywords)
}
export function setDescription (description) {
  description = description || BASE_TITLE
  window.document.querySelector('meta[name=description]').setAttribute('content', description)
}

/**
 * 链接是否是图片
 * @param url
 * @returns {boolean}
 */
export function isImage (url) {
  return /\w.(png|jpg|jpeg|svg|webp|gif|bmp)$/i.test(url)
}

/**
 * 链接是否是图片
 * @param url
 * @returns {boolean}
 */
export function isVideo (url) {
  return /\w.(mp4|avi|mov|wmv|flv|f4v|webm)$/i.test(url)
}
