import _mixin from '../_mixin'

export default {
  name: 'SRecordModel',
  cnName: '记录弹窗',
  draggable: true,
  // 属性相关
  props: {
    // 弹窗公共属性
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    modelColor: '#AD272D',
    // 记录内部属性
    recordBgSize: '750px 450px',
    recordTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/0f6b622310d181295f0412ecb3caf20d.png',
    recordTitleSize: '419px 29px',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    btnStatusTwo: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/33939d997b271886355ee5c40a4dc885.png',
    recordBtnColor: '#FEEACB',
    recordBtnSize: '184px 55px'
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'recordBgSize',
      label: '记录弹窗背景大小'
    },
    {
      target: 'recordTitle',
      label: '记录弹窗标题'
    },
    {
      target: 'recordTitleSize',
      label: '记录弹窗标题尺寸',
      description: '宽 高'
    },
    {
      target: 'btnStatusOne',
      label: '按钮背景1'
    },
    {
      target: 'btnStatusTwo',
      label: '按钮背景2'
    },
    {
      target: 'recordBtnColor',
      label: '按钮颜色'
    },
    {
      target: 'recordBtnSize',
      label: '按钮大小'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
