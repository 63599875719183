<template>
  <div class="s-new-weapon img-bg" :style="defaultStyle">
    <img v-if="titleImg.src" :src="titleImg.src" alt="" :style="titleImg.style"/>
    <div class="content-box img-bg" :style="contentStyle">
      <div class="role" :style="singleImgShow.style">
        <img :src="singleImgShow.src" alt="" :style="singleImgShow.style"/>
      </div>
      <div class="gun-wrap">
        <swiper :ref="uid" :options="swiperOption" class="list" :key='swiperKey'>
          <swiper-slide v-for="(item, index) in swiperImgList" :key="index">
            <div class="list-item">
              <img :src="item.src" :style="swiperImgStyle">
            </div>
          </swiper-slide>
        </swiper>
        <div class="s-swiper-pagination">
          <img
            v-for="(item, index) in swiperImgList" :key="index"
            class="page-weapon"
            :src="currentIndex === index ? item.thumbActive : item.thumbDefault" alt=""
            :style="swiperThumbImgStyle"
            @click="changeCurrent(index)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'STopicTurntableWeapon',
  props: {
    bg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    titleImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    contentBg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    singleImgShow: {
      type: Object,
      default: () => {
        return {}
      }
    },
    singleImgPosition: {
      type: String,
      default: ''
    },
    swiperPosition: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    swiperImgSize: {
      type: String,
      default: '950px 540px'
    },
    swiperThumbImgSize: {
      type: String,
      default: '130px 120px'
    },
    swiperImgList: {
      type: Array,
      default: () => []
    },
    swiperPaginationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uid: '',
      swiper: null,
      currentIndex: 0,
      swiperKey: Math.random().toString(36).substr(2),
      swiperOption: {
        slidesPerView: 'auto',
        autoplay: this.autoplay,
        oop: true,
        navigation: {
          nextEl: `swiper-prev--${this.uid}`,
          prevEl: `swiper-next--${this.uid}`
        }
      }
    }
  },
  watch: {
    autoplay: {
      handler: function (newVale) {
        this.swiperOption.autoplay = newVale
        this.swiperKey = Math.random().toString(36).substr(2)
        if (newVale) {
          this.$nextTick(() => {
            this.swiper = this.$refs[this.uid].swiper
            this.$refs[this.uid].swiper.on('slideChange', () => {
              this.currentIndex = this.swiper.realIndex
            })
          })
        } else {
          this.currentIndex = 0
        }
      }
    }
  },
  mounted () {
    this.uid = Date.now()
    this.$nextTick(() => {
      this.swiper = this.$refs[this.uid].swiper
      this.$refs[this.uid].swiper.on('slideChange', () => {
        this.currentIndex = this.swiper.realIndex
      })
    })
  },
  computed: {
    defaultStyle () {
      return {
        'background-image': `url(${this.bg.src})`,
        ...this.bg.style
      }
    },
    titleStyle () {
      const arr = this.titleSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    contentStyle () {
      return {
        'background-image': `url(${this.contentBg.src})`,
        ...this.contentBg.style
      }
    },
    // 图片样式
    swiperImgStyle () {
      const arr = this.swiperImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    // 缩略图图片样式
    swiperThumbImgStyle () {
      const arr = this.swiperThumbImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    }
  },
  methods: {
    // swiper切换
    changeCurrent (index) {
      this.swiper && this.swiper.slideTo(index)
    }
  }
}
</script>
<style lang="scss" scoped>
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.lay{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}
.s-new-weapon {
  position: relative;
  width: 1920px;
  height: 1001px;
  .content-box{
    position: relative;
    width: 1416px;
    height: 542px;
    margin: 60px auto 0;
    .role{
      position: absolute;
      top: -90px;
      left: 100px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .gun-wrap{
      position: absolute;
      top: 0;
      right: 0;
      //background-color: #C7D9FD;
      width: 880px;
      height: 540px;
      .list-item{
        img{
          object-fit: contain;
        }
      }
      .s-swiper-pagination{
        position: absolute;
        bottom: -65px;
        padding: 0 50px;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .page-weapon{
          cursor: pointer;
        }
      }
    }
  }
}
</style>
