<template>
  <div class="s-video-wrap">
    <div class="s-video" v-show="visible">
      <img class="cover" :src="coverImg" alt=""
           @click="handleClick"/>
      <video class="s-video--mini" :src="miniVideo" autoplay playsinline muted></video>
    </div>
    <s-dialog :visible.sync="videoVisible" v-if="videoVisible" width="1200px" background-color="transparent">
      <video class="s-video--main" controls autoplay playsinline :src="mainVideo"></video>
    </s-dialog>
  </div>

</template>

<script>
import Dialog from './Dialog.vue'
export default {
  name: 'SVideo',
  components: {
    [Dialog.name]: Dialog
  },
  props: {
    coverImg: {
      type: String,
      default: ''
    },
    miniVideo: {
      type: String,
      default: ''
    },
    mainVideo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true,
      videoVisible: false
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      this.videoVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.s-video-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;

  .s-video {
    position: relative;
    width: 100%;
    height: 100%;
    .cover {
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
      transition: .3s;
      z-index: 1;
    }
    .s-video--mini{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover {
      .cover {
        opacity: 1;
      }
    }
  }

  .s-video--main{
    width: 1200px;
    display: block;
  }

}

</style>
