import _mixin from './_mixin'
export default {
  name: 'SPendant',
  cnName: '挂件',
  type: 'base',
  // 属性相关
  props: {
    src: 'https://rescdn.ssjj.cn/website/2022/09/09/f75e3f236d4988a2ef97b56a26101226.png',
    link: ''
  },
  propsDesc: [{
    target: 'src',
    label: '图片地址'
  }, {
    target: 'link',
    label: '跳链'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '221px',
    position: 'fixed',
    right: '0',
    top: '200px',
    'z-index': 99
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
