<template>
  <div class="page-render" :style="{zoom: zoom}">
    <div class="full-wrap">
      <ComponentRender v-for="item in data"
                       :component="item"
                       :key="item.id"
                       :is-edit="isEdit"
                       :is-choose="isChoose"
                       :current-id="currentId"/>
    </div>
  </div>
</template>

<script>
import ComponentRender from './ComponentRender.vue'
import eventBus from './eventBus'
export default {
  name: 'PageRender',
  components: { ComponentRender },
  props: {
    tokenName: {
      type: String,
      default: 'activeToken'
    },
    data: {
      type: Array,
      required: true,
      default: () => [
      ]
    },
    request: {
      type: Function,
      required: true
    },
    autoZoom: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isChoose: {
      type: Boolean,
      default: false
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  computed: {
  },
  data () {
    return {
      zoom: 1
    }
  },
  provide () {
    return {
      tokenName: this.tokenName,
      eventBus
    }
  },
  created () {
    this.autoZoom && this.initZoom()
    window.$request = this.request
  },
  methods: {
    initZoom () {
      const zoom = () => {
        const designW = 1920
        const clientW = document.body.clientWidth
        this.zoom = clientW / designW
      }
      const debounce = (fn, delay) => {
        let timer
        return function () {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            fn()
          }, delay)
        }
      }
      const debounceZoom = debounce(zoom, 100)
      zoom()
      window.addEventListener('resize', debounceZoom)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-render{
  transition: .3s;
  .full-wrap{
    overflow-x: hidden;
  }
  //overflow: auto;
  //position: absolute;
  //width: 100%;
  //height: 100%;
  //left: 0;
  //right: 0;
}
</style>
