<template>
  <div class="base-image-list" v-show="visible">
    <div v-for="(item,index) in list" :key="index" class="base-image-list-item">
      <img :style="{width: imgWidth,height: imgHeight}" :src="item.src" alt="" />
      <p class="text">{{item.label}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImageList',
  props: {
    imgWidth: {
      type: String,
      default: 'auto'
    },
    imgHeight: {
      type: String,
      default: 'auto'
    },
    list: {
      type: Array,
      required: true,
      default: () => [
        // {label: 'xxx', src: 'xxx'}
      ]
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.base-image-list{
  display: flex;
  justify-content: space-between;
  &-item{
    position: relative;
    img{
      display: block;
    }
    .text{
      position: absolute;
      bottom: 0;
      width: 100%;
      color: #fff;
      font-size: 40px;
      text-align: center;
      line-height: 80px;
    }
  }
}
</style>
