import _mixin from './_mixin'

export default {
  name: 'SVideo',
  cnName: '视频',
  type: 'complex',
  // 属性相关
  props: {
    coverImg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/play.png?v=1668135036126',
    miniVideo: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/big.mp4?v=1666848470704',
    mainVideo: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/big.mp4?v=1666848470704'
  },
  propsDesc: [
    {
      target: 'coverImg',
      label: '按钮图片'
    },
    {
      target: 'miniVideo',
      label: '缩略视频链接'
    },
    {
      target: 'mainVideo',
      label: '主视频链接'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '500px',
    height: '300px'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
