<template>
  <STopic2Wrapper v-bind="$attrs">
    <div class="s-components" v-show="visible" >
      <template v-for="(item,index) in list" >
        <div class="info" :class="{active:curIndex === index}" :key="index">
            <slot :name="index + ''">
            </slot>
        </div>
      </template>
      <div class="carousel-wrap">
        <div class="carousel-wrap-inner">
          <el-carousel ref="carousel" class="s-topic2-weapon-carousel carousel"
                       indicator-position="none"
                       :autoplay="autoplay"
                       :interval="5000"
                       arrow="never"
                       @change="onChange"
          >
            <el-carousel-item class="item" v-for="(item,index) in list" :key="item.label + index">
              <img class="img" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel>
          <img class="btn-prev" @click="toggle('prev')" :src="prevImg" alt=""/>
          <img class="btn-next" @click="toggle('next')" :src="nextImg" alt=""/>
        </div>
        <div class="label-list">
          <div class="content">
            <div class="item" v-for="(item,index) in list" :key="index"
                 :class="{active: curIndex === index}"
                 @click="changeTab(index)"
                 @mouseenter="changeTab(index)"
                 :style="curIndex === index ? thumbItemActiveStyleObj : thumbItemStyleObj"
            >
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </STopic2Wrapper>
</template>

<script>
export default {
  name: 'STopic2Weapon',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    prevImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png'
    },
    nextImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/33b1279f41a2b1a9d796e076f996d306.png'
    },
    thumbItemStyle: {
      type: String,
      default: '{}'
    },
    thumbItemActiveStyle: {
      type: String,
      default: '{}'
    }
  },
  computed: {
    thumbItemStyleObj () {
      try {
        return JSON.parse(this.thumbItemStyle)
      } catch (e) {
        console.error('thumbItemStyle 格式错误')
        return {}
      }
    },
    thumbItemActiveStyleObj () {
      try {
        return JSON.parse(this.thumbItemActiveStyle)
      } catch (e) {
        console.error('thumbItemActiveStyle 格式错误')
        return {}
      }
    }
  },
  data () {
    return {
      visible: true,
      curIndex: 0
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    onChange (index) {
      this.curIndex = index
    },
    toggle (type) {
      if (type === 'prev') {
        this.$refs.carousel.prev()
      } else {
        this.$refs.carousel.next()
      }
    },
    changeTab (index) {
      this.$refs.carousel.setActiveItem(index)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-components{
  position: relative;
  width: 1920px;
  height: 1000px;
  .info{
    position: relative;
    z-index: 1;
    display: none;
    &.active{
      display: block;
    }
  }
  .carousel-wrap{
    position: absolute;
    top: 310px;
    left: 50%;
    transform: translateX(-50%);
    .carousel-wrap-inner{
      position: relative;
      .carousel{
        margin: 0 auto;
        width: 100%;
        height: 410px;
        overflow: hidden;
        .item{
          width: 100%;
          height: 100%;
          .img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .btn-prev,
      .btn-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: .3s;
        width: 67px;
        height: 67px;
        object-fit: contain;
        opacity: .5;
        &:hover{
          opacity:1;
        }
      }
      .btn-prev{
        left: -100px;
      }
      .btn-next{
        right: -100px;
      }
    }

    .label-list{
      display: flex;
      margin: 80px auto 0;
      text-align: center;
      justify-content: center;
      color: #fff;
      .content{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item{
        width: 250px;
        height: 80px;
        margin: 0 10px;
        line-height: 80px;
        font-size: 25px;
        border: 1px solid #969696;
        background-color: rgba(103, 103, 103,.2);
        transition: .3s;
        cursor: pointer;
        &.active,
        &:hover{
          background-color: rgba(189, 20, 24,.2);
          border: 1px solid #BD1418;
          //font-weight: 700;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.s-topic2-weapon-carousel{
  .el-carousel__container{
    width: 100%;
    height: 100%;
  }
}
</style>
