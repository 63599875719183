<!--海外登录组件-->
<template>
  <div class="s-login-oversea">
    <div class="user-info" v-if="!$route.query.hideLogin">
      <div class="btn-sign hv-highlight" v-if="!activeToken" @click="openLogin">{{signInText}}</div>
      <div class="user-wrap" :style="{color: color}" v-else>
        <span class="username">{{ activeName }}</span>
        <span class="sign-out cursor-pointer" @click="signOut">{{ signOutText }}</span>
      </div>
    </div>
  </div>
</template>

<script>

const DEPLOY_ENV = process.env.VUE_APP_DEPLOY_ENV
const fromMap = {
  devVn: 'Website_401001001',
  testVn: 'Website_401001001',
  Vn: 'Website_401001001',
  devEurope: 'Website_301001003',
  testEurope: 'Website_301001003',
  Europe: 'Website_301001003'
}
const defaultFrom = fromMap[DEPLOY_ENV]
// 用户中心
export const centerLinkMap = {
  develop: 'http://172.31.2.176:63023/',
  development: 'http://172.31.2.176:63023/',

  devVn: 'https://u-test.truykichpc.vn/',
  devEurope: 'http://172.31.2.176:63023/',

  testEurope: 'http://172.31.2.176:63023/', // 内网-欧洲-测试环境
  // seaDev: 'http://172.31.2.176:63034/', // 内网-东南亚-测试环境
  // testEurope: 'https://u-test-azure.battleteams2.com/', // 外网测试环境
  testVn: 'https://u-test.truykichpc.vn/', // 外网测试环境

  vn: 'https://u.truykichpc.vn/',
  Europe: 'https://u-eu.battleteams2.com/'
}

const modeMap = {
  dev: 'dev',
  devVn: 'devSea',
  testVn: 'testSea',
  vn: 'Sea',
  devEurope: 'devEurope',
  testEurope: 'testEurope',
  Europe: 'Europe'
}
export default {
  name: 'SLoginVn',
  components: {},
  props: {
    tokenName: {
      type: String,
      default: 'activeToken'
    },
    signInText: {
      type: String,
      default: 'Sign In'
    },
    signOutText: {
      type: String,
      default: 'Sign Out'
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      activeToken: '',
      activeNick: '',
      activeAccount: '',
      activeWdId: '',
      activeName: '',
      iframe: null
    }
  },
  inject: {
    eventBus: {
      default: null
    }
  },
  watch: {},
  computed: {},
  created () {
    const { token } = this.$route.query
    if (token) {
      this.setUserByQueryToken(token)
    } else {
      this.setUserInfo()
    }
  },
  mounted () {
    this.initLoginCb()
    window.$checkLogin = this.checkLogin
  },
  methods: {
    /**
     * 通过query 中的 token 设置用户信息
     * @param token
     */
    setUserByQueryToken (token) {
      console.log('--- 通过地址栏设置token ---')
      const tokenStr = token.replace(/-/g, '+').replace(/_/g, '/').split('.')[1]
      const userInfo = JSON.parse(window.atob(tokenStr))
      console.log('--- 通过地址栏token 设置用户信息 ---')
      this.setUserInfo({
        ...userInfo.user,
        user_token: token
      })
      const newQuery = JSON.parse(JSON.stringify(this.$route.query))
      delete newQuery.token
      this.$router.replace({
        path: this.$route.path,
        query: newQuery
      })
    },
    initLoginCb () {
      window.thirdLoginCallback = (userData) => {
        console.log('----thirdLoginCallback----', userData)
        if (userData.code !== 0) {
          // Vue.closeIframe()
          // Vue.prototype.$tip(Vue.prototype.$t('loginFailTip'))
          console.log('Login Fail !')
        } else {
          this.activeToken = userData.data.token
          this.setUserInfo()
          // api.setAuthToken(userData.data.token)
          // Vue.closeIframe()
          // const device = JSON.parse(localStorage.getItem('device'))
          // Vue.getUser(device, token)
        }
        this.iframe.contentWindow.document.close()
      }
    },
    openLogin () {
      let url = 'https://u-sea.battleteams2.com'
      let retrunUrl = 'https://u-sea.battleteams2.com/thirdlogin/index.html?app_key=website&lng=en-us&platform=pc'
      // const retrunUrl = 'https://u.truykichpc.vn/thirdlogin/index.html?app_key=website&lng=en-us&platform=pc'
      const env = process.env.VUE_APP_DEPLOY_ENV
      const from = this.$route.query.from
      const share_code = this.$route.query.code
      const type = 'vtc'
      const parmas = 'height=720, width=960,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no'
      if (['devVn', 'testVn'].includes(env)) {
        url = 'https://u-test.truykichpc.vn'
        retrunUrl = 'https://u-test.truykichpc.vn/thirdlogin/index.html?app_key=website&lng=en-us&platform=pc'
      }
      const channelQuery = from ? `&from=${from}` : `&channel_id=${201}&sub_channel_id=${201001}&channel_ad_id=${201001002}`
      const shareQuery = share_code ? `&share_code=${share_code}` : ''
      const result = `${url}/v1/third/index?scene=website&type=vtc${channelQuery}${shareQuery}&return_url=${encodeURIComponent(retrunUrl)}`

      this.selfLoginWindows = this.selfLoginWindows || []
      this.selfLoginWindows.push(window.open(result, type, parmas))
      // 防止多次监听导致多次触发loginSuccess
      if (!this.messageListend) {
        window.addEventListener('message', (a) => {
          // console.log(a.data.token);
          if (a.data.token) {
            if (this.selfLoginWindows) {
              this.selfLoginWindows.forEach((i) => {
                i && i.close()
              })
            }
            this.selfLoginWindows = null

            this.$emit('loginSuccess', true)

            if (this.eventBus) {
              this.eventBus.fire('loginSuccess', a.data ? a.data.userData : {})
            }
            this.setUserInfo(a.data ? a.data.userData : {})
            window.removeEventListener('message', () => {
              console.log('事件结束')
            })
            this.messageListend = false
          }
        })
        this.messageListend = true
      }
    },
    // openLogin () {
    //   const url = 'https://u-sea.battleteams2.com'
    //   const returnUrl = 'https://rescdn.truykichpc.vn/client/forward/index.html?1=1'
    //   // if (['devVn', 'testVn'].includes(process.env.VUE_APP_DEPLOY_ENV)) {
    //   //   url = 'https://u-test.truykichpc.vn'
    //   //   returnUrl = 'https://u-test.truykichpc.vn/data/forward/index.html'
    //   // }
    //   const result = `${url}/v1/third/index?channel_id=${201}&sub_channel_id=${201001}&channel_ad_id=${201001002}&type=vtc&return_url=${encodeURIComponent(returnUrl)}`
    //   console.log('---result----', result)
    //   window.open(result, '_blank')
    //   // 开关打开
    //   // this.iframe = document.createElement('iframe')
    //   // this.iframe.id = 'thirdLoginIframe'
    //   // this.iframe.src = result
    //   // this.iframe.width = 960
    //   // this.iframe.height = 720
    //   // // this.iframe.border = 'no'
    //   // // this.iframe.frameBorder = 'no'
    //   // this.iframe.style = 'position: fixed; top: 100px; left: 50%; transform:translateX(-50%);  z-index: 999; background: #fff;'
    //   //
    //   // document.body.appendChild(this.iframe)
    //   // this.iframe.contentWindow.document.open()
    //   // iframe.contentWindow.document.close()
    //   // this.$iframe(result, {
    //   //   width: '960px',
    //   //   height: '720px'
    //   // })
    // },
    checkLogin () {
      if (this.activeToken) {
        return true
      } else {
        this.openLogin()
        return false
      }
    },
    signOut () {
      this.setUserInfo({
        account: '',
        nick: '',
        wd_id: '',
        user_token: ''
      })
      this.$emit('signOut', true)
      if (this.eventBus) {
        this.eventBus.fire('signOut')
      }
    },

    setUserInfo (info) {
      console.log('--- 设置用户信息 ---')
      if (info) {
        const { account, nick, wd_id, user_token } = info
        const name = nick || account || wd_id || '--'
        localStorage.setItem(this.tokenName, user_token)
        localStorage.setItem('activeNick', nick)
        localStorage.setItem('activeAccount', account)
        localStorage.setItem('activeWdId', wd_id)
        localStorage.setItem('activeName', name)

        this.activeToken = user_token
        this.activeNick = nick
        this.activeAccount = account
        this.activeWdId = wd_id
        this.activeName = name
      } else {
        this.activeToken = localStorage.getItem(this.tokenName) || ''
        this.activeName = localStorage.getItem('activeName') || ''
      }
    }
  },
  beforeDestroy () {}
}
</script>

<style lang="scss" scoped>
.s-login-oversea{
  .user-info{
    .btn-sign{}
    .user-wrap{
      .username{
        margin-right: 20px;
      }
    }
  }
}
.ss-dialog{
  .ss-close {
    color: #c8c9cc;
    font-size: 22px;
    cursor: pointer;
    text-align: right;
    position: relative;
    transition: .3s;
    z-index: 1;
    &:hover{
      color: #fff;
    }
    &.ss-close--out{
      position: absolute;
      right: -60px;
      top: -50px;
    }
  }
  .ss-close-content {
    width: 70px;
    height: 70px;
    text-align: center;
    vertical-align: center;
    font-size: 36PX;
    display: inline-block;
    top: 0;
    right: 0;
    position: absolute;
  }
  &::v-deep(.el-dialog){
    background-color: transparent;

    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0;
      word-break: break-word !important;
    }
  }
}
</style>
