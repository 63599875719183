<template>
  <div v-if="tipModelShow" class="lay">
    <div class="model" :style="modelStyle">
      <div class="close img-bg" :style="closeStyle" @click="close"></div>
      <img :src="tipTitle" alt="" class="title-model" :style="titleStyle"/>
      <div v-if="modelType===1" class="text-model">{{contentText}}</div>
      <div v-else class="text-model">确认是否以<span>{{buyInfo.price}}点券</span>购买【{{buyInfo.award[0].name}}】</div>
      <div v-if="modelType===1" class="btn-status btn-close img-bg" :style="btnStatusStyle" @click="close">{{tipBtnText}}</div>
      <div v-else class="btn-list">
        <div class="btn-status btn-cancel img-bg" :style="btnStatusStyle" @click="close">{{tipCancelText}}</div>
        <div class="btn-status btn-close img-bg" :style="btnStatusStyle" @click="confirmFun(buyInfo)">{{tipConfirmText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'STipModel',
  props: {
    // 公共弹窗属性
    modelShow: {
      type: Boolean,
      default: true
    },
    // 1: 默认打脸提示  2：二次确认购买提示
    modelType: {
      type: Number,
      default: 1
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    tipBgSize: {
      type: String,
      default: ''
    },
    // tip弹窗独有属性
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: '419px 29px' // 宽高
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    tipBtnColor: {
      type: String,
      default: ''
    },
    tipBtnText: {
      type: String,
      default: '关闭'
    },
    tipCancelText: {
      type: String,
      default: '取消'
    },
    tipConfirmText: {
      type: String,
      default: '确认'
    },
    tipBtnSize: {
      type: String,
      default: '192px 57px'
    },
    contentText: {
      type: String,
      default: ''
    },
    buyInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tipModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.tipModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.tipBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '450px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    titleStyle () {
      const arr = this.tipTitleSize.split(' ')
      const width = arr[0] || '358px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    btnStatusStyle () {
      const arr = this.tipBtnSize.split(' ')
      const width = arr[0] || '192px'
      const height = arr[1] || '57px'
      return {
        width,
        height,
        color: `${this.tipBtnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatusOne})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.tipModelShow = false
    },
    confirmFun (data) {
      // 确认购买
      this.$emit('update:modelShow', false)
      this.tipModelShow = false
      // 购买触发奖励弹窗
      this.$emit('openAwardModel', data)
    },
    open () {
      this.$emit('update:modelShow', true)
      this.tipModelShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .title-model{
    margin: 42px 0 20px;
  }
  .text-model{
    width: 80%;
    height: 240px;
    margin: 0 auto 20px;
    line-height: 250px;
  }
  .btn-list{
    display: flex;
    justify-content: space-around;
  }
  .btn-status{
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto;
    cursor: pointer;
  }
  .btn-close{
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
  .btn-cancel{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
</style>
