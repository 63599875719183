import _mixin from '../_mixin'

export default {
  name: 'SActivityThree',
  cnName: '(废弃)活动3-任意战斗奖励',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/d477c83a85b1452bf492736a8ee18b43.jpeg',
  // 属性相关
  props: {
    /* 首页配置 */
    activityId: '4MEG4QG376SOYR52XYGCMWB4CQ',
    // 背景
    defaultBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/707a8e879cb6233bf2af1276f8cd96cf.jpg',
      style: {
        width: '1920px',
        height: '966px'
      }
    },
    // 标题
    titleImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/ce9df3dbd53183bbfe3b0ca4760becb9.png',
      style: {
        width: '1006px',
        height: '163px',
        margin: '112px 0 0 0'
      }
    },
    // 日期
    dateList: [
      {
        dateImg: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/10bc45c37b719b48deba32da90c00e67.png',
        dateImgSize: '214px 30px'
      },
      {
        dateImg: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/65463378cc79b3418259d469aa6c0f8b.png',
        dateImgSize: '214px 30px'
      },
      {
        dateImg: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/c6cb971c1f491a1a3073c31880ff4771.png',
        dateImgSize: '214px 30px'
      }
    ],
    // 轮播奖励
    awardList: [
      {
        awardImgOne: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgTwo: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgSize: '697px 335px'
      },
      {
        awardImgOne: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgTwo: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgSize: '697px 335px'
      },
      {
        awardImgOne: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgTwo: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        awardImgSize: '697px 335px'
      }
    ],
    // 左切换
    swiperLeft: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/ecfb9b04955bf6f136f3e0f733a1d7e1.png',
      style: {
        width: '115px',
        height: '58px'
      }
    },
    // 右切换
    swiperRight: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/ea7f78ccc208b7c109865b3f0205e7ff.png',
      style: {
        width: '115px',
        height: '58px'
      }
    },
    // 未开始按钮
    btnNoBegin: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/a516d94a82e9ec8e2d544f91af5f8ca8.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 未完成按钮
    btnUnfinished: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 领取按钮
    btnReceive: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/1661320b553ba86d9cb5dff06f16eb87.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 已领取按钮
    btnReceived: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/5fc479087b6e5cba9e794fff0cc20956.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 已过期按钮
    btnExpire: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/4653df6c52ad2beaa34a8974a08acd35.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 活动规则
    ruleImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
      style: {
        width: '1200px',
        height: '100px'
      }
    },
    /* 公共弹窗配置 */
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/66ff37c9751d3b7b50f31fa4a5097b04.png',
      style: {
        width: '750px',
        height: '450px'
      }
    },
    modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/9d4318b1f4af8a822f0bda055005e4c7.png',
      style: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '-20px',
        right: '-30px',
        left: '-',
        bottom: '_',
        cursor: 'pointer'
      }
    },
    modelColor: '#C09248',
    /* tip弹窗配置 */
    tipTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/0791485f27905518fbcfa8c0cf09a5f0.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    // 关闭按钮
    btnClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/ff207bb79bb6dbb97c47300b993283bd.png',
      style: {
        width: '181px',
        height: '51px'
      }
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/eddd856a3fe7cf5bfaa08f333ad7fc5e.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    awardOtherDesc: '请前往邮箱查收奖励',
    // 奖励框的背景和大小配置
    awardBoxBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/208f60ae752f40ddbfeab59dd5b50fdf.png',
      style: {
        width: '151px',
        height: '126px'
      }
    },
    // 开心收下按钮
    btnHappy: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/fc38f8d82bb8022393c4fbfceaa21b6d.png',
      style: {
        width: '181px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    /* 首页配置 */
    {
      target: '首页配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'defaultBg',
      label: '默认背景',
      formType: 'image'
    },
    {
      target: 'titleImg',
      label: '标题图片',
      formType: 'image'
    },
    {
      target: 'dateList',
      label: '日期',
      formType: 'list',
      listConfig: [
        {
          title: '日期图片',
          target: 'dateImg'
        },
        {
          title: '日期图片尺寸',
          target: 'dateImgSize'
        }
      ]
    },
    {
      target: 'awardList',
      label: '奖励',
      formType: 'list',
      listConfig: [
        {
          title: '奖励图片1',
          target: 'awardImgOne'
        },
        {
          title: '奖励图片2',
          target: 'awardImgTwo'
        },
        {
          title: '奖励图片尺寸',
          target: 'awardImgSize'
        }
      ]
    },
    {
      target: 'swiperLeft',
      label: '左切换',
      formType: 'image'
    },
    {
      target: 'swiperRight',
      label: '右切换',
      formType: 'image'
    },
    {
      target: 'btnNoBegin',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'btnUnfinished',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'btnReceived',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'btnExpire',
      label: '已过期按钮',
      formType: 'image'
    },
    {
      target: 'ruleImg',
      label: '活动规则',
      formType: 'image'
    },

    /* 弹窗配置 */
    {
      target: '公共弹窗配置',
      formType: 'divider'
    },
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'modelClose',
      label: '弹窗关闭icon',
      formType: 'image'
    },
    {
      target: 'modelColor',
      label: '弹窗文案颜色'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题',
      formType: 'image'
    },
    {
      target: 'btnClose',
      label: '关闭按钮',
      formType: 'image'
    },
    /* 奖励弹窗配置 */
    {
      target: '奖励弹窗配置',
      formType: 'divider'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题',
      formType: 'image'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励框背景',
      formType: 'image'
    },
    {
      target: 'btnHappy',
      label: '开心收下按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
