import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopicCardPoolWeapon',
  cnName: '军备五级枪',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/30/e65852d8c27bbb85154b51f1d29393fc.jpeg',
  // 属性相关
  props: {
    /* 页面属性 */
    ..._mixinWrap.props,
    contentBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/9e6a526c0063625f552a1c848be530f2.jpg',
      style: {
        width: '1416px',
        height: '542px'
      }
    },
    singleImgShow: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/dc0722d518dba73ec178ca304dfe9fe1.jpg',
      style: {
        width: '428px',
        height: '629px'
      }
    },
    /* 轮播 */
    autoplay: false,
    swiperImgList: [
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/de0eec1822e42f822d7a1e66608ab4cf.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/de0eec1822e42f822d7a1e66608ab4cf.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/de0eec1822e42f822d7a1e66608ab4cf.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/de0eec1822e42f822d7a1e66608ab4cf.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/de0eec1822e42f822d7a1e66608ab4cf.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      }
    ],
    swiperImgSize: '950px 540px',
    swiperPaginationImgSize: '130px 130px',
    /* 按钮跳转 */
    btnJump: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/daa6b92cd73b80717321422e4875f7af.jpg',
      style: {
        width: '264px',
        height: '73px'
      }
    },
    btnJumpLink: 'https://www.baidu.com/',
    /* 视频弹窗 */
    // coverImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/ae23d6b65b789186d048dc27441d70ca.jpg',
    // coverImgSize: '368px 208px',

    coverImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/ae23d6b65b789186d048dc27441d70ca.jpg',
      style: {
        width: '368px',
        height: '208px'
      }
    },
    videoSize: '1080px 607px',
    mainVideo: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/media/julyAo.4d0b2bb9.mp4',
    closeImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/bbd1bb0232ef4e6c1f3092d36c435dd3.png',
    /* 文案配置 */
    textColor: '#232635',
    textDesc: '返场时间：2023年3月',
    textDescPosition: '200px 290px - -',
    textDescFontSize: '38px'
  },
  propsDesc: [
    /* 页面属性 */
    {
      target: '页面配置',
      formType: 'divider'
    },
    ..._mixin.propsDesc,
    {
      target: 'contentBg',
      label: '轮播底图',
      formType: 'image'
    },
    {
      target: 'singleImgShow',
      label: '视频区底图',
      formType: 'image'
    },
    /* ================================= 轮播图 =========================== */
    {
      target: '轮播图',
      formType: 'divider'
    },
    {
      target: 'autoplay',
      label: '是否自动轮播',
      formType: 'switch'
    },
    // 轮播
    {
      target: 'swiperImgList',
      label: '轮播图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '主图',
          target: 'src'
        },
        {
          title: '缩略(默认)',
          target: 'thumbDefault'
        },
        {
          title: '缩略(选中)',
          target: 'thumbActive'
        }
      ]
    },
    {
      target: 'swiperImgSize',
      label: '轮播图尺寸'
    },
    {
      target: 'swiperPaginationImgSize',
      label: '缩略图尺寸'
    },
    /* 按钮跳转 */
    {
      target: '按钮跳转',
      formType: 'divider'
    },
    {
      target: 'btnJump',
      label: '按钮图片',
      formType: 'image'
    },
    {
      target: 'btnJumpLink',
      label: '按钮跳转链接'
    },
    /* 视频弹窗 */
    {
      target: '视频配置',
      formType: 'divider'
    },
    {
      target: 'coverImg',
      label: '视频封面',
      formType: 'image'
    },
    {
      target: 'videoSize',
      label: '视频弹窗大小'
    },
    {
      target: 'mainVideo',
      label: '视频链接'
    },
    {
      target: 'closeImg',
      label: '关闭icon',
      formType: 'image'
    },
    /* 文案配置 */
    {
      target: '文案配置',
      formType: 'divider'
    },
    {
      target: 'textColor',
      label: '文本颜色'
    },
    {
      target: 'textDesc',
      label: '文案'
    },
    {
      target: 'textDescPosition',
      label: '文案位置'
    },
    {
      target: 'textDescFontSize',
      label: '文案字体大小'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
