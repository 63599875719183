import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopicMap',
  cnName: '地图展示',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/31/12f418d446c7e108b3062d73e9b622e3.jpeg',
  // 属性相关
  props: {
    /* 页面属性 */
    // titleImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/3d5eef2ebfa37dccb94985e2b71bd00b.png',
    // titleSize: '1282px 235px',
    // ..._mixinWrap.props,
    bg: {
      src: '',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    titleImg: _mixinWrap.props.titleImg,
    footerArrow: _mixinWrap.props.footerArrow,
    imgSize: '1920px 1000px',
    thumbPosition: '_ _ 100px _',
    thumbSize: '300px 200px',
    thumbMargin: '0 40px',
    list: [
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/1661320b553ba86d9cb5dff06f16eb87.jpg',
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/5fc479087b6e5cba9e794fff0cc20956.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/707a8e879cb6233bf2af1276f8cd96cf.png'
      },
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/ea7f78ccc208b7c109865b3f0205e7ff.jpg',
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/c0ab6e7a73ea84d76d3ea9ae0904201f.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/ce9df3dbd53183bbfe3b0ca4760becb9.png'
      }
      // {
      //   img: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
      //   thumb: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
      //   thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      // },
      // {
      //   img: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
      //   thumb: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
      //   thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/af54f1f5bd4c487e3ce810e56f0b5890.png'
      // }
    ]
  },
  propsDesc: [
    /* 页面属性 */
    ..._mixinWrap.propsDesc.filter(item => item.target !== 'bg'),
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '缩略图-默认',
          target: 'thumb'
        },
        {
          title: '缩略图-选中',
          target: 'thumbActive'
        }
      ]
    },
    {
      target: 'imgSize',
      label: '大图尺寸'
    },
    {
      target: 'thumbSize',
      label: '小图尺寸'
    },
    {
      target: 'thumbPosition',
      label: '小图位置'
    },
    {
      target: 'thumbMargin',
      label: '小图间距'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
