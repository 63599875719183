<!--领取成功弹窗-->
<template>
  <SFiveModalCommon ref="SFiveModalCommon"
                    :title="config.title"
  >
    <div class="content" v-html="config.content">
    </div>
    <div class="s-btn-wrap">
      <div v-if="config.cancelBtnText" :style="modalCancelBtnStyleObj" class="s-btn s-btn-cancel" @click="cancel">{{config.cancelBtnText}}</div>
      <div v-if="config.confirmBtnText" :style="modalConfirmBtnStyleObj" class="s-btn s-btn-confirm" @click="confirm">{{config.confirmBtnText}}</div>
    </div>
  </SFiveModalCommon>

</template>

<script>
import SFiveModalCommon from './FiveModalCommon.vue'
export default {
  name: 'SFiveModalConfirm',
  components: {
    SFiveModalCommon
  },
  props: {
    title: {
      type: String,
      default: '恭喜获得'
    },
    btnText: {
      type: String,
      default: '开心收下'
    }
  },
  inject: {
    // 由 FiveWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      config: {
        title: '提示',
        content: '',
        confirmBtnText: '确定',
        cancelBtnText: '取消',
        onConfirm: () => {},
        onCancel: () => {}
      }
    }
  },
  computed: {
    modalConfirmBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalConfirmBtnStyle)
      } catch (e) {
        console.error('modalConfirmBtnStyle 格式错误')
        return {}
      }
    },
    modalCancelBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalCancelBtnStyle)
      } catch (e) {
        console.error('modalCancelBtnStyle 格式错误')
        return {}
      }
    }
  },
  async mounted () {
  },
  methods: {
    async open (options) {
      this.config = Object.assign({
        title: '提示',
        content: '',
        confirmBtnText: '确定',
        cancelBtnText: '取消',
        onConfirm: () => {},
        onCancel: () => {}
      }, options)
      this.$refs.SFiveModalCommon.open()
    },
    confirm () {
      this.config.onConfirm()
      this.close()
    },
    cancel () {
      this.config.onCancel()
      this.close()
    },
    close () {
      this.$refs.SFiveModalCommon.close()
    }
  }
}
</script>

<style scoped lang="scss">
//@import "../common.scss";
.content{
  margin-bottom: 0px;
  height: 250px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0 40px;
}
.s-btn-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.s-btn{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 222px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  margin: 0 20px;
  transition: .3s;
  cursor: pointer;
}
.s-btn-confirm{
  &:hover{
    filter: brightness(1.1);
  }
}
</style>
