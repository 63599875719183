<template>
  <STopic2Wrapper v-bind="$attrs">
    <div class="s-components" v-show="visible" >
      <template v-for="(item,index) in list" >
        <div class="info" :class="{active:curIndex === index}" :key="index">
            <slot :name="index + ''">
            </slot>
        </div>
      </template>

      <div class="s-swiper">
        <swiper ref="swiper" :options="swiperOption" v-if="list.length" class="s-swiper-list">
          <swiper-slide v-for="(item, index) in list" :key="index" class="s-swiper-list-item" :style="itemStyleObj">
            <div class="s-swiper-list-item-wrap" :style="{'background-image': `url(${itemBg})`}">
              <img :src="item.img" :alt="item.label">
              <h5>{{ item.label }}</h5>
              <p v-if="item.desc">{{item.desc}}</p>
            </div>
          </swiper-slide>
        </swiper>
        <!--      方向箭头-->

        <div class="s-control-bar">
          <div class="s-progress">
            <div class="s-left" :style="{width: sliderWidth * (currentIndex) + '%'}"></div>
            <div class="s-slider" :style="{width: sliderWidth + '%'}"></div>
          </div>
          <div class="s-arrow">
            <img :src="prevImg" @click="slidePrev" class="btn-prev"/>
            <img :src="nextImg" @click="slideNext" class="btn-next"/>
          </div>
        </div>

      </div>
    </div>
  </STopic2Wrapper>
</template>

<script>
export default {
  name: 'STopic2PvpAward',
  props: {
    itemBg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/dfb410cbc0dca9458ea5b43a02b6dfcf.png'
    },
    list: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    prevImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/e4abe26c47da4e34b59a2d7222f7f09c.png'
    },
    nextImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/0b2553ee92c9b7c5afbcc581bc643d28.png'
    },
    itemStyle: {
      type: String,
      default: '{}'
    },
    thumbItemActiveStyle: {
      type: String,
      default: '{}'
    }
  },
  data () {
    return {
      uid: '',
      visible: true,
      swiperVisible: false,
      curIndex: 1,
      swiper: null,
      currentIndex: 0,
      swiperOption: { // map轮播选项
        slidesPerView: 5,
        autoplay: this.autoplay,
        loop: true
      }
    }
  },
  computed: {
    sliderWidth () {
      return 100 / this.list.length
    },
    itemStyleObj () {
      try {
        return JSON.parse(this.itemStyle)
      } catch (e) {
        console.error('itemStyle 格式错误')
        return {}
      }
    }
  },
  created () {
    this.uid = new Date().getTime()
  },
  mounted () {
    this.$nextTick(() => {
      this.swiper = this.$refs.swiper.swiper
      // this.swiperOption.navigation.prevEl = `swiper-prev--${this.uid}`
      // this.swiperOption.navigation.nextEl = `swiper-next--${this.uid}`
      this.swiper.on('slideChange', () => {
        this.currentIndex = this.swiper.realIndex
        console.log(this.currentIndex)
      })
    })
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    onChange (index) {
      this.curIndex = index
    },
    slidePrev () {
      this.swiper.slidePrev()
    },
    slideNext () {
      this.swiper.slideNext()
    },
    toggle (type) {
      if (type === 'prev') {
        this.$refs.carousel.prev()
      } else {
        this.$refs.carousel.next()
      }
    },
    changeTab (index) {
      this.$refs.carousel.setActiveItem(index)
    }
  }
}
</script>
<style lang="scss" scoped>
$itemHeight: 427px;
.s-components{
  position: relative;
  width: 1920px;
  height: 1000px;
  .info{
    position: relative;
    z-index: 1;
    display: none;
    &.active{
      display: block;
    }
  }
  .s-swiper{
    position: absolute;
    width: 1400px;
    top: 350px;
    left: 50%;
    transform: translateX(-50%);
    .s-swiper-list{
      position: relative;
      //overflow-y: auto !important;
      .s-swiper-list-item{
        .s-swiper-list-item-wrap{
          border-radius: 12px;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          width: 250px;
          height: $itemHeight;
          background-size: cover;
          //background-color: #0B89FF;
          //transform: scale(.95);
          img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            object-fit: contain;
            transition: 1s;
          }
          h5{
            position: absolute;
            left: 0;
            top: initial;
            bottom: 0;
            line-height: 60px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            //background-color: rgba(0,0,0,.5);
            transition: .5s;
          }
          p{
            position: absolute;
            left: 0;
            bottom: -100%;
            width: 100%;
            color: #fff;
            padding: 20px;
            font-size: 14px;
            //background-color: rgba(0,0,0,.5);
            transition: .8s;
          }

          &:hover{
            img{
              transform: scale(1.2);
            }
            h5{
              background-color: rgba(0,0,0,.2);
              bottom: $itemHeight - 60px;
              border-radius: 4px;
            }
            p{
              background-color: rgba(0,0,0,.2);
              bottom: 0;
            }
            //transform: scale(1);
            //transform: translateY(-20px);
          }
        }

      }
    }
  }
  .s-control-bar{
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 30px auto 0;
    .s-progress{
      background-color: #4C5474;
      height: 2px;
      flex: 1;
      display: flex;
      align-items: center;
      .s-left{
        flex-shrink: 0;
        height: 4px;
        transition: .3s;
      }
      .s-slider{
        flex-shrink: 0;
        height: 4px;
        background-color: #AD1626;
      }
    }

    .s-arrow{
      margin-left: 20px;
      img{
        cursor: pointer;
        transition: .3s;
        width: 50px;
        height: 50px;
        object-fit: contain;
        opacity: .8;
        &:hover{
          opacity:1;
          filter: brightness(1.1);
        }
      }
      .btn-prev{

      }
      .btn-next{
      }
    }
  }
}
</style>
<style lang="scss">
</style>
