import _mixin from './_mixin'

export default {
  name: 'SBuy',
  cnName: '购买',
  type: 'complex',
  needLogin: true,
  // 属性相关
  props: {
    /* 购买 */
    activityId: '34X7KQ7A5FB6KR4S26XTYIYMYM',
    activityGroup: [
      { groupId: '1944', bg: 'https://rescdn-crash.ssjj.cn/website/2023/03/27/8fd08bb2746986953a2036f63067b1c0.png' }
    ],
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelBgSize: '750px 450px',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    buyTitle: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/d7727332796838e69c63bf80ed4b8bf5.png',
    buyTitleSize: '719px 39px',
    btnStatus: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    btnColor: '#FFFFFF',
    btnSize: '184px 55px',
    btnBuyText: '购买',
    btnAlreadyBuyText: '已购买',
    btnCancelText: '取消',
    btnConfirmText: '确认',
    btnHappyText: '开心收下',
    btnCloseText: '关闭',
    /* 奖励弹窗配置 */
    awardTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/dd63755a299381588c4e41b75541c1d4.png',
    awardTitleSize: '446px 29px',
    awardOtherDesc: '请前往邮箱查收奖励',
    awardBoxBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/b3d2ea367a040f0c4f8791b415007fc4.png',
    awardBoxBgSize: '201px 132px',
    /* 提示弹窗配置 */
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px'

  },
  propsDesc: [
    {
      target: '购买配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动地址id'
    },
    {
      target: 'activityGroup',
      label: '活动礼包配置',
      formType: 'list',
      listConfig: [
        {
          title: '礼包id',
          target: 'groupId'
        },
        {
          title: '任务背景',
          target: 'bg'
        }
      ]
    },
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelBgSize',
      label: '公共弹窗背景尺寸'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'buyTitle',
      label: '地址标题'
    },
    {
      target: 'buyTitleSize',
      label: '地址标题尺寸',
      description: '宽 高'
    },
    {
      target: 'btnStatus',
      label: '奖励按钮背景'
    },
    {
      target: 'btnColor',
      label: '按钮文案颜色'
    },
    {
      target: 'btnSize',
      label: '奖励按钮大小'
    },
    {
      target: 'btnBuyText',
      label: '购买文案'
    },
    {
      target: 'btnAlreadyBuyText',
      label: '已购买文案'
    },
    {
      target: 'btnCancelText',
      label: '取消文案'
    },
    {
      target: 'btnConfirmText',
      label: '确认文案'
    },
    {
      target: 'btnHappyText',
      label: '开心收下文案'
    },
    {
      target: 'btnCloseText',
      label: '关闭文案'
    },
    /* 奖励弹窗配置 */
    {
      target: '奖励弹窗配置',
      formType: 'divider'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题'
    },
    {
      target: 'awardTitleSize',
      label: '奖励标题尺寸',
      description: '宽 高'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励框背景'
    },
    {
      target: 'awardBoxBgSize',
      label: '奖励框背景尺寸'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
