import _mixin from './_mixin'

export default {
  name: 'SBanner',
  cnName: '头图',
  type: 'complex',
  // 属性相关
  props: {
    bannerSrc: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/66c0e8a7865089142740a3ac2956e413.jpg',
    bannerSize: '1920 999px',
    hasLogo: false,
    logoSrc: 'https://h5.ssjj.cn/common/industrial/pc/images/logo.png?v=1667439096388',
    logoLink: 'https://www.ssjj.cn/'
  },
  propsDesc: [
    {
      target: 'bannerSrc',
      label: '头图地址'
    },
    {
      target: 'bannerSize',
      label: '头图尺寸',
      description: '宽 高'
    },
    {
      target: 'hasLogo',
      label: 'Logo',
      formType: 'switch'
    },
    {
      target: 'logoSrc',
      label: 'logo地址',
      showOn: {
        target: 'hasLogo',
        value: true
      }
    },
    {
      target: 'logoLink',
      label: 'logo跳链',
      showOn: {
        target: 'hasLogo',
        value: true
      }
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
