import _mixin from './_mixin'
import enums from '@/utils/enums'
export default {
  name: 'SButton',
  cnName: '按钮',
  type: 'base',
  draggable: true,
  // 属性相关
  props: {
    defaultBg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/join.png?v=1.04',
    hoverBg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/join-hover.png?v=1.04',
    disabled: false,
    size: '329px 94px',
    text: '',
    link: '',
    channelLinks: [
      ...enums.channel.map(item => ({
        ...item,
        link: ''
      }))
    ]
  },
  propsDesc: [
    {
      target: 'defaultBg',
      label: '默认背景'
    },
    {
      target: 'hoverBg',
      label: 'hover背景'
    },
    {
      target: 'size',
      label: '按钮尺寸',
      description: '宽 高'
    },
    {
      target: 'text',
      label: '文本'
    },
    {
      target: 'link',
      label: '跳链'
    },
    {
      target: 'channelLinks',
      label: '渠道跳链',
      formType: 'list',
      listConfig: [
        {
          title: '渠道名',
          target: 'label'
        },
        {
          title: '渠道标识',
          target: 'value'
        },
        {
          title: '渠道跳链',
          target: 'link'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    top: '0',
    left: '0',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
