<template>

<STopicWrapper class="new-role" v-bind="$attrs">
  <div class="role-box">
    <div v-for="(item,index) in roleList" :key="index" class="role-item" :class="animateTypeAllStyle">
      <!--角色背景-->
      <img :src="item.contentBg" alt=""
            :style="{width: contentBgSize.split(' ')[0],height: contentBgSize.split(' ')[1]}"/>
      <!--角色-->
      <img v-if="item.roleImg"
            :src="item.roleImg" alt=""
            :class="animateTypeStyle"
            :style="{
            width: item.roleImgSize.split(' ')[0],
            height: item.roleImgSize.split(' ')[1],
            position: 'absolute',
            cursor: 'pointer',
            top: item.roleImgPosition.split(' ')[0],
            right: item.roleImgPosition.split(' ')[1],
            bottom: item.roleImgPosition.split(' ')[2],
            left: item.roleImgPosition.split(' ')[3]}"/>
      <!--角色描述-->
      <img v-if="item.roleDescImg"
            :src="item.roleDescImg" alt=""
            :style="{
            width: roleDescSize.split(' ')[0],
            height: roleDescSize.split(' ')[1],
            position: 'absolute',
            top: item.roleDescPosition.split(' ')[0],
            right: item.roleDescPosition.split(' ')[1],
            bottom: item.roleDescPosition.split(' ')[2],
            left: item.roleDescPosition.split(' ')[3]}"
      />
    </div>
  </div>
</STopicWrapper>

</template>

<script>

export default {
  name: 'STopicMoreRole',
  props: {
    contentBgSize: {
      type: String,
      default: '430px 620px'
    },
    roleDescSize: {
      type: String,
      default: '500px 70px'
    },
    roleList: {
      type: Array,
      default: () => []
    },
    animateType: {
      type: String,
      default: 'zoom'
    }
  },
  data () {
    return {
      // 图片本身动效
      animateTypeStyle: '',
      // 整张图动效
      animateTypeAllStyle: ''
    }
  },
  watch: {
    animateType: {
      handler: function (newVal) {
        if (newVal === 'zoom') {
          this.animateTypeStyle = 's-zoom'
          this.animateTypeAllStyle = ''
        } else if (newVal === 'shake') {
          this.animateTypeStyle = ''
          this.animateTypeAllStyle = 's-shake'
        } else {
          this.animateTypeStyle = ''
          this.animateTypeAllStyle = ''
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.new-role{
  .role-box{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0 100px;
    margin-top: 35px;
    .role-item{
      position: relative;
      .role-desc-text{
        font-size: 20px;
        text-align: left;
        line-height: 30px;
      }
    }
    .s-zoom{
      transition: all ease 0.3s;
      &:hover{
        transform: scale(1.1);
      }
    }
    .s-shake{
      transition: all ease 0.3s;
      &:hover{
        transform: rotate(5deg)
      }
    }
  }
}
</style>
