import BaseGroup from './BaseGroup'
export default {
  name: 'BaseMoveGroup',
  cnName: '拖拽组',
  type: 'box',
  draggable: true,
  // 属性相关
  props: {
    ...BaseGroup.props,
    width: 'auto',
    minHeight: 'auto'
  },
  propsDesc: [
    ...BaseGroup.propsDesc
  ],

  // 样式相关
  styles: {
    ...BaseGroup.styles,
    position: 'absolute',
    top: '0px',
    left: '0px'
  },
  stylesDesc: [
    ...BaseGroup.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ...BaseGroup.eventsDesc
  ],

  methods: [
    ...BaseGroup.methods
  ],
  children: []
}
