<!--拉新回流分享-->
<!--双方均能活动礼包 用于较复杂的分享场景-->
<template>
  <ModalCommon ref="ModalCommon"
               :title="config.title">
    <div class="wrap">
      <div class="content">
        <div class="link-wrap" :style="{color: textColor, 'background-color': bgColor}">
          <p class="bg-cv">{{shareLink}}</p>
          <div class="line" :style="{'background-color': textColor}"></div>
          <div class="btn-share hv-highlight flex-shrink-0" @click="copy">复制</div>
        </div>
        <div class="divider"></div>
        <div class="info-wrap" v-html="desc" :style="{color: textColor, 'background-color': bgColor}"></div>
      </div>
    </div>
  </ModalCommon>
</template>

<script>
import { copyContent, queryToString } from '../utils'
import ModalCommon from './ModalCommon'
export default {
  name: 'ModalShareNewOld',
  components: {
    ModalCommon
  },
  props: {
    code: {
      type: String,
      required: true
    },
    name: {
      required: true
    },
    desc: {
      type: String,
      default: ''
    },
    linkDesc: {
      type: String,
      default: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> '
    },
    textColor: {
      type: String,
      default: '#eee'
    },
    bgColor: {
      type: String,
      default: 'rgba(0,0,0, .5)'
    },
    link4399: {
      type: String,
      default: ''
    },
    channelList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: false,
      myCode: '',
      config: {
        title: '分享好友'
      }
    }
  },
  inject: {
    $MainWrap: {
      default: null
    },
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      return this.request ? this.request : window.$request
    },
    shareLink () {
      const { code, name } = this
      let { from, wdFrom, channelId } = this.$route.query
      const tabIndex = this.$MainWrap.curIndex
      const wdFromMap = {}
      const fromMap = {}
      this.channelList.forEach(item => {
        wdFromMap[item.channelId] = item.wdFrom
        fromMap[item.channelId] = item.from
      })
      // const wdFromMap = {
      //   2: 'swjoy',
      //   4: 'bilibili',
      //   5: 'wegame',
      //   6: '4399',
      //   7: 'yly',
      //   9: 'douyu',
      //   10: 'steam',
      //   11: 'zhengqi',
      //   12: 'huya'
      // }
      // const fromMap = {
      //   2: '615activity_819200038',
      //   4: '615activity_1638400033',
      //   5: '615activity_2048000056',
      //   6: '615activity_2457600038',
      //   7: '615activity_2867200041',
      //   8: '615activity_3276800048',
      //   9: '615activity_3686400040',
      //   10: '615activity_4096000029',
      //   11: '615activity_4505600031',
      //   12: '615activity_4915200034'
      // }
      // 游戏内将渠道id映射为wdFrom
      wdFrom = wdFrom || wdFromMap[channelId]
      // 游戏内将渠道id映射为from
      from = from || fromMap[channelId]
      let url = ''
      if (wdFrom === '4399' && this.link4399) {
        url = `${this.link4399}?tabIndex=${tabIndex}&code=${code}&name=${encodeURIComponent(
          name
        )}`
      } else {
        const query = JSON.parse(JSON.stringify(this.$route.query))
        delete query.token
        query.code = code
        query.tabIndex = tabIndex
        query.name = encodeURIComponent(name)
        query.from = from
        query.wdFrom = wdFrom
        url = `${location.origin}${location.pathname}?${queryToString(query)}`
      }
      return url
    }
  },
  async created () {
    // this.getShareCode()
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      // this.eventBus.on('loginSuccess', this.getShareCode)
      // 登出操作添加
      // this.eventBus.on('signOut', this.getShareCode)
    }
  },
  methods: {
    open (options) {
      if (!window.$checkLogin()) return
      this.config = Object.assign({
        title: '分享好友'
      }, options)
      this.$refs.ModalCommon.open()
    },
    copy () {
      copyContent(
        `${this.linkDesc} ${this.shareLink}`
      ).then(() => {
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      })
    },
    // 检查是否是客态助力页面
    isGuideView () {
      const { code } = this.$route.query
      return !!code
    },
    // 好有助力
    // help (code, role_name) {
    //   const helpFn = this.$parent.phase === 1 ? secretTreasureHelp : secretTreasureV2Help
    //   helpFn({ code }).then(data => {
    //     console.log('----- help -----')
    //     console.log(data)
    //     const name = role_name ? `【${decodeURIComponent(role_name)}】` : ''
    //     this.tipFn({ msg: `成功助力好友${name}，快去告诉他吧~` })
    //     this.clearPath()
    //   }).catch(err => {
    //     this.tipFn(err)
    //     this.clearPath()
    //   })
    // },
    clearPath () {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      delete query.code
      delete query.name
      this.$router.replace({
        path: window.location.pathname,
        query
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> act1 取消登录监听')
      this.eventBus.off('loginSuccess', this.getShareCode)
      this.eventBus.off('signOut', this.getShareCode)
    }
  }
}
</script>

<style scoped lang="scss">
.wrap{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 60px 40px;
  .link-wrap{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255,255,255, .5);
    border-radius: 4px;
    p{
      flex: 1;
      flex-shrink: 0;
      height: 55px;
      line-height: 55px;
      padding: 0 20px;
      //color: #eee;
      //background-image: url("../../../assets/images/fungus/link_bg.png");
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: normal;
      font-size: 20px;
    }
    .line{
      width: 1px;
      height: 30px;
      background-color: #999;
    }
    .btn-share{
      margin-left: 10px;
      width: 177px;
      font-size: 27px;
      height: 73px;
      line-height: 73px;
      text-align: center;
      //color: #eee;
      &:hover{
        //color: #fff;
      }
    }
  }
  .divider{
    height: 1px;
    border: 1px dashed #2d2d2d;
    margin: 20px 0;
  }
  .info-wrap{
    font-size: 16px;
    padding: 15px;
    white-space: pre-line;
    background-color: rgba(255,255,255, .5);
    //text-shadow: 0 0 1px #fff;
    border-radius: 4px;
    height: 240px;
    overflow-y: auto;
    //color: #eee;
  }
}
</style>
