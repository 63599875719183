import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopic2Activity',
  cnName: '精彩活动',
  type: 'activity_topic2',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/website/2023/11/01/fcbcd75a50bdbd34f3e5cda1d555e397.jpg',
  // 属性相关
  props: {
    list: [
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e52bc62730cbd8de7c98c302a7e321a7.jpg',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/327049322bee6ada52458c9d9c476368.png',
        label: '每日探险好礼'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/11/2d3ce4da55a642c3d2965ed52446ad49.jpg',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/050e54b14cbe2e44535bfa72df478bd1.png',
        label: '藏宝图'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/17/844b9addae30fb63a3b461c6ab8b044c.jpg',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/fbe23f84899484844055e4d240370d34.png',
        label: '财神大放送'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/17/219c54bc4e40a39d7832a12a5cb12b16.jpg',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/5c5504252a2c263bc431b3fb65ec8456.png',
        label: '探宝月：团购王'
      }
    ],
    thumbWrapStyle: '{}',
    thumbItemStyle: '{}'
  },
  propsDesc: [
    /* 页面属性 */
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '缩略图',
          target: 'thumb'
        },
        {
          title: '名称',
          target: 'label'
        }
      ]
    },
    {
      target: 'thumbWrapStyle',
      label: '缩略图容器样式',
      formType: 'textarea'
    },
    {
      target: 'thumbItemStyle',
      label: '缩略图样式',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
