var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap",class:{
       active: _vm.currentId === _vm.component.id,
       hover: _vm.isHover,
       'is-edit': _vm.isEdit,
       'is-choose': _vm.isChoose,
       'is-draggable': _vm.component.draggable && !['relative', 'static'].includes(_vm.component.styles.position)
     },style:(_vm.component.styles),attrs:{"id":_vm.component.id},on:{"click":function($event){return _vm.chooseComponent($event,_vm.component)},"mousemove":function($event){_vm.isHover = true},"mouseout":function($event){_vm.isHover = false}}},[(_vm.isEdit)?_c('div',{staticClass:"border border-top"}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"border border-right"}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"border border-bottom"}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"border border-left"}):_vm._e(),(!_vm.component.children)?_c(_vm.component.name,_vm._b({ref:_vm.component.id,tag:"component"},'component',_vm.component.props,false),[_vm._v(" "+_vm._s(_vm.component.props.slotText)+" ")]):[(_vm.component.isTab)?_c(_vm.component.name,_vm._b({ref:_vm.component.id,tag:"component",scopedSlots:_vm._u([_vm._l((_vm.component.children),function(_component,_index){return {key:_vm.getSlotName(_index),fn:function(){return [_c('ComponentRender',{key:_component.id,attrs:{"component":_component,"is-edit":_vm.isEdit,"is-choose":_vm.isChoose,"current-id":_vm.currentId}})]},proxy:true}})],null,true)},'component',_vm.component.props,false)):_c(_vm.component.name,_vm._b({ref:_vm.component.id,tag:"component"},'component',_vm.component.props,false),_vm._l((_vm.component.children),function(_component){return _c('ComponentRender',{key:_component.id,attrs:{"component":_component,"is-edit":_vm.isEdit,"is-choose":_vm.isChoose,"current-id":_vm.currentId}})}),1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }