<template>
  <div class="component-wrap"
       :id="component.id"
       :class="{
         active: currentId === component.id,
         hover: isHover,
         'is-edit': isEdit,
         'is-choose': isChoose,
         'is-draggable': component.draggable && !['relative', 'static'].includes(component.styles.position)
       }"
       :style="component.styles"
       @click.stop="chooseComponent(component)"
       @mousemove.self="isHover = true"
       @mouseout="isHover = false"
  >
    <!-- 没有children的组件 直接渲染-->
    <component
      v-if="!component.children"
      :ref="component.id"
      :is="component.name"
      v-bind="component.props"
    >
      {{ component.props.slotText }}
    </component>
    <!-- 有children 的组件 将子组件渲染到当前组件的 slot 中  -->
    <!-- Tab 组件这种有多个插槽的需要单独处理 -->
    <component
      v-else-if="component.name !== 'STab'"
      :ref="component.id"
      :is="component.name"
      v-bind="component.props"
    >
      <ComponentRender
        v-for="_component in component.children"
        :component="_component"
        :key="_component.id"
        :is-edit="isEdit"
        :is-choose="isChoose"
        :current-id="currentId"/>
    </component>
    <!-- Tab 组件这种有多个插槽的需要单独处理  -->
    <component
      v-else
      :ref="component.id"
      :is="component.name"
      v-bind="component.props"
    >
      <template
        v-for="(_component, _index) in component.children"
        v-slot:[getSlotName(_index)]
      >
        <ComponentRender
          :component="_component"
          :key="_component.id"
          :is-edit="isEdit"
          :is-choose="isChoose"
          :current-id="currentId"/>
      </template>
    </component>
  </div>
</template>

<script>

export default {
  name: 'ComponentRender',
  props: {
    component: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    currentId: {
      type: String,
      default: ''
    },
    isChoose: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    pageBuilder: {
      default: null
    }
  },
  data () {
    return {
      isHover: ''
    }
  },
  computed: {},
  created () {
  },
  mounted () {
    // this.component.methods.forEach(item => {
    this.$root.$on(`${this.component.id}-click`, methodName => {
      // 执行组件内对应的方法
      console.log(`${this.component.id}-click`)
      console.log(methodName)
      this.$refs[this.component.id][methodName]()
    })
    // })
  },
  beforeDestroy () {
    this.component.methods.forEach(item => {
      this.$root.$off(`${this.component.id}-${item.fn}`)
    })
  },
  methods: {
    chooseComponent (comp) {
      if (this.isEdit) {
        if (this.isChoose) {
          this.pageBuilder.$refs.partRight.switchControlledComponent(comp)
        } else {
          this.$store.commit('SET_CURRENT_ID', comp.id)
        }
      } else {
        this.doFn(comp.events.click)
      }
    },
    getSlotName (index) {
      return index + ''
    },
    doFn (eventStr) {
      console.log(eventStr)
      if (!eventStr) return
      const arr = eventStr.split('--')
      const event = arr[0]
      const method = arr[1]
      this.$root.$emit(event, method)
    },
    onMousedown () {

    }
  }
}
</script>
<style lang="scss" scoped>
.component-wrap {
  position: relative;

  &::before {
    display: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background-color: rgba(0,0,0, .1);
    z-index: 9;
    box-sizing: border-box;
    cursor: pointer;
  }

  &.is-edit {
    &::before {
      display: block;
    }

    &.active {
      // box-shadow: 0 0 20px cornflowerblue;
      &::before {
        border: 6px solid #00FF00;
        transition: .1s;
      }
    }

    &.hover {
      &::before {
        border: 6px solid #FF9900;
        background-color: rgba(255, 155, 0, .01);
      }
    }
  }

  &.is-choose {
    &::before {
      display: block;
    }

    &.active {
      // box-shadow: 0 0 20px cornflowerblue;
      &::before {
        border: 6px solid #00FF00;
        transition: .1s;
      }
    }

    &.hover {
      &::before {
        border: 6px solid #4ddbff;
        background-color: rgba(77, 219, 255, .1);
      }
    }
  }

  &.is-draggable{
    &::before {
      cursor: move;
    }
  }
}
</style>
