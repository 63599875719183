<template>
  <STopic2Wrapper v-bind="$attrs">
    <slot>
    </slot>
  </STopic2Wrapper>
</template>

<script>

export default {
  name: 'STopic2Empty',
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.content-img{
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //object-fit: contain;
}
</style>
