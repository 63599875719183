import _mixin from './_mixin'
export default {
  name: 'SAdvertising',
  cnName: '广告',
  type: 'complex',
  // 属性相关
  props: {
    bgImage: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/big3.jpg?v=1665632635351'

  },
  propsDesc: [{
    target: 'src',
    label: '图片地址'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1600px'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
