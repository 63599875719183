<template>
  <div class="s-page" v-show="visible" tabindex="0">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BasePage',
  props: {

  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.s-page{
  overflow: overlay;
}
</style>
