<template>
  <div class="resize-box">
    <div class="resize-bar" :style="{width: defaultWidth}"></div>
    <div class="resize-line"></div>
    <div class="resize-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SResizeBox',
  props: {
    defaultWidth: {
      type: String,
      default: '360px'
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.resize-box{
  position: relative;
  display: inline-block;
  height: 100%;
  //width: 100%;
  overflow: hidden;
}
.resize-content{
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
  bottom: 0;
  background-color: #fff;
}
// 拖拽条样式
.resize-bar {
  width: 200px;
  height: inherit;
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
}
/* 拖拽线 */
.resize-line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 5px solid #ccc;
  pointer-events: none;
  transition: .3s;
}
.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  border-left: 5px solid #aaa;
}
.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: 100vh;
}
</style>
