import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopic2PveAward',
  cnName: '奖励展示',
  type: 'activity_topic2',
  preview: '',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/92f2d13257eab5bc493de18247b52365.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    list: [
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/fc38f8d82bb8022393c4fbfceaa21b6d.mp4',
        label: '晶核1',
        type: '奖励类型',
        desTitle: '描述',
        desc: 'lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/ff207bb79bb6dbb97c47300b993283bd.mp4',
        label: '奖品2',
        type: '奖励类型',
        desTitle: '描述',
        desc: ''
      },
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/fc38f8d82bb8022393c4fbfceaa21b6d.mp4',
        label: '奖品3',
        type: '奖励类型',
        desTitle: '描述',
        desc: ''
      },
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/ff207bb79bb6dbb97c47300b993283bd.mp4',
        label: '奖品4',
        type: '奖励类型',
        desTitle: '描述',
        desc: ''
      },
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/fc38f8d82bb8022393c4fbfceaa21b6d.mp4',
        label: '奖品5',
        type: '奖励类型',
        desTitle: '描述',
        desc: ''
      },
      {
        thumb: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/ff207bb79bb6dbb97c47300b993283bd.mp4',
        label: '奖品6',
        type: '奖励类型',
        desTitle: '描述',
        desc: ''
      }
    ],
    thumbWrapStyle: '{}',
    thumbItemStyle: '{}'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '缩略图',
          target: 'thumb'
        },
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '视频',
          target: 'video'
        },
        {
          title: '名称',
          target: 'label'
        },
        {
          title: '类型',
          target: 'type'
        },
        {
          title: '描述标题',
          target: 'desTitle'
        },
        {
          title: '描述',
          target: 'desc'
        }
      ]
    }
    // {
    //   target: 'thumbWrapStyle',
    //   label: '缩略图容器样式',
    //   formType: 'textarea',
    //   placeholder: '可用于调整缩略图容器的位置等信息'
    // },
    // {
    //   target: 'thumbItemStyle',
    //   label: '缩略图样式',
    //   formType: 'textarea',
    //   placeholder: '可用于调整缩略图的大小等信息'
    // }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
