<template>
  <div class="s-advertising" v-show="visible" :style="style">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'SAdvertising',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  computed: {
    style () {
      return {
        background: this.isHover ? this.hoverBg : this.defaultBg
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.s-advertising{
  white-space: pre-line
}
</style>
