<template>
  <div class="fixed-slider">
<!--    <div class="show-slider common-slider" v-show="!showSlider" @click="showSliderFunc(true)">-->
<!--      <div class="show-slider-text">展<br>开<br>导<br>航</div>-->
<!--      <div class="arrow-right"></div>-->
<!--    </div>-->
    <div class="version-data-menu common-slider"
         :style="{'background-image': 'url(' + $MainWrap.sideBarBg.src + ')' }"
         v-if="showSlider"
         :datasrc="menuActiveIndex">
      <div class="slider-top">
        <div class="my" :style="{color: $MainWrap.topTextColor}">我的{{$MainWrap.actKeyName}}:</div>
        <div class="point" :style="{color: $MainWrap.topTextColor}">*{{$MainWrap.point}}</div>
        <div class="mini-color-btn"
             :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
             @click="showPointDetail">{{$MainWrap.actKeyName}}明细</div>
      </div>
      <a href="#version-data-1" class="summary-menu-each menu-first"
         :style="{color: menuActiveIndex === '#version-data-1' ? $MainWrap.textColorActive : $MainWrap.textColorLevel1}"
         :class="{ isMenuActive: menuActiveIndex === '#version-data-1' }">
        <div class="summary-menu-text">{{$MainWrap.sideBarNavText1}}</div>
      </a>
      <a href="#version-data-2" class="summary-menu-each menu-second"
         :style="{color: menuActiveIndex === '#version-data-2' ? $MainWrap.textColorActive : $MainWrap.textColorLevel1}"
         :class="{ isMenuActive: menuActiveIndex === '#version-data-2' }" >
        <div class="summary-menu-text">{{$MainWrap.sideBarNavText2}}</div>
      </a>
      <a href="#version-data-3" class="summary-menu-each menu-third"
         :style="{color: menuActiveIndex === '#version-data-3' ? $MainWrap.textColorActive : $MainWrap.textColorLevel1}"
         :class="{ isMenuActive: menuActiveIndex === '#version-data-3' }">
        <div class="summary-menu-text">{{$MainWrap.sideBarNavText3}}</div>
      </a>
      <div :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
           class="mini-color-btn" @click="jumpTo('https://h5.ssjj.cn/pc2mobile/newUserActivity')">创角必得</div>
      <div :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
           class="mini-color-btn" @click="jumpTo('https://h5.ssjj.cn/pc2mobile/oldUserActivity')">回归专属</div>
      <div :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
           class="mini-color-btn" @click="jumpTo('https://www.ssjj.cn')">前往官网</div>
      <div :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
           class="mini-color-btn" @click="kf">联系客服</div>
    </div>
<!--    <div v-show="showSlider" class="retract-expand common-slider" @click="showSliderFunc(false)">收 起<span class="arrow-left"></span></div>-->
  </div>
</template>

<script>

export default {
  name: 'OfficialActiveSliderBar',
  components: {
  },
  props: {
  },
  data () {
    return {
      menuActiveIndex: '#version-data-0',
      showSlider: true,
      zoomValue: 1,
      container: null
    }
  },
  inject: ['$MainWrap'],
  async created () {
  },
  mounted () {
    this.menuActiveIndex = window.location.hash
    // console.log(window.location.hash, '--window.location.hash--')
    this.container = document.querySelector('#pageRenderParent') || window
    this.container.addEventListener('scroll', this.onScroll)
  },
  methods: {
    showPointDetail () {
      this.$MainWrap.openPointLog()
    },
    jumpTo (url) {
      window.open(url + window.location.search)
    },
    kf (url) {
      window.open('https://www.wooduan.com/service/service/630ebde509187f8e02edde1d')
    },
    onScroll (e) {
      // console.log(this._getScrollTop(e))
      const scrollTop = this._getScrollTop(e) + 10
      const height0 = document.getElementById('version-data-1').offsetTop
      const height1 = document.getElementById('version-data-2').offsetTop
      const height2 = document.getElementById('version-data-3').offsetTop
      // const height3 = document.getElementById('version-data-fourth').offsetTop
      if (scrollTop < height0) {
        this.menuActiveIndex = '#version-data-zero'
      } else if (scrollTop >= height0 && scrollTop < height1) {
        this.menuActiveIndex = '#version-data-1'
      } else if (scrollTop >= height1 && scrollTop < height2) {
        this.menuActiveIndex = '#version-data-2'
      } else if (scrollTop >= height2) {
        this.menuActiveIndex = '#version-data-3'
      }
    },

    _getScrollTop (e) {
      if (e.target.scrollTop !== undefined) {
        return e.target.scrollTop
      } else if (e.target.document) {
        return (
          e.target.document.documentElement.scrollTop ||
          window.pageYOffset ||
          e.target.document.body.scrollTop ||
          0
        )
      }
      return e.target.documentElement.scrollTop || e.target.body.scrollTop || 0
    },
    toTop (status) {
      window.scrollTo(0, 0)
    },
    showSliderFunc (status) {
      this.showSlider = status
    }
  }
}
</script>

<style scoped lang="scss">
.common-slider {
  -webkit-transition: all linear 400ms;
  transition: all linear 400ms;
}
.retract-expand {
  font-size: 27px;
  margin-top: 10px;
  font-weight: 500;
  color: #FDEDC2;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mini-color-btn {
  width: 100px;
  height: 25px;
  margin: 15px auto 0;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  border: 1PX solid;
  border-radius: 12px;
}
.slider-top {
  height: 200px;
  font-size: 37px;
  font-weight: bold;
  color: #FFECBB;
  padding: 60px 30px 0;
  .my{
    font-size: 16px;
    color: #FFECBB;
  }
  .mini-color-btn{
    background-color: #fff;
  }
  .money-detail {
    font-size: 28px;
    font-weight: 500;
    color: #FAD7A5;
  }
}
.version-data-menu {
  position: relative;
  width: 180px;
  height: 527px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/slider-bg.png");
  .summary-menu-each {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
    text-decoration: none;
    font-size: 30px;
  }
  .isMenuActive {
    //background: url("../../../../assets/images/versionActivity/slider/choose-menu.png") no-repeat;
    background-size: 100% 100%;
  }
}
.arrow-left {
  width: 25px;
  height: 25px;
  background-size: 100% 100%;
  display: flex;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/arrow-left.png");
  margin-left: 15px;
}
.retract-expand {
  display: flex;
  justify-content: center;
}
.arrow-right {
  width: 25px;
  height: 25px;
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/arrow-right.png");
  margin: 10px auto 0;
}
.show-slider {
  width: 112px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/show-slider-bg.png");
  cursor: pointer;
}
.show-slider-text {
  font-size: 27px;
  font-weight: bold;
  color: #F7F1E4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.fixed-slider {
  position: fixed;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  z-index: 9;
}
</style>
