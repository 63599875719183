<template>
  <div class="tab1 img-bg" :style="{...defaultBg.style, 'background-image': `url(${defaultBg.src})`}">
    <div class="content">
      <div class="appoint" :style="{width: kvImg.style.width}">
        <img :src="titleImg1.src" alt="" :style="{...titleImg1.style}"/>
        <img :src="kvImg.src" alt="" :style="{...kvImg.style}"/>
        <div class="award-box">
          <img :src="leftAwardImg.src" alt="" :style="{...leftAwardImg.style}"/>
        </div>
        <img :class="['img-bg']" v-if="appointAwards.status === 0" :src="btnNoBegin.src" alt="" @click="receiveAward(1)" :style="{...btnNoBegin.style}"/>
        <img :class="['img-bg']" v-if="appointAwards.status === 1" :src="btnUnfinished.src" alt="" @click="receiveAward(1)" :style="{...btnUnfinished.style}"/>
        <img :class="['btn-status-light img-bg']" v-if="appointAwards.status === 2" :src="btnReceive.src" alt="" @click="receiveAward(1)" :style="{...btnReceive.style}"/>
        <img :class="['img-bg']" v-if="appointAwards.status === 3" :src="btnReceived.src" alt="" @click="receiveAward(1)" :style="{...btnReceived.style}"/>
        <img :class="['img-bg']" v-if="appointAwards.status === 4" :src="btnExpire.src" alt="" @click="receiveAward(1)" :style="{...btnExpire.style}"/>
      </div>
      <div class="login" :style="{width: rightAwardImg1.style.width}">
        <img :src="titleImg2.src" alt="" :style="{...titleImg2.style}"/>
        <img :src="rightAwardImg1.src" alt="" :style="{...rightAwardImg1.style}"/>
        <div class="award-box1">
          <img :src="rightAwardImg2.src" alt="" :style="{...rightAwardImg2.style}"/>
          <img :src="rightAwardImg3.src" alt="" :style="{...rightAwardImg3.style}"/>
        </div>
        <img :class="['img-bg']" v-if="loginAwards.status === 0" :src="btnNoBegin.src" alt="" @click="receiveAward(2)" :style="{...btnNoBegin.style}"/>
        <img :class="['img-bg']" v-if="loginAwards.status === 1" :src="btnUnfinished.src" alt="" @click="receiveAward(2)" :style="{...btnUnfinished.style}"/>
        <img :class="['btn-status-light img-bg']" v-if="loginAwards.status === 2" :src="btnReceive.src" alt="" @click="receiveAward(2)" :style="{...btnReceive.style}"/>
        <img :class="['img-bg']" v-if="loginAwards.status === 3" :src="btnReceived.src" alt="" @click="receiveAward(2)" :style="{...btnReceived.style}"/>
        <img :class="['img-bg']" v-if="loginAwards.status === 4" :src="btnExpire.src" alt="" @click="receiveAward(2)" :style="{...btnExpire.style}"/>
      </div>
    </div>
    <!--提示弹窗-->
    <STipComModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipTitle="tipTitle"
      :btnClose="btnClose"
      :contentText="tipModel.text"
    >
    </STipComModel>
    <!--奖励弹窗-->
    <SAwardComModel
      :modelShow.sync="awardModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardTitle="awardTitle"
      :awardOtherDesc="awardOtherDesc"
      :awardBoxBg="awardBoxBg"
      :btnHappy="btnHappy"
      :awardData="awardModel.award"
    >
    </SAwardComModel>
  </div>
</template>

<script>
export default {
  name: 'SActivityOne',
  props: {
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    defaultBg: {
      type: Object,
      required: true
    },
    /* 新版本预约福利配置 */
    titleImg1: {
      type: Object,
      required: true
    },
    kvImg: {
      type: Object,
      required: true
    },
    leftAwardImg: {
      type: Object,
      required: true
    },
    /* 新版本登录福利配置 */
    titleImg2: {
      type: Object,
      required: true
    },
    rightAwardImg1: {
      type: Object,
      required: true
    },
    rightAwardImg2: {
      type: Object,
      required: true
    },
    rightAwardImg3: {
      type: Object,
      required: true
    },
    /* 通用按钮 */
    btnNoBegin: {
      type: Object,
      required: true
    },
    btnUnfinished: {
      type: Object,
      required: true
    },
    btnReceive: {
      type: Object,
      required: true
    },
    btnReceived: {
      type: Object,
      required: true
    },
    btnExpire: {
      type: Object,
      required: true
    },
    /* 公共弹窗配置 */
    modelBg: {
      type: Object,
      required: true
    },
    modelClose: {
      type: Object,
      required: true
    },
    modelColor: {
      type: String,
      required: true
    },
    /* tip弹窗配置 */
    tipTitle: {
      type: Object,
      required: true
    },
    btnClose: {
      type: Object,
      required: true
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      type: Object,
      required: true
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: Object,
      required: true
    },
    btnHappy: {
      type: Object,
      required: true
    }

  },
  inject: {
    eventBus: {
      default: null
    }
  },
  data () {
    return {
      appointAwards: { status: 2 },
      loginAwards: { status: 1 },
      // 提示弹窗
      tipModel: {
        show: false,
        text: ''
      },
      // 奖励弹窗
      awardModel: {
        show: false,
        award: []
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  mounted () {
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.getCheckStatus()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    if (!$checkLogin()) return
    this.getCheckStatus()
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getCheckStatus()
        }
      },
      immediate: false
    }
  },
  methods: {
    // 获取初始化状态
    getCheckStatus () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.appointAwards = { ...res.groups[0] }
        this.loginAwards = { ...res.groups[1] }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
        this.tipModel.type = 0
      })
    },
    // 领取左边的奖励
    receiveAward (type) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      const params = {
        a_id: this.activityId
      }
      if (type === 1) {
        if (this.appointAwards.status !== 2) return
        params.g_id = this.appointAwards.id
      } else if (type === 2) {
        if (this.loginAwards.status !== 2) return
        params.g_id = this.loginAwards.id
      }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params
      }).then((res) => {
        if (res.success) {
          const award = []
          if (type === 1) {
            this.appointAwards.materials.forEach((item) => {
              award.push({ name: item.name, count: item.amount, images: item.icon })
            })
            this.awardModel.show = true
            this.awardModel.award = award
          } else if (type === 2) {
            this.loginAwards.materials.forEach((item) => {
              award.push({ name: item.name, count: item.amount, images: item.icon })
            })
            this.awardModel.show = true
            this.awardModel.award = award
          }
          this.getCheckStatus()
        } else {
          this.tipModel.show = true
          this.tipModel.text = res.msg
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 重置
    reset () {
      this.appointAwards.status = 2
      this.loginAwards.status = 1
      // this.getCheckStatus()
    }
  }
}
</script>

<style scoped lang="scss">
// 公共样式
.img-bg{
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
}
.lay{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}
// 内容样式
.tab1{
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
    .appoint{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 163px;
      height: 870px;
    }
    .login{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 870px;
    }
    .award-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    .award-box1{
      width: 780px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    .btn-status-light{
      cursor: pointer;
      z-index: 2;
      &:hover{
        filter: brightness(120%)
      }
    }
  }
}
</style>
