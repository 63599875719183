<template>
  <STopicWrapper class="s-topic-map" v-bind="$attrs" :style="imgStyle">
    <div class="content" :style="imgStyle">
      <transition-group name="slide-fade" mode="out-in" tag="div">
        <img class="img-bg" v-show="curIndex === index" v-for="(item,index) in list" :src="item.img" alt=""
             :key="item.img" :style="imgStyle">
      </transition-group>
      <div class="thumb-bar" :style="thumbPos">

        <div v-for="(item,index) in list" class="thumb-item" @click="curIndex = index" :key="index" :style="{
        backgroundImage: `url(${curIndex === index ? item.thumbActive : item.thumb}`,
        ...thumbStyle
      }">
        </div>
      </div>
    </div>
  </STopicWrapper>
</template>

<script>

export default {
  name: 'STopicMap',
  props: {
    imgSize: {
      type: String,
      default: ''
    },
    thumbSize: {
      type: String,
      default: ''
    },
    thumbPosition: {
      type: String,
      default: ''
    },
    thumbMargin: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
    // 地图样式
    imgStyle () {
      const sizeArr = this.imgSize.split(' ')
      return {
        width: sizeArr[0] || '',
        height: sizeArr[1] || ''
      }
    },
    thumbStyle () {
      const sizeArr = this.thumbSize.split(' ')
      return {
        width: sizeArr[0] || '',
        height: sizeArr[1] || '',
        margin: this.thumbMargin
      }
    },
    thumbPos () {
      const posArr = this.thumbPosition.split(' ')
      return {
        top: posArr[0] || '',
        right: posArr[1] || '',
        bottom: posArr[2] || '',
        left: posArr[3] || ''
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.img-bg {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.thumb-bar {
  position: absolute;
  width: 100%;
  z-index: 99;
  align-items: center;
  text-align: center;

  .thumb-item {
    transition: .3s;
    cursor: pointer;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      transform: translateY(-5px);
    }
  }

}
</style>
