var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"animateImage"},[_c('div',{staticClass:"box",style:({
      'background-image': `url(${_vm.src})`,
      width: _vm.width,
      height: _vm.height
    })},[(_vm.mask)?_c('div',{staticClass:"mask",style:({
      'background-image': `url(${_vm.src})`,
      '-webkit-mask-image': `url(${_vm.mask})`,
      '-moz-mask-image': `url(${_vm.mask})`,
      'mask-image': `url(${_vm.mask})`,
    })}):_vm._e()]),(_vm.mask)?_c('svg',{staticStyle:{"display":"none"},attrs:{"xlmns":"http://www.w3.org/2000/svg","version":"1.1"}},[_c('filter',{attrs:{"id":"heat","filterUnits":"objectBoundingBox","x":"0","y":"0","width":"100%","height":"100%"}},[_c('feTurbulence',{ref:"feTurbulence",attrs:{"type":"fractalNoise","numOctaves":"1","seed":"2"}}),_c('feDisplacementMap',{attrs:{"xChannelSelector":"G","yChannelSelector":"B","scale":"22","in":"SourceGraphic","in2":""}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }