<template>
  <div v-if="visible">
    <div v-if="coverImg" class="full-img-wrap">
      <img :src="coverImg" alt="">
    </div>
    <div v-else class="s-mobile-fit-notices">
      <div class="s-mobile-fit-notices__title animate__animated animate__fadeIn">{{ title }}</div>
      <div class="s-mobile-fit-notices__tips">
        <p class="animate__animated animate__fadeInLeft">{{ tips }}</p>
        <img class="animate__animated animate__fadeInRight" :src="img" alt="">
      </div>
      <div class="s-mobile-fit-notices__btn animate__animated animate__fadeIn" @click="hide">
        {{ btnText }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'SMobileFitNotices',
  props: {
    coverImg: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: 'https://rescdn.ssjj.cn/website/2023/10/11/bbcc400ce2438be3d0318582ffdb3b19.png'
    },
    title: {
      type: String,
      default: '温馨提示'
    },
    tips: {
      type: String,
      default: '当前页面在电脑端访问效果更佳'
    },
    btnText: {
      type: String,
      default: '我知道了'
    }
  },
  data () {
    return {
      visible: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    init () {
      if (this._isMobile()) {
        this.show()
        document.body.style.overflow = 'hidden'
      } else {
        this.hide()
      }
    },
    _isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
}
</script>
<style lang="scss" scoped>
.full-img-wrap{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #000;
  img{
    width: 100%;
    display: block;
  }
}
.s-mobile-fit-notices {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #efefef;

  .s-mobile-fit-notices__title {
    font-size: 28PX;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20PX;
  }

  .s-mobile-fit-notices__tips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50PX;

    p {
      font-size: 16PX;
      color: #eee;
      margin-bottom: 20PX;
    }

    img {
      width: 60%;
      max-width: 600PX;
    }
  }

  .s-mobile-fit-notices__btn {
    width: 200PX;
    color: #efefef;
    font-size: 20PX;
    border-radius: 4PX;
    border: 1PX solid #fff;
    text-align: center;
    padding: 10PX 20PX;
    cursor: pointer;
  }
}
</style>
