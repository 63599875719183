import _mixin from '../_mixin'

export default {
  name: 'SActivityOne',
  cnName: '(废弃)活动1-新版本福利',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/8c2cb76d85b2bbb1d15ae73a1650167a.jpeg',
  // 属性相关
  props: {
    activityId: '7SLKHSXL63XGM5YGGMCUUUJV7A',
    // 背景
    defaultBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/707a8e879cb6233bf2af1276f8cd96cf.jpg',
      style: {
        width: '1920px',
        height: '966px'
      }
    },
    /* 新版本预约福利配置 */
    // 标题
    titleImg1: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/df5e8cf88710526809d11c569f4fd68a.png',
      style: {
        width: '424px',
        height: '105px',
        margin: '130px 0 0 0'
      }
    },
    // kv图
    kvImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/41054ac008f8068178444dfbac452d4a.png',
      style: {
        width: '618px',
        height: '340px'
      }
    },
    // 左边奖励
    leftAwardImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/a70e9d648edf6fcd116c5550555a6e7f.png',
      style: {
        width: '618px',
        height: '122px'
      }
    },
    /* 新版本登录福利配置 */
    // 标题
    titleImg2: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/0e288d85e8fd0b232a88f87d032a9883.png',
      style: {
        width: '424px',
        height: '105px',
        margin: '130px 0 0 0'
      }
    },
    // 右边奖励
    rightAwardImg1: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/c8a7d1feae6ae6a194da1d76e03ab276.png',
      style: {
        width: '780px',
        height: '305px'
      }
    },
    rightAwardImg2: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/6f34b1947d0502a4f249fc34f647ad23.png',
      style: {
        width: '389px',
        height: '204px'
      }
    },
    rightAwardImg3: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/6f34b1947d0502a4f249fc34f647ad23.png',
      style: {
        width: '389px',
        height: '204px'
      }
    },
    // 通用按钮
    btnNoBegin: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/a516d94a82e9ec8e2d544f91af5f8ca8.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    btnUnfinished: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    btnReceive: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/1ab95782b66f3f58ef061d894ff1f96d.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    btnReceived: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/b9f5ea675d3c0c27fe6fd52ce79fe085.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    btnExpire: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/4653df6c52ad2beaa34a8974a08acd35.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    /* 公共弹窗配置 */
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/66ff37c9751d3b7b50f31fa4a5097b04.png',
      style: {
        width: '750px',
        height: '450px'
      }
    },
    modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/9d4318b1f4af8a822f0bda055005e4c7.png',
      style: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '-20px',
        right: '-30px',
        left: '-',
        bottom: '_',
        cursor: 'pointer'
      }
    },
    modelColor: '#C09248',
    /* tip弹窗配置 */
    tipTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/0791485f27905518fbcfa8c0cf09a5f0.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    // 关闭按钮
    btnClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/ff207bb79bb6dbb97c47300b993283bd.png',
      style: {
        width: '181px',
        height: '51px'
      }
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/eddd856a3fe7cf5bfaa08f333ad7fc5e.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    awardOtherDesc: '请前往邮箱查收奖励',
    // 奖励框的背景和大小配置
    awardBoxBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/208f60ae752f40ddbfeab59dd5b50fdf.png',
      style: {
        width: '151px',
        height: '126px'
      }
    },
    // 开心收下按钮
    btnHappy: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/fc38f8d82bb8022393c4fbfceaa21b6d.png',
      style: {
        width: '181px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'defaultBg',
      label: '默认背景',
      formType: 'image'
    },
    /* 新版本预约福利配置 */
    {
      target: '新版本预约福利配置',
      formType: 'divider'
    },
    {
      target: 'titleImg1',
      label: '预约福利标题',
      formType: 'image'
    },
    {
      target: 'kvImg',
      label: '预约福利KV图',
      formType: 'image'
    },
    {
      target: 'leftAwardImg',
      label: '预约福利奖励',
      formType: 'image'
    },
    /* 新版本登录福利配置 */
    {
      target: '新版本登录福利配置',
      formType: 'divider'
    },
    {
      target: 'titleImg2',
      label: '登录福利标题',
      formType: 'image'
    },
    {
      target: 'rightAwardImg1',
      label: '登录福利奖励1',
      formType: 'image'
    },
    {
      target: 'rightAwardImg2',
      label: '登录福利奖励2',
      formType: 'image'
    },
    {
      target: 'rightAwardImg3',
      label: '登录福利奖励3',
      formType: 'image'
    },
    {
      target: '通用按钮',
      formType: 'divider'
    },
    {
      target: 'btnNoBegin',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'btnUnfinished',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'btnReceived',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'btnExpire',
      label: '已过期按钮',
      formType: 'image'
    },
    /* 弹窗配置 */
    {
      target: '公共弹窗配置',
      formType: 'divider'
    },
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'modelClose',
      label: '弹窗关闭icon',
      formType: 'image'
    },
    {
      target: 'modelColor',
      label: '弹窗文案颜色'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题',
      formType: 'image'
    },
    {
      target: 'btnClose',
      label: '关闭按钮',
      formType: 'image'
    },
    /* 奖励弹窗配置 */
    {
      target: '奖励弹窗配置',
      formType: 'divider'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题',
      formType: 'image'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励框背景',
      formType: 'image'
    },
    {
      target: 'btnHappy',
      label: '开心收下按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
