<template>
  <div class="s-login common-layout common-layout-index" v-show="!isInGame">
    <div v-show="loginVisible && !isInGame" class="app-login-box">
      <div @click.self.prevent="closeLogin" class="login-mask"></div>
      <div id="loginIn"></div>
    </div>
    <!--        根据参数调整登录方式-->
    <div class="common-login" v-if="loginChannelType === 'normal'" :style="{color: textColor}">
      <div class="loginCon" >
        <ul class="unlogin f-cb" v-show="!activeToken">
          <li>
            <div @click="login('login')">登录</div>
          </li>
          <li class="" v-if="currentPage !== 'report'">
            <div @click="login('register')">注册</div>
          </li>
        </ul>
        <ul class="haslogin f-cb" v-show="activeToken">
          <li>
            <!-- <a
              :href="`${memberUrl}?token=${activeToken}`"
              class="head-link user_account"
              target="_blank"
            >{{ activeAccount }}</a> -->
            <div>{{ activeAccount || activeWdId || '--' }}</div>
          </li>
          <li>
            <div class="head-link logout" @click="signOut">退出</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="common-wegame" v-if="loginChannelType === 'wegame'" :style="{color: '#fff'}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        type="wegame"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === 'douyu'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        type="douyu"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === 'huya'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        type="huya"
        ref="thirdOnlyLogin"
      />
    </div>
    <div
      class="common-wegame"
      v-if="['steam', 'zhengqi'].includes(loginChannelType)"
    >
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        :type="loginChannelType"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === 'swjoy'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        type="swjoy"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === 'yly'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        type="yly"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === '4399'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        type="4399"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === '360'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        type="360"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
    <div class="common-wegame" v-if="loginChannelType === 'bilibili'" :style="{color: textColor}">
      <ThirdOnlyLogin
        :userInfo="{token: activeToken, name: activeAccount || activeWdId || '--'}"
        type="bilibili"
        :memberUrl="memberUrl"
        @signOut="signOut"
        @threeLogin="threeLogin"
        ref="thirdOnlyLogin"
      />
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
// import { tokenName, getMemberCenterUrl } from '../utils/util'
import ThirdOnlyLogin from './ThirdOnlyLogin.vue'

const envMap = {
  develop: {
    frontHost: 'http://172.31.2.176:63005',
    apiHost: 'http://172.31.2.176:9300',
    logApiHost: 'http://172.31.2.176:61400',
    thirdReturnHost: 'http://172.31.2.176:61050'
  },
  dev: {
    frontHost: 'http://172.31.2.176:63005',
    apiHost: 'http://172.31.2.176:9300',
    logApiHost: 'http://172.31.2.176:61400',
    thirdReturnHost: 'http://172.31.2.176:61050'
  },
  development: {
    frontHost: 'http://172.31.2.176:63005',
    apiHost: 'http://172.31.2.176:9300',
    logApiHost: 'http://172.31.2.176:61400',
    thirdReturnHost: 'http://172.31.2.176:61050'
  },
  crash: {
    frontHost: 'https://u.ssjj.cn',
    apiHost: 'https://u.ssjj.cn',
    logApiHost: 'https://log.ssjj.cn',
    thirdReturnHost: 'https://h5.ssjj.cn'
  },
  pre: {
    frontHost: 'https://u-uat.ssjj.cn',
    apiHost: 'https://u-uat.ssjj.cn',
    logApiHost: 'https://log-uat.ssjj.cn',
    thirdReturnHost: 'https://h5-uat.ssjj.cn'
  },
  master: {
    frontHost: 'https://u.ssjj.cn',
    apiHost: 'https://u.ssjj.cn',
    logApiHost: 'https://log.ssjj.cn',
    thirdReturnHost: 'https://h5.ssjj.cn'
  },
  vpn: {
    frontHost: 'https://u.ssjj.cn',
    apiHost: 'https://u.ssjj.cn',
    logApiHost: 'https://log.ssjj.cn',
    thirdReturnHost: 'https://h5.ssjj.cn'
  },
  production: {
    frontHost: 'https://u.ssjj.cn',
    apiHost: 'https://u.ssjj.cn',
    logApiHost: 'https://log.ssjj.cn',
    thirdReturnHost: 'https://h5.ssjj.cn'
  }
}
const nodeEnv = process.env.VUE_APP_DEPLOY_ENV || 'master'
console.log(nodeEnv)
const env = envMap[nodeEnv]
// const env = envMap.dev
export default {
  name: 'SLogin',
  props: {
    tokenKey: {
      type: String,
      default: 'activeToken'
    },
    showChannelLogin: {
      type: Boolean,
      default: false
    },
    channelOptions: {
      type: Array,
      default: () => ['wooduan', 'lenovo', 'wegame', 'douyu', 'huya', 'steam', 'zhengqi', 'swjoy', 'yly', '4399', '360', 'bilibili']
    },
    textColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    ThirdOnlyLogin
  },
  data () {
    return {
      loginVisible: false,
      currentPage: '',
      from: '',
      loginChannelType: this.$route.query.wdFrom || 'normal',
      // memberUrl: getMemberCenterUrl(process.env.NODE_ENV)
      memberUrl: '',
      activeAccount: '',
      activeToken: '',
      activeWdId: '',
      isInGame: !!this.$route.query.token
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    tokenName: {
      default: 'token'
    }
  },
  computed: {
    tokenStr () {
      return this.tokenName || this.tokenKey
    },
    isCommonLogin () {
      const currentPage = this.$route.name
      return [].includes(currentPage)
    }
  },
  created () {
    // 检查是否正常引入loginSdk
    this.checkSdkInsert()
    // 检查是否有token自动登录
    this.autoSaveToken()
    this.setUserInfo()
    // setTimeout(() => {
    //   window.$checkLogin = this.checkLogin()
    // }, 2000)
  },
  mounted () {
    this.currentPage = this.$route.name
    // this.loginChannelType = this.$route.query.wdFrom ? this.$route.query.wdFrom : 'normal';
    this.from = this.$route.query.from || 'website'

    // if (this.eventBus) {
    //   this.eventBus.on('loginSuccess', (obj) => {
    //     console.log('> 登录成功：')
    //   })
    // }
  },
  methods: {
    /**
     * 检查是否正常引入loginSdk
     */
    checkSdkInsert () {
      if (!window.initUnilogin) {
        console.error('uniLoginSdk未引入! 登录组件将无法正常使用')
      } else {
        window.$checkLogin = this.checkLogin
      }
    },

    /**
     * 自动存储token
     */
    autoSaveToken () {
      const token = this.$route.query.token
      if (token) {
        console.log('--isInGame--')
        localStorage.setItem(this.tokenStr, token)
      }
      // this.setUserInfo()
    },
    /**
     * 检查是否是在游戏内引入(游戏内引入的不显示登录信息，直接通过token自动登录)
     */
    checkInGame () {
      const token = this.$route.query.token
      if (token) {
        localStorage.setItem(this.tokenStr, token)
      }
    },

    // ...mapMutations(['setShowLogin', 'setActiveToken', 'setAccountName', 'setWdId']),
    setUserInfo (info) {
      if (info) {
        const {
          // eslint-disable-next-line camelcase
          userData: { account, wd_id, nick },
          token
        } = info
        localStorage.setItem('activeAccount', account)
        localStorage.setItem('activeToken', token)
        localStorage.setItem('activeWdId', wd_id)
        localStorage.setItem('activeNick', nick)

        this.activeToken = token
        this.activeNick = nick
        this.activeAccount = account
        this.activeWdId = wd_id
      } else {
        this.activeToken = localStorage.getItem('activeToken') || ''
        this.activeNick = localStorage.getItem('activeNick') || ''
        this.activeAccount = localStorage.getItem('activeAccount') || ''
        this.activeWdId = localStorage.getItem('activeWdId') || ''
      }
    },
    removeUserInfo () {
      this.activeToken = ''
      localStorage.removeItem('activeToken')
      this.activeNick = ''
      localStorage.removeItem('activeNick')
      this.activeAccount = ''
      localStorage.removeItem('activeAccount')
      this.activeWdId = ''
      localStorage.removeItem('activeWdId')
    },
    // insertSdk () {
    //   const js = 'http://172.31.2.176:63199/ssjj2res/login-sdk-fe/login/unilogin.js'
    //   const css = 'http://172.31.2.176:63199/ssjj2res/login-sdk-fe/login/unilogin.css'
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = js
    //   document.body.appendChild(script)
    //   const link = document.createElement('link')
    //   link.rel = 'stylesheet'
    //   link.type = 'text/css'
    //   link.href = css
    //   document.body.appendChild(link)
    // },
    threeLogin (type) {
      const map = {
        wegame: {
          width: 680,
          height: 650
        },
        douyu: {
          width: 1300,
          height: 800
        },
        huya: {
          width: 750,
          height: 420
        },
        steam: {
          width: 800,
          height: 800
        },
        zhengqi: {
          width: 800,
          height: 800
        },
        swjoy: {
          width: 440,
          height: 585
        },
        yly: {
          width: 900,
          height: 899
        },
        4399: {
          width: 680,
          height: 650
        },
        360: {
          width: 680,
          height: 650
        },
        bilibili: {
          width: 980,
          height: 600
        }
      }
      const { width = 680, height = 650 } = map[type] || {}
      const parmas =
        'height=' +
        height +
        ', width=' +
        width +
        ',toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no'

      const returnUrl =
        `${env.thirdReturnHost}/common/thirdlogin/index.html?app_key=website&lng=zh&platform=pc`
      const url = `${env.apiHost}/v1/third/index?type=${type}&app_key=website&is_bind=false&return_url=${encodeURIComponent(
        returnUrl
      )}&from=${this.$route.query.from ? this.$route.query.from : ''}`
      this.selfLoginWindows = this.selfLoginWindows || []
      this.selfLoginWindows.push(window.open(url, type, parmas))
      // 防止多次监听导致多次触发loginSuccess
      if (!this.messageListend) {
        window.addEventListener('message', (a) => {
          // console.log(a.data.token);
          if (a.data.token) {
            if (this.selfLoginWindows) {
              this.selfLoginWindows.forEach((i) => {
                i && i.close()
              })
            }
            this.selfLoginWindows = null
            this.getUserToken(a.data)
            window.removeEventListener('message', () => {
              console.log('事件结束')
            })
            this.messageListend = false
          }
        })
        this.messageListend = true
      }
    },
    initLogin (value) {
      // 获取最新的from,from动态
      this.from = this.$route.query.from || 'website'
      const option = {
        container: '#loginIn', // 容器，支持选择器默认是body
        useCookie: false, // 是否使用cookie存储token，否则使用localStorage，默认为false
        tokenName: this.tokenStr, // cookie或者localStorage的key,不传会自动生成
        appName: '', // 业务名 'website' 网站 或 'client' 登录器，默认website
        lng: 'zh', // 适配语言， zh代表中文,目前只有
        alt4Modal: true,
        platform: 'pc', // 适配设备，pc或移动端，不传会自动根据userAgent判断
        mode: (process.env.VUE_APP_DEPLOY_ENV === 'testVn' ? 'testSea' : process.env.VUE_APP_DEPLOY_ENV) || 'master', // 环境，不传默认为master
        channel_id: 1, // 渠道id
        sub_channel_id: 4096, // 子渠道id
        from: this.from, // 渠道重构后的参数 channel_id,sub_channel_id 默认不传，服务端根据from判断渠道来源,
        channelLogin: this.showChannelLogin,
        channelOptions: this.channelOptions,
        autoShowChannelLogin: this.channelLogin && !!this.$route.query.fromChannel // 有渠道登录并且有来自渠道的标记 则自动显示渠道登录
      }
      window.instance = window.initUnilogin(option)
      // eslint-disable-next-line no-undef
      instance.login()
      // 增加登录事件监听

      // eslint-disable-next-line no-undef
      // if (!instance.litseners) {
      // eslint-disable-next-line no-undef
      if (!instance.litseners || !instance.litseners.login || instance.litseners.login.length === 0) {
      // eslint-disable-next-line no-undef
        instance.on('login', (r) => {
        // console.log(r, '--login--')
          this.getUserToken(r)
        })
        // eslint-disable-next-line no-undef
        instance.on('register', (r) => {
          // console.log(r, '--register--')
          this.getUserToken(r)
        })
      }

      // if (value === 'login') {
      //   instance.login();
      //   // 增加登录事件监听
      //   instance.on('login', (r) => {
      //     console.log(r, '--login--');
      //     this.getUserToken(r)
      //   });
      // } else {
      //   instance.register();
      //   // 增加注册事件监听,有'login' ，'register' ，'logout'三种事件
      //   instance.on('register', (r) => {
      //     console.log(r, '--register--');
      //     this.getUserToken(r)
      //   });
      // }
    },
    getUserToken (obj) {
      if (obj) {
        this.loginSuccess(obj)
      }
    },
    login (value) {
      this.loginVisible = true
      this.initLogin(value)
      // instance._toggleShow(true);
    },
    // checkLogin () {
    //   let isLogin = true
    //   if (!this.activeToken) {
    //     console.log('> checkLogin: 未登录')
    //     this.login('login')
    //     isLogin = false
    //   }
    //   return isLogin
    // },
    checkLogin (options = { showLogin: true }) {
      if (this.activeToken) {
        return true
      } else {
        if (options.showLogin) {
          this.$nextTick(this.loginFunc)
        }
        return false
      }
    },
    loginFunc (type, width, height) {
      if (this.loginChannelType === 'normal') {
        this.login(type)
      } else {
        this.threeLogin(this.loginChannelType, width, height)
      }
    },

    loginSuccess (loginInfo) {
      this.setUserInfo(loginInfo)
      this.closeLogin(false)
      this.$emit('loginSuccess', true)
      if (this.eventBus) {
        this.eventBus.fire('loginSuccess', loginInfo)
      }
    },

    signOut () {
      this.removeUserInfo()
      this.$emit('signOut', true)
      if (this.eventBus) {
        this.eventBus.fire('signOut')
      }
    },
    closeLogin () {
      if (window.instance) {
        window.instance._toggleShow(false)
      }
      this.loginVisible = false
    }
  }
}
</script>
<style scoped>
.s-login{
  width: 100%;
}
.app-login-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  z-index: 6666;
}

.login-mask{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 7777;
}

#loginIn {
  position: absolute;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 14PX;
}

a {
  text-decoration: none;
}

.common-login-center {
  flex: 1;
}

.logo-container {
  display: flex;
  flex-direction: row;
  color: #fff;
}

.huo-yuan {
  width: 122px;
  height: 47px;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid #ddd;
}

.report-record {
  margin-left: 100px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.container {
  position: relative;
  font-family: "Microsoft Yahei";
  background: #000;
  min-width: 1000px;
}

.common-layout {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}

.common-layout-index .logo-container {
  height: 100px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.common-layout-img {
  width: 236px;
  height: 44px;
}

.loginCon {
  width: auto;
  padding-top: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.common-wegame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f-cb {
  zoom: 1;
}

.fl {
  float: left;
}

.unlogin,
.haslogin {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
}

.unlogin li,
.haslogin li,
.parent li {
  margin: 0 30px;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.unlogin li a,
.haslogin li a,
.parent li a {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.unlogin li:hover,
.haslogin li:hover,
.parent li:hover {
  filter: brightness(1.1);
}

.goHome:hover {
  border-bottom: 0 !important;
}

/**登录注册 */
.topBar {
  position: fixed;
  top: 0;
  height: 50px;
  background: #1c1c1c;
  z-index: 999;
}

.topBar_login_layer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  left: 0;
  top: 0;
}

.quick-login span {
  display: inline-block;
  line-height: 60px;
  height: 60px;
  vertical-align: bottom;
}

#qq_login_btn a {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  background: url(./img/qq_gray.png) no-repeat;
  background-size: 100% 100%;
  margin: 0 15px;
}

#wb_login_btn a {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  background: url(./img/wb_gray.png) no-repeat;
  background-size: 100% 100%;
  margin: 0 15px;
}

#wx_login_btn a {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  background: url(./img/wx_gray.png) no-repeat;
  background-size: 100% 100%;
  margin: 0 15px;
}

#qq_login_btn a:hover {
  background: url(./img/qq_light.png) no-repeat;
  background-size: 100% 100%;
}

#wb_login_btn a:hover {
  background: url(./img/wb_light.png) no-repeat;
  background-size: 100% 100%;
}

#wx_login_btn a:hover {
  background: url(./img/wx_light.png) no-repeat;
  background-size: 100% 100%;
}

.topBar_login_layer .phone_code,
.topBar_login_layer .img_code {
  width: 216px !important;
}

.topBar_login_layer .btn-code,
.topBar_login_layer .forgetGetCode {
  width: 90px;
  height: 42px;
  color: #dfdfe3;
  background: #3e3e4b;
  line-height: 42px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0 none;
}

.topBar_login_layer .link-login {
  display: block;
  text-align: center;
  font-size: 14px;
}

.animated-btn:hover {
  animation: btn_hover 0.2s linear, btn_hover_light 2.5s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes btn_hover {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.93);
  }
}

@keyframes btn_hover_light {
  0% {
    box-shadow: 0 0 0 #101010;
  }
  100% {
    box-shadow: 0 0 40px #101010;
  }
}

.tabs {
  width: 340px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.tabs > div {
  width: 159.8px;
  display: inline-block;
  line-height: 40px;
  font-size: 20px;
  color: #b2b7c0;
  text-align: center;
  position: relative;
}

.tabs > div.active {
  color: #fff;
  text-shadow: 0 0 10px #fff;
}

.tabs > div.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 26px;
  height: 3px;
  background: #ce292d;
  left: 50%;
  margin-left: -13px;
}

.tabs > div {
  border-right: 1px solid #32363c;
  cursor: pointer;
}

.tabs > div:last-child {
  border: none;
}

.width_135 {
  width: 135px !important;
}

@media screen and (max-width: 1000px) {
  .container {
    position: relative;
    overflow: hidden;
    font-family: "Microsoft Yahei";
    background: #000;
    min-width: 1000px;
  }

  .logo-container {
  }
}

</style>
<style>

#unilogin-inserted-otherchannel-itemscon {
  align-content: flex-start !important;

}
#unilogin-inserted-otherchannel-itemscon > div{
  margin-bottom: 10px !important;
}
</style>
