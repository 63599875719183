import _mixin from '../_mixin'

export default {
  name: 'STopicKV',
  cnName: 'KV头图',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/30/dc3e42f445c1787417e8e090fd2b9591.jpeg',
  // 属性相关
  props: {
    defaultBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/5cb04672ce172df1737c24d6665649ff.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    logoImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/1ed1c622322e00a43f5b3d9d4945b9bb.png',
      style: {
        width: '285px',
        height: '50px',
        top: '27px',
        right: '',
        bottom: '',
        left: '43px'
      }
    },
    btnReturn: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/5185a878df3933f0c222be2cf9a376b3.png',
      style: {
        width: '189px',
        height: '53px',
        top: '27px',
        right: '74px',
        bottom: '',
        left: ''
      }
    },
    btnReturnLink: 'https://www.ssjj.cn/',
    btnDownLoad: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/36449970e47fcb337eb8f84dad40d27b.png',
      style: {
        width: '277px',
        height: '80px',
        top: '',
        right: '821px',
        bottom: '350px',
        left: ''
      }
    },
    giftImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/06/06/0bf0285b9ea6cb63f00c85f2caa37352.png',
      style: {
        width: '500px',
        height: '200px',
        top: '',
        right: '710px',
        bottom: '90px',
        left: ''
      }
    }
  },
  propsDesc: [
    {
      target: 'defaultBg',
      label: 'kv图',
      formType: 'image'
    },
    /* logo配置 */
    {
      target: 'logoImg',
      label: 'logo图片',
      formType: 'image'
    },
    /* 返回按钮配置 */
    {
      target: 'btnReturn',
      label: '返回按钮图片',
      formType: 'image'
    },
    {
      target: 'btnReturnLink',
      label: '返回按钮转链'
    },
    /* 下载按钮配置 */
    {
      target: 'btnDownLoad',
      label: '下载按钮图片',
      formType: 'image'
    },
    /* 礼包图配置 */
    {
      target: 'giftImg',
      label: '礼包图片',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
