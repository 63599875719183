<template>
  <div class="fixed-slider">
<!--    <div class="show-slider common-slider" v-show="!showSlider" @click="showSliderFunc(true)">-->
<!--      <div class="show-slider-text">展<br>开<br>导<br>航</div>-->
<!--      <div class="arrow-right"></div>-->
<!--    </div>-->
    <div class="version-data-menu common-slider"
         :style="{width: $MainWrap.sideBarBg[0].bgWidth, height: $MainWrap.sideBarBg[0].bgHeight, 'background-image': 'url(' + $MainWrap.sideBarBg[0].bgSrc + ')' }"
         v-if="showSlider"
         :datasrc="menuActiveIndex">
      <div class="slider-top">
        专属活动倒计时：<br><span>{{$MainWrap.sliderData.expire_left >= 0 && $MainWrap.sliderData.hour >= 0 ? `${$MainWrap.sliderData.expire_left}日${$MainWrap.sliderData.hour}时` : '已过期'}}</span>
      </div>
      <div class="slider-main" @click="checkHref">
        <a :href="'#'+ $MainWrap.part1Id" class="summary-menu-each menu-first"
           :hid="`${$MainWrap.part1Id}`"
           :style="{color: menuActiveIndex === `#${$MainWrap.part1Id}` ? $MainWrap.textHoverColor : $MainWrap.textColor, 'background-image': menuActiveIndex === `#${$MainWrap.part1Id}` ? 'url(' + $MainWrap.sideBarBg[0].chooseSlider + ')' : 'none'}"
           :class="{ isMenuActive: menuActiveIndex === `#${$MainWrap.part1Id}` }">
          <div class="summary-menu-text" :hid="`${$MainWrap.part1Id}`">{{$MainWrap.sideBarNavText1}}</div>
        </a>
        <a :href="'#'+ $MainWrap.part2Id" class="summary-menu-each menu-second"
           :hid="`${$MainWrap.part2Id}`"
           :style="{color: menuActiveIndex === `#${$MainWrap.part2Id}` ? $MainWrap.textHoverColor : $MainWrap.textColor, 'background-image': menuActiveIndex === `#${$MainWrap.part2Id}` ? 'url(' + $MainWrap.sideBarBg[0].chooseSlider + ')' : 'none'}"
           :class="{ isMenuActive: menuActiveIndex === `#${$MainWrap.part2Id}` }" >
          <div class="summary-menu-text" :hid="`${$MainWrap.part2Id}`">{{$MainWrap.sideBarNavText2}}</div>
        </a>
        <a :href="'#'+ $MainWrap.part3Id" class="summary-menu-each menu-third"
           :hid="`${$MainWrap.part3Id}`"
           :style="{color: menuActiveIndex === `#${$MainWrap.part3Id}` ? $MainWrap.textHoverColor : $MainWrap.textColor, 'background-image': menuActiveIndex === `#${$MainWrap.part3Id}` ? 'url(' + $MainWrap.sideBarBg[0].chooseSlider + ')' : 'none'}"
           :class="{ isMenuActive: menuActiveIndex === `#${$MainWrap.part3Id}` }">
          <div class="summary-menu-text" :hid="`${$MainWrap.part3Id}`">{{$MainWrap.sideBarNavText3}}</div>
        </a>
        <a :href="'#'+ $MainWrap.part4Id" class="summary-menu-each menu-four"
           :hid="`${$MainWrap.part4Id}`"
           :style="{color: menuActiveIndex === `#${$MainWrap.part4Id}` ? $MainWrap.textHoverColor : $MainWrap.textColor, 'background-image': menuActiveIndex === `#${$MainWrap.part4Id}` ? 'url(' + $MainWrap.sideBarBg[0].chooseSlider + ')' : 'none'}"
           :class="{ isMenuActive: menuActiveIndex === `#${$MainWrap.part4Id}` }">
          <div class="summary-menu-text" :hid="`${$MainWrap.part4Id}`">{{$MainWrap.sideBarNavText4}}</div>
        </a>
        <div v-if="$MainWrap.wdFrom != 4399" :style="{color: $MainWrap.miniBtnColor, 'border-color': $MainWrap.miniBtnColor}"
             class="mini-color-btn" @click="kf">联系客服</div>
      </div>
    </div>
<!--    <div v-show="showSlider" class="retract-expand common-slider" @click="showSliderFunc(false)">收 起<span class="arrow-left"></span></div>-->
  </div>
</template>

<script>

export default {
  name: 'OfficialActiveSliderBar',
  components: {
  },
  props: {
  },
  watch: {
    $route: {
      handler: function (newVal) {
        console.log(newVal, '--newVal--')
        this.menuActiveIndex = newVal.hash
      },
      immediate: true
    }
  },
  data () {
    return {
      menuActiveIndex: '#version-data-0',
      showSlider: false,
      zoomValue: 1,
      container: null
    }
  },
  inject: ['$MainWrap'],
  async created () {
  },
  mounted () {
    this.menuActiveIndex = window.location.hash
    console.log(window.location.hash, '--window.location.hash--')
    this.container = document.querySelector('#pageRenderParent') || window
    this.container.addEventListener('scroll', this.onScroll)
  },
  methods: {
    checkHref (e) {
      // const hid = e.target.getAttribute('hid')
      // if (hid) {
      //   console.log(e.target.getAttribute('hid'), '---ss-----')
      //   this.menuActiveIndex = window.location.hash
      // }
    },
    showPointDetail () {
      this.$MainWrap.openPointLog()
    },
    jumpTo (url) {
      window.open(url + window.location.search)
    },
    kf (url) {
      window.open('https://www.wooduan.com/service/service/630ebde509187f8e02edde1d')
    },
    onScroll (e) {
      // console.log(this._getScrollTop(e))
      const scrollTop = this._getScrollTop(e) + 10
      // const height0 = document.getElementById('version-data-1').offsetTop
      // const height1 = document.getElementById('version-data-2').offsetTop
      // const height2 = document.getElementById('version-data-3').offsetTop
      // const height3 = document.getElementById('version-data-4').offsetTop
      // // const height3 = document.getElementById('version-data-fourth').offsetTop
      this.showSlider = scrollTop > 400
      // if (scrollTop < height0) {
      //   this.menuActiveIndex = '#version-data-0'
      // } else if (scrollTop >= height0 && scrollTop < height1) {
      //   this.menuActiveIndex = '#version-data-1'
      // } else if (scrollTop >= height1 && scrollTop < height2) {
      //   this.menuActiveIndex = '#version-data-2'
      // } else if (scrollTop >= height2 && scrollTop < height3) {
      //   this.menuActiveIndex = '#version-data-3'
      // } else if (scrollTop >= height3) {
      //   this.menuActiveIndex = '#version-data-4'
      // }
    },

    _getScrollTop (e) {
      if (e.target.scrollTop !== undefined) {
        return e.target.scrollTop
      } else if (e.target.document) {
        return (
          e.target.document.documentElement.scrollTop ||
          window.pageYOffset ||
          e.target.document.body.scrollTop ||
          0
        )
      }
      return e.target.documentElement.scrollTop || e.target.body.scrollTop || 0
    },
    toTop (status) {
      window.scrollTo(0, 0)
    },
    showSliderFunc (status) {
      this.showSlider = status
    }
  }
}
</script>

<style scoped lang="scss">
.common-slider {
  -webkit-transition: all linear 400ms;
  transition: all linear 400ms;
}
.retract-expand {
  font-size: 27px;
  margin-top: 10px;
  font-weight: 500;
  color: #FDEDC2;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mini-color-btn {
  width: 100px;
  height: 25px;
  margin: 15px auto 0;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  border: 1PX solid;
  border-radius: 12px;
}
.slider-main {
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.slider-top {
  height: 200px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  padding: 140px 15px 0;
  text-align: center;
  span{
    color: #F02A2F;
  }
}
.version-data-menu {
  position: relative;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/slider-bg.png");
  .summary-menu-each {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-decoration: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 24px;
  }
  .isMenuActive {
    //background: url("../../../../assets/images/versionActivity/slider/choose-menu.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 35px;
  }
}
.arrow-left {
  width: 25px;
  height: 25px;
  background-size: 100% 100%;
  display: flex;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/arrow-left.png");
  margin-left: 15px;
}
.retract-expand {
  display: flex;
  justify-content: center;
}
.arrow-right {
  width: 25px;
  height: 25px;
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/arrow-right.png");
  margin: 10px auto 0;
}
.show-slider {
  width: 112px;
  height: 221px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //background-image: url("../../../../assets/images/versionActivity/slider/show-slider-bg.png");
  cursor: pointer;
}
.show-slider-text {
  font-size: 27px;
  font-weight: bold;
  color: #F7F1E4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.fixed-slider {
  position: fixed;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  z-index: 100;
}
</style>
