import { render, staticRenderFns } from "./Act3.vue?vue&type=template&id=7067b234&scoped=true"
import script from "./Act3.vue?vue&type=script&lang=js"
export * from "./Act3.vue?vue&type=script&lang=js"
import style0 from "./Act3.vue?vue&type=style&index=0&id=7067b234&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7067b234",
  null
  
)

export default component.exports