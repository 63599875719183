/**
 * 注意！任何组件配置 不可重名。即使是跨文件夹 也不可重名
 */

const modulesFiles = require.context('./', true, /.js$/) // /^S.*.js$/

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // const moduleName = modulePath.replace(/^.\/(.*)\.js/, '$1')
  let moduleName = modulePath.replace(/^.\/(.*)\.js/, '$1')
  if (moduleName.indexOf('/') > -1) {
    moduleName = moduleName.split('/')[1]
  }
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
delete modules.index
delete modules._mixin
// console.log(modules)
export const eventTpl = {
  type: '',
  target: '',
  fn: '',
  isChooseControlledComponent: false
}
export const typeMap = {
  box: '容器组件',
  base: '基础组件',
  complex: '复合组件',
  activity_five: '【活动】五合一',
  activity_topic: '【活动】专题',
  activity_topic2: '【活动】专题(new)',
  activity_other: '【活动】其他'
}
export default modules
