import _mixin from './_mixin'

export default {
  name: 'SAnimateImage',
  cnName: '动效图片',
  type: 'base',
  draggable: true,
  // 属性相关
  props: {
    src: 'https://rescdn-crash.ssjj.cn/website/2023/09/22/cf7b9609f347c09e62cb418d957b47fe.jpg',
    mask: 'https://rescdn-crash.ssjj.cn/website/2023/09/22/9f75ef15066409c66a403b716e35eec5.png',
    width: '1920px',
    height: '1000px'
  },
  propsDesc: [
    {
      target: 'src',
      label: '图片地址'
    },
    {
      target: 'mask',
      label: '遮罩地址'
    },
    {
      target: 'width',
      label: '宽'
    },
    {
      target: 'height',
      label: '高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    // position: 'absolute',
    top: '0'
    // display: 'inline-block'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
