<template>
  <div class="page-builder-group" v-show="visible" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FixedGroup',
  props: {
    isFlex: {
      type: Boolean,
      default: false
    },
    alignItems: {
      type: String,
      default: ''
    },
    justifyContent: {
      type: String,
      default: ''
    },
    flexDirection: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {
    style () {
      return {
        display: this.isFlex ? 'flex' : '',
        'align-items': this.alignItems,
        'justify-content': this.justifyContent,
        'flex-direction': this.flexDirection
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-builder-group{
}
</style>
