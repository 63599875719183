<template>
  <div class="s-component">
    <div class="ss-button"
         v-show="visible"
         :style="{...style, ...textStyleObj}"
         @click="handleClick"
         @mouseover="isHover = true"
         @mouseout="isHover = false">
      {{ text }}
    </div>
    <ModalShare ref="ModalShare" :activity-id="activityId" :tipText="tipText"/>
  </div>

</template>

<script>

import ModalShare from './_components/ModalShare.vue'

export default {
  name: 'SButtonShare',
  components: {
    ModalShare
  },
  props: {
    activityId: {
      type: String,
      default: ''
    },
    defaultBg: {
      type: String,
      default: ''
    },
    hoverBg: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '200px 150px' // 宽高
    },
    text: {
      type: String,
      default: ''
    },
    btnOtherStyle: {
      type: String,
      default: '{}'
    },
    tipText: {
      type: String,
      default: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> '
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  computed: {
    style () {
      const arr = this.size.split(' ')
      return {
        width: arr[0] || '200px',
        height: arr[1] || '50px',
        'background-image': `url(${this.isHover ? (this.hoverBg || this.defaultBg) : this.defaultBg})`
      }
    },
    textStyleObj () {
      try {
        return JSON.parse(this.btnOtherStyle)
      } catch (e) {
        console.error('SButtonShare style 格式错误')
        return {}
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      this.$refs.ModalShare.open()
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: .3s;
  text-decoration: none;
  color: inherit;
  user-select: none;
  &:hover{
    filter: brightness(110%)
  }
}
</style>
