<!--海外登录组件-->
<template>
  <div class="s-login-oversea">
    <div class="user-info" v-if="!$route.query.hideLogin">
      <div class="btn-sign hv-highlight" v-if="!activeToken" @click="openLogin">{{signInText}}</div>
      <div class="user-wrap" :style="{color: color}" v-else>
        <span class="username cursor-pointer" @click="goUserCenter">{{ activeName }}</span>
        <span class="sign-out cursor-pointer" @click="signOut">{{ signOutText }}</span>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false"
               :visible.sync="loginVisible"
               :modal-append-to-body="false"
               :before-close="closeLogin"
               class="ss-dialog"
               width="480px">
      <div class="ss-close">
        <div class="ss-close-content" @click="closeLogin()">×</div>
      </div>
      <div id="uniLogin"></div>
    </el-dialog>
  </div>
</template>

<script>

const DEPLOY_ENV = process.env.VUE_APP_DEPLOY_ENV
const fromMap = {
  devVn: 'Website_401001001',
  testVn: 'Website_401001001',
  Vn: 'Website_401001001',
  devEurope: 'Website_301001003',
  testEurope: 'Website_301001003',
  Europe: 'Website_301001003'
}
const defaultFrom = fromMap[DEPLOY_ENV]
// 用户中心
export const centerLinkMap = {
  develop: 'http://172.31.2.176:63023/',
  development: 'http://172.31.2.176:63023/',

  devVn: 'https://u-test.truykichpc.vn/',
  devEurope: 'http://172.31.2.176:63023/',

  testEurope: 'http://172.31.2.176:63023/', // 内网-欧洲-测试环境
  // seaDev: 'http://172.31.2.176:63034/', // 内网-东南亚-测试环境
  // testEurope: 'https://u-test-azure.battleteams2.com/', // 外网测试环境
  testVn: 'https://u-test.truykichpc.vn/', // 外网测试环境

  vn: 'https://u.truykichpc.vn/',
  Europe: 'https://u-eu.battleteams2.com/'
}

const modeMap = {
  dev: 'dev',
  devVn: 'devSea',
  testVn: 'testSea',
  vn: 'sea',
  devEurope: 'devEurope',
  testEurope: 'testEurope',
  Europe: 'Europe'
}
export default {
  name: 'SLoginOversea',
  components: {},
  props: {
    tokenName: {
      type: String,
      default: 'activeToken'
    },
    language: {
      type: String,
      default: 'en-us'
    },
    signInText: {
      type: String,
      default: 'Sign In'
    },
    signOutText: {
      type: String,
      default: 'Sign Out'
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      loginVisible: false,
      openTab: 'login',
      activeToken: '',
      activeNick: '',
      activeAccount: '',
      activeWdId: '',
      activeName: ''
    }
  },
  inject: {
    eventBus: {
      default: null
    }
  },
  watch: {},
  computed: {},
  created () {
    const { token } = this.$route.query
    if (token) {
      this.setUserByQueryToken(token)
    } else {
      this.setUserInfo()
    }
    this.checkSdkInsert()
  },
  mounted () {},
  methods: {
    /**
     * 通过query 中的 token 设置用户信息
     * @param token
     */
    setUserByQueryToken (token) {
      console.log('--- 通过地址栏设置token ---')
      const tokenStr = token.replace(/-/g, '+').replace(/_/g, '/').split('.')[1]
      const userInfo = JSON.parse(window.atob(tokenStr))
      console.log('--- 通过地址栏token 设置用户信息 ---')
      this.setUserInfo({
        ...userInfo.user,
        user_token: token
      })
      const newQuery = JSON.parse(JSON.stringify(this.$route.query))
      delete newQuery.token
      this.$router.replace({
        path: this.$route.path,
        query: newQuery
      })
    },
    /**
     * 检查是否正常引入loginSdk
     */
    checkSdkInsert () {
      if (!window.uniLoginSdk) {
        console.error('uniLoginSdk未引入! 海外登录组件将无法正常使用')
      } else {
        window.$checkLogin = this.checkLogin
      }
    },
    checkLogin () {
      if (this.activeToken) {
        return true
      } else {
        this.openLogin()
        return false
      }
    },
    /**
     * 打开登录弹窗
     * @param tab {string} login/register 默认开启的tab
     */
    openLogin (tab = 'login') {
      this.loginVisible = true
      this.$nextTick(() => {
        console.log(process.env, '---NODE_ENV---')
        console.log(process.env.VUE_APP_DEPLOY_ENV, '---VUE_APP_DEPLOY_ENV---')
        // eslint-disable-next-line no-undef
        uniLoginSdk.init({
          el: '#uniLogin',
          mode: modeMap[DEPLOY_ENV] || 'master',
          language: this.language || 'en-us',
          tab,
          scene: 'website',
          // WEB端传A0001, 客户端传A0004
          app_key: 'A0001',
          // TODO 需区分大区环境 给 兜底from
          from: this.$route.query.from || defaultFrom,
          channel_id: this.$route.query.channel_id || '',
          sub_channel_id: this.$route.query.sub_channel_id || '',
          loginSuccess: (data) => {
            // this.$store.commit('user/SET_TOKEN', data.user_token)
            // this.$store.commit('user/SET_USERINFO', data)

            this.loginVisible = false
            this.closeLogin()
            console.log('> 登录成功')
            this.$emit('loginSuccess', true)

            this.setUserInfo(data)

            if (this.eventBus) {
              this.eventBus.fire('loginSuccess', data)
            }
          },
          thirdLogin: (data) => {
            console.log('> 调用三方登录')
            console.log(data)
          }
        })
      })
    },
    closeLogin (done) {
      console.log('> 关闭登录弹窗')
      // eslint-disable-next-line no-undef
      uniLoginSdk.destroy()
      this.loginVisible = false
      done && done()
    },
    goUserCenter () {
      const userCenterLink = `${this.centerLink}?lang=${window.localLang}&token=${this.token}`
      console.log(userCenterLink, '---userCenterLink---')
      window.open(userCenterLink)
    },
    signOut () {
      this.setUserInfo({
        account: '',
        nick: '',
        wd_id: '',
        user_token: ''
      })
      this.$emit('signOut', true)
      if (this.eventBus) {
        this.eventBus.fire('signOut')
      }
    },

    setUserInfo (info) {
      console.log('--- 设置用户信息 ---')
      if (info) {
        const { account, nick, wd_id, user_token } = info
        const name = nick || account || wd_id || '--'
        localStorage.setItem(this.tokenName, user_token)
        localStorage.setItem('activeNick', nick)
        localStorage.setItem('activeAccount', account)
        localStorage.setItem('activeWdId', wd_id)
        localStorage.setItem('activeName', name)

        this.activeToken = user_token
        this.activeNick = nick
        this.activeAccount = account
        this.activeWdId = wd_id
        this.activeName = name
      } else {
        this.activeToken = localStorage.getItem(this.tokenName) || ''
        this.activeName = localStorage.getItem('activeName') || ''
      }
    }
  },
  beforeDestroy () {}
}
</script>

<style lang="scss" scoped>
.s-login-oversea{
  .user-info{
    .btn-sign{}
    .user-wrap{
      .username{
        margin-right: 20px;
      }
    }
  }
}
.ss-dialog{
  .ss-close {
    color: #c8c9cc;
    font-size: 22px;
    cursor: pointer;
    text-align: right;
    position: relative;
    transition: .3s;
    z-index: 1;
    &:hover{
      color: #fff;
    }
    &.ss-close--out{
      position: absolute;
      right: -60px;
      top: -50px;
    }
  }
  .ss-close-content {
    width: 70px;
    height: 70px;
    text-align: center;
    vertical-align: center;
    font-size: 36PX;
    display: inline-block;
    top: 0;
    right: 0;
    position: absolute;
  }
  &::v-deep(.el-dialog){
    background-color: transparent;

    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0;
      word-break: break-word !important;
    }
  }
}
</style>
