import _mixin from './_mixin'
// import BaseGroup from './BaseGroup'

const defaultImg1 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/9f75ef15066409c66a403b716e35eec5.png'
const activeImg1 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/cf7b9609f347c09e62cb418d957b47fe.png'
const defaultImg2 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/3cfd21a6c4b6cbb1870b02cbc47abb95.png'
const activeImg2 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/c58ac7a95f265e81e01c0c8246e92b6c.png'
const defaultImg3 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/5b6b5b68822cd4a6f15db7dbc644d45a.png'
const activeImg3 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/9bd6b0759757562f3a0fff269145024c.png'
const defaultImg4 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/513130f101e1f6231640427ba43eeab9.png'
const activeImg4 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/e3d19fcf6e1af1593c030eaa72161286.png'
const defaultImg5 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/e9810be6ca12781292e119323d01bf99.png'
const activeImg5 = 'https://rescdn-crash.ssjj.cn/website/2023/05/22/2d3ce4da55a642c3d2965ed52446ad49.png'
// function genId () {
//   return Date.now()
// }

export default {
  name: 'STab',
  cnName: 'Tab标签页',
  type: 'complex',
  isTab: true,
  // 属性相关
  props: {
    active: 0,
    btnList: [
      { default: defaultImg1, active: activeImg1, isNeedLogin: 0 },
      { default: defaultImg2, active: activeImg2, isNeedLogin: 1 },
      { default: defaultImg3, active: activeImg3, isNeedLogin: 0 },
      { default: defaultImg4, active: activeImg4, isNeedLogin: 0 },
      { default: defaultImg5, active: activeImg5, isNeedLogin: 0 }
    ],
    btnPosition: '0 _ _ 0',
    btnSize: '384px 111px',
    btnDirection: 'row',
    btnMargin: '0 0 0 0',
    childrenList: []
  },
  propsDesc: [
    {
      target: 'active',
      label: 'active',
      formType: 'input-number'
    },
    {
      target: 'btnSize',
      label: '按钮尺寸'
    },
    {
      target: 'btnMargin',
      label: '按钮外边距',
      description: '上 右 下 左 (不存在用"_"代替，例: 10px 10px _ _)'
    },
    {
      target: 'btnPosition',
      label: '按钮位置',
      description: '上 右 下 左 (不存在用"_"代替，例: 10px 10px _ _)'
    },
    {
      target: 'btnDirection',
      label: '按钮方向',
      formType: 'radio',
      options: [
        { label: '横向', value: 'row' },
        { label: '竖向', value: 'column' }
      ]
    },
    {
      target: 'btnList',
      label: '按钮列表',
      formType: 'list',
      listConfig: [
        {
          title: '按钮图',
          target: 'default'
        },
        {
          title: '按钮激活图',
          target: 'active'
        },
        {
          title: '是否需要登录',
          target: 'isNeedLogin'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '100%',
    height: 'auto',
    padding: '0 0 0 0'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: [
    // {
    //   id: genId() + '_BaseGroup_0',
    //   ...BaseGroup
    // },
    // {
    //   id: genId() + '_BaseGroup_1',
    //   ...BaseGroup
    // }
  ]
}
