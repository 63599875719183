<template>
  <img
    class="s-back-top hv-highlight"
    :src="src"
    alt=""
    :style="{width, height}"
    @click="scrollTo(0)">
</template>

<script>
export default {
  name: 'SBackTop',
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: 'auto'
    },
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true,
      container: null
    }
  },
  mounted () {
    this.container = document.querySelector('#pageRenderParent') || window
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    scrollTo (top) {
      this.container.scrollTo({
        top,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.s-back-top {
  display: block;
}
</style>
