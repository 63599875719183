<!--领取成功弹窗-->
<template>
  <ModalCommon ref="ModalCommon"
                    title="恭喜你获得">
    <div class="wrap">
      <div class="content" >
        <div class="img-list">
          <div class="item-wrap" v-for="(item, index) in list" :key="index">
            <img :src="item.icon" alt="">
            <p>{{item.name}} <span v-if="item.amount && item.amount > 1"> x {{item.amount}}</span></p>
          </div>
        </div>
      </div>
<!--      <div class="text">{{ tipText }}</div>-->
      <div class="tip" :style="{color: $MainWrap.textColorLevel1}">
        虚拟道具请前往游戏内邮箱查收
        <span v-if="showAddress">
          ，实物奖励请填写<span class="btn-address" @click="openAddress" :style="{color: $MainWrap.textColorActive}" >收货地址</span>
        </span>
      </div>
      <div class="s-btn-wrap">
        <div :style="modalConfirmBtnStyleObj" class="btn btn-confirm hv-highlight" @click="close">{{btnText}}</div>
      </div>
    </div>
  </ModalCommon>

</template>

<script>
import ModalCommon from './ModalCommon.vue'
export default {
  name: 'ModalReceive',
  components: {
    ModalCommon
  },
  props: {
    title: {
      type: String,
      default: '恭喜获得'
    },
    btnText: {
      type: String,
      default: '开心收下'
    }
  },
  inject: {
    // 由 $MainWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      visible: false,
      shareLink: '',
      coverUrl: '',
      list: [],
      tipText: '请前往游戏内邮箱查收',
      showAddress: true
    }
  },
  computed: {
    modalConfirmBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalConfirmBtnStyle)
      } catch (e) {
        console.error('ModalReceiveBtnStyle 格式错误')
        return {}
      }
    }
  },
  async mounted () {
  },
  methods: {
    /**
     * 打开弹窗
     * @param awardsList 奖励列表 [{name: 'xxx', icon: 'xxx.png', amount: 1}]
     * @param showAddress 是否显示填写地址按钮
     * @returns {Promise<void>}
     */
    async open (awardsList, showAddress = true) {
      if (!awardsList || !awardsList.length) {
        console.error('awardsList is empty')
        return
      }
      this.showAddress = showAddress
      this.list = awardsList || []
      this.$refs.ModalCommon.open()
    },
    close () {
      this.$refs.ModalCommon.close()
    },

    openAddress () {
      this.$MainWrap.openAddress()
    }
  }
}
</script>

<style scoped lang="scss">
//@import "../common.scss";

.wrap{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
.content{
  flex: 1;
  margin-bottom: 0px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.img-list{

  height: 300px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .item-wrap{
    margin: 0 20px 10px;
    img{
      display: block;
      width: 173px;
      height: 161px;
      margin: 0 auto 10px;
      border-radius: 4px;
      box-shadow: 0 0 5px #ccc;
      padding: 15px;
      background-color: rgba(0, 0, 0, 0.3);
      object-fit: contain;
    }
    p{
      font-size: 20px;
      letter-spacing: normal;
      text-align: center;
      //color: #fff;
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    }
  }
}

.tip{
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  .btn-address{
    text-decoration: underline;
    font-weight: 700;
  }
  //text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
//.text{
//  text-align: center;
//  font-size: 24px;
//  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
//}
.s-btn-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 50px;
}
.btn-confirm{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 222px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  cursor: pointer;
}
</style>
