<template>
  <img
    class="s-image"
    :class="{'pointer': !!link}"
    :src="src"
    alt=""
    :style="{width, height}"
    @click="handleClick">
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    },
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      if (!this.link) return
      window.open(this.link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.s-image {
  display: block;

  &.pointer {
    cursor: pointer;
  }
}
</style>
