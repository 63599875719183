import _mixin from '../_mixin'

export default {
  name: 'SAllChannelActivity',
  cnName: '全渠道回流活动',
  type: 'activity_other',
  preview: 'https://rescdn.ssjj.cn/website/2024/05/17/1f251f33e8d30b965516cb67201f8eda.jpg',
  // 属性相关
  props: {
    part1Id: 'version-data-1',
    part2Id: 'version-data-2',
    part3Id: 'version-data-3',
    part4Id: 'version-data-4',
    part1Top: '280px', // 活动1位置
    part1Obj: [
      {
        bgWidth: '1368px',
        bgHeight: '476px'
      }
    ],
    part1Btn: [
      {
        btnWidth: '249px',
        btnHeight: '136px'
      }
    ],

    part2Top: '700px', // 活动2位置

    part2Obj: [
      {
        bgWidth: '1320px',
        bgHeight: '586px',
        bgSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/65e43ca70a193f14ce809ebbcd7c2ab5.png',
        itemWidth: '318px',
        itemHeight: '378px',
        padding: '30px 0 0 0'
      }
    ],
    part2ItemObj: [
      {

      }
    ],
    part2Btn: [
      {
        btnWidth: '166px',
        btnHeight: '91px'
      }
    ],

    part3Top: '1120px', // 活动3位置

    part3Obj: [
      {
        bgWidth: '1322px',
        bgHeight: '684px',
        bgSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/1064e7f8d0f93ead15daa5cc92bc1f5c.png',
        itemWidth: '197px',
        itemHeight: '445px',
        padding: '0 0 0 0'
      }
    ],
    part3Btn: [
      {
        btnWidth: '166px',
        btnHeight: '91px'
      }
    ],

    part4Top: '1650px', // 活动4位置
    part4SpaceBetween: '20', // 活动4每列间距
    part4SlidesPerView: '8', // 活动4列数
    part4Record: '{"top": "-700px", "left": "100px"}', // 活动4抽奖记录样式
    part4Obj: [
      {
        bgWidth: '1600px',
        bgHeight: '300px',
        bgSrc: 'https://rescdn.ssjj.cn/website/2024/05/20/d1bcdb188edd8de8443a32500292d87e.png',
        leftSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/2c23987cb55cd7e8d0ef8cb987b2000b.png',
        rightSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/4560ca1a1eaeca4764a6a9fb42732f75.png',
        arrowWidth: '116px',
        arrowHeight: '116px',
        itemWidth: '200px',
        itemHeight: '200px',
        padding: '0 0 0 0'
      }
    ],
    part4Btn: [
      {
        btnWidth: '485px',
        btnHeight: '152px',
        bgSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/ae2988927cdac30e023088514b001166.png',
        textBgSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/7dd3b9e1d2b2a24cce411ef40440401f.png',
        textColor: '#000000',
        btnTextColor: '#ffffff'
      }
    ],
    mainHeight: '4100px',
    bg: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/20/2b2f750f2e78c8e9cdec60ca8d28c9e6.jpg'
    },
    // ===== 活动 ID 配置 =====
    activityId1: 'OJLS324A2KLROICVFGMK4ZHDE4',
    activityId2: 'PETA67YB75E55UCPJDW722XHT4',
    activityId3: 'JEUTF72XNQ6OLMRYUUJ5MYL3T4',
    activityId4: 'NANZ4L4P2FQKB53CG6J2BLDBXE',
    // ===== text color =====
    textColorLevel1: '#333',
    textColorLevel2: '#666',
    textColorLevel3: '#999',
    textColorActive: '#C3636B',
    miniBtnColor: '#EA0037',

    // ===== 领取按钮 =====
    // 未开始按钮
    receiveBtn0: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/234450e150aee7449299fb3361673a2b.png'
    },
    // 未完成按钮
    receiveBtn1: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/234450e150aee7449299fb3361673a2b.png'
    },
    // 领取按钮
    receiveBtn2: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/234450e150aee7449299fb3361673a2b.png'
    },
    // 已领取按钮
    receiveBtn3: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/07ad6dacffe1694a0f3f2497dff3cde9.png'
    },
    // 已过期按钮
    receiveBtn4: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/96466c62a8fdfc9289e818cfc06b2981.png'
    },
    // ===== 抽奖按钮 =====
    // drawActiveItem: '1,2',
    drawBgColor: '#C4CCE6,#E8E9EC',
    drawBgColorActive: '#FFBE57,#FFE8BB',

    // ====== 侧边栏 ======
    sideBarBg: [
      {
        bgSrc: 'https://rescdn.ssjj.cn/website/2024/05/17/36cc90569a9f479fdca8086845ad5deb.png',
        bgWidth: '192px',
        bgHeight: '523px',
        chooseSlider: 'https://rescdn.ssjj.cn/website/2024/05/17/9f27b4f75b3a514980f2412348ee61f6.png'
      }
    ],
    textColor: '#000',
    textHoverColor: '#fff',
    sideBarNavText1: '回归一键领',
    sideBarNavText2: '战斗免费领',
    sideBarNavText3: '豪礼天天领',
    sideBarNavText4: '大奖免费抽',

    // 弹窗相关
    modalBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/05/17/a6bba4d3e7bcc51f1ef47eeb6e40cf6d.png',
      style: {
        width: '750px',
        height: '650px'
      }
    },
    modalCloseIcon: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/13/f76e2285005d4f4cdc20b79f15a87f5b.png',
      style: {
        width: '54px',
        height: '42px',
        right: '-60px',
        top: '0px'
      }
    },
    modalTitleStyle: '{"color": "#A81548", "opacity": "0.8", "font-weight": "700"}',
    modalTextStyle: '{"color": "#ffffff"}',
    modalConfirmBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/15/99b4684fb700b34aa5da97b1024be328.png)","color": "#FFF", "font-weight": "700"}',
    modalCancelBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/15/b4c489022fc32a83793352f2e4d29c78.png)","color": "#FFF", "font-weight": "700"}'
  },
  propsDesc: [
    {
      target: 'part1Top',
      label: '一键领活动定位'
    },
    {
      target: 'part1Id',
      label: '一键领活动锚点'
    },
    {
      target: 'part2Id',
      label: '额外福利活动锚点'
    },
    {
      target: 'part3Id',
      label: '登录活动锚点'
    },
    {
      target: 'part4Id',
      label: '抽奖活动锚点'
    },
    {
      target: 'part1Obj',
      label: '一键领活动配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'bgWidth'
        },
        {
          title: '图片高度',
          target: 'bgHeight'
        }
      ]
    },
    {
      target: 'part1Btn',
      label: '一键领按钮配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'btnWidth'
        },
        {
          title: '图片高度',
          target: 'btnHeight'
        }
      ]
    },
    {
      target: 'part2Top',
      label: '战斗领活动定位'
    },
    {
      target: 'part2Obj',
      label: '战斗领活动配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'bgWidth'
        },
        {
          title: '图片高度',
          target: 'bgHeight'
        },
        {
          title: '图片背景链接',
          target: 'bgSrc'
        },
        {
          title: '物品图宽度',
          target: 'itemWidth'
        },
        {
          title: '物品图高度',
          target: 'itemHeight'
        },
        {
          title: '容器间距',
          target: 'padding'
        }
      ]
    },
    {
      target: 'part2Btn',
      label: '战斗领按钮配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'btnWidth'
        },
        {
          title: '图片高度',
          target: 'btnHeight'
        }
      ]
    },
    {
      target: 'part3Top',
      label: '额外福利活动定位'
    },
    {
      target: 'part3Obj',
      label: '额外福利活动配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'bgWidth'
        },
        {
          title: '图片高度',
          target: 'bgHeight'
        },
        {
          title: '图片背景链接',
          target: 'bgSrc'
        },
        {
          title: '物品图宽度',
          target: 'itemWidth'
        },
        {
          title: '物品图高度',
          target: 'itemHeight'
        },
        {
          title: '容器间距',
          target: 'padding'
        }
      ]
    },
    {
      target: 'part3Btn',
      label: '额外福利按钮配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'btnWidth'
        },
        {
          title: '图片高度',
          target: 'btnHeight'
        }
      ]
    },
    {
      target: 'part4Top',
      label: '抽奖活动定位'
    },
    {
      target: 'part4SpaceBetween',
      label: '抽奖活动每项间距'
    },
    {
      target: 'part4SlidesPerView',
      label: '抽奖活动列数'
    },
    {
      target: 'part4Record',
      label: '抽奖记录按钮样式',
      formType: 'textarea'
    },
    {
      target: 'part4Obj',
      label: '免费抽活动配置',
      formType: 'list',
      listConfig: [
        {
          title: '背景图片宽度',
          target: 'bgWidth'
        },
        {
          title: '背景图片高度',
          target: 'bgHeight'
        },
        {
          title: '图片背景链接',
          target: 'bgSrc'
        },
        {
          title: '向左箭头链接',
          target: 'leftSrc'
        },
        {
          title: '向右箭头链接',
          target: 'rightSrc'
        },
        {
          title: '箭头按钮宽度',
          target: 'arrowWidth'
        },
        {
          title: '箭头按钮高度',
          target: 'arrowHeight'
        },
        {
          title: '物品图宽度',
          target: 'itemWidth'
        },
        {
          title: '物品图高度',
          target: 'itemHeight'
        },
        {
          title: '容器间距',
          target: 'padding'
        }
      ]
    },
    {
      target: 'part4Btn',
      label: '免费抽按钮配置',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'btnWidth'
        },
        {
          title: '图片高度',
          target: 'btnHeight'
        },
        {
          title: '图片背景链接',
          target: 'bgSrc'
        },
        {
          title: '再抽X次背景链接',
          target: 'textBgSrc'
        },
        {
          title: '再抽X次文字色值',
          target: 'textColor'
        },
        {
          title: '抽奖文字颜色',
          target: 'btnTextColor'
        }
      ]
    },
    {
      target: 'mainHeight',
      label: '主体高度(除头部)'
    },
    {
      target: 'bg',
      label: '背景图',
      formType: 'image'
    },
    {
      target: '=== 活动 ID 配置 ===',
      formType: 'divider'
    },
    {
      target: 'activityId1',
      label: '一键领'
    },
    {
      target: 'activityId2',
      label: '战斗领'
    },
    {
      target: 'activityId3',
      label: '额外福利'
    },
    {
      target: 'activityId4',
      label: '免费抽'
    },
    {
      target: '公共文本颜色',
      formType: 'divider'
    },
    {
      target: 'textColorLevel1',
      label: '一级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel2',
      label: '二级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel3',
      label: '三级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorActive',
      label: '活跃文本颜色',
      formType: 'color'
    },
    {
      target: 'miniBtnColor',
      label: '小按钮颜色',
      formType: 'color'
    },

    {
      target: '---补给商店列表---',
      formType: 'divider'
    },
    {
      target: 'shopList',
      label: '购买物品列表',
      formType: 'list',
      listConfig: [
        {
          title: '物品图',
          target: 'awardImg'
        },
        {
          title: '按钮图',
          target: 'btnImg'
        }
      ]
    },
    {
      target: '--抽奖相关--',
      formType: 'divider'
    },
    // {
    //   target: 'drawActiveItem',
    //   label: '高亮显示项'
    // },
    {
      target: 'drawBgColor',
      label: '默认背景过渡色'
    },
    {
      target: 'drawBgColorActive',
      label: '高亮背景过渡色'
    },
    {
      target: '-----领取按钮-----',
      formType: 'divider'
    },
    {
      target: 'receiveBtn0',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn1',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn2',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn3',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn4',
      label: '已过期按钮',
      formType: 'image'
    },
    {
      target: 'sideBarBg',
      label: '侧边栏背景',
      formType: 'list',
      listConfig: [
        {
          title: '图片宽度',
          target: 'bgWidth'
        },
        {
          title: '图片高度',
          target: 'bgHeight'
        },
        {
          title: '图片背景链接',
          target: 'bgSrc'
        },
        {
          title: '选中态背景',
          target: 'chooseSlider'
        }
      ]
    },
    {
      target: 'textColor',
      label: '侧边栏默认色值',
      formType: 'color'
    },
    {
      target: 'textHoverColor',
      label: '侧边栏选中色值',
      formType: 'color'
    },
    {
      target: 'sideBarNavText1',
      label: '导航文本1'
    },
    {
      target: 'sideBarNavText2',
      label: '导航文本2'
    },
    {
      target: 'sideBarNavText3',
      label: '导航文本3'
    },
    {
      target: 'sideBarNavText4',
      label: '导航文本4'
    },
    {
      target: '===== 公共弹窗 ======',
      formType: 'divider'
    },
    {
      target: 'modalBg',
      label: '弹窗背景图',
      formType: 'image'
    },
    {
      target: 'modalCloseIcon',
      label: '关闭按钮',
      formType: 'image'
    },
    {
      target: 'modalTitleStyle',
      label: '标题样式',
      formType: 'textarea'
    },
    {
      target: 'modalTextStyle',
      label: '文本样式',
      formType: 'textarea'
    },
    {
      target: 'modalConfirmBtnStyle',
      label: '确认按钮样式',
      formType: 'textarea'
    },
    {
      target: 'modalCancelBtnStyle',
      label: '取消按钮样式',
      formType: 'textarea'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: [
    // {
    //   id: genId() + '_BaseGroup_0',
    //   ...BaseGroup
    // },
    // {
    //   id: genId() + '_BaseGroup_1',
    //   ...BaseGroup
    // }
  ]
}
