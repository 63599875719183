<template>
  <div class="address-box">
    <div class="btn-write img-bg" :style="btnStyle" @click="openAddress">{{btnWriteText}}</div>
    <AddressModel
      :modelShow.sync="addressModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :modelBgSize="modelBgSize"
      :addressTitle="addressTitle"
      :addressTitleSize="addressTitleSize"
      :btnStatus="btnStatus"
      :btnColor="btnColor"
      :btnSize="btnSize"
      :btnSubmitText="btnSubmitText"
      :btnReturnText="btnReturnText"
      :btnConfirmText="btnConfirmText"
      :addressStatus="addressModel.addressStatus"
      :addressFormData="addressModel.addressFormData"
      @saveAddress="saveAddress"
    ></AddressModel>
    <!--提示弹窗-->
    <TipModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipBgSize="modelBgSize"
      :tipTitle="tipTitle"
      :tipTitleSize="tipTitleSize"
      :btnStatusOne="btnStatus"
      :tipBtnColor="btnColor"
      :tipBtnText="btnCloseText"
      :tipBtnSize="btnSize"
      :contentText="tipModel.text"
    >
    </TipModel>
  </div>
</template>

<script>
import AddressModel from '@/components/BuilderComponents/Dialog/AddressModel.vue'
import TipModel from '@/components/BuilderComponents/Dialog/TipModel.vue'

export default {
  name: 'SAddress',
  components: { AddressModel, TipModel },
  props: {
    activityId: {
      type: String,
      default: ''
    },
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelBgSize: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    addressTitle: {
      type: String,
      default: ''
    },
    addressTitleSize: {
      type: String,
      default: '719px 39px'
    },
    btnStatus: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: '220px 66px'
    },
    btnWriteText: {
      type: String,
      default: ''
    },
    btnSubmitText: {
      type: String,
      default: ''
    },
    btnReturnText: {
      type: String,
      default: ''
    },
    btnConfirmText: {
      type: String,
      default: ''
    },
    btnCloseText: {
      type: String,
      default: ''
    },
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      addressModel: {
        show: false,
        // 0:输入态 1：锁定态 2：终态
        addressStatus: 0,
        // 地址填写弹窗
        addressFormData: {
          name: '',
          phone: '',
          address: ''
        }
      },
      // 提示弹窗文案
      tipModel: {
        show: false,
        text: ''
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    btnStyle () {
      const arr = this.btnSize.split(' ')
      const width = arr[0] || '220px'
      const height = arr[1] || '66px'
      return {
        width,
        height,
        color: `${this.btnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatus})`
      }
    }
  },
  methods: {
    openAddress () {
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/address',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.addressModel.show = true
        if (res.mobile && res.address) {
          this.addressModel.addressStatus = 2
          this.addressModel.addressFormData.name = res.real_name
          this.addressModel.addressFormData.phone = res.mobile
          this.addressModel.addressFormData.address = res.address
        } else {
          this.addressModel.addressStatus = 0
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 保存地址
    saveAddress (formData) {
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/address',
        method: 'post',
        data: { a_id: this.activityId, mobile: formData.phone, real_name: formData.name, address: formData.address }
      }).then((res) => {
        console.log('地址填写保存成功')
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .btn-write {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
      filter: brightness(120%);
    }
  }
</style>
