import _mixin from './_mixin'

export default {
  name: 'SRecord',
  cnName: '奖品记录列表',
  type: 'complex',
  needLogin: true,
  // 属性相关
  props: {
    /* 记录列表 */
    activityId: 'MSAKLOPHOUWOC4DK6SXPTCL2GM',
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    modelColor: '#AD272D',
    recordBgSize: '750px 450px',
    recordTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/0f6b622310d181295f0412ecb3caf20d.png',
    recordTitleSize: '419px 29px',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    btnStatusTwo: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/33939d997b271886355ee5c40a4dc885.png',
    recordBtnColor: '#FEEACB',
    recordBtnSize: '184px 55px',
    btnCloseText: '关闭',
    btnRecordText: '查看奖品',
    /* 提示弹窗配置 */
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px'
  },
  propsDesc: [
    {
      target: '记录列表配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动地址id'
    },
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'recordBgSize',
      label: '记录弹窗背景大小'
    },
    {
      target: 'recordTitle',
      label: '记录弹窗标题'
    },
    {
      target: 'recordTitleSize',
      label: '记录弹窗标题尺寸',
      description: '宽 高'
    },
    {
      target: 'btnStatusOne',
      label: '按钮背景1'
    },
    {
      target: 'btnStatusTwo',
      label: '按钮背景2'
    },
    {
      target: 'recordBtnColor',
      label: '按钮颜色'
    },
    {
      target: 'recordBtnSize',
      label: '按钮大小'
    },
    {
      target: 'btnCloseText',
      label: '关闭文案'
    },
    {
      target: 'btnRecordText',
      label: '查看奖品文案'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
