import _mixin from './_mixin'

export default {
  name: 'SLoginVn',
  cnName: '登录组件(越南)',
  type: 'complex',
  // 属性相关
  props: {
    signInText: 'Sign In',
    signOutText: 'Sign Out'
  },
  propsDesc: [
    {
      target: 'signInText',
      label: '登录按钮文案'
    },
    {
      target: 'signOutText',
      label: '退出按钮文案'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    top: '112px',
    right: '30px',
    color: '#fff',
    'font-size': '20px',
    'z-index': 10
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
