import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopicActivity',
  cnName: '精彩活动',
  type: 'activity_topic',
  isTab: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/04/04/e42735a38c66f215ad391a02a40928cb.jpeg',
  props: {
    ..._mixinWrap.props,

    tabList: [
      {
        tab: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        tabSelect: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png',
        activityImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        activityText: '嗨嗨，你想和A-SOUL一起打游戏吗？一个魂儿开年福利来咯！报名时间：2月10日~2月18日，直播时间：2月24日 20：00，爱豆上线，带你开黑！'
      },
      {
        tab: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/b20d125275cc2682f7df03aa04009837.png',
        tabSelect: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/daa371fe0c788350335aaabe5dff0d6a.png',
        activityImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/ac7f14b7a80d6869d96671d5d7df42be.jpg',
        activityText: '可在风麟玄鸾录活动中抽取武器，或选择使用墨韵兑换武器。'
      },
      {
        tab: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/ecb5af844118e152ed6d3fa53c2526aa.png',
        tabSelect: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/a8ee6fbe0e5f337abf309f7591005801.png',
        activityImg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/active3.4d71b782.jpg',
        activityText: '通过星耀无限模式掉落的碎片可合成'
      },
      {
        tab: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/d52b36bfbfe214d121e6b3cbe9b8de16.png',
        tabSelect: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/7f2980d8e09c0ef24c1429a4501bfe5f.png',
        activityImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/e8e92c5c6c13e1b8665a9dd972c4b436.jpg',
        activityText: ''
      },
      {
        tab: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/0424ad0e3e1be22b7329ad6a2f8bd68b.png',
        tabSelect: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/01bd0c9c7abefcb262300b4547ec3c31.png',
        activityImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/5a4835e9eecceea374e89675be456bed.jpg',
        activityText: '参与星坠远征 第一季，达到部分段位可领取到专属个性化外观奖励，进入游戏投票选出你期待的风格吧。'
      }
    ],

    tabBtnSize: '270px 70px',
    tabBtnAlign: 'space-evenly',
    tabContentSize: '1280px 550px'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,

    {
      target: 'tabList',
      label: 'tab切换',
      formType: 'list',
      listConfig: [
        {
          title: '默认tab',
          target: 'tab'
        },
        {
          title: '选中tab',
          target: 'tabSelect'
        },
        {
          title: '活动图',
          target: 'activityImg'
        },
        {
          title: '活动文案',
          target: 'activityText'
        }
      ]
    },
    {
      target: 'tabBtnSize',
      label: 'Tab按钮尺寸',
      formType: 'size'
    },
    {
      target: 'tabBtnAlign',
      label: 'Tab按钮对齐方式',
      formType: 'align'
    },
    {
      target: 'tabContentSize',
      label: 'Tab内容尺寸',
      formType: 'size'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
