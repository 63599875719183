import _mixin from './_mixin'
export default {
  name: 'SMobileFitNotices',
  cnName: 'H5适配提示层',
  type: 'complex',
  preview: '',
  // 属性相关
  props: {
    coverImg: '',
    img: 'https://rescdn.ssjj.cn/website/2023/10/11/bbcc400ce2438be3d0318582ffdb3b19.png',
    title: '温馨提示',
    tips: '当前页面在电脑端访问效果更佳',
    btnText: '我知道了'
  },
  propsDesc: [
    {
      target: '=== 完全覆盖 ===',
      formType: 'divider'
    },
    {
      target: 'coverImg',
      label: '图片'
    },
    {
      target: '=== 提示层可关闭 ===',
      formType: 'divider'
    },
    {
      target: 'img',
      label: '图片'
    },
    {
      target: 'title',
      label: '标题'
    },
    {
      target: 'tips',
      label: '提示'
    },
    {
      target: 'btnText',
      label: '按钮文字'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
