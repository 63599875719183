import _mixin from './_mixin'
export default {
  name: 'SFooter',
  cnName: '公共底部',
  // 属性相关
  props: {
    bgColor: '#000'
  },
  propsDesc: [{
    target: 'bgColor',
    label: '背景色'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
