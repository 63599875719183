<template>
  <STopic2Wrapper v-bind="$attrs">
    <div class="s-components s-version-welfare" v-show="visible">
      <div class="s-version-welfare-wrap">
        <el-carousel ref="carousel" :interval="4000" type="card" arrow="never" class="carousel">
          <el-carousel-item v-for="(item,index) in oList" :key="index">
            <component :is="_getLink(item.link)? 'a' : 'div'" :href="_getLink(item.link)" class="img-wrap" target="_blank">
              <img class="img" :src="item.img" alt="">
            </component>
          </el-carousel-item>
        </el-carousel>
        <img class="btn-prev" @click="toggle('prev')" :src="prevImg" alt=""/>
        <img class="btn-next" @click="toggle('next')" :src="nextImg" alt=""/>
      </div>
      <div class="news" v-if="oNews.length">
        <a :style="{color: curIndex === index ? newsColorHover : newsColor}"
           v-for="(item,index ) in oNews"
           @mouseover="curIndex = index"
           @mouseout="curIndex = -1"
           :key="item.title"
           :href="_getLink(item.link)"
           target="_blank"
           class="item">
          <span>{{item.title}}</span>
          <i class="el-icon-right"></i>
        </a>
      </div>
    </div>
  </STopic2Wrapper>
</template>

<script>
export default {
  name: 'STopic2VersionWelfare',
  props: {
    list: {
      type: Array,
      required: true
    },
    prevImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png'
    },
    nextImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/33b1279f41a2b1a9d796e076f996d306.png'
    },
    news: {
      type: Array,
      default: () => []
    },
    newsColor: {
      type: String,
      default: '#fff'
    },
    newsColorHover: {
      type: String,
      default: '#FFA94F'
    }
  },
  data () {
    return {
      visible: true,
      wdFrom: '',
      curIndex: -1
    }
  },
  computed: {
    oList () {
      return this.list.filter(item => {
        if (!item.showInChannel) {
          return true
        } else {
          const showList = item.showInChannel.split(',')
          return showList.includes(this.wdFrom)
        }
      })
    },
    oNews () {
      return this.news.filter(item => {
        if (!item.showInChannel) {
          return true
        } else {
          const showList = item.showInChannel.split(',')
          return showList.includes(this.wdFrom)
        }
      })
    }
  },
  created () {
    const { wdFrom, from } = this.$route.query
    if (wdFrom) {
      this.wdFrom = wdFrom
    } else if (from) {
      this.wdFrom = 'lenovo'
    } else {
      this.wdFrom = 'website'
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },

    toggle (type) {
      if (type === 'prev') {
        this.$refs.carousel.prev()
      } else {
        this.$refs.carousel.next()
      }
    },
    _getLink (url) {
      if (!url) return ''
      // 没有@说明只是给 官方渠道 配的链接
      if (!url.includes('@')) return url
      const links = url.split(',')
      const linkMap = {}
      links.forEach(item => {
        const [chanel, link] = item.split('@')
        linkMap[chanel] = link
      })
      return linkMap[this.wdFrom] || ''
    }
  }
}
</script>
<style lang="scss" scoped>
.s-components {
  padding-top: 330px;

  .s-version-welfare-wrap{
    position: relative;
    .carousel {
      height: 400px;
      width: 1600px;
      margin: 0 auto;
      overflow: hidden;
      .img-wrap {
        display: block;
        .img {
          display: block;
          margin: 0 auto;
          width: 724px;
          height: 363px;
          object-fit: contain;
        }
      }
    }
    .btn-prev,
    .btn-next{
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: .3s;
      width: 67px;
      height: 67px;
      object-fit: contain;
      opacity: .5;
      &:hover{
        opacity:1;
      }
    }
    .btn-prev{
      left: 300px;
    }
    .btn-next{
      right: 300px;
    }
  }

  .news{
    width: 750px;
    margin: 50px auto 0;
    opacity: .9;
    a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      font-size: 20px;
      margin-bottom: 10px;
      transition: .3s;
      span{
        flex: 1;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      i{
        flex-shrink: 0;
      }
      &:hover{
        color: #FFA94F;
        //text-decoration: underline;
      }
    }
  }
}
</style>
<style lang="scss">
.s-version-welfare {
  .el-carousel__mask {
    opacity: 0 !important;
  }
  .el-carousel__container{
    height: 363px;
  }
  .el-carousel__item--card {
    opacity: .8;
    filter: brightness(.5);
    &.is-active{
      opacity: 1;
      filter: brightness(1);
    }
  }
  .el-carousel__indicators{
    margin-top: 10px;
    .el-carousel__indicator{
      .el-carousel__button{
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }
      &.is-active{
        .el-carousel__button{
          width: 40px;
        }
      }
    }
  }

}
</style>
