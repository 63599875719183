import _mixin from './_mixin'

export default {
  name: 'SCommonBtn',
  cnName: '活动领取按钮',
  type: 'complex',
  needLogin: true,
  draggable: true,
  // 属性相关
  props: {
    activityId: 'XVTIMHG3F6GK3EAEIN725QBEP4',
    activityGroup: [
      { groupId: '1873' }
    ],
    // 未开始按钮
    btnNoBegin: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 未完成按钮
    btnUnfinished: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 领取按钮
    btnReceive: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/1661320b553ba86d9cb5dff06f16eb87.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 已领取按钮
    btnReceived: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/5fc479087b6e5cba9e794fff0cc20956.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    // 已过期按钮
    btnExpire: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png',
      style: {
        width: '281px',
        height: '79px'
      }
    },
    /* 弹窗配置 */
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/66ff37c9751d3b7b50f31fa4a5097b04.png',
      style: {
        width: '750px',
        height: '450px'
      }
    },
    modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/9d4318b1f4af8a822f0bda055005e4c7.png',
      style: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '-20px',
        right: '-30px',
        left: '-',
        bottom: '_',
        cursor: 'pointer'
      }
    },
    modelColor: '#C09248',
    /* 提示弹窗标题 */
    tipTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
      style: {
        width: '419px',
        height: '29px'
      }
    },
    // 关闭按钮
    btnClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/ff207bb79bb6dbb97c47300b993283bd.png',
      style: {
        width: '181px',
        height: '51px'
      }
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/eddd856a3fe7cf5bfaa08f333ad7fc5e.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    awardOtherDesc: '请前往邮箱查收奖励',
    // 奖励框的背景和大小配置
    awardBoxBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/208f60ae752f40ddbfeab59dd5b50fdf.png',
      style: {
        width: '151px',
        height: '126px'
      }
    },
    // 开心收下按钮
    btnHappy: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/fc38f8d82bb8022393c4fbfceaa21b6d.png',
      style: {
        width: '181px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    {
      target: 'activityId',
      label: '活动地址id'
    },
    {
      target: 'activityGroup',
      label: '活动礼包配置',
      formType: 'list',
      listConfig: [
        {
          title: '礼包id',
          target: 'groupId'
        }
      ]
    },
    {
      target: '不同状态的按钮',
      formType: 'divider'
    },
    {
      target: 'btnNoBegin',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'btnUnfinished',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'btnReceived',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'btnExpire',
      label: '已过期按钮',
      formType: 'image'
    },
    {
      target: '公共弹窗配置',
      formType: 'divider'
    },
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'modelClose',
      label: '弹窗关闭icon',
      formType: 'image'
    },
    {
      target: 'modelColor',
      label: '弹窗文案颜色'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题',
      formType: 'image'
    },
    {
      target: 'btnClose',
      label: '关闭按钮',
      formType: 'image'
    },
    /* 奖励弹窗配置 */
    {
      target: '奖励弹窗配置',
      formType: 'divider'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题',
      formType: 'image'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励框背景',
      formType: 'image'
    },
    {
      target: 'btnHappy',
      label: '开心收下按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
