<template>
  <div class="topic2-wrapper" :style="defaultStyle">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'STopic2Wrapper',
  props: {
    bg: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    defaultStyle () {
      return {
        width: this.bg?.style.width,
        height: this.bg?.style.height,
        'background-image': `url(${this.bg?.src})`
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.topic2-wrapper{
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
