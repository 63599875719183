<!--领取成功弹窗-->
<template>
  <ModalCommon ref="ModalCommon"
                    :title="config.title">
    <div class="content">
      <div class="tip">
        虚拟道具请前往游戏内邮箱查收
        <span v-if="config.needAddress">
          ，实物奖励请填写
          <span class="btn-address" @click="openAddress" :style="{color: $MainWrap.textColorActive}" >收货地址</span>
        </span>
      </div>
      <div class="list">
        <template v-if="list && list.length">
          <div class="item" v-for="item in list" :key="item.id">
            <span class="time">{{ item.created_at }}</span>
            <span class="text">获得【{{ item.gift_name }}】</span>
          </div>
        </template>
        <div v-else class="no-data" :style="{color: $MainWrap.textColorLevel3}">暂无数据</div>
      </div>
      <div class="pagination" v-if="total > 1" :style="{color: $MainWrap.textColorLevel1}">
        <div class="btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
        <div class="counter" @click="next">{{ page }}/{{ totalPage }}</div>
        <div class="btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </ModalCommon>

</template>

<script>
import ModalCommon from './ModalCommon.vue'
export default {
  name: 'ModalReceiveLog',
  components: {
    ModalCommon
  },
  props: {
    title: {
      type: String,
      default: '恭喜获得'
    },
    btnText: {
      type: String,
      default: '开心收下'
    }
  },
  inject: {
    // 由 MainWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      list: [],
      page: 1,
      total: 0,
      config: {
        title: '记录',
        api: '/v1/uni/receiveLog',
        activityId: '',
        pageSize: 10,
        needAddress: false
      }
    }
  },
  computed: {
    requestAPI () {
      return this.request ? this.request : window.$request
    },
    totalPage () {
      return Math.ceil(this.total / this.config.pageSize)
    }
  },
  async mounted () {
  },
  methods: {
    async open (options) {
      this.page = 1
      this.list = []
      if (options) {
        this.config = Object.assign({
          title: '记录',
          api: '/v1/uni/receiveLog',
          activityId: '',
          pageSize: 10,
          needAddress: false
        }, options)
      }
      this.getList()
      this.$refs.ModalCommon.open()
    },
    prev () {
      if (this.page > 1) {
        this.page--
        this.getList()
      }
    },
    next () {
      if (this.page < this.totalPage) {
        this.page++
        this.getList()
      }
    },
    getList () {
      this.requestAPI({
        url: this.config.api,
        method: 'get',
        params: {
          a_id: this.config.activityId,
          page: this.page,
          page_size: this.config.pageSize
        }
      }).then(data => {
        this.list = data.list
        this.total = data.total
        // console.log('this.total', this.total)
      })
    },
    openAddress () {
      this.$MainWrap.openAddress()
    },
    confirm () {
      this.config.onConfirm()
      this.close()
    },
    cancel () {
      this.config.onCancel()
      this.close()
    },
    close () {
      this.$refs.ModalCommon.close()
    }
  }
}
</script>

<style scoped lang="scss">
//@import "../common.scss";
.content{
  margin-bottom: 0px;
  font-size: 24px;
  padding: 0 60px;
  .tip{
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    .btn-address{
      text-decoration: underline;
      font-weight: 700;
    }
  }
  .list{
    padding: 10px 20px 0;
    height: 370px;
    overflow-y: auto;
    font-size: 18px;
    margin-bottom: 20px;
    .item{
      display: flex;
      margin-bottom: 15px;
      .time{
        width: 250px;
        flex-shrink: 0;
        margin-right: 20px;
      }
      .text{
        flex: 1;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  font-weight: 700;
  .counter{
    margin: 0 20px;
  }
}
.no-data{
  font-size: 18px;
  text-align: center;
  padding: 50px 0 0;
}
</style>
