import _mixin from '../_mixin'

export default {
  name: 'SAppointment',
  cnName: '申请资格',
  type: 'activity_other',
  needLogin: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/98af32050e5efa14b849fc26757247c9.jpg',
  props: {
    uniqueKey: '',
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/18/0bf0285b9ea6cb63f00c85f2caa37352.jpg',
      style: {
        width: '1920px',
        height: '1080px'
      }
    },
    btn: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/d7727332796838e69c63bf80ed4b8bf5.png',
      style: {
        width: '511px',
        height: '144px',
        top: '',
        right: '',
        bottom: '90px',
        left: '730px'
      }
    },
    btnDisabled: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/88cd16ff3921942639e5ef183a321936.png'
      // style: {
      //   width: '511px',
      //   height: '144px'
      // }
    },
    dialogBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/92f2d13257eab5bc493de18247b52365.png',
      style: {
        width: '1087px',
        height: '533px'
      }
    },
    confirmBtn: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/3741729c1293328e98ae185a279aa39d.png',
      style: {
        width: '391px',
        height: '124px'
      }
    },
    closeBtn: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/e42735a38c66f215ad391a02a40928cb.png',
      style: {
        width: '61px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    {
      target: 'uniqueKey',
      label: '唯一标识',
      placeholder: '必填, 用于区分不同的活动',
      required: true
    },
    {
      target: '图片配置',
      // label: '背景',
      formType: 'divider'
    },
    {
      target: 'bg',
      label: '背景',
      formType: 'image'
    },
    {
      target: 'btn',
      label: '按钮',
      formType: 'image'
    },
    {
      target: 'btnDisabled',
      label: '按钮禁用',
      formType: 'image'
    },
    {
      target: 'dialogBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'confirmBtn',
      label: '确认按钮',
      formType: 'image'
    },
    {
      target: 'closeBtn',
      label: '关闭按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
