<template>
    <div v-if="commonModelShow" class="model" :style="modelStyle">
<!--      <div class="img-bg close" :style="closeStyle" @click="close"></div>-->
      <div class="s-text" v-html="newText"></div>
    </div>
</template>

<script>
export default {
  name: 'SCommonModel',
  props: {
    // 公共弹窗属性
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: Object,
      required: true
    },
    /*  modelClose: {
      type: Object,
      required: true
    }, */
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      commonModelShow: false,
      newText: ''
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.commonModelShow = newVal
      },
      immediate: true
    },
    text: {
      handler: function (newVal) {
        this.newText = newVal.replaceAll('pt', 'px')
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const width = this.modelBg.style.width || '1184px'
      const height = this.modelBg.style.height || '764px'
      return {
        width,
        height,
        'background-image': `url(${this.modelBg.src})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      const width = this.modelClose.style.width || '40px'
      const height = this.modelClose.style.height || '30px'
      return {
        width,
        height,
        'background-image': `url(${this.modelClose.src})`,
        position: 'absolute',
        top: this.modelClose.style.top || '-',
        right: this.modelClose.style.right || '-',
        bottom: this.modelClose.style.bottom || '-',
        left: this.modelClose.style.left || '-'
      }
    }
  },
  methods: {
    /* close () {
      this.$emit('update:modelShow', false)
      this.commonModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.commonModelShow = true
    } */
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .model{
    position: relative;
    background-repeat: no-repeat;
    background-position: left top;
    padding: 50px 0 50px 70px;
    box-sizing: border-box;
  }
  .close{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .s-text{
    height: 665px;
    padding-right: 50px;
    overflow: auto;
  }
</style>
