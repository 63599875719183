import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/activity/:path',
    name: 'activity',
    component: () => import('../views/activity.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('../views/preview.vue')
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('../views/docs/component.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
