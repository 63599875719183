<template>
   <div class="content">
     <el-carousel class="s-topic2-map-carousel carousel" :autoplay="autoplay" :interval="5000" @change="handleChange" arrow="never">
       <el-carousel-item class="item" v-for="(item,index) in list" :key="index">
         <img :src="item.img" alt="">
         <template v-if="index === curIndex">
           <div class="cover">
             <slot :name="index + ''"></slot>
           </div>
         </template>
       </el-carousel-item>
     </el-carousel>
<!--     <div class="cover">-->
<!--       <slot>-->
<!--       </slot>-->
<!--     </div>-->
    </div>
</template>

<script>

export default {
  name: 'STopic2Map',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    tags: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    handleChange (val) {
      this.curIndex = val
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  position: relative;
  width: 1920px;
  height: 1000px;
  .carousel{
    width: 100%;
    height: 100%;
    .item{
      width: 100%;
      height: 100%;

      .cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // 防止覆盖底部 轮播锚点
        z-index: 2;
      }
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
</style>
<style lang="scss">
.s-topic2-map-carousel{
  .el-carousel__container{
    width: 100%;
    height: 100%;
  }
  .el-carousel__indicators--horizontal{
    bottom: 50px;
  }
  .el-carousel__button{
    margin: 0 5px;
    width: 60px;
    height: 6px;
  }
}
</style>
