<template>
  <div ref="animateImage">
    <div class="box" :style="{
        'background-image': `url(${src})`,
        width: width,
        height: height
      }">
      <div v-if="mask" class="mask" :style="{
        'background-image': `url(${src})`,
        '-webkit-mask-image': `url(${mask})`,
        '-moz-mask-image': `url(${mask})`,
        'mask-image': `url(${mask})`,
      }"></div>
    </div>
    <svg v-if="mask" xlmns="http://www.w3.org/2000/svg" version="1.1" style="display: none;">
      <filter id="heat" filterUnits="objectBoundingBox" x="0" y="0" width="100%" height="100%">
        <feTurbulence ref="feTurbulence" type="fractalNoise" numOctaves="1" seed="2" />
        <feDisplacementMap xChannelSelector="G" yChannelSelector="B" scale="22" in="SourceGraphic"  in2=""/>
      </filter>
    </svg>
  </div>
</template>

<script>
import { TimelineMax, TweenMax } from './gsap'
export default {
  name: 'SAnimateImage',
  props: {
    src: {
      type: String,
      required: true,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: 'auto'
    }
  },
  data () {
    return {
      visible: true
    }
  },
  watch: {
  },
  mounted () {
    this.initAnimate()
  },
  methods: {
    initAnimate () {
      if (!this.mask) return
      console.log('> 初始化 图片动画')
      const timeline = new TimelineMax({
        repeat: -1,
        yoyo: true
      })
      const feTurbulence = this.$refs.feTurbulence
      // eslint-disable-next-line new-cap
      const animate = new TweenMax.to(feTurbulence, 8, {
        onUpdate: function () {
          const bfX = this.progress() * 0.01 + 0.025
          const bfY = this.progress() * 0.003 + 0.01
          const bfStr = bfX.toString() + ' ' + bfY.toString()
          feTurbulence.setAttribute('baseFrequency', bfStr)
        }
      })
      timeline.add(animate, 0)
    },
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      if (!this.link) return
      window.open(this.link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.s-image {
  display: block;

  &.pointer {
    cursor: pointer;
  }
}

.box {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  filter: url(#heat);
}
</style>
