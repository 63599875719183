<template>
  <div class="tab" :style="{...iframeInfo.style, 'background-image': `url(${iframeInfo.src})`}">
    <iframe width="100%" height="100%" scrolling="no" frameborder ="0" :src="newIframeURL"></iframe>
  </div>
</template>

<script>
export default {
  name: 'SActivityIframe',
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    iframeInfo: {
      type: Object,
      required: true
    },
    iframeURL: {
      type: String,
      default: ''
    }
  },
  computed: {
    newIframeURL () {
      const token = localStorage.getItem('activeToken')
      if (token) {
        if (this.iframeURL.indexOf('?') <= -1) {
          return this.iframeURL + '?token=' + token + '&date=' + ((new Date()).getTime())
        } else {
          return this.iframeURL + '&token=' + token + '&date=' + ((new Date()).getTime())
        }
      } else {
        if (this.iframeURL.indexOf('?') <= -1) {
          return this.iframeURL + '?date=' + ((new Date()).getTime())
        } else {
          return this.iframeURL + '&date=' + ((new Date()).getTime())
        }
      }
    }
  },
  inject: {
    eventBus: {
      default: null
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        window.location.reload()
      })
    }
  }
}
</script>

<style scoped>
  .tab{
    overflow: hidden;
    padding-top: 90px;
    box-sizing: border-box;
  }
</style>
