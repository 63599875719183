<template>
  <div class="control-bar">
    <div class="status mb5">
      <el-switch :value="page.isEdit" active-text="编辑模式" @change="toggleEditStatus"></el-switch>
      <el-switch :value="page.isChoose" active-text="选择关联组件中" disabled></el-switch>
    </div>
    <div class="control-">
      <el-button size="small" type="danger" @click="clear">强制刷新</el-button>
      <el-button size="small" type="" @click="preview2">预览</el-button>
      <el-button size="small" :type="statusVisible ? 'primary' : ''" :plain="!statusVisible" @click="statusVisible = true">页面数据</el-button>
      <el-button size="small" type="" @click="sourceVisible = true">资源管理</el-button>
      <el-button size="small" type="success" @click="saveCustomComponent">保存自定义组件</el-button>
      <el-button size="small" type="primary" @click="save">缓存</el-button>
      <el-button size="small" type="primary" @click="exportFile">导出</el-button>
      <el-button size="small" type="file" class="pos-r" :loading="fileLoading" :disabled="fileLoading">
        导入
        <input v-show="!fileLoading" ref="file" class="input-file" type="file" accept="application/json" @change="onFileChange">
      </el-button>
    </div>

    <el-drawer
      title="页面数据"
      :visible.sync="statusVisible"
      direction="ltr">
      <div>
        <json-viewer
          :value="page.tree[0]?.children"
          :expand-depth="2"></json-viewer>
      </div>
    </el-drawer>
    <SourceManage :visible.sync="sourceVisible"></SourceManage>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { saveJSON, px2vw, deepClone } from '@/utils'
import SourceManage from './SourceManage'
import { getTreeNodeById } from '@/utils/tree'
export default {
  name: 'ControlBar',
  components: { SourceManage },
  props: {},
  data () {
    return {
      statusVisible: false,
      sourceVisible: false,
      fileLoading: false,
      simpleTree: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['page'])
  },
  created () {},
  mounted () {},
  methods: {
    onDelComponent (e) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('delComponent')
      })
    },
    clear () {
      localStorage.setItem('COMPONENT_TREE', '')
      window.location.reload()
    },
    preview () {
      function fullScreen () {
        // const element = this.$refs['fullScreen']
        const element = document.querySelector('#pageContent')
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen()
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        } else {
          console.log('当前浏览器不支持部分全屏！')
        }
      }
      this.zoom = 100
      this.$store.commit('SET_IS_EDIT', false)
      fullScreen()
    },
    preview2 () {
      window.open('#/preview')
    },
    toggleEditStatus (val) {
      this.$store.commit('SET_IS_EDIT', val)
    },
    saveCustomComponent () {
      this.$prompt('请输入自定义组件名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        const currentComponent = getTreeNodeById(this.page.tree, this.page.currentId)
        // console.log(currentComponent)
        this.$store.commit('SAVE_CUSTOM_COMPONENT', {
          name: value,
          component: currentComponent
        })
      })
    },
    save () {
      this.$store.commit('SAVE_TREE_TO_LOCALSTORAGE')
    },
    /**
     * 导出文件
     * 1.导出全量文件 page.json 包含配置数据 和 原版的组件数据
     * 2.导出精简文件 page.production.json 只包含经过px单位转换的组件数据 用于页面渲染
     */
    exportFile () {
      const data = this.page.tree
      // 全量版
      saveJSON(data, 'page.json')
      // 精简转义版
      this.simpleTree = deepClone(data)
      this._shakeTree(this.simpleTree)
      saveJSON(this.simpleTree, 'page.production.json')
      // this._shakeTree(data)
    },
    onFileChange (e) {
      // console.log(e)
      this.fileLoading = true
      if (this.page.tree[0].children.length > 0) {
        this.$confirm('导入配置将覆盖当前页面编辑内容，是否确认导入?', '提示', {
          type: 'warning'
        }).then(() => {
          this.importFile(e)
        }).catch(() => {
          this.$message.success('取消导入')
          this._onFinishImport()
        })
      } else {
        this.importFile(e)
      }
    },
    importFile (e) {
      const _this = this
      const file = e.target.files[0]
      console.log(file.type)
      if (file.type.indexOf('json') === -1) {
        _this.$message.warning('只能上传json文件')
        _this._onFinishImport()
        return
      }
      const reader = new FileReader()// 新建一个FileReader
      reader.readAsText(file, 'UTF-8')// 读取文件
      reader.onload = function (evt) { // 读取完文件之后会回来这里
        const fileStr = evt.target.result // 读取文件内容
        _this.$store.commit('IMPORT_CONFIG', fileStr)
        _this.$message.success('导入成功！')
        _this._onFinishImport()
      }
    },
    _onFinishImport () {
      this.$refs.file.value = ''
      this.fileLoading = false
    },
    /**
     * 清理树
     * @param data
     * @private
     * @description 清理树中渲染无关的数据，并对px进行vm转义
     */
    _shakeTree (data) {
      if (Array.isArray(data)) { // 如果是数组
        console.log('> 传入值是数组 递归数组')
        return data.map(child => {
          return this._shakeTree(child)
        })
      } else if (Object.prototype.toString.call(data) === '[object Object]') { // 如果是{}对象
        console.log('> 传入值是{}对象 遍历对象')
        Object.keys(data).forEach((key) => {
          if (['propsDesc', 'stylesDesc', 'eventsDesc'].includes(key)) {
            console.log('> 对象key是描述 删除此项')
            delete data[key]
            return
          }
          if (typeof data[key] === 'string') {
            console.log(`'> 对象[${key}]是 string`)
            data[key] = px2vw(data[key], 1920)
            console.log(`'> 对象[${key}]转化为:${data[key]}`)
          } else if (Object.prototype.toString.call(data[key]) === '[object Object]' || Object.prototype.toString.call(data[key]) === '[object Array]') {
            console.log('> 目标是数组或对象')
            console.log(`'> 对象[${key}]是 目标是数组或对象`)
            // 如果是对象或数组则 递归
            this._shakeTree(data[key])
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.control-bar{
  .input-file{
    font-size: 100px;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &::-webkit-file-upload-button{
      cursor: pointer;
    }
  }
}
</style>
