import { render, staticRenderFns } from "./Topic2Map.vue?vue&type=template&id=3d46406c&scoped=true"
import script from "./Topic2Map.vue?vue&type=script&lang=js"
export * from "./Topic2Map.vue?vue&type=script&lang=js"
import style0 from "./Topic2Map.vue?vue&type=style&index=0&id=3d46406c&prod&lang=scss&scoped=true"
import style1 from "./Topic2Map.vue?vue&type=style&index=1&id=3d46406c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d46406c",
  null
  
)

export default component.exports