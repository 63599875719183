<template>
  <div class="s-component" :style="imgStyle">
    <div>
      <div class="img-bg"
           :class="{active: curIndex === index}"
           v-show="curIndex === index"
           v-for="(item,index) in list"
           :style="{backgroundImage: `url(${item.img}`}"
           :key="item.img">
        <template v-if="index === curIndex">
          <div class="">
            <slot :name="index + ''">
            </slot>
          </div>
        </template>
      </div>
    </div>

    <div class="thumb-bar" :style="thumbPos">
      <div v-for="(item,index) in list"
           class="thumb-item"
           :class="{active: curIndex === index}"
           @click="curIndex = index"
           :key="index"
           :style="{
              backgroundImage: `url(${item.thumb}`
            }">
        <img :src="item.thumb" alt="">
        <div class="label">{{item.label}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'STopic2Model',
  props: {
    imgSize: {
      type: String,
      default: ''
    },
    thumbSize: {
      type: String,
      default: ''
    },
    thumbPosition: {
      type: String,
      default: ''
    },
    thumbMargin: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
    // 地图样式
    imgStyle () {
      const sizeArr = this.imgSize.split(' ')
      return {
        width: sizeArr[0] || '',
        height: sizeArr[1] || ''
      }
    },
    thumbStyle () {
      const sizeArr = this.thumbSize.split(' ')
      return {
        width: sizeArr[0] || '',
        height: sizeArr[1] || '',
        margin: this.thumbMargin
      }
    },
    thumbPos () {
      const posArr = this.thumbPosition.split(' ')
      return {
        top: posArr[0] || '',
        right: posArr[1] || '',
        bottom: posArr[2] || '',
        left: posArr[3] || ''
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  width: 1920px;
  height: 1000px;
}

.img-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  &.active {
    animation-name: zoom; // 动画名
    animation-duration: 10s; // 动画时长
    animation-iteration-count: 1; // 动画次数
    transition-timing-function: linear; // 动画过渡
    animation-fill-mode : forwards; // 动画结束后的状态
  }
}

.thumb-bar {
  position: absolute;
  width: 100%;
  z-index: 99;
  bottom: 70px;
  align-items: center;
  text-align: center;

  .thumb-item {
    position: relative;
    transition: .3s;
    cursor: pointer;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 320px;
    height: 140px;
    margin: 0 40px;
    z-index: 2;

    &.active {
      transform: scale(1.2);
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
    .label{
      position: absolute;
      left: 0;
      bottom: 0;
      height: 40px;
      width: 100%;
      background-color: rgba(0,0,0,.5);
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 40px;
    }
  }

}

@keyframes zoom { // 动画设置
  0% {
    background-size: 100% 100%;
  }

  100% {
    background-size: 110% 110%;
  }
}
</style>
