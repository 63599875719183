<template>
  <div class="s-role-list" v-show="visible">
    <div class="s-role-list-item"
         v-for="(item, index) in list"
         :key="index"
         :style="{
           ...bgStyle,
           'background-image': `url(${item.bg})`
         }">
      <img class="s-role-list-item-img" :class="animateMap[animateType]" :style="coverStyle" :src="item.cover" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SRoleList',
  props: {
    active: {
      type: [Number, String],
      default: 0
    },
    animateType: {
      type: String,
      default: 'fadeIn' // fadeIn || zoom
    },

    bgSize: {
      type: String,
      default: '356px 900px' // 宽高
    },
    coverSize: {
      type: String,
      default: '200px 150px' // 宽高
    },
    list: {
      type: Array,
      default: () => [{}, {}]
    }
  },
  data () {
    return {
      visible: true,
      animateMap: {
        fadeIn: 's-fade-in',
        zoomIn: 's-zoom-in'
      }
    }
  },
  computed: {
    bgStyle () {
      const arr = this.bgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    coverStyle () {
      const arr = this.coverSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    }
  },
  watch: {
    active: {
      handler (newVal) {
        this.myActive = newVal
      },
      immediate: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    changeTab (index) {
      this.$emit('update: active', index)
      this.myActive = index
      console.log(this.$slots)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-role-list{
  position: relative;
  display: flex;
  justify-content: space-between;
  .s-role-list-item{
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .s-role-list-item-img{
      transition: .3s;
      &.s-fade-in{
        opacity: 0;
      }
      &.s-zoom-in{
        transform: scale(1);
      }
    }
    &:hover{
      .s-role-list-item-img{
        &.s-fade-in{
          opacity: 1;
        }
        &.s-zoom-in{
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
