<template>
  <div class="kv img-bg" :style="{...defaultBg.style, 'background-image': `url(${defaultBg.src})`}">
    <img v-if="logoImg.src" class="s-topic-kv--logo" :src="logoImg.src" alt="" :style="logoImg.style"/>
    <a :href="btnReturnLink" target="_blank" v-if="btnReturn.src" class="btn-return img-bg" :style="{...btnReturn.style, 'background-image': `url(${btnReturn.src})`}">
    </a>
    <img v-if="btnDownLoad.src" :src="btnDownLoad.src" :style="{...btnDownLoad.style}" class="btn-download" alt="" @click="download">
    <img v-if="giftImg.src" :src="giftImg.src" :style="{...giftImg.style}" class="gift" alt="">
  </div>
</template>

<script>

export default {
  name: 'STopicKV',
  props: {
    defaultBg: {
      type: Object,
      required: true
    },
    logoImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnReturn: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnReturnLink: {
      type: String,
      default: ''
    },
    btnDownLoad: {
      type: Object,
      default: () => {
        return {}
      }
    },
    giftImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    request: {
      type: Function
    }
  },
  data () {
    return {
      downLoadLink: ''
    }
  },
  mounted () {
    // 获取下载链接
    this.downloadFun()
  },
  computed: {
  },
  methods: {
    // 获取下载链接
    downloadFun () {
      const { from, wdFrom } = this.$route.query
      // eslint-disable-next-line no-undef
      const request = this.request ? this.request : $request
      request({
        url: '/v1/config',
        method: 'get',
        params: {
          from,
          wdFrom
        }
      }).then((res) => {
        this.downLoadLink = res.game_download_url
      }).catch((err) => {
        this.$message({
          message: err.msg || '系统错误',
          type: 'error'
        })
      })
    },
    download () {
      if (!this.downLoadLink) {
        const downloadPage = `https://www.battleteams2.com/gameInfo/download?from=${this.$route.query.from || ''}&wdFrom=${this.$route.query.wdFrom || ''}`
        window.open(downloadPage)
      } else {
        window.location.href = this.downLoadLink
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.s-topic-kv--logo{
  position: absolute;
}
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.kv{
 position: relative;
  .btn-return{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: .3s;
    &:hover{
      filter: brightness(110%)
    }
  }
  .btn-download{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: .3s;
    &:hover{
      filter: brightness(110%)
    }
    //img{
    //  width: 100%;
    //}
    //a{
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //}
  }
  .gift{
    position: absolute;
  }
}
</style>
