<template>
  <div class="s-tab" v-show="visible">
    <div class="tab-header" :style="tabHeaderStyle">
      <div class="tab-btn"
           v-for="(item, index) in btnList"
           :class="{active: index === myActive}"
           :key="index"
           :style="btnStyle"
           @click="changeTab(index)">
        <img :src="index === myActive ? item.active : item.default" alt="">
      </div>
    </div>
    <div class="tab-body">
      <div class="tab-item"
           v-show="index === myActive"
           v-for="(item, index) in btnList"
           :key="index">
        <template v-if="index === myActive">
          <slot :name="index + ''">
            当前tab:{{index}} 内暂无内容
          </slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'STab',
  props: {
    active: {
      type: [Number, String],
      default: 0
    },
    btnDirection: {
      type: String,
      default: 'row' // row || column
    },
    btnPosition: {
      type: String,
      default: '0 0 _ _' // 上 右 下 左
    },
    btnMargin: {
      type: String,
      default: '0' // 边距
    },
    btnSize: {
      type: String,
      default: '200px 150px' // 宽高
    },
    btnList: {
      type: Array,
      default: () => [{}, {}]
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.tab) {
      this.myActive = Number(this.$route.query.tab)
    }
  },
  data () {
    return {
      visible: true,
      myActive: 0
    }
  },
  computed: {
    tabHeaderStyle () {
      const arr = this.btnPosition.split(' ')
      return {
        top: arr[0] || '',
        right: arr[1] || '',
        bottom: arr[2] || '',
        left: arr[3] || '',
        display: 'flex',
        'flex-direction': this.btnDirection
      }
    },
    btnStyle () {
      const arr = this.btnSize.split(' ')
      return {
        width: arr[0] || '50px',
        height: arr[1] || '50px',
        margin: this.btnMargin
      }
    }
  },
  watch: {
    active: {
      handler (newVal) {
        this.myActive = newVal
      },
      immediate: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    changeTab (index) {
      if (this.btnList[index].isNeedLogin === 1) {
        // eslint-disable-next-line no-undef
        if (!$checkLogin()) return
      }
      this.$emit('update: active', index)
      this.myActive = index
      console.log(this.$slots)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-tab{
  position: relative;
  .tab-header{
    position: absolute;
    display: flex;
    z-index: 2;
    .tab-btn{
      width: 150px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 30px;
      // background-color: #C7D9FD;
      color: #fff;
      img{
        width: 100%;
        height: 100%;
      }
      &.active{
        // background-color: #409EFF;
      }
      cursor: pointer;
    }
  }
  .tab-body{
    .tab-item{
      min-height: 200px;
    }
  }
}
</style>
