<template>
  <div class="tab4 img-bg">
    <swiper ref="swiper" :options="swiperOptions" class="swiper-no-swiping">
      <swiper-slide v-for="(item, index) in newSwiperList" :key="index" class="good-item">
        <div class="append">
          <slot :name="index + ''">
          </slot>
        </div>
        <img class="award-img" :src="awardList[index] && awardList[index].awardImg" alt=""/>

        <img :class="['s-btn']" v-if="item && item.status === 0" :src="btnNoBegin.src" alt=""
             @click="receiveAward(item)" :style="{...getPosition(btnPos), ...getSize(btnSize)}"/>
        <img :class="['s-btn']" v-if="item && item.status === 1" :src="btnUnfinished.src" alt=""
             @click="receiveAward(item)" :style="{...getPosition(btnPos), ...getSize(btnSize)}"/>
        <img :class="['hv-highlight s-btn']" v-if="item && item.status === 2" :src="btnReceive.src" alt=""
             @click="receiveAward(item)" :style="{...getPosition(btnPos), ...getSize(btnSize)}"/>
        <img :class="['s-btn']" v-if="item && item.status === 3" :src="btnReceived.src" alt=""
             @click="receiveAward(item)" :style="{...getPosition(btnPos), ...getSize(btnSize)}"/>
        <img :class="['s-btn']" v-if="item && item.status === 4" :src="btnExpire.src" alt="" @click="receiveAward(item)"
             :style="{...getPosition(btnPos), ...getSize(btnSize)}"/>
      </swiper-slide>
    </swiper>
    <div class="arrow arrow-prev"
         :style="{...swiperLeft.style,'background-image': `url(${swiperLeft.src})`}"></div>
    <div class="arrow arrow-next"
         :style="{...swiperRight.style,'background-image': `url(${swiperRight.src})`}"></div>
  </div>
</template>

<script>
import { getPosition, getSize } from '@/components/BuilderComponents/utils'

export default {
  name: 'SAct4',
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    awardList: {
      type: Array,
      required: true
    },
    swiperLeft: {
      type: Object,
      required: true
    },
    swiperRight: {
      type: Object,
      required: true
    },

    btnSize: {
      type: String
    },
    btnPos: {
      type: String
    },
    btnNoBegin: {
      type: Object,
      required: true
    },
    btnUnfinished: {
      type: Object,
      required: true
    },
    btnReceive: {
      type: Object,
      required: true
    },
    btnReceived: {
      type: Object,
      required: true
    },
    btnExpire: {
      type: Object,
      required: true
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      swiperOptions: {
        speed: 600,
        autoplay: false,
        navigation: {
          prevEl: '.arrow-prev',
          nextEl: '.arrow-next'
        }
      },
      // 轮播数据
      newSwiperList: [
        { status: 1 },
        { status: 1 },
        { status: 1 },
        { status: 1 },
        { status: 1 }
      ]
    }
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.getCheckStatus)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    $checkLogin()
    this.getCheckStatus()
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getCheckStatus()
        }
      },
      immediate: false
    }
  },
  methods: {
    getSize,
    getPosition,
    // 获取初始化状态
    getCheckStatus () {
      this.requestAPI({
        url: '/v1/uni/weekendStatus',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.newSwiperList = res.info || []
        this.$forceUpdate()
        const $swiper = this.$refs.swiper
        if ($swiper && $swiper.swiper) {
          $swiper.swiper.slideTo(res.focus, 800)
        }
        // this.$refs.swiper.swiper.slideTo(res.focus, 800)

        // // 检查是否处于活动时间
        // this.$MainWrap.checkStartEnd(res.start_at, res.end_at)
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 领取奖励
    receiveAward (item) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断当前状态
      if (item.status !== 2) {
        return
      }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: { g_id: item.g_id, a_id: this.activityId }
      }).then((data) => {
        console.log('---- Tab4 --- receive ----', data)
        this.$MainWrap.openReceiveModal(data.gift.materials)
        this.getCheckStatus()
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 重置
    reset () {
      // this.getCheckStatus()
      this.newSwiperList.forEach((item) => {
        item.status = 1
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> act4 取消登录监听')
      this.eventBus.off('loginSuccess', this.getCheckStatus)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 公共样式
.img-bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.lay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}

// 内容样式
.tab4 {
  .good-item {
    width: 1920px;
    height: 966px;
    position: relative;

    .append {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .award-img {
      display: block;
      width: 1920px;
      height: 966px;
    }

    .s-btn {
      position: absolute;
      z-index: 2;
    }
  }

  .arrow {
    position: absolute;
    top: 190px;
    cursor: pointer;
    background-size: 100%;
    z-index: 1;
    outline: none;

    &:hover {
      filter: brightness(120%)
    }
  }

  .arrow-prev {
    left: -130px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: .5;
    }
  }

  .arrow-next {
    right: -130px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: .5;
    }
  }

}
</style>
