import _mixin from './_mixin'
export default {
  name: 'SVideoList',
  cnName: '视频列表',
  type: 'complex',
  // 属性相关
  props: {
    list: [
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '跳转跳转跳转跳转跳转跳转跳转',
        src: '',
        link: 'https://www.baidu.com',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '视频视频视频视频视频视频视频视频视频视频',
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/big.mp4?v=1666848470704',
        link: '',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '撒到家啦见识到了伺机待发垃圾啊十大垃圾发啦受打击法鸡三打哈反垃圾是打发垃圾啊三打哈反垃圾阿萨倒垃圾发撒',
        src: 'xxx',
        link: 'xxx',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '撒到家啦见识到了伺机待发垃圾啊十大垃圾发啦受打击法鸡三打哈反垃圾是打发垃圾啊三打哈反垃圾阿萨倒垃圾发撒',
        src: 'xxx',
        link: 'xxx',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '撒到家啦见识到了伺机待发垃圾啊十大垃圾发啦受打击法鸡三打哈反垃圾是打发垃圾啊三打哈反垃圾阿萨倒垃圾发撒',
        src: 'xxx',
        link: 'xxx',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '撒到家啦见识到了伺机待发垃圾啊十大垃圾发啦受打击法鸡三打哈反垃圾是打发垃圾啊三打哈反垃圾阿萨倒垃圾发撒',
        src: 'xxx',
        link: 'xxx',
        date: '2022-10-22'
      },
      {
        cover: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: '撒到家啦见识到了伺机待发垃圾啊十大垃圾发啦受打击法鸡三打哈反垃圾是打发垃圾啊三打哈反垃圾阿萨倒垃圾发撒',
        src: 'xxx',
        link: 'xxx',
        date: '2022-10-22'
      }
    ],
    imgWidth: '300px'
  },
  propsDesc: [{
    target: 'list',
    label: '视频列表',
    formType: 'list',
    listConfig: [
      {
        title: '封面地址',
        target: 'cover'
      },
      {
        title: '视频地址',
        target: 'src'
      },
      {
        title: '视频标题',
        target: 'label'
      },
      {
        title: '时间',
        target: 'date'
      },
      {
        title: '跳转链接',
        target: 'link'
      }
    ]
  }, {
    target: 'imgWidth',
    label: '图片宽度'
  }, {
    target: 'imgHeight',
    label: '图片高度'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1600px'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
