import axios from 'axios'
// import { Message } from 'element-ui'

// 获取地址栏参数方法
function getQueryString (name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.href.split('?')[1]?.match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}
const lang = getQueryString('lang')

const service = axios.create({
  baseURL: process.env.baseURL,
  timeout: 15000
})

service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers.token = localStorage.getItem('activeToken') || ''
    config.headers['wizard-lang'] = lang || 'en-us'
    // if (config.url.includes('?')) {
    //   config.url = config.url + '&date=' + ((new Date()).getTime()) // 兼容IE11兼容模式请求相同的 URL ，浏览器默认会使用之前的缓存数据，而不是重新请求接口
    // } else {
    //   config.url = config.url + '?date=' + ((new Date()).getTime()) // 兼容IE11兼容模式请求相同的 URL ，浏览器默认会使用之前的缓存数据，而不是重新请求接口
    // }
    return config
  },
  (error) => {
    // do something with request error
    // for debug
    console.log(error)
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code === 0) {
      return res.data
    } else if (res.code === 430157) { // 请求的资源不存在（特指活动页面接口）
      window.location.href = '/404'
    } else {
      // if (res.data && res.data.msg) { // 如果有自定义提示信息则
      //   Message.error(res.data.msg)
      // } else {
      //   Message.error(res.msg)
      // }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        msg: res.msg || res.message,
        code: res.code
      })
    }
  },
  (error) => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        localStorage.clear()
        // 延时三秒
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          msg: 'Login Expired',
          code: error.response.status
        })
      } else if (error.response.status === 404) {
        window.location.href = '/404'
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          msg: 'System Error',
          code: error.response.status
        })
      }
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        msg: 'Request Timeout',
        code: '500'
      })
    }
  }

)

export default service
