import { render, staticRenderFns } from "./AnchorNav.vue?vue&type=template&id=57382fdd&scoped=true"
import script from "./AnchorNav.vue?vue&type=script&lang=js"
export * from "./AnchorNav.vue?vue&type=script&lang=js"
import style0 from "./AnchorNav.vue?vue&type=style&index=0&id=57382fdd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57382fdd",
  null
  
)

export default component.exports