<template>
  <div class="login-con">
    <div :class="['un-login', 'login-box', `un-login-${type}`]" @click="threeLogin" v-if="!userInfo.token">
      <div>账号登录</div>
    </div>
    <div class="has-login login-box" v-else>
      <div>
        <!--                <a
                            :href="`${memberUrl}?token=${ssjjActiveToken}`"
                            class="head-link user_account"
                            target="_blank"
                        >{{ activeAccountName }}</a>-->
        <div style="color: #ffffff;">{{ userInfo.name }}</div>
      </div>
      <div @click="signOut">退出</div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ThirdLoginIn',
  props: {
    userInfo: {
      type: Object,
      required: true
    },
    memberUrl: {
      type: String,
      default: ''
    },
    // 三方登录类型
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    signOut () {
      this.$emit('signOut')
    },
    threeLogin () {
      const { type } = this
      this.$emit('threeLogin', type)
    }
  },
  computed: {
    // ssjjActiveToken () {
    //   return this.$store.state.ssjjActiveToken
    // },
    // activeAccountName () {
    //   return this.$store.state.activeAccountName
    // }
  }
}
</script>
<style scoped lang="scss">
.login-con {
  //color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  font-size: 18px;
  /*width: auto;*/
  /*height: 44px;*/
  /*padding-top: 4px;*/
  /*box-sizing: border-box;*/
  /*display: flex;*/
}

.un-login {
  width: 170px !important;
  height: 50px;
  //color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-sizing: border-box;
  background-size: 100% 100%;
  cursor: pointer;

  &.un-login-wegame {
    padding-left: 45px;
    background: center / cover no-repeat url("./img/wegame-login.jpg");
  }
}

.login-box {
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.login-box > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box > div a {
  //color: #fff;
}
</style>
