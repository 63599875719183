import _mixin from '../_mixin'
export default {
  name: 'STopic2SideNav',
  cnName: '侧导航',
  type: 'activity_topic2',
  preview: '',
  // 属性相关
  props: {
    list: [
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' },
      { targetId: '', text: 'xxxxx', hideInChannel: '' }
    ],
    listWrapStyle: '{}',
    listItemStyle: '{}',
    listItemActiveStyle: '{}'
    // src: ''
  },
  propsDesc: [
    {
      target: 'list',
      label: '锚点列表',
      formType: 'list',
      listConfig: [
        {
          title: '锚点ID',
          target: 'targetId'
        },
        {
          title: '描述',
          target: 'text'
        },
        {
          title: '在这些渠道隐藏',
          target: 'hideInChannel',
          placeholder: '例如: wegame,duyu,4399'
        }
      ]
    },
    {
      target: 'listWrapStyle',
      label: '列表容器样式',
      formType: 'textarea',
      placeholder: '可用于调整列表容器的位置等信息'
    },
    {
      target: 'listItemStyle',
      label: '列表项样式',
      formType: 'textarea',
      placeholder: '可用于调整列表项的大小等信息'
    },
    {
      target: 'listItemActiveStyle',
      label: '列表项选中样式',
      formType: 'textarea',
      placeholder: '可用于调整列表项选中的大小等信息'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
