<template>
  <div class="s-live" v-show="visible">
    <!--直播-->
    <div ref='live' class="live">
      <div class="live-title">
<!--        <img class="live-title-img" src="../../assets/images/taitan/live/live-title.png" alt="">-->
        <span class="live-match">{{ channelList[channelIndex] ? channelList[channelIndex].title : '' }}</span>
      </div>
      <div class="live-content">
        <div class="live-iframe" :style="{width: liveWidth, height: liveHeight}">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            :src="channelList[channelIndex] ? channelList[channelIndex].link : '' "
          />
        </div>
        <div class="channel-list" v-if="channelList.length > 1">
          <div
            v-for="(item,index) in channelList"
            :key="index"
            class="channel-list-item"
            :class="channelIndex === index ? 'channel-list-item-select' : '' "
            @click="channelIndex = index">
            <img :src="item.thumb" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SLive',
  props: {
    liveWidth: {
      type: String,
      default: '100%'
    },
    liveHeight: {
      type: String,
      default: '900px'
    },
    channelList: {
      type: Array,
      required: true,
      default: () => [
        { title: '', link: '', thumb: '' }
      ]
    }
  },
  data () {
    return {
      visible: true,
      channelIndex: 0
    }
  },
  computed: {
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .s-live{
    /* 直播 */
    .live{
      box-sizing: border-box;
    }
    .live-title{
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    .live-title-img{
      width: 167px;
      height: 46px;
      margin-right: 20px;
    }
    .live-match{
      display: block;
      width: 450px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 700;
      color: #a66609;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
    .live-iframe{
      background-color: #000;
    }
    .channel-list{
      margin-top: 20px;
      display: flex;
    }
    .channel-list-item{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 162px;
      height: 60px;
      //background-image: url("../../assets/images/taitan/live/live-no-select.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .channel-list-item img{
      max-width: 100%;
      max-height: 100%;
    }
    .channel-list-item-select{
      width: 157px;
      height: 56px;
      //background-image: url("../../assets/images/taitan/live/live-select.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
</style>
