import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopicCommonEmpty',
  cnName: '公共空白',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/31/a7338dc2a9757ee2b3e92b6a32d59763.jpeg',
  // 属性相关
  props: {
    ..._mixinWrap.props,
    // 内容图
    contentImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/06/06/0bf0285b9ea6cb63f00c85f2caa37352.png',
      style: {
        width: '1280px',
        height: '650px'
      }
    }
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'contentImg',
      label: '内容图',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
