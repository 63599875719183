import _mixin from './_mixin'
const img = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map-thumb-active4.png?v=1666848470704'
const img2 = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map-thumb4.png?v=1666848470704'

export default {
  name: 'BaseSwiper',
  cnName: '轮播',
  type: 'complex',
  // 属性相关
  props: {
    list: [
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map1.png?v=1666848470704',
        label: 'xxx'
      },
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map2.png?v=1666848470704',
        label: 'xxx'
      },
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map3.png?v=1666848470704',
        label: 'xxx'
      },
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map4.png?v=1666848470704',
        label: 'xxx'
      }
    ],
    autoplay: true,
    swiperItemSize: '100% 542px',
    arrowVisible: false,
    paginationVisible: true,
    paginationDirection: 'row',
    paginationList: [
      { default: img2, active: img },
      { default: img2, active: img },
      { default: img2, active: img },
      { default: img2, active: img }
    ],
    paginationPosition: '',
    paginationPositionType: 'center-bottom',
    paginationItemSize: '150px 110px',
    paginationItemMargin: '0 0 0 20px'

  },
  propsDesc: [
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '图片地址',
          target: 'src'
        },
        {
          title: '图片标题',
          target: 'label'
        }
      ]
    },
    {
      target: 'swiperItemSize',
      label: '图片尺寸'
    },
    {
      target: 'autoplay',
      label: '自动轮播',
      formType: 'switch'
    },
    {
      target: 'arrowVisible',
      label: '箭头按钮',
      formType: 'switch'
    },
    {
      target: 'paginationVisible',
      label: '分页器',
      formType: 'switch'
    },
    {
      target: 'paginationDirection',
      label: '分页器方向',
      formType: 'radio',
      options: [
        { label: '横向', value: 'row' },
        { label: '竖向', value: 'column' }
      ],
      showOn: {
        target: 'paginationVisible',
        value: true
      }
    },
    {
      target: 'paginationItemSize',
      label: '分页器尺寸',
      showOn: {
        target: 'paginationVisible',
        value: true
      }
    },
    {
      target: 'paginationPosition',
      label: '分页器位置A',
      description: '上 右 下 左 (不存在用"_"代替，例: 10px 10px _ _)',
      showOn: {
        target: 'paginationVisible',
        value: true
      }
    },
    {
      target: 'paginationPositionType',
      label: '分页器位置B',
      formType: 'select',
      showOn: {
        target: 'paginationVisible',
        value: true
      },
      options: [
        { label: '左上', value: 'left-top' },
        { label: '左中', value: 'left-middle' },
        { label: '左下', value: 'left-bottom' },
        { label: '中上', value: 'center-top' },
        { label: '中中', value: 'center-middle' },
        { label: '中下', value: 'center-bottom' },
        { label: '右上', value: 'right-top' },
        { label: '右中', value: 'right-middle' },
        { label: '右下', value: 'right-bottom' }
      ]
    },
    {
      target: 'paginationItemMargin',
      label: '分页器间距',
      description: '上 右 下 左',
      showOn: {
        target: 'paginationVisible',
        value: true
      }
    },
    {
      target: 'paginationList',
      label: '分页器列表',
      formType: 'list',
      listConfig: [
        {
          title: '默认图',
          target: 'default'
        },
        {
          title: '选中图',
          target: 'active'
        }
      ],
      showOn: {
        target: 'paginationVisible',
        value: true
      }
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1500px',
    height: 'auto',
    padding: '0 0 0 0'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
