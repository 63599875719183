import _mixin from './_mixin'
const img1 = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1.6fd6bdf4.png'
const img1Hover = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1-active.8e7d1944.png'
const img2 = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1.6fd6bdf4.png'
const img2Hover = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1-active.8e7d1944.png'
const img3 = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1.6fd6bdf4.png'
const img3Hover = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/nav1-active.8e7d1944.png'
const img4 = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/6403ba57d5cd900e2b90b9c4d178780d.png'
const img4Hover = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/593b7b2f36cd00fcf9b6fcb228e038b9.png'
const img5 = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/1d3540f76da2cfec8f648115b2c019f6.png'
const img5Hover = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/8fc9cb81515a214f76d81c606ef481fb.png'
const img6 = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/bd5b7bd9db1684d020ed2b45f5757e4f.png'
const img6Hover = 'https://rescdn-crash.ssjj.cn/website/2023/02/23/0b988ebab6eb7972917bb82f0cef9891.png'

export default {
  name: 'SAnchorNav',
  cnName: '锚点导航',
  type: 'complex',
  // 属性相关
  props: {
    list: [
      { targetId: '', default: img1, active: img1Hover },
      { targetId: '', default: img2, active: img2Hover },
      { targetId: '', default: img3, active: img3Hover },
      { targetId: '', default: img4, active: img4Hover },
      { targetId: '', default: img5, active: img5Hover },
      { targetId: '', default: img6, active: img6Hover }
    ],
    itemSize: '140px 50px',
    itemMargin: '0 0 5px 0',
    backTopImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/27/8a8377d387520a9a2a06c6e7d05d5c37.png',
      style: {
        width: '140px',
        height: '50px'
      }
    },
    backTopImgMargin: '10px 0 0 0'

  },
  propsDesc: [
    {
      target: 'list',
      label: '锚点列表',
      formType: 'list',
      listConfig: [
        {
          title: '锚点ID',
          target: 'targetId'
        },
        {
          title: '默认图',
          target: 'default'
        },
        {
          title: '选中图',
          target: 'active'
        }
      ]
    },
    {
      target: 'itemSize',
      label: 'item尺寸',
      formType: 'size'
    },
    {
      target: 'itemMargin',
      label: 'item间距'
    },
    {
      target: 'backTopImg',
      label: '返回顶部按钮',
      formType: 'image'
    },
    {
      target: 'backTopImgMargin',
      label: '返回顶部按钮间距'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'fixed',
    right: '0',
    top: '300px',
    height: 'auto',
    'z-index': '999'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
