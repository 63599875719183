import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopic2WeaponWheel',
  cnName: '武器[转盘]',
  type: 'activity_topic2',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/website/2023/11/01/d64f16a377979a0acb4cbd1483e0ac8f.jpg',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/50e18c1a105049b8936aa1bbeecd6634.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    autoplay: false,
    list: [
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e793304c689ca03e3be2e3ac1fcf3e14.png',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/421edbb38d2e33c613c9feb252c892f1.png',
        thumbActive: 'https://rescdn.ssjj.cn/website/2023/10/16/63374529529cf9dbad66f5989a1a913d.png',
        label: 'RPK断罪之心'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e793304c689ca03e3be2e3ac1fcf3e14.png',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/421edbb38d2e33c613c9feb252c892f1.png',
        thumbActive: 'https://rescdn.ssjj.cn/website/2023/10/16/63374529529cf9dbad66f5989a1a913d.png',
        label: 'RPK断罪之心'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e793304c689ca03e3be2e3ac1fcf3e14.png',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/421edbb38d2e33c613c9feb252c892f1.png',
        thumbActive: 'https://rescdn.ssjj.cn/website/2023/10/16/63374529529cf9dbad66f5989a1a913d.png',
        label: 'RPK断罪之心'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e793304c689ca03e3be2e3ac1fcf3e14.png',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/421edbb38d2e33c613c9feb252c892f1.png',
        thumbActive: 'https://rescdn.ssjj.cn/website/2023/10/16/63374529529cf9dbad66f5989a1a913d.png',
        label: 'RPK断罪之心'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/e793304c689ca03e3be2e3ac1fcf3e14.png',
        thumb: 'https://rescdn.ssjj.cn/website/2023/10/16/421edbb38d2e33c613c9feb252c892f1.png',
        thumbActive: 'https://rescdn.ssjj.cn/website/2023/10/16/63374529529cf9dbad66f5989a1a913d.png',
        label: 'RPK断罪之心'
      }
    ],
    prevImg: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/ea7f78ccc208b7c109865b3f0205e7ff.png',
    nextImg: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/5fc479087b6e5cba9e794fff0cc20956.png',
    thumbWrapStyle: '{}',
    thumbItemStyle: '{}'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'autoplay',
      label: '自动播放',
      formType: 'switch'
    },
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '缩略图',
          target: 'thumb'
        },
        {
          title: '缩略图选中',
          target: 'thumbActive'
        },
        {
          title: '名称',
          target: 'label'
        }
      ]
    },
    {
      target: 'prevImg',
      label: '上一页按钮图片'
    },
    {
      target: 'nextImg',
      label: '下一页按钮图片'
    },
    {
      target: 'thumbWrapStyle',
      label: '缩略图容器样式',
      formType: 'textarea',
      placeholder: '可用于调整缩略图容器的位置等信息'
    },
    {
      target: 'thumbItemStyle',
      label: '缩略图样式',
      formType: 'textarea',
      placeholder: '可用于调整缩略图的大小等信息'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
