<template>
  <div class="common-btn">
    <img v-if="groupAward[0].status === 0 " class="img-bg" :src="btnNoBegin.src" alt="" :style="{...btnNoBegin.style}"/>
    <img v-if="groupAward[0].status === 1 " class="img-bg" :src="btnUnfinished.src" alt="" :style="{...btnUnfinished.style}"/>
    <img v-if="groupAward[0].status === 2 " class="img-bg btn-light" :src="btnReceive.src" alt="" :style="{...btnReceive.style}" @click="receiveAward()"/>
    <img v-if="groupAward[0].status === 3 " class="img-bg" :src="btnReceived.src" alt="" :style="{...btnReceived.style}"/>
    <img v-if="groupAward[0].status === 4 " class="img-bg" :src="btnExpire.src" alt="" :style="{...btnExpire.style}"/>
    <!--提示弹窗-->
    <STipComModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipTitle="tipTitle"
      :btnClose="btnClose"
      :contentText="tipModel.text"
    >
    </STipComModel>
    <!--奖励弹窗-->
    <SAwardComModel
      :modelShow.sync="awardModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardTitle="awardTitle"
      :awardOtherDesc="awardOtherDesc"
      :awardBoxBg="awardBoxBg"
      :btnHappy="btnHappy"
      :awardData="awardModel.award"
    >
    </SAwardComModel>
  </div>
</template>

<script>

export default {
  name: 'SCommonBtn',
  inject: {
    eventBus: {
      default: null
    }
  },
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    activityId: {
      type: String,
      default: ''
    },
    activityGroup: {
      type: Array,
      default: () => []
    },
    btnNoBegin: {
      type: Object,
      required: true
    },
    btnUnfinished: {
      type: Object,
      required: true
    },
    btnReceive: {
      type: Object,
      required: true
    },
    btnReceived: {
      type: Object,
      required: true
    },
    btnExpire: {
      type: Object,
      required: true
    },
    /* 公共弹窗配置 */
    modelBg: {
      type: Object,
      required: true
    },
    modelClose: {
      type: Object,
      required: true
    },
    modelColor: {
      type: String,
      required: true
    },
    /* tip弹窗配置 */
    tipTitle: {
      type: Object,
      required: true
    },
    btnClose: {
      type: Object,
      required: true
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      type: Object,
      required: true
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: Object,
      required: true
    },
    btnHappy: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      groupAward: [],
      awardModel: {
        show: false,
        award: []
      },
      // 提示弹窗文案
      tipModel: {
        show: false,
        text: ''
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.activityInfo()
        }
      },
      immediate: false
    },
    activityGroup: {
      handler: function (newVal) {
        this.groupAward = newVal
        // 获取活动信息
        this.activityInfo()
      },
      immediate: true
    }
  },
  mounted () {
    // 获取活动信息
    this.activityInfo()
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.activityInfo()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
  },
  methods: {
    // 获取活动信息
    activityInfo () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        if (res.groups) {
          const newGroupAward = []
          this.groupAward.forEach((k) => {
            res.groups.forEach((item) => {
              if (Number(k.groupId) === Number(item.id)) {
                k.id = item.id
                k.status = item.status
                k.award = [{ image: item.materials.icon, name: item.materials.name, count: item.materials.amount }]
                newGroupAward.push(k)
              }
            })
          })
          this.groupAward = newGroupAward
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 领取奖品
    receiveAward () {
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: { a_id: this.activityId, g_id: this.groupAward[0].id }
      }).then((res) => {
        if (!res.success) {
          this.tipModel.show = true
          this.tipModel.text = res.msg
          return
        }
        this.awardModel.show = true
        this.awardModel.award = this.groupAward[0].award
        this.activityInfo()
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 重置按钮
    reset () {
      this.groupAward.forEach((item) => {
        item.status = 1
      })
      this.activityInfo()
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .btn-gray{
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  .btn-light {
    cursor: pointer;
    &:hover{
      filter: brightness(120%);
    }
  }
</style>
