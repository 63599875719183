<template>
  <div v-if="helpModelShow" class="layModel">
    <div class="help-model" :style="modelStyle">
      <div class="close img-bg" :style="closeStyle" @click="close"></div>
      <img :src="helpTitle" alt="" class="title-model" :style="titleStyle"/>
      <div class="copy-desc">【{{roleName}}】邀你助力领神枪</div>
      <div class="award-model img-bg" :style="gunBgStyle" >
        <img :src="gunImg" alt="" class="gun-img"/>
      </div>
      <div class="btn-help img-bg" :style="btnCopyStyle" @click="toHelp">{{helpBtnText}}</div>
      <div class="right-rule">
        <div v-for="(item,index) in helpRuleTextList" :key="index" class="rule-item">
          <p><span class="text-icon img-bg" :style="styleIcon"></span>{{item.ruleText}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SHelpModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    helpBgSize: {
      type: String,
      default: ''
    },
    helpTitle: {
      type: String,
      default: ''
    },
    helpTitleSize: {
      type: String,
      default: '' // 宽高
    },
    gunBg: {
      type: String,
      default: ''
    },
    awardBoxBgSize: {
      type: String,
      default: ''
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    helpBtnColor: {
      type: String,
      default: ''
    },
    helpBtnText: {
      type: String,
      default: ''
    },
    helpBtnSize: {
      type: String,
      default: ''
    },
    textIcon: {
      type: String,
      default: ''
    },
    helpRuleTextList: {
      type: Array,
      required: false
    },
    roleName: {
      type: String,
      default: ''
    },
    gunImg: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      helpModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.helpModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.helpBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '450px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    titleStyle () {
      const arr = this.helpTitleSize.split(' ')
      const width = arr[0] || '358px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    styleIcon () {
      return {
        'background-image': `url(${this.textIcon})`
      }
    },
    gunBgStyle () {
      const arr = this.awardBoxBgSize.split(' ')
      const width = arr[0] || '401px'
      const height = arr[1] || '112px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.gunBg})`
      }
    },
    btnCopyStyle () {
      const arr = this.helpBtnSize.split(' ')
      const width = arr[0] || '192px'
      const height = arr[1] || '57px'
      return {
        width,
        height,
        color: `${this.helpBtnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatusOne})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.helpModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.helpModelShow = true
    },
    toHelp () {
      this.$emit('toHelp')
    }
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .layModel{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 9;
  }
  .help-model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .title-model{
    margin: 42px 0 20px;
  }
  .copy-desc{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .award-model{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    .gun-img{
      width: 80%;
      height: 80%;
    }
  }
  .btn-help{
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto 20px;
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
  .right-rule{
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
    padding: 0 70px;
    box-sizing: border-box;
    .rule-item{
      display: flex;
      align-items: center;
      line-height: 35px;
      .text-icon{
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

</style>
