var render = function render(){var _vm=this,_c=_vm._self._c;return _c('STopicWrapper',_vm._b({staticClass:"new-role"},'STopicWrapper',_vm.$attrs,false),[_c('div',{staticClass:"role-box"},_vm._l((_vm.roleList),function(item,index){return _c('div',{key:index,staticClass:"role-item",class:_vm.animateTypeAllStyle},[_c('img',{style:({width: _vm.contentBgSize.split(' ')[0],height: _vm.contentBgSize.split(' ')[1]}),attrs:{"src":item.contentBg,"alt":""}}),(item.roleImg)?_c('img',{class:_vm.animateTypeStyle,style:({
            width: item.roleImgSize.split(' ')[0],
            height: item.roleImgSize.split(' ')[1],
            position: 'absolute',
            cursor: 'pointer',
            top: item.roleImgPosition.split(' ')[0],
            right: item.roleImgPosition.split(' ')[1],
            bottom: item.roleImgPosition.split(' ')[2],
            left: item.roleImgPosition.split(' ')[3]}),attrs:{"src":item.roleImg,"alt":""}}):_vm._e(),(item.roleDescImg)?_c('img',{style:({
            width: _vm.roleDescSize.split(' ')[0],
            height: _vm.roleDescSize.split(' ')[1],
            position: 'absolute',
            top: item.roleDescPosition.split(' ')[0],
            right: item.roleDescPosition.split(' ')[1],
            bottom: item.roleDescPosition.split(' ')[2],
            left: item.roleDescPosition.split(' ')[3]}),attrs:{"src":item.roleDescImg,"alt":""}}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }