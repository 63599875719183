export const getSize = (str) => {
  const arr = str.split(' ')
  return {
    width: arr[0],
    height: arr[1]
  }
}
export const getPosition = (str) => {
  const arr = str.split(' ')
  return {
    top: arr[0],
    right: arr[1],
    bottom: arr[2],
    left: arr[3]
  }
}
/**
 * 获取限购数量
 * @param str 限购数量字符串 '0|0|0|0|3'
 */
export const getLimit = (str) => {
  if (!str) return {}
  const arr = str.split('|').map(item => parseInt(item))
  return {
    hour: arr[0],
    day: arr[1],
    week: arr[2],
    month: arr[3],
    limit: arr[4]
  }
}

export function isPhone (account) {
  return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(account)
}
// 复制文字
export function copyContent (text) {
  let target = null
  target = document.createElement('div')
  target.id = 'tempTarget'
  target.style.opacity = '0'
  target.innerText = text
  document.body.appendChild(target)
  return new Promise((resolve, reject) => {
    try {
      const range = document.createRange()
      range.selectNode(target)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
      target.parentElement.removeChild(target)
      resolve()
    } catch (e) {
      target.parentElement.removeChild(target)
      reject(e)
    }
  })
}
export const math = {
  // 加法
  add: (arg1, arg2) => {
    let r1, r2, m
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    // eslint-disable-next-line prefer-const
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  },
  // 减法
  subtr: (arg1, arg2) => {
    let r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    // eslint-disable-next-line prefer-const
    m = Math.pow(10, Math.max(r1, r2))
    // last modify by deeka
    // 动态控制精度长度
    // eslint-disable-next-line prefer-const
    n = (r1 >= r2) ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  },
  // 乘法获取准确值
  mul: (arg1, arg2) => {
    let m = 0; const s1 = arg1.toString(); const s2 = arg2.toString()
    try { m += s1.split('.')[1].length } catch (e) {}
    try { m += s2.split('.')[1].length } catch (e) {}
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
  },
  // 除法获取精确值
  div: (arg1, arg2) => {
    let t1 = 0; let t2 = 0; let r1; let r2
    try { t1 = arg1.toString().split('.')[1].length } catch (e) { }
    try { t2 = arg2.toString().split('.')[1].length } catch (e) { }
    Math.r1 = Number(arg1.toString().replace('.', ''))
    Math.r2 = Number(arg2.toString().replace('.', ''))
    return (Math.r1 / Math.r2) * Math.pow(10, t2 - t1)
  }
}

// query 转地址栏字符串
export const queryToString = (query) => {
  let str = ''
  for (const key in query) {
    if (query[key] !== undefined) {
      str += `${key}=${query[key]}&`
    }
  }
  return str.slice(0, -1)
}
