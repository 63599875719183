import _mixin from '../_mixin'
export default {
  name: 'SAct4',
  cnName: '活动4',
  type: 'activity_five',
  isTab: true,
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/02/06/3b3a740c39f27789cf1d5dc5d74a64db.jpg',
  // 属性相关
  props: {
    /* 首页配置 */
    activityId: 'S2NFDNPFUNAGJ52DGIPCQQXJSM',
    // 轮播奖励
    awardList: [
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/01/29/81cb55a6453e19cb0b5a33a54f779daa.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/01/29/81cb55a6453e19cb0b5a33a54f779daa.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/01/29/81cb55a6453e19cb0b5a33a54f779daa.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/01/29/81cb55a6453e19cb0b5a33a54f779daa.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/01/29/81cb55a6453e19cb0b5a33a54f779daa.png'
      }
    ],
    // 左切换
    swiperLeft: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/469e68eb596de0e7a6f1b03d670f1669.png',
      style: {
        width: '91px',
        height: '83px',
        top: '500px',
        left: '110px',
        right: '',
        bottom: ''
      }
    },
    // 右切换
    swiperRight: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/7ddfebb0bde3dde88e5555d67519a234.png',
      style: {
        width: '91px',
        height: '83px',
        top: '500px',
        left: '',
        right: '110px',
        bottom: ''
      }
    },

    /* 领取按钮 */
    // 尺寸
    btnSize: '360px 100px',
    // 左领取按钮位置
    btnPos: '769px 0 0 776px',
    // 未开始按钮
    btnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/67b89552ab28e1234f938e8213e365ac.png'
    },
    // 未完成按钮
    btnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/152426d1a883235da066b967de04c616.png'
    },
    // 领取按钮
    btnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/451765f427c8ba9b3e45faa46be826e8.png'
    },
    // 已领取按钮
    btnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c37cf7d6d93de034025df9b81dbfee42.png'
    },
    // 已过期按钮
    btnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/4302923e1bd3311edb392c03de82f896.png'
    }
  },
  propsDesc: [
    /* 首页配置 */
    {
      target: '首页配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'awardList',
      label: '奖励',
      formType: 'list',
      listConfig: [
        {
          title: '奖励图片',
          target: 'awardImg'
        }
      ]
    },
    {
      target: 'swiperLeft',
      label: '左切换',
      formType: 'image'
    },
    {
      target: 'swiperRight',
      label: '右切换',
      formType: 'image'
    },

    {
      target: '== 领取按钮 ==',
      formType: 'divider'
    },
    {
      target: 'btnSize',
      label: '尺寸',
      formType: 'size'
    },
    {
      target: 'btnPos',
      label: '按钮位置',
      formType: 'position'
    },
    {
      target: 'btnNoBegin',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'btnUnfinished',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'btnReceived',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'btnExpire',
      label: '已过期按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
