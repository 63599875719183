export default {
  // base: {
  //   id: '',
  //   name: ''
  // },
  props: {
  },
  propsDesc: [
  ],
  styles: {
    // 位置
    position: 'relative',
    top: '',
    left: '',
    right: '',
    bottom: '',
    'z-index': '',
    // 盒子
    width: '',
    height: '',
    'min-height': '',
    margin: '0 auto',
    padding: '0',
    // 背景
    'background-color': '',
    'background-image': '',
    'background-repeat': 'no-repeat',
    'background-size': '100% 100%',
    // 文字
    color: '#333',
    'font-size': '14px'
  },
  stylesDesc: [
    {
      type: '位置',
      children: [
        {
          key: 'position',
          label: '定位',
          formType: 'select',
          options: [
            { label: '相对定位', value: 'relative' },
            { label: '绝对定位', value: 'absolute' },
            { label: '固定定位', value: 'fixed' }
          ]
        },
        {
          key: 'top',
          label: '上'
        },
        {
          key: 'right',
          label: '右'
        },
        {
          key: 'bottom',
          label: '下'
        },
        {
          key: 'left',
          label: '左'
        },
        {
          key: 'z-index',
          label: '层级'
        }
      ]
    },
    {
      type: '盒子',
      children: [
        {
          key: 'width',
          label: '宽'
        },
        {
          key: 'height',
          label: '高'
        },
        {
          key: 'min-height',
          label: '最小高度'
        },
        {
          key: 'margin',
          label: '外边距'
        },
        {
          key: 'padding',
          label: '内边距'
        }
      ]
    },
    {
      type: '背景',
      children: [
        {
          key: 'background-image',
          label: '背景图',
          description: 'url(xxx.png)'
        },
        {
          key: 'background-color',
          label: '背景色',
          description: ''
        },
        {
          key: 'background-repeat',
          label: '重复',
          description: ''
        },
        {
          key: 'background-size',
          label: '大小',
          description: ''
        }
      ]
    },
    {
      type: '文字',
      children: [
        {
          key: 'color',
          label: '颜色',
          description: ''
        },
        {
          key: 'font-size',
          label: '字号',
          description: ''
        }
      ]
    }
    // {
    //   type: '变形',
    //   children: [
    //     {
    //       key: 'zoom',
    //       label: '缩放',
    //       description: '',
    //     }
    //   ]
    // }
  ],
  eventsDesc: [{
    target: 'click',
    label: '点击'
  }],
  methods: [{
    label: '显示',
    fn: 'show'
  }, {
    label: '隐藏',
    fn: 'hide'
  }]
}
