<template>
  <div class="s-component">
    <div class="s-five-tab">
      <div v-for="(item,index) in list"
           class="s-five-tab-item"
           :class="{active: curIndex === index}"
           @click="onTabChange(index)"
           :key="index"
           :style="{
              backgroundImage: `url(${ curIndex === index ? item.activeImg : item.img}`
            }">
      </div>
    </div>
    <div class="s-five-content" :style="{backgroundImage: `url(${bg}`}">
      <div class="s-five-content-item"
           v-show="curIndex === index"
           v-for="(item,index) in list"
           :key="index">
        <template v-if="index === curIndex">
            <slot :name="index + ''">
            </slot>
        </template>
      </div>
    </div>
    <SFiveModalReceive ref="SFiveModalReceive"></SFiveModalReceive>
    <SFiveModalConfirm ref="SFiveModalConfirm"></SFiveModalConfirm>
    <ModalConfirm ref="ModalConfirm"></ModalConfirm>
  </div>
</template>

<script>
import SFiveModalReceive from './_components/FiveModalReceive.vue'
import SFiveModalConfirm from './_components/FiveModalConfirm.vue'
import ModalConfirm from '../_components/ModalConfirm.vue'
export default {
  name: 'SFiveWrap',
  components: {
    SFiveModalReceive,
    ModalConfirm,
    SFiveModalConfirm
  },
  props: {
    // tab列表
    // [{img: '', activeImg: '', needLogin: '', }]
    list: {
      type: Array,
      required: true
    },
    // content 主背景
    bg: {
      type: String,
      required: true
    },

    // ===== modal =====
    modalBg: {
      type: Object,
      default: () => {}
    },
    modalCloseIcon: {
      type: Object,
      default: () => {}
    },
    modalTitleStyle: {
      type: String,
      default: '{}'
    },
    modalTextStyle: {
      type: String,
      default: '{}'
    },
    modalConfirmBtnStyle: {
      type: String,
      default: '{}'
    },
    modalCancelBtnStyle: {
      type: String,
      default: '{}'
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  provide () {
    return {
      $MainWrap: this
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  created () {
    const tabIndex = this.$route.query.tabIndex
    this.curIndex = tabIndex ? Number(tabIndex) : 0
  },
  mounted () {
    // const tabIndex = this.$route.query.tabIndex
    // this.curIndex = tabIndex ? Number(tabIndex) : 0
    // this.$refs.SFiveModalReceive.open([{ name: 'xxx', icon: 'https://rescdn.ssjj.cn/website/2024/01/29/7ddfebb0bde3dde88e5555d67519a234.png' }])
  },
  methods: {
    onTabChange (index) {
      if (this.list[index].isNeedLogin === '1') {
        // eslint-disable-next-line no-undef
        if (!$checkLogin()) return
      }
      this.curIndex = index
    },
    /**
     * 打开领取弹窗
     * @param awardsList 奖励列表 [{name: '', icon: ''}]
     */
    openReceiveModal (awardsList) {
      this.$refs.SFiveModalReceive.open(awardsList)
    },
    confirm (options) {
      this.$refs.ModalConfirm.open(options)
    },
    // 检查开始/结束时间
    async checkStartEnd (start, end) {
      const time = await this.requestAPI({
        url: '/v1/uni/t',
        method: 'get'
      })
      // console.log('服务器时间：', time)
      // console.log('开始时间：', start * 1000)
      // console.log('结束时间：', end * 1000)
      if (time < start * 1000) {
        this.$message('当前活动未开始, 请关注《生死狙击2》其他活动~')
      } else if (time > end * 1000) {
        this.$message('当前活动已结束, 请关注《生死狙击2》其他活动~')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$tabHeight: 136px;
.s-component {
  position: relative;
  width: 1920px;
  height: 966px;
  user-select: none;
}

.s-five-tab {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;

  .s-five-tab-item {
    width: 384px;
    height: $tabHeight;
    transition: .3s;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    color: #fff;
  }
}

.s-five-content {
  width: 100%;
  height: 966px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
