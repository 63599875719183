import _mixin from './_mixin'

const bg0 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/e42735a38c66f215ad391a02a40928cb.png'
const cover0 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/3741729c1293328e98ae185a279aa39d.png'
const bg1 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/a4ec1a9aafc4d455055c81ff3e94d92e.png'
const cover1 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/d7727332796838e69c63bf80ed4b8bf5.png'
const bg2 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/5c81bd411e125c2d8907f366f50ee28d.png'
const cover2 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/92f2d13257eab5bc493de18247b52365.png'
const bg3 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/98af32050e5efa14b849fc26757247c9.png'
const cover3 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/88cd16ff3921942639e5ef183a321936.png'
const bg4 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/1a2010609b022af266bccfafaa1d88fa.png'
const cover4 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/50e18c1a105049b8936aa1bbeecd6634.png'
const bg5 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/0bf0285b9ea6cb63f00c85f2caa37352.png'
const cover5 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/855064fb45742bd88c0bd92178894634.png'
const bg6 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/feda4ac3abc4934b3dcc41d9d97ad0a1.png'
const cover6 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/c108b230e38a8aa6793a47ae98134a77.png'
const bg7 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/51f8dc2daa353987dd1ebc57707a9184.png'
const cover7 = 'https://rescdn-crash.ssjj.cn/website/2023/03/21/2d3ce4da55a642c3d2965ed52446ad49.png'

export default {
  name: 'SLottery',
  cnName: '抽奖',
  type: 'complex',
  needLogin: true,
  // 属性相关
  props: {
    /* 抽奖页配置 */
    activityId: 'MSAKLOPHOUWOC4DK6SXPTCL2GM',
    lotteryImg: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/e3d19fcf6e1af1593c030eaa72161286.png',
    list: [
      { bg: bg0, cover: cover0 },
      { bg: bg1, cover: cover1 },
      { bg: bg2, cover: cover2 },
      { bg: bg3, cover: cover3 },
      { bg: bg4, cover: cover4 },
      { bg: bg5, cover: cover5 },
      { bg: bg6, cover: cover6 },
      { bg: bg7, cover: cover7 }
    ],
    /* 公共弹窗配置 */
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png', // 弹窗背景
    modelBgSize: '750px 450px',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png', // 弹窗右上角关闭
    modelColor: '#D54A51', // 弹窗文本色号
    btnStatus: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png', // 按钮背景1
    btnTextColor: '#FFFFFF', // 按钮文本色号
    btnSize: '192px 57px',
    btnCloseText: '关闭',
    btnHappyText: '开心收下',
    btnAddressText: '填写收件信息',
    btnSubmitText: '提交',
    btnReturnText: '返回修改',
    btnConfirmText: '确认',
    /* 奖励弹窗配置 */
    awardTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/dd63755a299381588c4e41b75541c1d4.png',
    awardTitleSize: '446px 29px',
    awardOtherDesc: '请前往邮箱查收奖励',
    awardBoxBg: '',
    awardBoxBgSize: '201px 132px',
    /* 提示弹窗配置 */
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px',
    /* 填写地址弹窗 */
    addressBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    addressBgSize: '1000px 600px',
    addressTitle: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/d7727332796838e69c63bf80ed4b8bf5.png',
    addressTitleSize: '719px 39px'
  },
  propsDesc: [
    /* 抽奖页配置 */
    {
      target: '抽奖页配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '抽奖活动唯一标识'
    },
    {
      target: 'lotteryImg',
      label: '点击抽奖图片'
    },
    {
      target: 'list',
      label: '宫格抽奖图',
      formType: 'list',
      listConfig: [
        {
          title: '默认图',
          target: 'bg'
        },
        {
          title: '选中图',
          target: 'cover'
        }
      ]
    },
    /* 公共弹窗配置 */
    {
      target: '公共弹窗配置',
      formType: 'divider'
    },
    {
      target: 'modelBg',
      label: '弹窗背景'
    },
    {
      target: 'modelBgSize',
      label: '弹窗背景大小'
    },
    {
      target: 'modelClose',
      label: '弹窗右上角关闭'
    },
    {
      target: 'modelColor',
      label: '弹窗文本色号'
    },
    {
      target: 'btnStatus',
      label: '按钮背景'
    },
    {
      target: 'btnTextColor',
      label: '按钮文本颜色'
    },
    {
      target: 'btnSize',
      label: '按钮尺寸'
    },
    {
      target: 'btnCloseText',
      label: '按钮文案1'
    },
    {
      target: 'btnHappyText',
      label: '按钮文案2'
    },
    {
      target: 'btnAddressText',
      label: '按钮文案3'
    },
    {
      target: 'btnSubmitText',
      label: '提交'
    },
    {
      target: 'btnReturnText',
      label: '返回修改'
    },
    {
      target: 'btnConfirmText',
      label: '确认'
    },
    /* 奖励弹窗配置 */
    {
      target: '奖励弹窗配置',
      formType: 'divider'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题'
    },
    {
      target: 'awardTitleSize',
      label: '奖励标题尺寸',
      description: '宽 高'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励框背景'
    },
    {
      target: 'awardBoxBgSize',
      label: '奖励框背景尺寸'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    },
    /* 填写地址弹窗配置 */
    {
      target: '填写地址弹窗配置',
      formType: 'divider'
    },
    {
      target: 'addressBg',
      label: '填写地址弹窗背景'
    },
    {
      target: 'addressBgSize',
      label: '填写地址弹窗背景尺寸'
    },
    {
      target: 'addressTitle',
      label: '填写地址弹窗标题'
    },
    {
      target: 'addressTitleSize',
      label: '填写地址标题尺寸',
      description: '宽 高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
