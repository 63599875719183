import componentConfig from '@/components/BuilderComponentsConfig'
import { deepClone, getCacheSize } from '@/utils'
import { addTreeNodeById, delTreeNodeById, resetNodeId } from '@/utils/tree'

const state = {
  isEdit: true, // 是否是编辑模式
  tree: localStorage.getItem('COMPONENT_TREE') ? JSON.parse(localStorage.getItem('COMPONENT_TREE')) : [],
  currentId: '', // 当前选中组件id
  isChoose: false, // 是否处于选择关联组件状态（为组件绑定事件时）
  customComponents: localStorage.getItem('CUSTOM_COMPONENTS') ? JSON.parse(localStorage.getItem('CUSTOM_COMPONENTS')) : [] // 自定义组件
}

const getters = {
  // Filtering currentUser
  page: state => {
    return {
      tree: state.tree,
      currentId: state.currentId,
      isEdit: state.isEdit,
      // 是否处于选择关联组件状态（为组件绑定事件时）
      isChoose: state.isChoose,
      customComponents: state.customComponents
    }
  }
}

const mutations = {
  /**
   * 添加组件
   * @param state
   * @param component
   * @constructor
   */
  ADD_COMPONENT (state, component) {
    addTreeNodeById(state.tree, state.currentId, component)
  },
  /**
   * 删除组件
   * @param state
   * @param id
   * @constructor
   */
  DEL_COMPONENT (state, id) {
    delTreeNodeById(state.tree, id)
  },
  /**
   * 保存当前组件树到本地存储
   * @param state
   * @constructor
   */
  SAVE_TREE_TO_LOCALSTORAGE (state) {
    localStorage.setItem('COMPONENT_TREE', JSON.stringify(state.tree))
    const size = getCacheSize('localStorage')
    console.log('> 当前已用存储：' + (size / 1024).toFixed(2) + 'KB')
  },
  /**
   * 设置当前选中组件的ID
   * @param state
   * @param currentId
   * @constructor
   */
  SET_CURRENT_ID (state, currentId) {
    state.currentId = currentId
  },

  /**
   * 设置编辑状态
   * @param state
   * @param flag
   * @constructor
   */
  SET_IS_EDIT (state, flag) {
    state.isEdit = flag
    console.log(flag)
  },
  /**
   * 设置选择关联组件状态
   * @param state
   * @param flag
   * @constructor
   */
  SET_IS_CHOOSE (state, flag) {
    state.isChoose = flag
    console.log(flag)
  },

  /**
   * 保存自定义组件
   * @param state
   * @param customComp 组件 {name, component}
   * @constructor
   */
  SAVE_CUSTOM_COMPONENT (state, customComp) {
    if (state.customComponents.some(item => item.name === customComp.name)) {
      this.$message.error('组件名重复')
      return
    }
    state.customComponents.push(customComp)
    localStorage.setItem('CUSTOM_COMPONENTS', JSON.stringify(state.customComponents))
  },
  /**
   * 删除自定义组件
   * @param state
   * @param index
   * @constructor
   */
  DEL_CUSTOM_COMPONENT (state, index) {
    state.customComponents.splice(index, 1)
    localStorage.setItem('CUSTOM_COMPONENTS', JSON.stringify(state.customComponents))
  },
  /**
   * 导入配置项
   * @param state
   * @param dataStr
   * @constructor
   */
  IMPORT_CONFIG (state, dataStr) {
    state.tree = JSON.parse(dataStr)
    // console.log(dataStr)
    // console.log(dataStr.tree)
    localStorage.setItem('COMPONENT_TREE', dataStr)
    // window.location.reload()
  }
}

const actions = {

  /**
   * 添加组件
   * @param commit
   * @param name 组件名
   */
  addComponent ({ commit }, name) {
    const component = deepClone({
      id: name + '_' + Date.now(),
      ...componentConfig[name]
    })
    commit('ADD_COMPONENT', component)
    commit('SET_CURRENT_ID', component.id)
    commit('SAVE_TREE_TO_LOCALSTORAGE')
  },
  /**
   * 删除组件
   * @param state
   * @param commit
   * @param id 组件Id
   */
  delComponent ({ state, commit }, id) {
    const targetId = id || state.currentId
    const rootId = state.tree[0].id
    if (targetId === rootId) return
    commit('DEL_COMPONENT', targetId)
    commit('SET_CURRENT_ID', rootId) // 删除当前组件后 重置为根元素的 id
    commit('SAVE_TREE_TO_LOCALSTORAGE')
  },
  updateComponent ({ commit }, data) {},
  /**
   * 添加自定义组件
   * @param commit
   * @param component
   */
  addCustomComponent ({ commit }, component) {
    // 先重设组件及其子组件的ID 防止id冲突
    const comp = deepClone(component)
    resetNodeId(comp)
    commit('ADD_COMPONENT', comp)
    commit('SET_CURRENT_ID', comp.id)
    commit('SAVE_TREE_TO_LOCALSTORAGE')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
