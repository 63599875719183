<template>
  <div class="s-tags" v-show="visible"  :style="listStyle">
    <span class="item" :style="itemStyle" v-for="item in tags.split(',')" :key="item">{{item}}</span>
  </div>
</template>

<script>
export default {
  name: 'STags',
  props: {
    tags: {
      type: String,
      default: 'tag1,tag2'
    },
    fontSize: {
      type: String,
      default: '24px'
    },
    color: {
      type: String,
      default: '#fff'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    border: {
      type: String,
      default: '2px solid #fff'
    },
    backgroundColor: {
      type: String,
      default: 'rgba(0,0,0,0.3)'
    },
    padding: {
      type: String,
      default: '5px 20px'
    },
    itemOtherStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  computed: {
    listStyle () {
      return {
        'font-size': this.fontSize,
        color: this.color,
        'text-align': this.textAlign
      }
    },
    itemStyle () {
      return {
        border: this.border,
        'background-color': this.backgroundColor,
        padding: this.padding
      }
    },
    safeHtml () {
      // 过滤除了 span b 外的html标签
      return this.text.replace(/<(?!span|b|\/span|\/b).*?>/gi, '')
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .s-tags{
    margin: 0 -10px;
    .item{
      display: inline-block;
      margin: 0 10px 10px;
    }
  }
</style>
