<template>
  <div class="s-component" :style="{'background-image': `url(${defaultBg.src})`, ...defaultBg.style}">
    <div class="crystal-nucleus">
      <div class="crystal-nucleus-box">
        <div class="box-left">
          <div class="box-left-item" v-for="(core, index) in crystalNucleus.desc" :key="core.name" @click="chooseFunc('chooseCoreIndex', index)">
            <img :src="core.img" :class="chooseCoreIndex===index ? 'choose-core' : ''" alt="">
            <div>{{core.coreName}}</div>
          </div>
        </div>
        <div class="box-right">
          <div class="box-right-top">
            <div class="intro-left">
              <div class="sample-item" @click="chooseFunc('chooseSimpleIndex', indx)" v-for="(i, indx) in chooseTab.list" :key="i.name">
                <img :class="chooseSimpleIndex===indx ? 'choose-simple' : ''" :src="i.img" alt="">
                {{i.name}}
              </div>
            </div>
            <div class="intro-right">
              <div class="intro-logo">
                <img class="intro-img" :src="chooseSimple.img"/>
                <div class="intro-text">
                  <div class="intro-name">{{chooseSimple.name}}</div>
                  <div class="intro-tag">{{chooseCore.coreName}}</div>
                </div>
              </div>
              <div class="intro-desc" v-html="chooseSimple.desc"></div>
            </div>
          </div>
          <div class="box-right-bottom">
            <div class="tab-name" v-for="(tab, index) in chooseCore.intro" :class="tab.list.length ? (chooseTabIndex === index ? 'choose-tab' : '') : 'lock-tab'" :key="tab.name" @click="chooseFunc('chooseTabIndex', index)">
              <img v-if="!tab.list.length" src="@/assets/images/lock.png" alt="">{{tab.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'STopic2CrystalNucleus',
  props: {
    crystalNucleusJson: {
      type: String,
      default: '{}'
    },
    defaultBg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chooseCore: {},
      chooseTab: {},
      chooseSimple: {},

      chooseCoreIndex: 0,
      chooseTabIndex: 0,
      chooseSimpleIndex: 0
    }
  },
  computed: {
    crystalNucleus () {
      try {
        return JSON.parse(this.crystalNucleusJson)
      } catch (e) {
        console.error('STopic2CrystalNucleus json 格式错误')
        return {}
      }
    }
  },
  mounted () {
    this.resetChoose()
  },
  methods: {
    chooseFunc (str, index) {
      if (str === 'chooseTabIndex' && !this.crystalNucleus.desc[this.chooseCoreIndex].intro[index].list.length) {
        return
      }
      if (str === 'chooseCoreIndex') {
        this.chooseTabIndex = 0
        this.chooseSimpleIndex = 0
      } else if (str === 'chooseTabIndex') {
        this.chooseSimpleIndex = 0
      }
      this[str] = index
      this.resetChoose()
    },
    resetChoose () {
      this.chooseCore = this.crystalNucleus.desc[this.chooseCoreIndex]
      this.chooseTab = this.crystalNucleus.desc[this.chooseCoreIndex].intro[this.chooseTabIndex]
      this.chooseSimple = this.crystalNucleus.desc[this.chooseCoreIndex].intro[this.chooseTabIndex].list[this.chooseSimpleIndex]
      console.log(this.chooseSimple.desc, '--this.chooseSimple---')
    }
  }
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  width: 1920px;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
  .crystal-nucleus {
    color: #fff;
    .crystal-nucleus-box {
      width: 1400px;
      height: 452px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .box-left {
      width: 277px;
      background: rgba(85, 102, 138, .5);
      padding: 24px 70px;
      height: 100%;
      overflow-y: auto;
      font-size: 18px;
      .box-left-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        img {
          width: 136px;
          height: 136px;
          margin-bottom: 15px;
        }
      }
      .choose-core {
        border-radius: 8px 8px 8px 8px;
        border: 3px solid #618F8B;
      }
    }
    // 修改滚动条样式
    .box-left::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: rgba(255,255,255,0.1);
    }
    .box-right {
      background: rgba(85, 102, 138, .5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 23px;
      width: 1100px;
      .box-right-top {
        height: 358px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .intro-left {
          margin: 40px 0;
          height: 278px;
          width: 650px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;
        }
        .intro-right {
          height: 278px;
          width: 450px;
          margin: 40px 0;
          padding: 0 40px;
          border-left: 2px solid #364247;
        }
        .intro-logo {
          display: flex;
          margin-bottom: 31px;
          .intro-img {
            width: 110px;
            height: 110px;
            border-radius: 0px 0px 0px 0px;
          }
          .intro-tag {
            padding: 0 4px;
            height: 32px;
            background: #c57e23;
            text-align: center;
            line-height: 32px;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
          }
          .intro-text {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .intro-name {
            width: 160px;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
          }
        }
        .intro-desc {
          font-size: 18px;
          color: #FFFFFF;
          line-height: 36px;
        }
        .sample-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 162px;
          font-size: 18px;
          margin-bottom: 33px;
          img {
            background: rgba(255,255,255,0);
            width: 86px;
            height: 86px;
            margin-bottom: 11px;
          }
          .choose-simple {
            border: 3px solid #618F8B;
            border-radius: 8px 8px 8px 8px;
          }
        }
      }
      .intro-left::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: rgba(255,255,255,0.1);
      }
      .box-right-bottom {
        width: 100%;
        height: 94px;
        display: flex;
        .tab-name {
          width: 220px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #435374;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 62px;
        }
        .lock-tab {
          background: #A1ABAA;
        }
        .choose-tab {
          background: #2D4C59;
        }
      }
    }
  }
</style>
