<template>
  <div class="s-text" v-show="visible" :style="{...style, ...otherStyleObj}" v-html="safeHtml">
  </div>
</template>

<script>
export default {
  name: 'SText',
  props: {
    text: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    isBold: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#333'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    lineHeight: {
      type: [String, Number],
      default: 1.5
    },
    textIndent: {
      type: Boolean,
      default: false
    },
    otherStyle: {
      type: String,
      default: '{}'
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  computed: {
    style () {
      return {
        'font-size': this.fontSize,
        'font-weight': this.isBold ? 'bold' : null,
        color: this.color,
        'text-align': this.textAlign,
        'line-height': this.lineHeight,
        'text-indent': this.textIndent ? '2em' : null
      }
    },
    otherStyleObj () {
      try {
        return JSON.parse(this.otherStyle)
      } catch (e) {
        console.error('otherStyle 格式错误')
        return {}
      }
    },
    safeHtml () {
      // 过滤除了 span b 外的html标签
      return this.text.replace(/<(?!span|b|\/span|\/b).*?>/gi, '')
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .s-text{
    white-space: pre-line
  }
</style>
