<template>
  <div class="s-video-list-wrap">

    <div class="s-video-list" v-show="visible">
      <div
         v-for="(item,index) in list"
         :key="index"
         class="s-video-list-item"
         @click="handleClick(item)"
         :style="{width: imgWidth}">
        <div class="cover-wrap">
          <img class="cover" :style="{width: imgWidth,height: imgHeight}" :src="item.cover" alt=""/>
        </div>
        <p class="label">{{ item.label }}</p>
        <p class="date">{{ item.date }}</p>
      </div>
    </div>
    <s-dialog :visible.sync="videoVisible" v-if="videoVisible" width="1200px" background-color="transparent" >
      <video class="s-video" controls autoplay :src="src"></video>
    </s-dialog>
  </div>

</template>

<script>
export default {
  name: 'SVideoList',
  props: {
    imgWidth: {
      type: String,
      default: 'auto'
    },
    imgHeight: {
      type: String,
      default: 'auto'
    },
    list: {
      type: Array,
      required: true,
      default: () => [
        // {label: 'xxx', src: 'xxx', link: '', date: ''}
      ]
    }
  },
  data () {
    return {
      visible: true,
      videoVisible: false,
      src: ''
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick (item) {
      console.log('open video player')
      if (item.link) {
        window.open(item.link, '_blank')
      } else {
        this.src = item.src
        this.$nextTick(() => {
          this.videoVisible = true
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.s-video-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  &-item {
    position: relative;
    display: block;
    text-decoration: none;
    margin: 0 10px 20px;
    width: 100%;
    &:hover{
      cursor: pointer;
    }

    .cover-wrap {
      position: relative;

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        opacity: 0;
        transition: .3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("https://rescdn.ssjj.cn/ssjj2res/ssjj2-website-fe/nuxt/dist/client/img/index-video-enterbg.44c1e1b.png");
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(0, 0, 0, .5);
      }

      .cover {
        display: block;
      }
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: #ccc;
      font-size: 16px;
      line-height: 30px;
    }

    .date {
      font-size: 12px;
      color: #808080;
    }
  }
}
.s-video{
  width: 1200px;
  display: block;
}
</style>
