import _mixin from './_mixin'

export default {
  name: 'SAddress',
  cnName: '填写收货地址',
  type: 'complex',
  needLogin: true,
  // 属性相关
  props: {
    /* 填写地址 */
    activityId: 'MSAKLOPHOUWOC4DK6SXPTCL2GM',
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelBgSize: '1000px 600px',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    addressTitle: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/d7727332796838e69c63bf80ed4b8bf5.png',
    addressTitleSize: '719px 39px',
    btnStatus: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    btnColor: '#FFFFFF',
    btnSize: '220px 66px',
    btnWriteText: '填写收件地址',
    btnSubmitText: '提交',
    btnReturnText: '返回修改',
    btnConfirmText: '确认',
    btnCloseText: '关闭',
    /* 提示弹窗配置 */
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px'
  },
  propsDesc: [
    {
      target: '地址填写配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动地址id'
    },
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelBgSize',
      label: '公共弹窗背景尺寸'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'addressTitle',
      label: '地址标题'
    },
    {
      target: 'awardTitleSize',
      label: '地址标题尺寸',
      description: '宽 高'
    },
    {
      target: 'btnStatus',
      label: '奖励按钮背景'
    },
    {
      target: 'btnColor',
      label: '按钮文案颜色'
    },
    {
      target: 'btnSize',
      label: '奖励按钮大小'
    },
    {
      target: 'btnWriteText',
      label: '填写收件地址'
    },
    {
      target: 'btnSubmitText',
      label: '提交'
    },
    {
      target: 'btnReturnText',
      label: '返回修改'
    },
    {
      target: 'btnConfirmText',
      label: '确认'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
