/**
 * 注意！任何组件配 不可重名。即使是跨文件夹 也不可重名
 */

const modulesFiles = require.context('./', true, /.vue$/) // /^S.*.js$/
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  let moduleName = modulePath.replace(/^.\/(.*)\.vue/, '$1')
  const modulePathArr = moduleName.split('/')
  if (modulePathArr.slice(-1)[0] === 'index') {
    moduleName = modulePathArr.slice(-2)[0]
  } else {
    moduleName = modulePathArr.slice(-1)[0]
  }
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const components = Object.keys(modules).map(key => modules[key])
// console.log(components)
export default components
