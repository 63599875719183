const events = {
  lisenters: {},
  on (type, fn) {
    events.lisenters[type] = events.lisenters[type] || []
    events.lisenters[type].push(fn)
  },
  off (type, fn) {
    if (!events.lisenters[type]) return false
    const index = events.lisenters[type].findIndex((i) => i === fn)
    if (index === -1) return false
    events.lisenters[type].splice(index, 1)
    return true
  },
  fire (type, ...args) {
    if (!events.lisenters[type]) return
    const slices = events.lisenters[type].slice(0)
    slices.forEach((fn) => fn(...args))
  }
}

export default events
