<template>
  <div class="part-right" v-if="currentComponent">
    <div class="current">
      <!--      <el-alert-->
      <!--        :title="currentComponent.cnName"-->
      <!--        type="success"-->
      <!--        :closable="false"-->
      <!--        :description="`${currentComponent.id}`">-->
      <!--      </el-alert>-->
      <el-card class="mb5" shadow="never">
        <el-input placeholder="组件名称" size="mini" v-model="currentComponent.cnName" class="mb5">
          <template slot="prepend">组件名称</template>
        </el-input>
        <el-input placeholder="组件名称" size="mini" v-model="currentComponent.id" readonly>
          <template slot="prepend">组件&nbsp;&nbsp;&nbsp;ID</template>
          <el-button slot="append"
                     type="primary"
                     icon="el-icon-document-copy"
                     v-clipboard:copy="currentComponent.id"
                     v-clipboard:success="onCopySuccess"
          ></el-button>
        </el-input>
      </el-card>
    </div>
    <div class="control" v-if="page.tree.length && page.currentId">
      <el-card shadow="never" :body-style="{padding: '0 20px 10px'}">
        <el-tabs v-model="activeName">
          <el-tab-pane label="属性" name="props">
            <el-form label-width="130px" class="part-right-form">
              <el-form-item v-for="(item,index) in currentComponent.propsDesc"
                            :label="item.label"
                            v-show="_formItemVisible(item)"
                            :key="index">
                <el-divider v-if="item.formType === 'divider'">{{item.target}}</el-divider>
                <el-input v-else-if="item.formType === 'textarea'" size="mini"
                          type="textarea"
                          rows="10"
                          v-model="currentComponent.props[item.target]"/>
                <el-input-number v-else-if="item.formType === 'input-number'" size="mini"
                                 v-model="currentComponent.props[item.target]"/>
                <el-select v-else-if="item.formType === 'select'" size="mini"
                           v-model="currentComponent.props[item.target]">
                  <el-option v-for="_item in item.options" :key="_item.value" :value="_item.value"
                             :label="_item.label"/>
                </el-select>
                <el-radio-group v-else-if="item.formType === 'radio'" size="mini"
                                v-model="currentComponent.props[item.target]">
                  <el-radio v-for="_item in item.options" :key="_item.value" :label="_item.value">{{ _item.label }}
                  </el-radio>
                </el-radio-group>
                <el-switch v-else-if="item.formType === 'switch'" size="mini"
                           v-model="currentComponent.props[item.target]">
                </el-switch>
                <ListEditor v-else-if="item.formType === 'list'" :key="currentComponent.id"
                            :list.sync="currentComponent.props[item.target]"
                            :config="item.listConfig"/>
                <el-input v-else size="mini"
                          @focus="onInputFocus(currentComponent.props, item.target)"
                          v-model="currentComponent.props[item.target]"
                          :placeholder="item.description"/>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="样式" name="styles">
            <el-form label-width="60px" class="part-right-form">
              <el-collapse v-model="activeNames">
                <el-collapse-item v-for="styleItem in currentComponent.stylesDesc" :name="styleItem.type"
                                  :key="styleItem.type"
                                  :title="styleItem.type">
                  <el-form-item v-for="(item,index) in styleItem.children" :label="item.label" :key="index">
                    <el-input-number v-if="item.formType === 'input-number'" size="mini"
                                     v-model="currentComponent.styles[item.key]"/>
                    <el-select v-else-if="item.formType === 'select'" size="mini"
                               v-model="currentComponent.styles[item.key]" :placeholder="item.description">
                      <el-option v-for="_item in item.options" :key="_item.value" :value="_item.value"
                                 :label="_item.label"/>
                    </el-select>
                    <el-input v-else size="mini"
                              v-model="currentComponent.styles[item.key]"
                              :placeholder="item.description"/>
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>

              <!--            <el-form-item v-for="(item,index) in currentComponent.stylesDesc" :label="item.label" :key="index">-->
              <!--              <el-input-number v-if="item.formType === 'input-number'" size="small" v-model="currentComponent.styles[item.target]"/>-->
              <!--              <el-select v-else-if="item.formType === 'select'" size="small" v-model="currentComponent.styles[item.target]" >-->
              <!--                <el-option v-for="_item in item.options" :key="_item" :value="_item" :label="_item"/>-->
              <!--              </el-select>-->
              <!--              <el-input v-else size="small" v-model="currentComponent.styles[item.target]"/>-->
              <!--            </el-form-item>-->
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="事件" name="event">
            <div class="event-wrap">
              <el-row :gutter="5" class="mb5">
                <el-col :span="7">事件</el-col>
                <el-col :span="7">联动组件</el-col>
                <el-col :span="7">动作</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <el-row :gutter="5" class="mb5" v-for="(item,index) in currentComponent.eventsList" :label="item.label"
                      :key="index">
                <el-col :span="7">
                  <el-select size="mini" class="w-100" v-model="item.type">
                    <el-option v-for="_item in currentComponent.eventsDesc" :key="_item.target" :value="_item.target"
                               :label="_item.label"/>
                  </el-select>
                </el-col>
                <el-col :span="7">
                  <!-- 已经选中关联对象-->
                  <div v-if="item.target">
                    <el-input size="mini" class="w-100" readonly v-model="item.target"></el-input>
                  </div>
                  <!-- 正在选择关联对象-->
                  <el-button v-else-if="item.isChooseControlledComponent"
                             size="mini"
                             class="w-100"
                             @click="closeChooseControlledComponent(item)">
                    取消选择
                  </el-button>
                  <!-- 未选中关联对象-->
                  <el-button v-else
                             size="mini"
                             class="w-100"
                             @click="openChooseControlledComponent(item, index)">选择</el-button>
                </el-col>
                <el-col :span="7">
                  <el-select size="mini" class="w-100" v-model="item.fn">
                    <el-option v-for="_item in item.targetMethods" :key="_item.fn" :value="_item.fn"
                               :label="_item.label"/>
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <el-button type="danger" @click="delEvent(index)" icon="el-icon-delete" size="mini"></el-button>
<!--                  <i class="el-icon-delete" @click="delEvent(index)"></i>-->
                </el-col>
              </el-row>
              <el-button size="mini" type="primary" @click="addEvent">添加</el-button>
              <el-button size="mini" type="success" @click="saveEvent">保存</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="高级" name="more"></el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import config, { eventTpl } from '@/components/BuilderComponentsConfig'
import { getTreeNodeById } from '@/utils/tree'
import { mapGetters } from 'vuex'
import ListEditor from './_components/ListEditor.vue'
import { deepClone } from '@/utils'

// import PageRender from './PageRender'
export default {
  name: 'PartRight',
  components: {
    ListEditor
  },
  props: {},
  data () {
    return {
      activeNames: ['位置', '盒子', '背景', '字体'],
      componentConfigs: config,
      activeName: 'props',

      controlledComponent: {}, // 联动组件
      controlledComponentConfig: {},
      // chooseControlledComponentFlag: false,
      addEventIndex: 0

    }
  },
  computed: {
    ...mapGetters(['page']),
    currentComponent () {
      return getTreeNodeById(this.page.tree, this.page.currentId)
    }
  },
  created () {
  },
  methods: {
    addEvent () {
      console.log(deepClone(eventTpl))
      this.currentComponent.eventsList.push(deepClone(eventTpl))
    },
    delEvent (index) {
      this.currentComponent.eventsList.splice(index, 1)
    },
    // 开启选择联动组件
    openChooseControlledComponent (item, index) {
      this.$store.commit('SET_IS_CHOOSE', true) // 全局开启选择模式
      item.isChooseControlledComponent = true // 当前组件的关联组件按钮 进入选择状态
      this.addEventIndex = index
    },

    closeChooseControlledComponent (item) {
      this.$store.commit('SET_IS_CHOOSE', false)
      if (item) {
        item.isChooseControlledComponent = false
      } else {
        // todo 点击其他地方解除选择关联组件状态或者禁止其他地方点击
      }
    },
    // 选择联动组件
    switchControlledComponent (comp, index) {
      // const name = comp.name
      // this.ite = comp
      // this.iteConfig = this.componentConfigs[name]

      console.log(this.addEventIndex)
      this.currentComponent.eventsList[this.addEventIndex].target = comp.id
      this.currentComponent.eventsList[this.addEventIndex].targetMethods = comp.methods
      // this.iteIndex = index

      this.$store.commit('SET_IS_CHOOSE', false)
      this.currentComponent.isChooseControlledComponent = false
    },
    /**
     * 保存组件联动事件
     */
    saveEvent () {
      this.currentComponent.eventsList && this.currentComponent.eventsList.forEach(_item => {
        this.currentComponent.events[_item.type] = `${_item.target}-${_item.type}--${_item.fn}`
        // this.currentComponent.events[_item.type] = () => {
        //   console.log(1)
        //   // eslint-disable-next-line no-eval
        //   this.$refs[_item.target + ''][0][_item.fn]()
        // }
      })
      // this.$forceUpdate()
    },
    _formItemVisible (formItem) {
      return formItem.showOn ? this.currentComponent.props[formItem.showOn.target] === formItem.showOn.value : true
    },
    onCopySuccess () {
      this.$message.success('复制成功')
    },
    async onInputFocus (target, key) {
      // target[key] = await navigator.clipboard.readText()
    }

  }
}
</script>
<style lang="scss" scoped>
.part-right {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  flex-shrink: 0;

  .control {
    background-color: #fff;
  }

  .part-right-form {
    ::v-deep(.el-form-item) {
      margin-bottom: 0 !important;

      .el-form-item__label {
        font-size: 12px;
      }
      .el-divider{
        background-color: #409EFF;
      }
      .el-divider__text{
        color: #409EFF;
      }
    }

    ::v-deep(.el-collapse-item) {
      .el-collapse-item__header {
        height: 36px;
        line-height: 36px;
        background-color: #efefef;
        padding-left: 10px;
      }

      .el-collapse-item__content {
        padding-bottom: 10px;
      }
    }
  }

  .event-wrap {
    font-size: 12px;
  }
}
</style>
