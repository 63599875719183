import _mixin from './_mixin'

export default {
  name: 'SLoginOversea',
  cnName: '登录组件(海外)',
  type: 'complex',
  // 属性相关
  props: {
    language: 'en-us',
    signInText: 'Sign In',
    signOutText: 'Sign Out'
  },
  propsDesc: [
    {
      target: 'language',
      label: '语言(刷新页面生效)',
      formType: 'select',
      options: [
        {
          label: '中文',
          value: 'zh-cn'
        },
        // {
        //   label: '繁体中文',
        //   value: 'zh-tw'
        // },
        {
          label: '英文',
          value: 'en-us'
        },
        // {
        //   label: '德语',
        //   value: 'de-de'
        // }, {
        //   label: '波兰语',
        //   value: 'pl-pl'
        // }, {
        //   label: '土耳其语',
        //   value: 'tr-tr'
        // }, {
        //   label: '法语',
        //   value: 'fr-fr'
        // }, {
        //   label: '西班牙语',
        //   value: 'es-es'
        // }, {
        //   label: '葡萄牙语',
        //   value: 'pt-pt'
        // },
        {
          label: '印尼语',
          value: 'in-id'
        }
      ]
    },
    {
      target: 'signInText',
      label: '登录按钮文案'
    },
    {
      target: 'signOutText',
      label: '退出按钮文案'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    top: '124px',
    right: '40px',
    'z-index': 10,
    fontSize: '24px',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
