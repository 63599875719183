<template>
  <img
    class="s-pendant"
    :class="{'pointer': !!link}"
    :src="src"
    alt=""
    width="100%"
    height="100%"
    @click="link ? handleClick : null"
    v-show="visible">
</template>

<script>
export default {
  name: 'SPendant',
  props: {
    src: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      window.open(this.link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.s-pendant {
  display: block;

  &.pointer {
    cursor: pointer;
  }
}
</style>
