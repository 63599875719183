<!--领取成功弹窗-->
<template>
  <el-dialog
    :visible.sync="visible"
    title=""
    class="modal five-common-modal"
    :show-close="false"
    :width="$MainWrap.modalBg.style.width"
    :modal-append-to-body="false"
  >
    <img class="modal-close" @click="visible = false" :src="$MainWrap.modalCloseIcon.src" :style="$MainWrap.modalCloseIcon.style"/>
    <div class="five-common-modal-container" :style="{'background-image': `url(${$MainWrap.modalBg.src})`, height: $MainWrap.modalBg.style.height}">
<!--      <img :src="title.src" :style="title.style" alt="" class="s-modal-title">-->
      <div class="s-modal-title" v-if="title" :style="titleStyleObj">{{title}}</div>
      <div class="s-modal-body" :style="textStyleObj">
        <slot></slot>
      </div>
    </div>
  </el-dialog>

</template>

<script>
export default {
  name: 'SFiveModalCommon',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '提示'
    }
  },
  data () {
    return {
      visible: false,
      shareLink: '',
      coverUrl: '',
      list: [],
      tipText: '请前往游戏内邮箱查收'
    }
  },
  inject: {
    // 由 FiveWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  async created () {
  },
  computed: {
    titleStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalTitleStyle)
      } catch (e) {
        console.error('modalTitleStyle 格式错误')
        return {}
      }
    },
    textStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalTextStyle)
      } catch (e) {
        console.error('modalTextStyle 格式错误')
        return {}
      }
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.modal-title{
  font-size: 40px;
  margin-bottom: 30px;
}
.modal-body{
  height: 400px;
  overflow-y: hidden;
}
//@import "../common.scss";
</style>
<style lang="scss">
.five-common-modal{
  .el-dialog {
    background-color: transparent;
    box-shadow: none;
    background-size: 100% 100%;
    padding: 0;

    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .five-common-modal-container{
    position: relative;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .s-modal-title{
      display: block;
      margin: 0 auto;
      font-size: 50px;
      color: #fff;
      padding: 30px 0 30px;
      font-weight: 700;
    }
    .s-modal-body{
      flex: 1;
      overflow-y: auto;
    }
  }

  .modal-close {
    position: absolute;
    top: 44px;
    right: 4px;
    width: 33px;
    height: 33px;
    cursor: pointer;
    z-index: 1;
    &:hover{
      filter: brightness(1.1);
    }
  }
}
</style>
