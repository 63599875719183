import _mixin from '../_mixin'

export default {
  name: 'STopicTurntableWeapon',
  cnName: '转盘五级枪',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/31/36db006f9da2c47aa67e49fe905afa20.jpeg',
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/9cdb2c030010e9059baec333be06b047.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    titleImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/3d5eef2ebfa37dccb94985e2b71bd00b.png',
      style: {
        width: '1280px',
        height: '235px'
      }
    },
    contentBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/8a28c4fb26baef827254b19380b4f4a4.jpg',
      style: {
        width: '1416px',
        height: '542px'
      }
    },

    singleImgShow: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/e09f703fde61e25457902f6e81823ce1.png',
      style: {
        width: '428px',
        height: '800px',
        top: '-100px',
        right: '',
        bottom: '',
        left: '100px'
      }
    },
    textColor: '#232635',
    // defaultBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/9cdb2c030010e9059baec333be06b047.jpg',
    // defaultBgSize: '1920px 1000px',
    // titleImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/2764e48cbd04999ad8f05a6aa4a9acdc.png',
    // titleSize: '1282px 233px',
    // contentBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/8a28c4fb26baef827254b19380b4f4a4.jpg', // 内容背景
    // singleImgShow: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/e09f703fde61e25457902f6e81823ce1.png', // 图片单独展示
    // singleImgSize: '428px 800px',
    // singleImgPosition: '-93px - - 100px', // 单张图片的位置
    swiperPosition: '116px 41px - -',
    autoplay: false,
    swiperImgList: [
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/e4cdb66e488316ad35b91db7c8ce87a7.png',

        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/3dc9ef50f509e20b4edaa8597dab4cab.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/890502315fbe7f39e8b0b0fc51e0ddfd.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/33f6e0e8c113f7a2c95e12cd44813398.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/661d7298ced3248e5fe69aaca75f5d48.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/8c7ae17bd6f5bfd8832d0249253f3bb8.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/cc36bd69bfcf00aef7450d7928910da4.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/25ae4ff3d686111c731fad9ee798eac2.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/ac6df446d5c21c1a7e61cbf9c5fd27b2.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/97f0ae82d6d0988e38d03cc7fe980d3d.png',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/925eec8026d01f4cf68418ad5acad233.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/d74b04b7c58be1e270c751e80cecf881.png'
      }
    ],
    swiperImgSize: '880px 540px',
    swiperPaginationImgSize: '130px 130px'
  },
  propsDesc: [
    {
      target: 'bg',
      label: '背景图',
      formType: 'image'
    },
    {
      target: 'titleImg',
      label: '标题',
      formType: 'image'
    },
    {
      target: 'contentBg',
      label: '底部内容背景',
      formType: 'image'
    },
    {
      target: 'singleImgShow',
      label: '角色图',
      formType: 'image'
    },
    // 轮播
    {
      target: '轮播图',
      formType: 'divider'
    },
    {
      target: 'autoplay',
      label: '是否自动轮播',
      formType: 'switch'
    },
    {
      target: 'swiperImgSize',
      label: '轮播图尺寸'
    },
    {
      target: 'swiperPaginationImgSize',
      label: '缩略图尺寸'
    },
    {
      target: 'swiperImgList',
      label: '轮播图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '主图',
          target: 'src'
        },
        {
          title: '缩略(默认)',
          target: 'thumbDefault'
        },
        {
          title: '缩略(选中)',
          target: 'thumbActive'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
