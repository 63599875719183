import _mixin from '../_mixin'
export default {
  name: 'SActUnlockLimitSupply',
  cnName: '活动-限时补给解锁',
  type: 'activity_five',
  isTab: true,
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/01/30/ae65f740a085a372aaea8c8221b4aae0.jpg',
  // 属性相关
  props: {
    activityId: 'CHRIT5CORDSQWVFRIN6MQRIE44',
    mainBg: 'https://rescdn.ssjj.cn/website/2024/01/30/5f738e8ef9c4fdeb4fa1b2505f31e0d0.png',
    // 轮播奖励
    awardList: [
      {
        img: 'https://rescdn.ssjj.cn/website/2024/01/30/f389351c69820a2fd3c06b56cd4232c9.png',
        textColor: '#A4F1FF',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/01/30/e54f5728658f50eae73860a3a43d7169.png'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2024/01/30/aa43e5b74536a6ef3cc0fa2682b95a21.png',
        textColor: '#A4F1FF',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/02/02/eeefaf42309323a0a837c0e3f1a624ad.png'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2024/01/30/3455b638ecf6e22900a20d2af5649e90.png',
        textColor: '#A4F1FF',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/02/02/eeefaf42309323a0a837c0e3f1a624ad.png'
      }
    ],
    // 左切换
    swiperLeft: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/469e68eb596de0e7a6f1b03d670f1669.png',
      style: {
        width: '91px',
        height: '83px',
        top: '500px',
        left: '110px',
        right: '',
        bottom: ''
      }
    },
    // 右切换
    swiperRight: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/7ddfebb0bde3dde88e5555d67519a234.png',
      style: {
        width: '91px',
        height: '83px',
        top: '500px',
        left: '',
        right: '110px',
        bottom: ''
      }
    },

    /* 领取按钮 */
    // 尺寸
    btnSize: '360px 100px',
    // 左领取按钮位置
    btnPos: '769px 0 0 776px',
    // 未开始按钮
    btnNoBegin: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/a516d94a82e9ec8e2d544f91af5f8ca8.png'
    },
    // 未完成按钮
    btnUnfinished: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/07ceb1a80f8a1eba2cc816c11c274d6c.png'
    },
    // 领取按钮
    btnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/451765f427c8ba9b3e45faa46be826e8.png'
    },
    // 已领取按钮
    btnReceived: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/17/5fc479087b6e5cba9e794fff0cc20956.png'
    },
    // 已过期按钮
    btnExpire: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/22/4653df6c52ad2beaa34a8974a08acd35.png'
    }
  },
  propsDesc: [
    {
      target: '基础配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'mainBg',
      label: '主背景图'
    },

    {
      target: '轮播配置',
      formType: 'divider'
    },
    {
      target: 'awardList',
      label: '奖励',
      formType: 'list',
      listConfig: [
        {
          title: '图片',
          target: 'img'
        },
        {
          title: '文字颜色',
          target: 'textColor'
        },
        {
          title: '按钮图片',
          target: 'btnImg'
        }
      ]
    },
    {
      target: 'swiperLeft',
      label: '左切换',
      formType: 'image'
    },
    {
      target: 'swiperRight',
      label: '右切换',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
