import _mixin from '../_mixin'

export default {
  name: 'SVideoModel',
  cnName: '视频弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/bbd1bb0232ef4e6c1f3092d36c435dd3.png',
    videoSize: '1080px 607px',
    videoLink: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/media/julyAo.4d0b2bb9.mp4'
  },
  propsDesc: [
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'videoSize',
      label: '视频弹窗背景大小'
    },
    {
      target: 'videoLink',
      label: '视频链接'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
