<!--拉新回归礼-->
<template>
  <div class="s-act" :style="{'background-image': `url(${mainBg.src})`}">
    <div v-if="code" class="back hv-highlight" @click="clearQuery" :style="{color: textColor}"><i class="el-icon-d-arrow-left"></i> 参与更多活动</div>

    <div class="friend" v-if="code" :style="{color: textColor}">【{{name}}】赠送你好礼</div>

    <!--左按钮-->
    <img class="s-btn" v-if="groups[0].status === 0" :src="btnNoBegin.src" alt="" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <img class="s-btn" v-if="groups[0].status === 1 && code" :src="btnUnfinished.src" alt="" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <img class="s-btn hv-highlight" v-if="groups[0].status === 1 && !code" :src="btnShare.src" @click="openShare()" alt="" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <img class="s-btn hv-highlight" v-if="groups[0].status === 2" :src="btnReceive.src" alt="" @click="receiveAward(groups[0])" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <img class="s-btn" v-if="groups[0].status === 3" :src="btnReceived.src" alt="" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <img class="s-btn" v-if="groups[0].status === 4" :src="btnExpire.src" alt="" :style="{...getSize(btnSize), ...getPosition(leftBtnPos)}"/>
    <!--右按钮-->
    <img class="s-btn" v-if="groups[1].status === 0" :src="btnNoBegin.src" alt="" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>
    <img class="s-btn" v-if="groups[1].status === 1 && code" :src="btnUnfinished.src" alt="" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>
    <img class="s-btn hv-highlight" v-if="groups[1].status === 1 && !code" :src="btnShare.src" @click="openShare()" alt="" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>
    <img class="s-btn hv-highlight" v-if="groups[1].status === 2" :src="btnReceive.src" alt="" @click="receiveAward(groups[1])" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>
    <img class="s-btn" v-if="groups[1].status === 3" :src="btnReceived.src" alt="" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>
    <img class="s-btn" v-if="groups[1].status === 4" :src="btnExpire.src" alt="" :style="{...getSize(btnSize), ...getPosition(rightBtnPos)}"/>

<!--    <div class="left-box">-->
<!--      <div class="item" :style="{color: textColor}" v-for="(item, index) in groups.filter((item,index) => index <=6 )" :key="index" @click="receiveAward(item)">-->
<!--        <div class="info">-->
<!--          <div class="count">{{item.name}}</div>-->
<!--          <img :src="item.materials[0].icon" alt="" class="award"/>-->
<!--          <div class="name">{{item.materials[0].name}} x {{item.materials[0].amount}}</div>-->
<!--        </div>-->
<!--        <div v-if="[0,1,4].indexOf(item.status) > -1" class="lock"><i class="el-icon-lock"></i></div>-->
<!--        <div v-else-if="item.status === 3" class="finish" :style="{color: textColor}"><i class="el-icon-check"></i></div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="right-box">-->
<!--      <div class="item" :style="{color: textColor}" v-for="(item, index) in groups.filter((item,index) => index > 6 )" :key="index" @click="receiveAward(item)">-->
<!--        <div class="info">-->
<!--          <div class="count">{{item.name}}</div>-->
<!--          <img :src="item.materials[0].icon" alt="" class="award"/>-->
<!--          <div class="name">{{item.materials[0].name}} x {{item.materials[0].amount}}</div>-->
<!--        </div>-->
<!--        <div v-if="[0,1,4].indexOf(item.status) > -1" class="lock"><i class="el-icon-lock"></i></div>-->
<!--        <div v-else-if="item.status === 3" class="finish" :style="{color: textColor}"><i class="el-icon-check"></i></div>-->
<!--      </div>-->
<!--    </div>-->
    <slot></slot>
    <ModalShareNewOld ref="ModalShareNewOld"
                      :code="myCode"
                      :name="myName"
                      :linkDesc="shareLinkDesc"
                      :desc="shareDesc"
                      :textColor="shareTextColor"
                      :bgColor="shareBgColor"
                      :link4399="shareLink4399"
                      :channel-list="shareChannelList"
    />
  </div>
</template>

<script>
import { getLimit, getPosition, getSize } from '@/components/BuilderComponents/utils'
import ModalShareNewOld from '../_components/ModalShareNewOld.vue'
export default {
  name: 'SActNewOld',
  components: {
    ModalShareNewOld
  },
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    mainBg: {
      type: Object,
      required: () => {
        return {
          src: '',
          style: {}
        }
      }
    },

    textColor: {
      type: String,
      default: '#fff'
    },

    btnSize: {
      type: String
    },

    leftBtnPos: {
      type: String
    },
    rightBtnPos: {
      type: String
    },

    btnNoBegin: {
      type: Object,
      required: true
    },
    btnShare: {
      type: Object,
      required: true
    },
    btnUnfinished: {
      type: Object,
      required: true
    },
    btnReceive: {
      type: Object,
      required: true
    },
    btnReceived: {
      type: Object,
      required: true
    },
    btnExpire: {
      type: Object,
      required: true
    },
    shareDesc: {
      type: String,
      default: ''
    },
    shareLinkDesc: {
      type: String,
      default: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> '
    },
    shareTextColor: {
      type: String,
      default: '#fff'
    },
    shareBgColor: {
      type: String,
      default: 'rgba(0,0,0,.6)'
    },
    shareLink4399: {
      type: String,
      default: ''
    },
    shareChannelList: {
      type: Array,
      default: () => []
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      // 后端数据
      groups: [{}, {}],
      postForm: {
        g_id: '',
        a_id: '',
        amount: 1
      },
      myCode: '',
      myName: '',
      code: '',
      name: '',
      // 是否是用户自己领取  该参数用来判断领完提示是否弹出。初始化进入时需要提示已领完，自己手动点击领取不用提示已领完
      isUserReceive: false
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.init()
        }
      },
      immediate: false
    }
  },
  mounted () {
    this.code = this.$route.query.code || ''
    this.name = this.$route.query.name || ''
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.init)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // window.$checkLogin()
    this.init()
  },
  methods: {
    getSize,
    getPosition,
    getLimit,
    async init () {
      await this.getAct()
      if (!window.$checkLogin()) return
      if (this.code) {
        await this.getGuestStatus()
      } else {
        await this.getMasterStatus()
      }
    },
    // 获取礼包信息
    async getAct () {
      return await this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((data) => {
        console.log('----- 拉新&回流 活动 -----', data)
        this.groups = data.groups || []
        this.$MainWrap.checkStartEnd(data.start_at, data.end_at)
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    // 获取主态活动状态
    async getMasterStatus () {
      return await this.requestAPI({
        url: '/v1/uni/masterGiftStatus',
        params: { a_id: this.activityId }
      }).then((res) => {
        console.log('--------- 主态 活动状态 ----------', res)
        this.groups[0].status = res.new_gift
        this.groups[1].status = res.back_gift
        this.myCode = res.code
        this.myName = res.role_name
        // this.$forceUpdate()
      })
        .catch((error) => {
          if (error.code === 430116) {
            return
          }
          this.$message.error(error.msg || '系统错误')
          if (this.shareKey) {
            this.clearQuery()
          }
        })
    },
    // 获取客态活动状态
    async getGuestStatus () {
      // 先调用这个日志接口 才能拿到 客态正常数据
      await this.requestAPI({
        url: '/v1/uni/inviteLog',
        params: { a_id: this.activityId }
      })
      await this.requestAPI({
        url: '/v1/uni/guestGiftStatus',
        params: { code: this.code, a_id: this.activityId }
      }).then((res) => {
        console.log('--------- 客态 活动状态 ----------', res)
        this.groups[0].status = res.new_gift
        this.groups[1].status = res.back_gift
        // 礼包无余量
        if (!this.isUserReceive && res.back_gift === 3 && res.new_gift === 3) {
          // this.backIndex()
          this.$message.error('哎呀来晚了，当前礼包已领完')
        }
      })
        .catch((error) => {
          console.error(error)
          // 自己点开自己的分享
          if (error && error.code === 430204) {
            this.$message.error('不能领取分享自己的礼包')
            this.clearQuery()
          } else {
            this.$message.error(error.msg)
          }
        })
    },

    // 领取奖励
    receiveAward (item) {
      if (item.status !== 2) return
      this.isUserReceive = true
      // 判断登录
      if (!window.$checkLogin()) return
      // // 判断当前状态
      this.requestAPI({
        url: '/v1/uni/inviteReceive',
        method: 'get',
        params: {
          g_id: item.id,
          a_id: this.activityId,
          code: this.code
        }
      }).then((res) => {
        console.log(res)
        this.$MainWrap.openReceiveModal(item.materials)
        this.init()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    clearQuery () {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      delete query.code
      delete query.name
      this.$router.replace({
        path: this.$route.path,
        query
      })
      this.code = ''
      this.init()
    },
    openShare () {
      this.$refs.ModalShareNewOld.open()
    },
    // 重置
    reset () {
      // this.getAct()
      this.clearQuery()
      this.groups.forEach((item) => {
        item.status = 1
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> SActUnlockLimitSupply  取消登录监听')
      this.eventBus.off('loginSuccess', this.init)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 内容样式
.s-act {
  width: 1920px;
  height: 966px;
  background-size: 100% 100%;
  padding-top: 136px;
  .back{
    font-size: 22px;
    position: absolute;
    top: 200px;
    left: 100px;
  }
  .friend{
    position: absolute;
    width: 100%;
    top: 300px;
    left: 0;
    text-align: center;
    font-weight: 700;
    font-size: 35px;
  }
  .s-btn{
    position: absolute;
  }
  .left-box, .right-box{
    position: absolute;
    top: 500px;
    height: 330px;
    display: flex;
    justify-content: space-between;
    //background-color: #F56C6C;
    //opacity: .6;
    .item{
      position: relative;
      height: 334px;
      padding: 30px 10px 10px;
      background-color: #0B89FF;
      opacity: .5;
      .info{
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: .3s;
        .count{
          font-size: 22px;
          text-align: center;
        }
        .award{
          width: 85px;
          height: 150px;
          object-fit: contain;
        }
        .name{
          height: 60px;
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
  .left-box{
    left: 143px;
    width: 700px;

    .item{
      width: 105px;
    }
  }
  .right-box{
    left: 1076px;
    width: 700px;
    .item{
      width: 175px;
    }
  }
}
</style>
