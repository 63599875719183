<template>
  <STopic2Wrapper v-bind="$attrs">
    <div class="s-components" v-show="visible" >
      <template v-for="(item,index) in list" >
        <div class="info" :class="{active:curIndex === index}" :key="index">
          <slot :name="index + ''">
          </slot>
        </div>
      </template>
      <div class="carousel-wrap">
        <div class="carousel-wrap-inner">
          <el-carousel ref="carousel" class="s-topic2-weapon-carousel carousel"
                       indicator-position="none"
                       :autoplay="autoplay"
                       :interval="5000"
                       arrow="never"
                       @change="onChange"
          >
            <el-carousel-item class="item" v-for="(item,index) in list" :key="item.label + index">
              <img class="img" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel>
          <img class="btn-prev" @click="toggle('prev')" :src="prevImg" alt=""/>
          <img class="btn-next" @click="toggle('next')" :src="nextImg" alt=""/>
        </div>
        <div class="label-list" :style="thumbWrapStyleObj">
            <div class="item" v-for="(item,index) in list" :key="index"
                 :class="{active: curIndex === index}"
                 @click="changeTab(index)"
                 :style="{
                   backgroundImage: `url(${curIndex === index ? item.thumbActive : item.thumb})`,
                   ...thumbItemStyleObj
                 }"
            >
              <p>{{item.label}}</p>
            </div>
        </div>
      </div>
    </div>
  </STopic2Wrapper>
</template>

<script>
export default {
  name: 'STopic2WeaponWheel',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    prevImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png'
    },
    nextImg: {
      type: String,
      default: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/33b1279f41a2b1a9d796e076f996d306.png'
    },
    thumbItemStyle: {
      type: String,
      default: '{}'
    },
    thumbWrapStyle: {
      type: String,
      default: '{}'
    }
  },
  computed: {
    thumbWrapStyleObj () {
      try {
        return JSON.parse(this.thumbWrapStyle)
      } catch (e) {
        console.error('thumbWrapStyle 格式错误')
        return {}
      }
    },
    thumbItemStyleObj () {
      try {
        return JSON.parse(this.thumbItemStyle)
      } catch (e) {
        console.error('thumbItemStyle 格式错误')
        return {}
      }
    }
  },
  data () {
    return {
      visible: true,
      curIndex: 0
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    onChange (index) {
      this.curIndex = index
    },
    toggle (type) {
      if (type === 'prev') {
        this.$refs.carousel.prev()
      } else {
        this.$refs.carousel.next()
      }
    },
    changeTab (index) {
      this.$refs.carousel.setActiveItem(index)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-components{
  position: relative;
  width: 1920px;
  height: 1000px;
  .info{
    position: relative;
    z-index: 10;
    display: none;
    &.active{
      display: block;
    }
  }
  .carousel-wrap{
    position: relative;
    padding-top: 200px;
    .carousel-wrap-inner{
      position: relative;
      .carousel{
        margin-left:550px;
        width: 1000px;
        height: 600px;
        overflow: hidden;
        .item{
          width: 100%;
          height: 100%;
          .img{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .btn-prev,
      .btn-next{
        position: absolute;
        cursor: pointer;
        transition: .3s;
        width: 45px;
        height: 45px;
        object-fit: contain;
        opacity: .5;
        &:hover{
          opacity:1;
        }
      }
      .btn-prev{
        right: 245px;
        bottom: 30px;
      }
      .btn-next{
        right: 200px;
        bottom: 50px;
      }
    }

    .label-list{
      position: absolute;
      left: 230px;
      top: 200px;
      color: #ADADAD;
      .item{
        position: relative;
        width: 298px;
        height: 116px;
        margin-bottom: 10px;
        font-size: 16px;
        //border: 1px solid #969696;
        //background-color: rgba(103, 103, 103,.2);
        transition: .3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        &.active{
          transform: scale(1.1);
          p{
            opacity: 1;
          }
          //font-weight: 700;
        }
        p{
          transition: .3s;
          opacity: 0;
          position: absolute;
          bottom: 10px;
          left: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.s-topic2-weapon-carousel{
  .el-carousel__container{
    width: 100%;
    height: 100%;
  }
}
</style>
