<template>
  <div class="s-topic-kv img-bg" :style="{...defaultBg.style}">
    <div class="s-topic-bg-wrap">
      <SAnimateImage :width="defaultBg.style.width" :height="defaultBg.style.height" :src="defaultBg.src" mask=""></SAnimateImage>
    </div>
    <img v-if="logoImg.src" class="s-topic-kv--logo" :src="logoImg.src" alt="" :style="logoImg.style"/>
    <a :href="btnReturnLink" target="_blank" v-if="btnReturn.src" class="btn-return img-bg" :style="{...btnReturn.style, 'background-image': `url(${btnReturn.src})`}">
    </a>
    <img v-if="btnDownLoad.src" :src="btnDownLoad.src" :style="{...btnDownLoad.style}" class="btn-download" alt="" @click="download">
    <img v-if="giftImg.src" :src="giftImg.src" :style="{...giftImg.style}" class="gift" alt="">
  </div>
</template>

<script>

import SAnimateImage from '../AnimateImage/index.vue'

export default {
  name: 'STopic2KV',
  components: { SAnimateImage },
  props: {
    defaultBg: {
      type: Object,
      required: true
    },
    logoImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnReturn: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnReturnLink: {
      type: String,
      default: ''
    },
    btnDownLoad: {
      type: Object,
      default: () => {
        return {}
      }
    },
    giftImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 发布代号
    pointMark: {
      type: String,
      default: ''
    },
    request: {
      type: Function
    }
  },
  data () {
    return {
      downLoadLink: ''
    }
  },
  mounted () {
    // 获取下载链接
    this.downloadFun()
  },
  computed: {
  },
  methods: {
    // 获取下载链接
    downloadFun () {
      const { from, wdFrom } = this.$route.query
      // eslint-disable-next-line no-undef
      const request = this.request ? this.request : $request
      request({
        url: '/v1/config',
        method: 'get',
        params: {
          from,
          wdFrom
        }
      }).then((res) => {
        this.downLoadLink = res.game_download_url
      }).catch((err) => {
        this.$message({
          message: err.msg || '系统错误',
          type: 'error'
        })
      })
    },

    download () {
      const { from, wdFrom } = this.$route.query
      if (this.wdFrom === 'steam') {
        window.open('https://store.steampowered.com/app/1969870/2/', '_blank')
        this._makePoint()
      } else if (this.wdFrom === 'zhengqi') {
        window.open('https://store.steamchina.com/app/1969870/2/', '_blank')
        this._makePoint()
      } else if (this.wdFrom === '4399') {
        window.open('https://my.4399.com/yxssjj2/wd-down', '_blank')
        this._makePoint()
      } else {
        // eslint-disable-next-line no-undef
        const request = this.request ? this.request : $request
        request({
          url: '/v1/config',
          method: 'get',
          params: {
            from,
            wdFrom
          }
        }).then((res) => {
          this.downLoadLink = res.game_download_url
          // window.open(this.downLoadLink, '_blank')
          this._makePoint()
          window.location.href = this.downLoadLink
        }).catch((err) => {
          this.$message({
            message: err.msg || '系统错误',
            type: 'error'
          })
        })
        // getVersionConfig({ from: this.$parent.from || 'website' }).then((res) => {
        //   this.downloadUrl = res.game_download_url || this.downloadUrl
        //   window.open(this.downloadUrl, '_blank')
        //   window._hmt && window._hmt.push([
        //     '_trackEvent',
        //     this.$parent.from || 'website',
        //     'CLICK',
        //     'preheatDownload'
        //   ])
        // })
      }
    },
    _makePoint () {
      const { from, wdFrom } = this.$route.query
      console.log(this.pointMark)
      let mark = ''
      if (this.pointMark) {
        mark = this.pointMark
      } else {
        // mark 为路由路径的最后一段
        mark = this.$route.path.split('/').pop()
        console.log(mark)
      }
      window._hmt && window._hmt.push([
        '_trackEvent',
        from || 'website',
        'CLICK',
        `${mark}Download`
      ])
    }
  }
}
</script>
<style lang="scss" scoped>
.s-topic-kv--logo{
  position: absolute;
}
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.s-topic-kv{
 position: relative;
  .btn-return{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: .3s;
    &:hover{
      filter: brightness(110%)
    }
  }
  .btn-download{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: .3s;
    &:hover{
      transform: scale(1.1);
      filter: brightness(110%)
    }
    //img{
    //  width: 100%;
    //}
    //a{
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //}
  }
  .gift{
    position: absolute;
  }
}
</style>
