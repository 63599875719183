<template>
  <div class="s-act">
    <slot></slot>
    <img :src="mainImg" alt="" class="main-img">

    <!-- 领取试用按钮-->
    <template v-if="act1[0]">
      <img class="pos-ab" v-if="act1[0].status === 0" :src="leftBtnNoBegin.src" alt=""
           :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
      <img class="pos-ab" v-if="act1[0].status === 1" :src="leftBtnUnfinished.src" alt=""
           :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
      <img class="pos-ab hv-highlight" v-if="act1[0].status === 2" :src="leftBtnReceive.src" alt="" @click="receiveAward(1, act1[0])"
           :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
      <img class="pos-ab" v-if="act1[0].status === 3" :src="leftBtnReceived.src" alt=""
           :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
      <img class="pos-ab" v-if="act1[0].status === 4" :src="leftBtnExpire.src" alt=""
           :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
    </template>
<!--    任务列表-->
    <div class="task-wrap">
      <div class="info" :style="{color: taskProgressColor}">
        <span>已获取合成卡： {{myCardNum}}</span>
        <span>当前线索： {{point}}</span>
      </div>
      <div class="task-list" :style="{color: taskListColor}">
        <div class="item" v-for="(item,index) in act2" :key="index">
          <div class="text">
            <span class="mr8">{{item.name}}</span>
            <span v-if="item.condition_ret">({{item.condition_ret.actual_val}}/{{item.condition_ret.expect_val}})</span>
          </div>
          <div class="point">{{index === 4 ? '+50' : '+25'}}</div>
          <img class="s-btn" v-if="item.status === 0" :src="miniBtnNoBegin.src" alt="" />
          <img class="s-btn" v-if="item.status === 1" :src="miniBtnUnfinished.src" alt="" />
          <img class="s-btn hv-highlight" v-if="item.status === 2" :src="miniBtnReceive.src" alt="" @click="receiveAward(2, item)"/>
          <img class="s-btn" v-if="item.status === 3" :src="miniBtnReceived.src" alt=""/>
          <img class="s-btn" v-if="item.status === 4" :src="miniBtnExpire.src" alt=""/>
        </div>
      </div>
    </div>
<!--    兑换卡-->
    <div class="card-wrap">
      <div class="card-list">
      <div class="item" v-for="(item,index) in act3" :key="index">
        <p class="num" :style="{color: clueColor}">{{(index + 1)*50}}</p>
        <img class="card" :src="miniCardImg.src" alt="">
        <img class="s-btn" v-if="item.status === 0" :src="miniBtnNoBegin.src" alt="" />
        <img class="s-btn" v-if="item.status === 1" :src="miniBtnUnfinished.src" alt="" />
        <img class="s-btn hv-highlight" v-if="item.status === 2" :src="miniBtnReceive.src" alt="" @click="receiveAward(3, item)"/>
        <img class="s-btn" v-if="item.status === 3" :src="miniBtnReceived.src" alt=""/>
        <img class="s-btn" v-if="item.status === 4" :src="miniBtnExpire.src" alt=""/>
      </div>
    </div>
    </div>

      <!--购买线索按钮-->
    <template>
      <img v-if="act4[0]" class="pos-ab hv-highlight" :src="rightBtn.src" alt="" @click="openCounter(act4[0])"
           :style="rightBtn.style"/>
    </template>

    <SFiveModalCounter ref="SFiveModalCounter"></SFiveModalCounter>
  </div>
</template>

<script>
import { getLimit, getPosition, getSize } from '@/components/BuilderComponents/utils'
import SFiveModalCounter from './_components/FiveModalCounter.vue'
import { math } from '../utils'

export default {
  name: 'SActFreeLegendWeapon',
  components: { SFiveModalCounter },
  props: {
    request: {
      type: Function
    },
    activityId1: {
      type: String,
      default: ''
    },
    activityId2: {
      type: String,
      default: ''
    },
    activityId3: {
      type: String,
      default: ''
    },
    activityId4: {
      type: String,
      default: ''
    },
    mainImg: {
      type: String,
      required: true
    },

    miniCardImg: {
      type: Object,
      required: true
    },

    taskProgressColor: {
      type: String,
      default: '#fff'
    },
    taskListColor: {
      type: String,
      default: '#fff'
    },
    clueColor: {
      type: String,
      default: '#fff'
    },

    /* 小领取按钮 */
    miniBtnNoBegin: {
      type: Object,
      required: true
    },
    miniBtnUnfinished: {
      type: Object,
      required: true
    },
    miniBtnReceive: {
      type: Object,
      required: true
    },
    miniBtnReceived: {
      type: Object,
      required: true
    },
    miniBtnExpire: {
      type: Object,
      required: true
    },
    /* 左侧按钮 */
    leftBtnSize: {
      type: String,
      default: '200px 50px'
    },
    leftBtnPos: {
      type: String,
      default: '800px 200px'
    },
    leftBtnNoBegin: {
      type: Object,
      required: true
    },
    leftBtnUnfinished: {
      type: Object,
      required: true
    },
    leftBtnReceive: {
      type: Object,
      required: true
    },
    leftBtnReceived: {
      type: Object,
      required: true
    },
    leftBtnExpire: {
      type: Object,
      required: true
    },
    /* 右侧按钮 */
    rightBtn: {
      type: Object,
      default: () => {
        return {
          src: '',
          style: {}
        }
      }
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      // 领取限时武器
      act1: [{ status: 2 }],
      // 任务列表
      act2: [],
      // 领取线索
      act3: [],
      // 购买线索
      act4: [{ status: 1 }],
      point: 0
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    myCardNum () {
      return this.act3.filter(item => item.status === 3).length
    }
  },
  mounted () {
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', this.init)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    $checkLogin()
    this.init()
  },
  watch: {
    activityId1 (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAct(1)
      }
    },
    activityId2 (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAct(2)
      }
    },
    activityId3 (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAct(3)
      }
    },
    activityId4 (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAct(4)
      }
    }
  },
  methods: {
    getSize,
    getPosition,
    // 获取初始化状态
    init () {
      this.getAct(1)
      this.getAct(2)
      this.getAct(3)
      this.getAct(4)
      this.getPoint()
    },
    // 获取初始化状态
    getAct (type) {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this[`activityId${type}`] }
      }).then((data) => {
        this[`act${type}`] = data.groups || []

        // 集满提示
        if (type === 3 && this.act3.every(item => item.status === 3)) {
          this.$MainWrap.confirm({ content: '您已完成任务并集齐合成卡，可前往仓库进行合成！', cancelBtnText: '' })
        }

        if (type === 1) {
          // 检查是否处于活动时间
          // console.log('> 免费传奇武器')
          this.$MainWrap.checkStartEnd(data.start_at, data.end_at)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 获取当前积分（线索）数量
    getPoint () {
      this.requestAPI({
        url: '/v1/uni/integral',
        method: 'get',
        params: {
          a_id: this.activityId2,
          history: false
        }
      }).then((data) => {
        this.point = data || 0
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    openCounter (item) {
      console.log(item)
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // const limit = math.mul(getLimit(item.limit_single).limit, 5) || 0
      const limit = getLimit(item.limit_single).limit || 0
      // const getNum = math.mul(item.acquired_amount, 10) || 0
      const leftNum = limit - this.point
      if (leftNum <= 0) {
        this.$message.warning('已达到限购数量')
        return
      }
      this.$refs.SFiveModalCounter.open({
        title: '购买数量',
        initNum: 5,
        min: 5,
        max: leftNum || 500,
        step: 5,
        unitPrice: item.price,
        confirmBtnText: '购买',
        onConfirm: (num, price) => {
          this.$MainWrap.confirm({
            content: `是否消耗 ${price} 点券购买线索？`,
            onConfirm: () => {
              this.receiveAward(4, item, num)
            },
            onCancel: () => {}
          })
        }
      })
    },
    receiveAward (type, item, num) {
      console.log('---receiveAward---', type, item, num)
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      const params = {
        a_id: this[`activityId${type}`],
        g_id: item.id
      }
      if (num !== undefined) {
        // params.amount = math.div(num, 5)
        params.amount = num
      }

      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params
      }).then((data) => {
        if (data.success) {
          if (type === 1) {
            this.$MainWrap.openReceiveModal(item.materials)
          } else if (type === 4) {
            this.$MainWrap.confirm({ content: '购买成功!', cancelBtnText: '' })
          } else {
            this.$MainWrap.confirm({ content: '领取成功!', cancelBtnText: '' })
          }

          if (type === 1) {
            this.getAct(1)
          } else {
            this.init()
          }
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 重置
    reset () {
      this.act1.status = 2
      this.act2.status = 1
      // this.getAct1()
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> SActFreeLegendWeapon  取消登录监听')
      this.eventBus.off('loginSuccess', this.init)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 公共样式
.auto-bg {
  background-repeat: no-repeat;
  background-size: 100%;
}
.hv-highlight{
  cursor: pointer;
  &:hover{
    filter: brightness(1.2);
  }
}

// 内容样式
.s-act {
  width: 100%;
  height: 966px;
  position: relative;

  .main-img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .task-wrap{
    position: absolute;
    top: 280px;
    left: 630px;
    width: 1100px;
    .info{
      text-align: right;
      height: 80px;
      line-height: 80px;
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
      span{
        margin-left: 50px;
      }
    }
    .task-list{
      width: 1050px;
      height: 150px;
      //background-color: rgba(0,0,0,.5);
      .item{
        float: left;
        //background-color: rgba(255,0,0,.5);
        margin-bottom: 21px;
        width: 488px;
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        font-weight: 700;
        .text{
          flex: 1;
          text-align: left;
          padding-left: 20px;
        }
        .point{
          width: 100px;
          text-align: center;
          font-size: 24px;
        }
        .s-btn{
          height: 30px;
          cursor: pointer;
        }
        &:nth-child(2n){
          margin-left: 54px;
        }
      }
    }
  }

  .card-list{
    position: absolute;
    bottom: 53px;
    left: 600px;
    width: 1140px;
    height: 375px;
    .item{
      position: absolute;
      width: 86px;
      height: 140px;
      .num{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
       /* color: #fff;*/
        font-size: 24px;
        line-height: 30px;
      }
      .card{
        display: block;
        width: 100%;
      }
      .s-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    .item:nth-child(1){
      top: 70px;
      left: 80px;
    }
    .item:nth-child(2){
      top: 30px;
      left: 260px;
    }
    .item:nth-child(3){
      top: 40px;
      left: 430px;
    }
    .item:nth-child(4){
      top: 30px;
      left: 610px;
    }
    .item:nth-child(5){
      top: 60px;
      left: 788px;
    }
    .item:nth-child(6){
      top: 90px;
      left: 980px;
    }
    .item:nth-child(7){
      top: 210px;
      left: 700px;
    }
    .item:nth-child(8){
      top: 200px;
      left: 520px;
    }
    .item:nth-child(9){
      top: 190px;
      left: 330px;
    }
    .item:nth-child(10){
      top: 230px;
      left: 150px;
    }
  }

  .pos-ab {
    position: absolute;
  }
}
</style>
