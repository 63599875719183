import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopic2Core',
  cnName: '新晶核',
  type: 'activity_topic2',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/website/2023/11/01/90961c9f223a1c03132fda4145affbe3.jpg',
  // 属性相关
  props: {
    list: [
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/855064fb45742bd88c0bd92178894634.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/13/d1e87bb65ac3684ddc85a781ef498904.mp4',
        label: '复生护盾'
      },
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/0791485f27905518fbcfa8c0cf09a5f0.jpg',
        video: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/ff207bb79bb6dbb97c47300b993283bd.mp4',
        label: '奇点飞刀'
      }
    ],
    thumbWrapStyle: '{}',
    thumbItemStyle: '{}',
    thumbItemActiveStyle: '{}'
  },
  propsDesc: [
    /* 页面属性 */
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '视频',
          target: 'video'
        },
        {
          title: '名称',
          target: 'label'
        }
      ]
    },
    {
      target: 'thumbWrapStyle',
      label: '标签容器样式',
      formType: 'textarea'
    },
    {
      target: 'thumbItemStyle',
      label: '标签样式',
      formType: 'textarea'
    },
    {
      target: 'thumbItemActiveStyle',
      label: '标签选中样式',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
