import _mixin from '@/components/BuilderComponentsConfig/_mixin'

export default {
  name: 'SCommonModel',
  cnName: '富文本展示',
  type: 'complex',
  draggable: true,
  // 属性相关
  props: {
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
      style: {
        width: '1184px',
        height: '764px'
      }
    },
    /* modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
      style: {
        width: '40px',
        height: '30px',
        top: '20px',
        right: '-50px',
        bottom: '',
        left: ''
      }
    }, */
    text: '<p><img style="display: block; margin-left: auto; margin-right: auto;" src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="568" height="50" /></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">1、前往【星耀无限】模式，完成任务，即可免费领取普通奖励</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">2、花费58元解锁进阶奖励，立得星尘结晶x1160，完成任务最高可得4倍奖励</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">3、普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X1；</span></p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">&nbsp; &nbsp; &nbsp; 普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X2</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">4、普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X1； 普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X2</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">5、普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X1； 普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X2</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">6、普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X1； 普通奖励统计：星尘结晶x570；首战材料包X1；英雄挑战材料包X2</span></p>\n' +
      '<p><span style="font-size: 18pt; color: #000000;">&nbsp; &nbsp; &nbsp;</span></p>\n' +
      '<p>&nbsp;</p>\n' +
      '<div style="text-align: center;"><img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /></div>\n' +
      '<div style="text-align: center;"><img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /></div>\n' +
      '<div style="text-align: center;"><img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /></div>\n' +
      '<div style="text-align: center;"><img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /> &nbsp;<img src="https://xzs-rescdn.ssjj.cn/assistant/image/20220817/2e27295a-4da1-4773-95da-ac513921d4b4.jpg" alt="" width="100" height="100" /></div>\n' +
      '<div style="text-align: center;">&nbsp;</div>\n' +
      '<p style="text-align: center;">&nbsp;</p>'
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    /* {
      target: 'modelClose',
      label: '弹窗关闭',
      formType: 'image'
    }, */
    {
      target: 'text',
      label: '文本',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
