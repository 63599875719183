import _mixin from './_mixin'
// import BaseGroup from './BaseGroup'
const cover = 'https://h5.ssjj.cn/common/industrial/pc/images/role4.png?v=1667439096388'
const bg = 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/role-bg.png?v=1.04'
// function genId () {
//   return Date.now()
// }

export default {
  name: 'SRoleList',
  cnName: '角色列表',
  type: 'complex',
  // 属性相关
  props: {
    list: [
      { bg, cover },
      { bg, cover },
      { bg, cover },
      { bg, cover }
    ],
    animateType: 'zoomIn',
    btnPosition: '0 _ _ 0',
    bgSize: '356px 900px',
    coverSize: '220px _'
  },
  propsDesc: [
    {
      target: 'list',
      label: '列表',
      formType: 'list',
      listConfig: [
        {
          title: '底图',
          target: 'bg'
        },
        {
          title: '覆盖图',
          target: 'cover'
        }
      ]
    },
    {
      target: 'animateType',
      label: '动画类型',
      formType: 'radio',
      options: [
        { label: 'fadeIn', value: 'fadeIn' },
        { label: 'zoomIn', value: 'zoomIn' }
      ]
    },
    {
      target: 'bgSize',
      label: '底图尺寸'
    },
    {
      target: 'coverSize',
      label: '覆盖图尺寸'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1500px',
    height: 'auto'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
