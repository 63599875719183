import _mixin from '../_mixin'
export default {
  name: 'SActBattleSupply',
  cnName: '活动-战斗补给助力',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/03/07/72023dac766617fbea6019cbe6aeecb5.jpg',
  // 属性相关
  props: {
    activityId: 'CZ3HVQ2GQCRIG2PSXYXJUQM2IQ',
    mainBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/07/4146efd7d656de1f6feeff4d6b6c9dfd.png'
    },

    // 领取按钮
    btnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/06/c462d104eb34b05f7e36f93bb0fc346f.png',
      style: {
        top: '249px',
        left: '1571px',
        width: '251px',
        height: '61px'
      }
    },
    textColor: '#FBE1C9'
  },
  propsDesc: [
    {
      target: '基础配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'mainBg',
      label: '主背景图',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'textColor',
      label: '奖励文字颜色',
      formType: 'color'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
