<template>
  <div v-if="tipModelShow" class="lay">
    <div class="model" :style="{...modelBg.style,color: modelColor,'background-image': `url(${modelBg.src})`,'background-size': `${this.modelBg.style.width} ${this.modelBg.style.height} `}">
      <div class="img-bg" :style="{...modelClose.style,'background-image': `url(${modelClose.src})`}" @click="close"></div>
      <img :src="tipTitle.src" alt="" :style="{...tipTitle.style}" class="title"/>
      <div class="text-desc">{{contentText}}</div>
      <img class="btn-status img-bg" :src="btnClose.src" alt='' :style="{...btnClose.style}" @click="close"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'STipComModel',
  props: {
    // 公共弹窗属性
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: Object,
      required: true
    },
    modelClose: {
      type: Object,
      required: true
    },
    modelColor: {
      type: String,
      required: true
    },
    tipTitle: {
      type: Object,
      required: true
    },
    btnClose: {
      type: Object,
      required: true
    },
    contentText: {
      type: String,
      default: '活动已结束，请关注下期活动~'
    }
  },
  data () {
    return {
      tipModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.tipModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.tipModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.tipModelShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .title{
    margin: 42px 0 20px 0
  }
  .text-desc{
    height: 250px;
    line-height: 250px;
    font-weight: 500;
  }
  .btn-status{
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
</style>
