<template>
  <STopicWrapper class="s-topic-swiper" v-bind="$attrs">
    <div class="content-box">
      <swiper :ref="uid" :options="swiperOption" class="list" :key='swiperKey'>
        <swiper-slide v-for="(item, index) in swiperImgList" :key="index">
          <div class="list-item">
            <img :src="item.src" :style="swiperImgStyle" :alt="item.label">
          </div>
        </swiper-slide>
      </swiper>
      <div class="s-swiper-pagination">
        <img
          v-for="(item, index) in swiperImgList" :key="index"
          class="page-weapon"
          :src="currentIndex === index ? item.thumbActive : item.thumbDefault" alt=""
          :style="swiperThumbImgStyle"
          @click="changeCurrent(index)">
      </div>
    </div>
  </STopicWrapper>
</template>

<script>
// import VideoModel from '@/components/BuilderComponents/Dialog/VideoModel.vue'

export default {
  name: 'STopicSwiper',
  components: { },
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    swiperImgSize: {
      type: String,
      default: '950px 540px'
    },
    swiperThumbImgSize: {
      type: String,
      default: '130px 120px'
    },
    swiperImgList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uid: '',
      swiper: null,
      currentIndex: 0,
      swiperKey: Math.random().toString(36).substr(2),
      swiperOption: {
        autoplay: this.autoplay,
        oop: true,
        navigation: {
          nextEl: `swiper-prev--${this.uid}`,
          prevEl: `swiper-next--${this.uid}`
        }
      },
      visible: true
    }
  },
  watch: {
    autoplay: {
      handler: function (newVale) {
        this.swiperOption.autoplay = newVale
        this.swiperKey = Math.random().toString(36).substr(2)
        if (newVale) {
          this.$nextTick(() => {
            this.swiper = this.$refs[this.uid].swiper
            this.$refs[this.uid].swiper.on('slideChange', () => {
              this.currentIndex = this.swiper.realIndex
            })
          })
        } else {
          this.currentIndex = 0
        }
      }
    }
  },
  mounted () {
    this.uid = Date.now()
    this.$nextTick(() => {
      this.swiper = this.$refs[this.uid].swiper
      this.$refs[this.uid].swiper.on('slideChange', () => {
        this.currentIndex = this.swiper.realIndex
      })
    })
  },
  computed: {
    // 图片样式
    swiperImgStyle () {
      const arr = this.swiperImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    // 缩略图图片样式
    swiperThumbImgStyle () {
      const arr = this.swiperThumbImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    // swiper切换
    changeCurrent (index) {
      console.log(index)
      this.swiper && this.swiper.slideTo(index)
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  object-fit: contain;
}

.lay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}

.close-icon {
  position: absolute;
  right: -90px;
  top: -15px;
  width: 88px;
  height: 80px;
  cursor: pointer;
}

.video-box {
  width: 1080px;
  height: 607px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .s-video {
    width: 100%;
    height: 100%;
  }
}

.s-topic-swiper {
  position: relative;
  width: 1920px;
  height: 1001px;

  .content-box {
    position: relative;
    width: 1416px;
    height: 542px;
    margin: 0 auto;

    .list {
      .list-item {
        img {
          object-fit: contain;
          //width: 950px;
          //height: 540px;
        }
      }

    }

    .s-swiper-pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .page-weapon {
          cursor: pointer;
        }
      }
  }
}
</style>
