import _mixin from './_mixin'

export default {
  name: 'SText',
  cnName: '文本',
  type: 'base',
  draggable: true,
  // 属性相关
  props: {
    text: '文本内容示例' +
      '\n' +
      '可以使用 "\\n" 进行换行 ' +
      '\n' +
      '可以使用标签 <span style="color: #f00">为文字添加颜色</span>' +
      '\n' +
      '可以使用标签 <b>设置粗体文字</b>',
    fontSize: '30px',
    isBold: false,
    color: '#333',
    textAlign: 'left',
    lineHeight: 1.5,
    textIndent: false,
    otherStyle: '{}'
  },
  propsDesc: [
    {
      target: 'text',
      label: '文本',
      formType: 'textarea'
    },
    {
      target: 'fontSize',
      label: '字号'
    },
    {
      target: 'isBold',
      label: '粗体',
      formType: 'switch'
    },
    {
      target: 'color',
      label: '颜色'
    },
    {
      target: 'textAlign',
      label: '对齐',
      formType: 'radio',
      options: [
        { label: '左对齐', value: 'left' },
        { label: '居中对齐', value: 'center' },
        { label: '右对齐', value: 'right' }
      ]

    },
    {
      target: 'lineHeight',
      label: '行高'
    },
    {
      target: 'textIndent',
      label: '首行缩进',
      formType: 'switch'
    },
    {
      target: 'otherStyle',
      label: '补充样式',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    left: '0',
    top: '0'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
