import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopic2Model',
  cnName: '模式',
  type: 'activity_topic2',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/third-first-bg.d615d3b8.jpg',
  // 属性相关
  props: {
    list: [
      {
        img: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/third-first-bg.d615d3b8.jpg',
        thumb: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/third-area-first.5da67986.png',
        label: '模式一'
      },
      {
        img: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/third-second-bg.e72370b9.jpg',
        thumb: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/third-area-second.8ddaec6b.png',
        label: '模式二'
      }
    ]
  },
  propsDesc: [
    /* 页面属性 */
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '缩略图',
          target: 'thumb'
        },
        {
          title: '名称',
          target: 'label'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
