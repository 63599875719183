import _mixin from './_mixin'

export default {
  name: 'SCustom',
  cnName: '自定义组件',
  type: 'base',
  draggable: true,
  // 属性相关
  props: {
    name: ''
  },
  propsDesc: [
    {
      target: 'name',
      label: '组件名'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
