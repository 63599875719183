<!--领取成功弹窗-->
<template>
  <ModalCommon ref="ModalCommon"
              :title="config.title">
    <div class="content">
      <div class="address-item">
        <div class="address-label">收货人：</div>
        <div class="address-value"><input class="address-input" :disabled="isShow" v-model="rewards_address.real_name"/></div>
      </div>
      <div class="address-item">
        <div class="address-label">手机号码：</div>
        <div class="address-value"><input class="address-input" :disabled="isShow"  v-model="rewards_address.mobile"/></div>
      </div>
      <div class="address-item">
        <div class="address-label">收货地址：</div>
        <div class="address-value"><input class="address-input" :disabled="isShow"  v-model="rewards_address.address"/></div>
      </div>
      <div class="address-tips" v-if="!isShow">*请仔细核实您填写的信息</div>
      <div class="address-tips"  v-if="isShow">*您的信息已提交，如需修改可联系客服</div>
    </div>
    <div class="s-btn-wrap">
      <div v-if="!isShow" :style="modalCancelBtnStyleObj" class="s-btn s-btn-cancel" @click="cancel">{{config.cancelBtnText}}</div>
      <div v-if="!isShow" :style="modalConfirmBtnStyleObj" class="s-btn s-btn-confirm" @click="confirm">{{config.confirmBtnText}}</div>
    </div>
  </ModalCommon>

</template>

<script>
import ModalCommon from './ModalCommon.vue'
import { isPhone } from '../utils'
export default {
  name: 'ModalAddress',
  components: {
    ModalCommon
  },
  props: {
    title: {
      type: String,
      default: '收货地址'
    },
    btnText: {
      type: String,
      default: '开心收下'
    }
  },
  inject: {
    // 由 MainWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      config: {
        title: '提示',
        content: '',
        confirmBtnText: '确定',
        cancelBtnText: '取消',
        onConfirm: () => {},
        onCancel: () => {}
      },
      visible: false,
      isShow: false,
      active_id: '',
      rewards_address: {
        mobile: '',
        real_name: '',
        address: ''
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    modalConfirmBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalConfirmBtnStyle)
      } catch (e) {
        console.error('modalConfirmBtnStyle 格式错误')
        return {}
      }
    },
    modalCancelBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalCancelBtnStyle)
      } catch (e) {
        console.error('modalCancelBtnStyle 格式错误')
        return {}
      }
    }
  },
  async mounted () {
  },
  methods: {
    open (active_id) {
      this.rewards_address = {
        mobile: '',
        real_name: '',
        address: ''
      }
      this.active_id = active_id
      return this.requestAPI({
        url: '/v1/uni/address',
        method: 'get',
        params: {
          a_id: this.active_id
        }
      }).then((res) => {
        console.log(res)
        this.$refs.ModalCommon.open()
        console.log(this.$refs.ModalCommon)
        if (!res.address) {
          this.isShow = false
        } else {
          this.rewards_address = { ...res }
          this.isShow = true
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // async open (options) {
    //   this.config = Object.assign({
    //     title: '提示',
    //     content: '',
    //     confirmBtnText: '确定',
    //     cancelBtnText: '取消',
    //     onConfirm: () => {},
    //     onCancel: () => {}
    //   }, options)
    //   this.$refs.ModalCommon.open()
    // },
    check () {
      if (!isPhone(this.rewards_address.mobile)) {
        this.$message({
          message: '手机号不正确，请重新输入',
          type: 'warning'
        })
        return false
      }
      if (!this.rewards_address.real_name.trim()) {
        this.$message({
          message: '请输入收货人',
          type: 'warning'
        })
        return false
      }
      const nameLength = this.rewards_address.real_name.trim().length
      if (nameLength < 1 || nameLength > 100) {
        this.$message({
          message: '收货人不得超过100字符',
          type: 'warning'
        })
        return false
      }
      if (!this.rewards_address.address.trim()) {
        this.$message({
          message: '请输入收货地址',
          type: 'warning'
        })
        return false
      }
      const addressLength = this.rewards_address.address.trim().length
      if (addressLength < 1 || addressLength > 100) {
        this.$message({
          message: '收货地址不得超过100字符',
          type: 'warning'
        })
        return false
      }
      return true
    },
    confirm () {
      if (!window.$checkLogin()) return

      if (this.check()) {
        return this.requestAPI({
          url: '/v1/uni/address',
          method: 'post',
          data: {
            mobile: this.rewards_address.mobile.trim(),
            real_name: this.rewards_address.real_name.trim(),
            address: this.rewards_address.address.trim(),
            a_id: this.active_id
          }
        }).then((data) => {
          this.$message.success('保存成功!')
          this.close()
        }).catch((err) => {
          console.error(err)
          this.$message.error(err.msg || '系统错误')
        })
      }
    },
    cancel () {
      this.config.onCancel()
      this.close()
    },
    close () {
      this.$refs.ModalCommon.close()
    }
  }
}
</script>

<style scoped lang="scss">
.content{
  padding: 0 100px;
  .address-item {
    .address-label {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .address-value {
      margin-bottom: 20px;
      background-color: rgba(0,0,0,.5);
      border-radius: 4px;
      input{
        width: 100%;
        border: none;
        background-color: transparent;
        color: #fff;
        font-weight: 700;
        font-size: 24px;
        padding: 10px 20px;
        outline: none;
      }
    }
  }
  .address-tips{
    text-align: center;
    font-size: 20px;
  }
  .address-submit {
    width: 121px;
    height: 42px;
    margin: 20px auto 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //background-image: url("../../../../assets/images/versionActivity/address-submit.png");
    cursor: pointer;
  }
}

.s-btn-wrap{
  padding: 20px 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-btn{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 222px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  margin: 0 20px;
  transition: .3s;
  cursor: pointer;
}
.s-btn-confirm{
  &:hover{
    filter: brightness(1.1);
  }
}
</style>
