<template>
  <el-tag :type="colorMap[code]" size="small">
    {{code | enumsMap(enums)}}
  </el-tag>
</template>

<script>
export default {
  name: 'EnumsColorful',
  components: {},
  props: {
    code: {
      type: [String, Number],
      required: true
    },
    enums: {
      type: Array,
      required: true
    },
    // 枚举数据 {100: 'color-green', 101: 'color-red, 102: 'color-orange', 103: 'color-blue, 104: 'color-purple'}
    colorMap: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  created () {},
  mounted () {},
  methods: {},
  beforeDestroy () {}
}
</script>
<style scoped lang="scss">
.page{}
</style>
