<!--通用选择弹窗-->
<template>
  <el-button :type="type" :size="size" :loading="loading" @click="handleClick">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'BaseAsyncButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      //        required: true,
      type: String,
      default: 'mini'
    },
    // onClick接受一个函数 该函数返回一个不带finally()的Promise
    onClick: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      loading: false
    }
  },
  components: {},
  watch: {},
  computed: {},
  created () {
  },
  methods: {
    handleClick () {
      this.loading = true
      try {
        this.onClick().finally(() => {
          this.loading = false
        })
      } catch (err) {
        console.warn(err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
