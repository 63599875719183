import _mixin from './_mixin'
export default {
  name: 'BaseImageList',
  cnName: '图片列表',
  type: 'complex',
  // 属性相关
  props: {
    list: [
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: 'xxx'
      },
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: 'xxx'
      },
      {
        src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/nationalDay/pc/images/prize7.png?v=1665632635351',
        label: 'xxx'
      }
    ],
    imgWidth: '500px'
  },
  propsDesc: [{
    target: 'list',
    label: '图片列表',
    formType: 'list',
    listConfig: [
      {
        title: '图片地址',
        target: 'src'
      },
      {
        title: '图片标题',
        target: 'label'
      }
    ]
  }, {
    target: 'imgWidth',
    label: '图片宽度'
  }, {
    target: 'imgHeight',
    label: '图片高度'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1600px'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
