<template>
  <transition name="s-popup-fade">
    <div class="s-popup s-popup-container"
         :class="[modal&&'s-popup-modal']"
         style="{'z-index': 10000}"
         v-show="myVisible"
         @click.self="handleClickModal">
      <div class="s-dialog" :style="dialogStyle">
        <i class="s-dialog-close-btn" v-if="closeBtn" @click="close"></i>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SDialog',
  props: {
    // 是否显示   支持 .sync 修饰符
    visible: {
      type: Boolean,
      default: true
    },
    // 是否需要遮罩层
    modal: {
      type: Boolean,
      default: true
    },
    // 是否可以通过点击 modal 关闭 Dialog
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    // 关闭前
    beforeClose: {
      type: Function
    },
    // dialog宽度
    width: {
      type: String,
      default: '50%'
    },
    // dialog高度
    height: {
      type: String,
      default: 'auto'
    },
    // dialog内边距
    padding: {
      type: String,
      default: 'auto'
    },
    // dialog圆角
    borderRadius: {
      type: String,
      default: '0px'
    },
    // 是否显示关闭按钮
    closeBtn: {
      type: Boolean,
      default: true
    },
    // 设计稿尺寸（宽度、px）。 为空或0时，动态传入的px不进行vw转化
    designSize: {
      type: Number,
      default: 0
    },
    // dialog背景颜色
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    // dialog背景图片
    backgroundImage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      myVisible: false
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        this.myVisible = newVal
      },
      immediate: true
    },
    myVisible (newVal) {
      this.handleScroll(newVal)
    }
  },
  computed: {
    dialogStyle () {
      return {
        width: this.width,
        height: this.height,
        padding: this.padding,
        'border-radius': this.borderRadius,
        'background-color': this.backgroundColor,
        'background-image': `url(${this.backgroundImage})`
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    handleScroll (flag) {
      const body = document.getElementsByTagName('body')[0]
      if (flag) {
        body.classList.add('s-popup-forbid-scroll')
        // document.body.appendChild(this.$el)
      } else {
        body.classList.remove('s-popup-forbid-scroll')
      }
    },
    handleClickModal (e) {
      this.closeOnClickModal && this.close()
    },

    show () {
      this.open()
    },
    hide () {
      this.close()
    },
    close () {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(() => this.$emit('update:visible', false))
      } else {
        this.$emit('update:visible', false)
        this.myVisible = false
      }
    },
    open () {
      this.$emit('update:visible', true)
      this.myVisible = true
    }
  },
  beforeDestroy () {
    this.handleScroll(false)
  }
}
</script>
<style scoped lang="scss">
.s-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.s-popup-modal{
  background: rgba(0, 0, 0, 0.5);
}

.s-popup-fade-enter-active {
  transition: all .3s ease-out;
}

.s-popup-fade-leave-active {
  transition: all .3s ease;
}

.s-popup-fade-enter-from,
.s-popup-fade-leave-to {
  opacity: 0;
}

.s-dialog{
  background-color: #fff;
  min-height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .s-dialog-close-btn{
    position: absolute;
    z-index: 9;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: center / cover no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABxElEQVRYhb3XO4vUUBTA8V8mImih4IhMYSfWVla+UFBrSwXxsVqIY62fwEcviDp+AkELQVaw0E5Ye0vXykaFLQQLQYuby4YweSdzqkxy5v7/HHLuuUm+HD4Cu3EJC+PHQRzFa5hk8Dd4jgcrgH/AS1yMAms4kyXcw8OR4Cke4VB2/Rh70vl0toEDQlngOHbh/cDwF7ic/d7CeXxN59MZvB1RIsKv5OBnsQFRYCyJSnhRYGiJVOiqq2XwZQJDSTSClwn0lWgMrxLoKpEK+8m1JvA6gTIJ+FiSfx93msIJG1FV/MNtPMkt+q4i/yk2m8JhR11CTuI3XuFTRe4mTmM/PjdYW5INo64xwU786bNAn/8usC4MtJUKTGy/7adko3VVAhF+PXfvnI5TtK1AEf439+xuF4k2AkkBvoVjtlu0k0STNozwRQEe+zz2+q2cBOFwUxt1O2EdPEbn2VEnEMu+VgHvJVEl0AbeWaJMoAu8k8QygT7w1hJFgQTPcKMHvJVEXmBIeGOJKJAIs/zmgPBGEul8OhsTXisxwUnDln1ZFE9WcEH2afYN33FCmGpDw/MRK7FXODn9yJ+I9uHXiPAYScb6Cf8Bi+mXeNX8CRcAAAAASUVORK5CYII=);
    top: 0;
    right: -80px;
    &:hover {
      animation: rotate 0.8s ease 0s 1;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style>
.s-popup-forbid-scroll {
  overflow: hidden;
  padding-right: calc(100vw - 100%);
}
</style>
