<template>
  <div class="tab3 img-bg" :style="{...defaultBg.style, 'background-image': `url(${defaultBg.src})`}">
    <img class="title" :src="titleImg.src" :style="{...titleImg.style}" alt=""/>
    <div class="content-box">
      <swiper ref="swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in newSwiperList" :key="index" class="goods-items">
          <img class="new-date img-bg" :style="{width: dateList[index] && dateList[index].dateImgSize.split(' ')[0], height: dateList[index] && dateList[index].dateImgSize.split(' ')[1]}" :src="dateList[index] && dateList[index].dateImg" alt=""/>
          <div class="good-item">
            <div class="left">
              <img class="award-img" :src="awardList[index] && awardList[index].awardImgOne" alt="" :style="{width: awardList[index] && awardList[index].awardImgSize.split(' ')[0], height: awardList[index] && awardList[index].awardImgSize.split(' ')[1]}" />
              <img :class="['img-bg']" v-if="item[0] && item[0].status === 0" :src="btnNoBegin.src" alt="" @click="receiveAward(item[0])" :style="{...btnNoBegin.style}"/>
              <img :class="['img-bg']" v-if="item[0] && item[0].status === 1" :src="btnUnfinished.src" alt="" @click="receiveAward(item[0])" :style="{...btnUnfinished.style}"/>
              <img :class="['btn-status-light img-bg']" v-if="item[0] && item[0].status === 2" :src="btnReceive.src" alt="" @click="receiveAward(item[0])" :style="{...btnReceive.style}"/>
              <img :class="['img-bg']" v-if="item[0] && item[0].status === 3" :src="btnReceived.src" alt="" @click="receiveAward(item[0])" :style="{...btnReceived.style}"/>
              <img :class="['img-bg']" v-if="item[0] && item[0].status === 4" :src="btnExpire.src" alt="" @click="receiveAward(item[0])" :style="{...btnExpire.style}"/>
            </div>
            <div class="right">
              <img class="award-img" :src="awardList[index] && awardList[index].awardImgTwo" alt="" :style="{width: awardList[index] && awardList[index].awardImgSize.split(' ')[0], height: awardList[index] && awardList[index].awardImgSize.split(' ')[1]}"/>
              <img :class="['img-bg']" v-if="item[1] && item[1].status === 0" :src="btnNoBegin.src" alt="" @click="receiveAward(item[1])" :style="{...btnNoBegin.style}"/>
              <img :class="['img-bg']" v-if="item[1] && item[1].status === 1" :src="btnUnfinished.src" alt="" @click="receiveAward(item[1])" :style="{...btnUnfinished.style}"/>
              <img :class="['btn-status-light img-bg']" v-if="item[1] && item[1].status === 2" :src="btnReceive.src" alt="" @click="receiveAward(item[1])" :style="{...btnReceive.style}"/>
              <img :class="['img-bg']" v-if="item[1] && item[1].status === 3" :src="btnReceived.src" alt="" @click="receiveAward(item[1])" :style="{...btnReceived.style}"/>
              <img :class="['img-bg']" v-if="item[1] && item[1].status === 4" :src="btnExpire.src" alt="" @click="receiveAward(item[1])" :style="{...btnExpire.style}"/>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="arrow arrow-prev"
           :style="{...swiperLeft.style,'background-image': `url(${swiperLeft.src})`}"></div>
      <div class="arrow arrow-next"
           :style="{...swiperRight.style,'background-image': `url(${swiperRight.src})`}"></div>
    </div>
    <img :src="ruleImg.src" alt="" :style="{...ruleImg.style}"/>
    <!--提示弹窗-->
    <STipComModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipTitle="tipTitle"
      :btnClose="btnClose"
      :contentText="tipModel.text"
    >
    </STipComModel>
    <!--奖励弹窗-->
    <SAwardComModel
      :modelShow.sync="awardModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardTitle="awardTitle"
      :awardOtherDesc="awardOtherDesc"
      :awardBoxBg="awardBoxBg"
      :btnHappy="btnHappy"
      :awardData="awardModel.award"
    >
    </SAwardComModel>
  </div>
</template>

<script>

export default {
  name: 'SActivityThree',
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    defaultBg: {
      type: Object,
      required: true
    },
    titleImg: {
      type: Object,
      required: true
    },
    dateList: {
      type: Array,
      required: false
    },
    awardList: {
      type: Array,
      required: true
    },
    swiperLeft: {
      type: Object,
      required: true
    },
    swiperRight: {
      type: Object,
      required: true
    },
    btnNoBegin: {
      type: Object,
      required: true
    },
    btnUnfinished: {
      type: Object,
      required: true
    },
    btnReceive: {
      type: Object,
      required: true
    },
    btnReceived: {
      type: Object,
      required: true
    },
    btnExpire: {
      type: Object,
      required: true
    },
    ruleImg: {
      type: Object,
      required: true
    },
    /* 公共弹窗配置 */
    modelBg: {
      type: Object,
      required: true
    },
    modelClose: {
      type: Object,
      required: true
    },
    modelColor: {
      type: String,
      required: true
    },
    /* tip弹窗配置 */
    tipTitle: {
      type: Object,
      required: true
    },
    btnClose: {
      type: Object,
      required: true
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      type: Object,
      required: true
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: Object,
      required: true
    },
    btnHappy: {
      type: Object,
      required: true
    }
  },
  inject: {
    eventBus: {
      default: null
    }
  },
  data () {
    return {
      swiperOptions: {
        speed: 600,
        autoplay: false,
        navigation: {
          prevEl: '.arrow-prev',
          nextEl: '.arrow-next'
        }
      },
      // 轮播数据
      newSwiperList: [
        [{ status: 1 }, { status: 1 }],
        [{ status: 1 }, { status: 1 }],
        [{ status: 1 }, { status: 1 }],
        [{ status: 1 }, { status: 1 }],
        [{ status: 1 }, { status: 1 }]
      ],
      // 提示弹窗
      tipModel: {
        show: false,
        text: ''
      },
      // 奖励弹窗
      awardModel: {
        show: false,
        award: []
      }
    }
  },
  mounted () {
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.getCheckStatus()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    if (!$checkLogin()) return
    this.getCheckStatus()
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getCheckStatus()
        }
      },
      immediate: false
    }
  },
  methods: {
    // 获取初始化状态
    getCheckStatus () {
      this.requestAPI({
        url: '/v1/uni/weekStatus',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.newSwiperList = res.info
        this.$forceUpdate()
        this.$refs.swiper.swiper.slideTo(res.focus, 800)
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 领取奖励
    receiveAward (item) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断当前状态
      if (item.status !== 2) {
        return
      }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: { g_id: item.g_id, a_id: this.activityId }
      }).then(() => {
        this.awardModel.show = true
        const rewards = item.reward
        rewards.images = rewards.image
        this.awardModel.award = [rewards]
        this.getCheckStatus()
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 重置
    reset () {
      this.newSwiperList.forEach((item, index) => {
        item[0].status = 1
        item[1].status = 1
      })
      // this.getCheckStatus()
    }
  }
}
</script>

<style scoped lang="scss">
// 公共样式
.img-bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.lay{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}
// 内容样式
.tab3{
  .content-box{
    position: relative;
    width: 1500px;
    margin: 20px auto 20px;
    .goods-items{
      width: 1500px;
      height: 100%;
      .new-date{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin: 0 auto 20px;
      }
      .good-item{
        width: 1500px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .award-img{
          margin-bottom: 20px;
        }
        .btn-status-light{
          cursor: pointer;
          z-index: 2;
          &:hover{
            filter: brightness(120%)
          }
        }
      }
    }
    .arrow {
      position: absolute;
      top: 190px;
      background-size: 100%;
      z-index: 1;
      &:hover{
        filter: brightness(120%)
      }
    }
    .arrow-prev {
      left: -130px;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: .5;
      }
    }
    .arrow-next {
      right: -130px;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: .5;
      }
    }
  }
}
</style>
