<template>
  <STopicWrapper class="activity" v-bind="$attrs">
    <div class="s-topic-content">
      <div class="tab-list" :style="{width: tabContentSize.split(' ')[0], justifyContent: tabBtnAlign}">
        <div v-for="(item,index) in tabList" :key="index" class="tab-item img-bg"
             @mouseover="tabHover = index"
             @mouseout="tabHover = -1"
             @click="selectTab(index)"
             :style="{
              width: tabBtnSize.split(' ')[0],
              height: tabBtnSize.split(' ')[1],
              backgroundImage: (index === tab || index === tabHover) ? `url(${item.tabSelect})` : `url(${item.tab})`}"
        ></div>
      </div>
      <TransitionGroup name="slide-fade" mode="out-in" tag="div" duration="800" class="relative">
        <div v-for="(item,index) in tabList" :key="index" v-show="index === tab" class="tab-content">
          <div class="activity-box img-bg" :style="{...activityStyle, 'background-image': `url(${item.activityImg})`}">
            <slot :name="index + ''"></slot>
          </div>
          <div v-show="item.activityText" class="activity-text">{{ item.activityText }}</div>
        </div>
      </TransitionGroup>
    </div>

  </STopicWrapper>
</template>

<script>

export default {
  name: 'STopicActivity',
  components: { },
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    tabBtnSize: {
      type: String,
      default: ''
    },
    tabBtnAlign: {
      type: String,
      default: 'space-evenly'
    },
    tabContentSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 当前选中的下标
      tab: 0,
      tabHover: -1
    }
  },
  computed: {
    // defaultStyle () {
    //   return {
    //     ...this.bg.style,
    //     'background-image': `url(${this.bg.src})`
    //   }
    // },
    // title样式
    // titleStyle () {
    //   const arr = this.titleSize.split(' ')
    //   return {
    //     width: arr[0] || '',
    //     height: arr[1] || ''
    //   }
    // },
    // 中间活动页样式
    activityStyle () {
      const arr = this.tabContentSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || '',
        'background-image': `url(${this.tabList[this.tab].activityImg})`
      }
    }
  },
  methods: {
    // 选中tab
    selectTab (index) {
      if (index === this.tab) return
      this.tab = index
    }
  }
}
</script>
<style lang="scss" scoped>
.img-bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.activity {
  position: relative;

  .tab-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 17px;

    .tab-item {
      cursor: pointer;
      transition: .3s;
    }
  }

  .tab-content{
    position: absolute;
    width: 100%;
    //left: 50%;
    //transform: translateX(-50%);
  }
  .activity-box {
    position: relative;
    margin: 0 auto 20px;

    .video-icon {
      position: absolute;
      cursor: pointer;
    }
  }

  .activity-text {
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
