<template>
  <transition name="slide-fade">
    <div class="s-anchor-nav"
         :style="listWrapStyleObj"
         v-show="visible">
      <div class="s-anchor-nav-item"
           :class="(active === index || hoverIndex === index) ? 'active' : ''"
           :style="active === index ? listItemActiveStyleObj : listItemStyleObj"
           v-for="(item, index) in oList"
           :key="index"
           @click="handleClick(item, index)"
      >
        <div class="label">{{ item.text }}</div>
        <div class="point"></div>
        <div class="line line-top" v-if="index === 0"></div>
        <div class="line line-bottom"></div>
      </div>
      <div class="s-anchor-top" @click="scrollTo(0)">
        TOP
        <i class="triangle"></i>
      </div>
<!--      <img class="s-anchor-top"-->
<!--           src="@/assets/images/preHeat/nav_top.png" @click="scrollTo(0)"/>-->
    </div>
  </transition>
</template>

<script>
export default {
  name: 'STopic2SideNav',
  props: {
    list: {
      type: Array,
      required: true
      // default: () => [
      //   {
      //     targetId: ''
      //     text: '',
      //   }
      // ]
    },
    listWrapStyle: {
      type: String,
      default: '{}'
    },
    listItemStyle: {
      type: String,
      default: '{}'
    },
    listItemActiveStyle: {
      type: String,
      default: '{}'
    }
  },
  data () {
    return {
      visible: false,
      active: 0,
      hoverIndex: null,
      container: null,
      targetOffsetTopList: []
    }
  },
  computed: {
    oList () {
      const wdFrom = this.$route.query.wdFrom || ''
      if (!wdFrom) return this.list
      return this.list.filter(item => {
        const hideList = item.hideInChannel.split(',')
        return !hideList.includes(wdFrom)
      })
    },
    listWrapStyleObj () {
      try {
        return JSON.parse(this.listWrapStyle)
      } catch (e) {
        console.error('listWrapStyle 格式错误')
        return {}
      }
    },
    listItemStyleObj () {
      try {
        return JSON.parse(this.listItemStyle)
      } catch (e) {
        console.error('listItemStyle 格式错误')
        return {}
      }
    },
    listItemActiveStyleObj () {
      try {
        return JSON.parse(this.listItemActiveStyle)
      } catch (e) {
        console.error('listItemActiveStyle 格式错误')
        return {}
      }
    }
  },
  mounted () {
    this.container = document.querySelector('#pageRenderParent') || window
    this.container.addEventListener('scroll', this._throttle(this.onScroll, 50))
    // this.container.addEventListener('scroll', this.onScroll)
  },
  methods: {
    setTopList () {
      // if (!this.targetOffsetTopList.length) {
      if (this.list.some(item => !item.targetId)) { // 如果锚点ID未完整填入
        console.warn('[STopic2SideNav]:请完善锚点ID')
        return
      }
      this.targetOffsetTopList = this.oList.map(item => {
        const dom = document.querySelector('#' + item.targetId)
        return dom.offsetTop
      })
      // }
    },
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick (item, index) {
      console.log(item, index)
      // this.active = index
      this.setTopList()
      // console.log(this.container)
      // console.log(item.targetId)
      // const target = document.querySelector('#' + item.targetId)
      // console.log(target.offsetTop)
      // this.container.scrollTo({
      //   top: this.targetOffsetTopList[index],
      //   behavior: 'smooth'
      // })
      this.scrollTo(this.targetOffsetTopList[index])
    },
    scrollTo (top) {
      this.container.scrollTo({
        top,
        behavior: 'smooth'
      })
    },
    onScroll (e) {
      this.setTopList()
      const rangeList = []
      this.targetOffsetTopList.forEach((item, index) => {
        rangeList.push([item, this.targetOffsetTopList[index + 1] || +Infinity])
      })
      const scrollTop = this._getScrollTop(e.target)
      // console.log(scrollTop)
      if (scrollTop >= 800) {
        this.visible = true
        this.$emit('visibleChange', true)
      } else {
        this.visible = false
        this.$emit('visibleChange', false)
      }
      let getIt = false
      // console.log('====')
      rangeList.forEach((item, index) => {
        // console.log(scrollTop, item)
        if (getIt) return
        const tolerance = 200 // 误差（缩放情况下）
        if (item[0] <= scrollTop + tolerance && scrollTop + tolerance < item[1]) {
          this.active = index
          getIt = true
        } else {
          this.active = -1
        }
      })
    },
    _getScrollTop (e) {
      if (e.scrollTop !== undefined) {
        return e.scrollTop
      } else if (e.document) {
        return (
          e.document.documentElement.scrollTop ||
          window.pageYOffset ||
          e.document.body.scrollTop ||
          0
        )
      }
      return e.documentElement.scrollTop || e.body.scrollTop || 0
    },

    // 节流方法
    _throttle (fn, time) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, time)
      }
    },
    _debounce (fn, time) {
      let timeout = null
      return function () {
        const _arguments = arguments
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          timeout = null
          fn.apply(this, _arguments)
        }, time)
      }
    }
  },
  beforeDestroy () {
    this.container.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss" scoped>
.s-anchor-nav {
  position: fixed;
  padding-right: 50px;
  top: 25%;
  right: 0;
  z-index: 22;
  min-width: 105px;
  .s-anchor-nav-item{
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    color: #C6C6C6;
    line-height: 35px;
    font-size: 20px;
    .label{
      transition: .3s;
      padding: 0 10px;
      text-align: right;
      white-space: nowrap;
      border: 1PX solid transparent;
    }
    &:last-child{
      .line-bottom {
        display: block;
      }
    }
    .line{
      position: absolute;
      right: -19px;
      width: 1PX;
      height: 35px;
      background-color: #B3B3B3;
      &.line-top {
        top: -26px;
      }
      &.line-bottom {
        bottom: -28px;
      }

    }
    .point{
      transition: .3s;
      position: absolute;
      opacity: 0;
      right: -20px;
      top: 49%;
      width: 10px;
      height: 10px;
      background-color: darkred;
      transform: rotate(45deg)  translateY(-50%);
    }

    &:hover{
      filter: brightness(1.1);
    }
    &.active {
      opacity: 1;
      color: #ffffff;
      .label{
        opacity: 1;
        background-color: rgba(189, 20, 24,.2);
        border: 1PX solid #BD1418;
      }
      .point{
        opacity: 1;
      }
    }
  }

  .s-anchor-top{
    margin-top: 30px;
    position: relative;
    transition: .3s;
    line-height: 35px;
    cursor: pointer;
    font-size: 20px;
    padding: 0 20px;
    text-align: center;
    border: 1px solid transparent;
    background-color: #E6A23C;
    color: #FFF4C5;
    &:hover{
      filter: brightness(1.1);
    }
    .triangle{
      position: absolute;
      top: -4px;
      left: 50%;
      width: 16px;
      height: 16px;
      background-color: #E6A23C;
      transform: rotate(45deg) translateX(-50%);
    }
  }

  //.s-anchor-top {
  //  cursor: pointer;
  //  transition: .3s;
  //  &:hover {
  //    color: #ffffff;
  //  }
  //}
}

// 滑入
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(50px);
  opacity: 0;
}
</style>
