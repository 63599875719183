<!--领取成功弹窗-->
<template>
  <ModalCommon ref="ModalCommon"
               :title="config.title">
    <div class="content">
        <div class="list">
          <template v-if="list && list.length">
            <div class="item" v-for="(item,index) in list" :key="index">
              <span class="time">{{ item.created_at }}</span>
              <span>{{item.summary}} 【{{item.change_type > 0 ? '+' : '-'}}{{ item.amount }}】</span>
            </div>
          </template>
          <div v-else class="no-data" :style="{color: $MainWrap.textColorLevel3}">暂无数据</div>
        </div>
      <div class="pagination" v-if="totalPage > 1" :style="{color: $MainWrap.textColorLevel1}">
        <div class="btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
        <div class="counter" @click="next">{{ page }}/{{ totalPage }}</div>
        <div class="btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </ModalCommon>

</template>

<script>
import ModalCommon from './ModalCommon.vue'
export default {
  name: 'ModalReceiveLog',
  components: {
    ModalCommon
  },
  props: {
    title: {
      type: String,
      default: '恭喜获得'
    },
    btnText: {
      type: String,
      default: '开心收下'
    }
  },
  inject: {
    // 由 MainWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      list: [],
      page: 1,
      totalPage: 0,
      config: {
        title: '积分明细',
        activityId: '',
        pageSize: 10
      }
    }
  },
  computed: {
    requestAPI () {
      return this.request ? this.request : window.$request
    }
  },
  async mounted () {
  },
  methods: {
    async open (options) {
      this.page = 1
      this.list = []
      if (options) {
        this.config = Object.assign({
          title: '积分明细',
          activityId: '',
          pageSize: 10
        }, options)
      }
      this.getList()
      this.$refs.ModalCommon.open()
    },
    prev () {
      if (this.page > 1) {
        this.page--
        this.getList()
      }
    },
    next () {
      if (this.page < this.totalPage) {
        this.page++
        this.getList()
      }
    },
    getList () {
      this.requestAPI({
        url: '/v1/uni/integral',
        method: 'get',
        params: {
          a_id: this.config.activityId,
          page: this.page,
          page_size: this.config.pageSize,
          history: true
        }
      }).then(data => {
        this.list = data.page_info.items
        this.totalPage = data.page_info.total_page
      })
    },
    confirm () {
      this.config.onConfirm()
      this.close()
    },
    cancel () {
      this.config.onCancel()
      this.close()
    },
    close () {
      this.$refs.ModalCommon.close()
    }
  }
}
</script>

<style scoped lang="scss">
//@import "../common.scss";
.content{
  margin-bottom: 0px;
  font-size: 24px;
  padding: 0 30px;
  .tip{
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    .btn-address{
      text-decoration: underline;
      font-weight: 700;
    }
  }
  .list{
    padding: 10px 20px 0;
    height: 380px;
    overflow-y: auto;
    font-size: 18px;
    margin-bottom: 20px;
    .item{
      display: flex;
      margin-bottom: 15px;
      .time{
        width: 230px;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  font-weight: 700;
  .counter{
    margin: 0 20px;
  }
}

.no-data{
  font-size: 18px;
  text-align: center;
  padding: 50px 0 0;
}
</style>
