<template>
  <STopicWrapper v-bind="$attrs">
    <img class="content-img" v-if="contentImg.src"  :src="contentImg.src" alt="" :style="contentImgStyle"/>
    <slot>
    </slot>
  </STopicWrapper>
</template>

<script>

export default {
  name: 'STopicCommonEmpty',
  props: {
    // bg: {
    //   type: Object,
    //   required: true
    // },
    // titleImg: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // },
    // footerArrow: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // },
    contentImg: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    // 内容区域图片
    contentImgStyle () {
      return {
        width: this.contentImg.style.width,
        height: this.contentImg.style.height
      }
    }
  },
  created () {
    setTimeout(() => {
      console.log(this.$attrs)
    }, 2000)
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.content-img{
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //object-fit: contain;
}
</style>
