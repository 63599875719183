import _mixin from '../_mixin'

export default {
  name: 'SActivityIframe',
  cnName: '活动-iframe嵌套活动',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/d477c83a85b1452bf492736a8ee18b43.jpeg',
  // 属性相关
  props: {
    // 未开始按钮
    iframeInfo: {
      src: '',
      style: {
        width: '1920px',
        height: '966px'
      }
    },
    iframeURL: 'https://h5.ssjj.cn/anniversary'
  },
  propsDesc: [
    {
      target: 'iframeInfo',
      label: 'iframe嵌套样式',
      formType: 'image'
    },
    {
      target: 'iframeURL',
      label: 'iframe嵌套地址'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
