<template>
  <STopicWrapper class="s-new-weapon" v-bind="$attrs">
    <div v-show="textDesc" :style="textStyle">{{ textDesc }}</div>
    <div class="content-box img-bg" :style="contentStyle">
      <div class="gun-wrap">
        <swiper :ref="uid" :options="swiperOption" class="list" :key='swiperKey'>
          <swiper-slide v-for="(item, index) in swiperImgList" :key="index">
            <div class="list-item">
              <img :src="item.src" :style="swiperImgStyle" :alt="item.label">
            </div>
          </swiper-slide>
        </swiper>
        <div class="s-swiper-pagination">
          <img
            v-for="(item, index) in swiperImgList" :key="index"
            class="page-weapon"
            :src="currentIndex === index ? item.thumbActive : item.thumbDefault" alt=""
            :style="swiperThumbImgStyle"
            @click="changeCurrent(index)">
        </div>
      </div>
      <div class="info">
        <img :src="singleImgShow.src" alt="" :style="singleImgShow.style"/>
        <div v-show="btnJump" class="btn-jump img-bg" :style="btnStyle">
          <a :href="btnJumpLink" target="_blank"></a>
        </div>
        <div v-show="coverImg" class="s-new-weapon-video" :style="coverImg.style" @click="videoModelShow=true">
          <img class="cover" :src="coverImg.src" alt=""/>
          <!--          <video class="s-video&#45;&#45;mini" :src="miniVideo" autoplay muted></video>-->
        </div>
      </div>
    </div>
    <!--视频弹窗-->
    <SVideoModel
      :modelShow.sync="videoModelShow"
      :modelClose="closeImg"
      :modelSize="videoSize"
      :videoLink="mainVideo">
    </SVideoModel>
  </STopicWrapper>
</template>

<script>
// import VideoModel from '@/components/BuilderComponents/Dialog/VideoModel.vue'

export default {
  name: 'STopicCardPoolWeapon',
  components: { },
  props: {
    contentBg: {
      type: Object,
      required: true
    },
    singleImgShow: {
      type: Object,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    swiperImgSize: {
      type: String,
      default: '950px 540px'
    },
    swiperThumbImgSize: {
      type: String,
      default: '130px 120px'
    },
    swiperImgList: {
      type: Array,
      default: () => []
    },
    /* 按钮跳转 */
    btnJump: {
      type: Object,
      default: () => {
        return {}
      }
    },
    btnJumpLink: {
      type: String,
      default: ''
    },
    /* 视频弹窗 */
    coverImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mainVideo: {
      type: String,
      default: ''
    },
    videoSize: {
      type: String,
      default: ''
    },
    closeImg: {
      type: String,
      default: ''
    },
    /* 文案配置 */
    textDesc: {
      type: String,
      default: ''
    },
    textDescPosition: {
      type: String,
      default: ''
    },
    textDescFontSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uid: '',
      swiper: null,
      currentIndex: 0,
      swiperKey: Math.random().toString(36).substr(2),
      swiperOption: {
        autoplay: this.autoplay,
        oop: true,
        navigation: {
          nextEl: `swiper-prev--${this.uid}`,
          prevEl: `swiper-next--${this.uid}`
        }
      },
      visible: true,
      videoModelShow: false
    }
  },
  watch: {
    autoplay: {
      handler: function (newVale) {
        this.swiperOption.autoplay = newVale
        this.swiperKey = Math.random().toString(36).substr(2)
        if (newVale) {
          this.$nextTick(() => {
            this.swiper = this.$refs[this.uid].swiper
            this.$refs[this.uid].swiper.on('slideChange', () => {
              this.currentIndex = this.swiper.realIndex
            })
          })
        } else {
          this.currentIndex = 0
        }
      }
    }
  },
  mounted () {
    this.uid = Date.now()
    this.$nextTick(() => {
      this.swiper = this.$refs[this.uid].swiper
      this.$refs[this.uid].swiper.on('slideChange', () => {
        this.currentIndex = this.swiper.realIndex
      })
    })
  },
  computed: {
    defaultStyle () {
      return {
        'background-image': `url(${this.bg.src})`,
        ...this.bg.style
      }
    },
    textStyle () {
      const arr = this.textDescPosition.split(' ')
      return {
        position: 'absolute',
        top: arr[0] || '',
        right: arr[1] || '',
        bottom: arr[2] || '',
        left: arr[3] || '',
        fontSize: `${this.textDescFontSize}`
      }
    },
    contentStyle () {
      return {
        'background-image': `url(${this.contentBg.src})`,
        ...this.contentBg.style
      }
    },
    btnStyle () {
      return {
        'background-image': `url(${this.btnJump.src})`,
        ...this.btnJump.style
      }
    },
    coverImgStyle () {
      const size = this.coverImgSize.split(' ')
      return {
        width: size[0] || '',
        height: size[1] || ''
      }
    },
    // 图片样式
    swiperImgStyle () {
      const arr = this.swiperImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    // 缩略图图片样式
    swiperThumbImgStyle () {
      const arr = this.swiperThumbImgSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    // swiper切换
    changeCurrent (index) {
      console.log(index)
      this.swiper && this.swiper.slideTo(index)
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  object-fit: contain;
}

.img-bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.lay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999;
}

.close-icon {
  position: absolute;
  right: -90px;
  top: -15px;
  width: 88px;
  height: 80px;
  cursor: pointer;
}

.video-box {
  width: 1080px;
  height: 607px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .s-video {
    width: 100%;
    height: 100%;
  }
}

.s-new-weapon {
  position: relative;
  width: 1920px;
  height: 1001px;

  .content-box {
    position: relative;
    width: 1416px;
    height: 542px;
    margin: 60px auto 0;

    .info {
      position: absolute;
      top: -35px;
      right: 40px;

      img {
        width: 100%;
        height: 100%;
      }

      .btn-jump {
        position: absolute;
        top: 205px;
        left: 50%;
        transform: translateX(-50%);
        width: 264px;
        height: 73px;

        &:hover {
          filter: brightness(120%)
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .s-new-weapon-video {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: .8;
          object-fit: contain;
          cursor: pointer;
          transition: .3s;
          z-index: 1;
        }

        .s-video--mini {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .gun-wrap {
      width: 950px;
      height: 540px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      .list-item {
        img {
          object-fit: contain;
          //width: 950px;
          //height: 540px;
        }
      }

      .s-swiper-pagination {
        position: absolute;
        bottom: -65px;
        padding: 0 50px;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .page-weapon {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
