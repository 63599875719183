<!--限时补给解锁-->
<!--点券兑换奖励-->
<template>
  <div class="s-act" :style="{'background-image': `url(${mainBg})`}">
    <swiper ref="swiper" :options="swiperOptions" class="swiper">
      <swiper-slide v-for="(item, index) in backAwardList" :key="index" class="good-item">
        <div class="append">
          <slot :name="index + ''">
          </slot>
        </div>
        <div class="item-wrap">
          <img class="s-award-img" :src="awardList[index].img" alt="">
          <p class="s-text" :style="{color: awardList[index].textColor}">
            <span>{{item.desc}}</span>
            <span class="limit" v-if="getLimit(item.limit_single).limit">限购 ({{item.acquired_amount}}/{{getLimit(item.limit_single).limit}})</span>
          </p>
          <img @click="openCounter(item)" class="s-btn" :src="awardList[index].btnImg" alt="">
        </div>
      </swiper-slide>
    </swiper>
    <div class="arrow arrow-prev" v-if="backAwardList.length > 3"
         :style="{...swiperLeft.style,'background-image': `url(${swiperLeft.src})`}"></div>
    <div class="arrow arrow-next" v-if="backAwardList.length > 3"
         :style="{...swiperRight.style,'background-image': `url(${swiperRight.src})`}"></div>
    <SFiveModalCounter ref="SFiveModalCounter"></SFiveModalCounter>
  </div>
</template>

<script>
import { getLimit, getPosition, getSize } from '@/components/BuilderComponents/utils'
import SFiveModalCounter from './_components/FiveModalCounter.vue'
export default {
  name: 'SActUnlockLimitSupply',
  components: {
    SFiveModalCounter
  },
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    mainBg: {
      type: String,
      required: true
    },
    awardList: {
      type: Array,
      required: true,
      default: () => [
      ]
    },
    swiperLeft: {
      type: Object,
      required: true
    },
    swiperRight: {
      type: Object,
      required: true
    },
    btnImg: {
      type: String
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      swiperOptions: {
        allowTouchMove: false,
        slidesPerView: 3,
        slidesPerGroup: 1,
        speed: 300,
        autoplay: false,
        navigation: {
          prevEl: '.arrow-prev',
          nextEl: '.arrow-next'
        }
      },
      // 后端数据
      backAwardList: [],
      postForm: {
        g_id: '',
        a_id: '',
        amount: 1
      }
    }
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.getCheckStatus)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    $checkLogin()
    this.getCheckStatus()
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getCheckStatus()
        }
      },
      immediate: false
    }
  },
  methods: {
    getSize,
    getPosition,
    getLimit,
    // 获取初始化状态
    getCheckStatus () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((data) => {
        this.backAwardList = data.groups || []
        // console.log(this.backAwardList)
        this.$forceUpdate()

        // 检查是否处于活动时间
        // console.log('> 限时补给解锁')
        this.$MainWrap.checkStartEnd(data.start_at, data.end_at)
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    openCounter (item) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return

      const leftNum = (getLimit(item.limit_single).limit - item.acquired_amount) || 0
      if (leftNum <= 0) {
        this.$message.warning('已达到限购数量')
        return
      }
      this.$refs.SFiveModalCounter.open({
        title: '购买数量',
        initNum: 1,
        min: 1,
        max: leftNum || 9999,
        step: 1,
        unitPrice: item.price,
        confirmBtnText: '购买',
        onConfirm: (num, price) => {
          this.$MainWrap.confirm({
            content: `是否消耗 ${price} 点券兑换奖励？`,
            onConfirm: () => {
              this.receiveAward(item, num)
            },
            onCancel: () => {}
          })
        }
      })
    },
    // 领取奖励
    receiveAward (item, num) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // // 判断当前状态
      // if (item.status !== 2) {
      //   return
      // }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: {
          g_id: item.id,
          a_id: this.activityId,
          amount: num
        }
      }).then((res) => {
        console.log(res)
        this.$MainWrap.openReceiveModal(item.materials)
        this.getCheckStatus()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 重置
    reset () {
      // this.getCheckStatus()
      this.backAwardList.forEach((item) => {
        item.status = 1
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> SActUnlockLimitSupply  取消登录监听')
      this.eventBus.off('loginSuccess', this.getCheckStatus)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 内容样式
.s-act {
  width: 1920px;
  height: 966px;
  background-size: 100% 100%;
  padding-top: 340px;
  .swiper{
    width: 1400px;
  }
  .good-item {
    width: 432px;
    position: relative;

    .append {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    .item-wrap{
      position: relative;
      .s-award-img {
        display: block;
        margin: 0 auto;
        width: 432px;
      }
      .s-text{
        position: absolute;
        top: 350px;
        left: 0;
        right: 0;
        height: 60px;
        line-height: 60px;
        font-size: 27px;
        color: #fff;
        font-weight: 700;
        text-align: center;
        span{
          margin: 0 20px;
        }
        .limit{
          font-size: 22px;
          opacity: .9;
        }
      }
      .s-btn{
        position: relative;
        z-index: 3;
        width: 361px;
        height: 99px;
        display: block;
        margin: 50px auto 0;
        cursor: pointer;
        transition: .3s;
        &:hover{
          filter: brightness(110%)
        }
      }
    }
  }

  .arrow {
    position: absolute;
    top: 190px;
    cursor: pointer;
    background-size: 100%;
    z-index: 1;

    &:hover {
      filter: brightness(120%)
    }
  }

  .arrow-prev {
    left: -130px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: .5;
    }
  }

  .arrow-next {
    right: -130px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: .5;
    }
  }

}
</style>
