<template>
  <div class="s-swiper">
      <swiper :ref="uid" :options="swiperOption" v-if="list.length" class="list">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div class="list-item">
            <img :src="item.src" :alt="item.label" :style="swiperItemStyle">
<!--            <div class="label">{{ item.label }}</div>-->
          </div>
        </swiper-slide>
      </swiper>
<!--      方向箭头-->
      <div v-if="arrowVisible" class="s-swiper-arrow swiper-button-prev" :class="`swiper-prev--${uid}`"></div>
      <div v-if="arrowVisible" class="s-swiper-arrow swiper-button-next" :class="`swiper-next--${uid}`"></div>
<!--      分液器-->
      <div v-if="paginationVisible"
           class="s-swiper-pagination"
           :class="`s-swiper-pagination--${paginationPositionType}`"
           :style="paginationStyle">
        <img class="s-swiper-pagination-item"
             :style="paginationItemStyle"
             v-for="(item, index) in paginationList" :key="index"
             @click="changeCurrent(index)"
             :src="currentIndex === index ? item.active : item.default" alt="">
      </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSwiper',
  components: {},
  props: {
    list: {
      type: Array,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    arrowVisible: {
      type: Boolean,
      default: false
    },
    arrowLeftImg: {},
    swiperItemSize: {
      type: String,
      default: '100% 500px' // 宽高
    },
    paginationVisible: {
      type: Boolean,
      default: true
    },
    paginationDirection: {
      type: String,
      default: 'row' // row || column
    },
    paginationPosition: {
      type: String,
      default: '' // 上 右 下 左
    },
    paginationPositionType: {
      type: String,
      default: 'center-bottom'
    },
    paginationItemSize: {
      type: String,
      default: '200px 150px' // 宽高
    },
    paginationItemMargin: {
      type: String,
      default: '0' // 外边距
    },
    paginationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      uid: '',
      swiper: null,
      currentIndex: 0,
      swiperOption: { // map轮播选项
        // slidesPerView: 'auto',
        autoplay: this.autoplay,
        // loop: true,
        navigation: {
          nextEl: `swiper-prev--${this.uid}`,
          prevEl: `swiper-next--${this.uid}`
        }
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // }
      }
    }
  },
  computed: {
    paginationStyle () {
      const arr = this.paginationPosition.split(' ')
      return {
        top: arr[0] || '',
        right: arr[1] || '',
        bottom: arr[2] || '',
        left: arr[3] || '',
        display: 'flex',
        'flex-direction': this.paginationDirection
      }
    },
    paginationItemStyle () {
      const arr = this.paginationItemSize.split(' ')
      return {
        width: arr[0] || '50px',
        height: arr[1] || '50px',
        margin: this.paginationItemMargin
      }
    },
    swiperItemStyle () {
      const arr = this.swiperItemSize.split(' ')
      return {
        width: arr[0] || '100%',
        height: arr[1] || 'auto'
      }
    }
  },
  mounted () {
    this.uid = Date.now()
    this.$nextTick(() => {
      this.swiper = this.$refs[this.uid].swiper
      this.$refs[this.uid].swiper.on('slideChange', () => {
        this.currentIndex = this.swiper.realIndex
      })
    })
  },
  methods: {
    changeCurrent (index) {
      console.log(index)
      this.swiper && this.swiper.slideTo(index)
    }
  }
}
</script>
<style scoped lang="scss">
.s-swiper {
  position: relative;
  color: #fff;
  text-align: center;
  width: 100%;

  .list {
    width: 100%;
    position: relative;

    .list-item {
      position: relative;

      img {
        width: 100%;
        display: block;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: pre-wrap;
        height: 100px;
        font-size: 26px;
        // background-image: url(../../../../assets/images/activity/commodity_label_bg.png);
        background-size: 100%;
        color: #C7D9FD;
        font-weight: 700;
      }
    }
  }
}
</style>
<style lang="scss">
.s-swiper-pagination{
  position: absolute;
  z-index: 1;
  &--left-top{
    top: 0;
    left: 0;
  }
  &--center-top{
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &--right-top{
    top: 0;
    right: 0;
  }
  &--left-middle{
    top: 0;
    left: 0;
  }
  &--center-middle{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &--right-middle{
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  &--left-bottom{
    bottom: 0;
    left: 0;
  }
  &--center-bottom{
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &--right-bottom{
    bottom: 0;
    right: 0;
  }
  .s-swiper-pagination-item{
    cursor: pointer;
  }
}
</style>
