import _mixin from './_mixin'
export default {
  name: 'SBackTop',
  cnName: '返回顶部',
  type: 'complex',
  draggable: true,
  // 属性相关
  props: {
    src: 'https://rescdn.ssjj.cn/website/2024/02/28/970e03c9eafa07d53946ac59d0d0cea7.png',
    width: '80px',
    height: ''
  },
  propsDesc: [{
    target: 'src',
    label: '图片地址'
  }, {
    target: 'width',
    label: '宽'
  }, {
    target: 'height',
    label: '高'
  }],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'fixed',
    left: '0',
    top: '0',
    display: 'inline-block'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
