import Vue from 'vue'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'

import pageBuilder from './modules/pageBuilder'

Vue.use(Vuex)
// const isProd = process.env.NODE_ENV === 'production'
export default new Vuex.Store({
  // plugins: isProd ? [] : [createLogger({ collapsed: false })],
  plugins: [],
  modules: {
    pageBuilder
  }
})
