import _mixin from './_mixin'
const huya = 'https://liveshare.huya.com/iframe/27498891'
const dy = 'https://open.douyu.com/tpl/h5/chain2/wooduan/7439056'
export default {
  name: 'SLive',
  cnName: 'Live 直播',
  type: 'complex',
  // 属性相关
  props: {
    liveWidth: '624px',
    liveHeight: '351px',
    channelList: [
      { link: huya, title: '虎牙直播', thumb: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map-thumb2.png?v=1666848470704' },
      { link: dy, title: '斗鱼直播', thumb: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/map-thumb3.png?v=1666848470704' }
    ]
  },
  propsDesc: [
    {
      target: 'liveWidth',
      label: '播放器宽度'
    },
    {
      target: 'liveHeight',
      label: '播放器高度'
    },
    {
      target: 'channelList',
      label: '频道列表',
      formType: 'list',
      listConfig: [
        {
          title: '频道名称',
          target: 'title'
        },
        {
          title: '直播链接',
          target: 'link'
        },
        {
          title: '按钮图',
          target: 'thumb'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '1440px',
    height: 'auto'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
