<template>
  <div class="buy-box">
    <div v-for="(item,index) in groupAward" :key="index"
         class="buy-item img-bg"
         :style="{backgroundImage: `url(${item.bg})`}">
      <div class="buy-desc">{{item.id}}</div>
      <div class="buy-desc">价格{{item.price}}</div>
      <div class="buy-desc">限购({{item.acquiredAmount}}/{{item.limitSingle}})</div>
      <div v-if="!item.acquired" class="btn-status btn-buy img-bg" :style="btnStyle" @click="openTip(item)">{{btnBuyText}}</div>
      <div v-else class="btn-status btn-already-buy img-bg" :style="btnStyle">{{btnAlreadyBuyText}}</div>
    </div>
    <!--奖励弹窗-->
    <AwardModel
      :modelShow.sync="awardModel.show"
      :modelType= 0
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardBgSize="modelBgSize"
      :awardTitle="awardTitle"
      :awardTitleSize="awardTitleSize"
      :awardOtherDesc="awardModel.type === 1 ? awardOtherDesc : ''"
      :awardBgImg="awardBoxBg"
      :awardBoxBgSize="awardBoxBgSize"
      :btnStatusOne="btnStatus"
      :awardBtnText="btnHappyText"
      :awardBtnColor="btnColor"
      :awardBtnSize="btnSize"
      :awardData="awardModel.award"
    >
    </AwardModel>
    <!--提示弹窗-->
    <TipModel
      :modelShow.sync="tipModel.show"
      :modelType="tipModel.type"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipBgSize="modelBgSize"
      :tipTitle="tipTitle"
      :tipTitleSize="tipTitleSize"
      :btnStatusOne="btnStatus"
      :tipBtnColor="btnColor"
      :tipBtnText="btnCloseText"
      :tipCancelText="btnCancelText"
      :tipConfirmText="btnConfirmText"
      :tipBtnSize="btnSize"
      :contentText="tipModel.text"
      :buyInfo = "tipModel.buyInfo"
      @openAwardModel="openAwardModel"
    >
    </TipModel>
  </div>
</template>

<script>
import TipModel from '@/components/BuilderComponents/Dialog/TipModel.vue'
import AwardModel from '@/components/BuilderComponents/Dialog/AwardModel.vue'

export default {
  name: 'SBuy',
  components: { AwardModel, TipModel },
  inject: {
    eventBus: {
      default: null
    }
  },
  props: {
    activityId: {
      type: String,
      default: ''
    },
    activityGroup: {
      type: Array,
      default: () => []
    },
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelBgSize: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    buyTitle: {
      type: String,
      default: ''
    },
    buyTitleSize: {
      type: String,
      default: '719px 39px'
    },
    btnStatus: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: '220px 66px'
    },
    btnBuyText: {
      type: String,
      default: ''
    },
    btnAlreadyBuyText: {
      type: String,
      default: ''
    },
    btnCancelText: {
      type: String,
      default: ''
    },
    btnConfirmText: {
      type: String,
      default: ''
    },
    btnHappyText: {
      type: String,
      default: ''
    },
    btnCloseText: {
      type: String,
      default: ''
    },
    awardTitle: {
      type: String,
      default: ''
    },
    awardTitleSize: {
      type: String,
      default: ''
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: String,
      default: ''
    },
    awardBoxBgSize: {
      type: String,
      default: ''
    },
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      groupAward: [],
      // 提示弹窗文案
      tipModel: {
        show: false,
        // 1: 打脸提示  2：购买二次确认提示
        type: 1,
        text: '',
        buyInfo: {}
      },
      // 奖励弹窗
      awardModel: {
        show: false,
        // 1: 不是实物  2: 是实物  3：谢谢参与
        type: 1,
        award: []
      }
    }
  },
  watch: {
    activityGroup: {
      handler: function (newVal) {
        this.groupAward = newVal
        this.getBuyStatus()
      },
      immediate: true
    },
    activityId: {
      handler: function () {
        this.getBuyStatus()
      },
      immediate: false
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    btnStyle () {
      const arr = this.btnSize.split(' ')
      const width = arr[0] || '220px'
      const height = arr[1] || '66px'
      return {
        width,
        height,
        color: `${this.btnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatus})`
      }
    }
  },
  mounted () {
    // 获取该活动是否是购买状态
    this.getBuyStatus()
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.getBuyStatus()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
  },
  methods: {
    // 获取当前活动的购买状态
    getBuyStatus () {
      // 获取购买信息
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        if (res.groups) {
          const newGroupAward = []
          this.groupAward.forEach((k) => {
            res.groups.forEach((item) => {
              if (Number(k.groupId) === Number(item.id)) {
                k.id = item.id
                k.price = item.price
                k.award = [{ image: item.icon, name: item.name, count: item.amount }]
                // 购买状态（true：已购买，不能再购买了）
                k.acquired = item.acquired
                // 已购买次数
                k.acquiredAmount = item.acquired_amount
                k.limitSingle = item.limit_single ? item.limit_single.split('|')[item.limit_single.split('|').length - 1] : 0
                newGroupAward.push(k)
              }
            })
          })
          this.groupAward = newGroupAward
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.type = 1
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 二次确认弹窗打开
    openTip (item) {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断点券是够足够，不用前端判断，后端处理
      // 二次确认打开
      this.tipModel.show = true
      this.tipModel.type = 2
      console.log('item', item)
      this.tipModel.buyInfo = item
    },
    // 购买并打开奖励弹窗
    openAwardModel (data) {
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/buy',
        method: 'get',
        params: { a_id: this.activityId, g_id: data.id }
      }).then((res) => {
        if (res.success) {
          // 购买成功
          this.awardModel.show = true
          this.awardModel.type = 1
          this.awardModel.award = data.award
          // 购买状态刷新
          this.getBuyStatus()
        } else {
          // 购买失败
          this.tipModel.show = true
          this.tipModel.type = 1
          this.tipModel.text = res.msg
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.type = 1
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 重置
    reset () {
      this.getBuyStatus()
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .buy-item{
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 700px;
    height: 200px;
    .buy-desc{
      font-size: 28px;
      font-weight: bold;
    }
  }
  .btn-status{
    font-size: 28px;
    font-weight: bold;
  }
  .btn-buy {
    cursor: pointer;
    &:hover{
      filter: brightness(120%);
    }
  }
  .btn-already-buy{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
</style>
