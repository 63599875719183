import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopicNewMode',
  cnName: '新模式',
  type: 'activity_topic',
  isTab: true,
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/35e13205f9d501d65993a1ee87054f13.jpeg',
  props: {
    ..._mixinWrap.props,
    smallSize: '220px 220px',
    bigSize: '930px 450px',
    list: [
      {
        small: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/aa2e04133adc162bf0d62cac97d1f540.png',
        big: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/88cd16ff3921942639e5ef183a321936.png'
      },
      {
        small: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/046fb2b2b333ba286149bf5dc3441a7d.png',
        big: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/21e615f6aa4b8ed9155bcd74b8daec24.png'
      },
      {
        small: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/0bfafa12ad97dba60ece2df3180a42eb.png',
        big: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/4d0c7fca70a4c857d1cc194b931bb28c.png'
      },
      {
        small: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/cdcaae79f188bbf3778281d51281957b.png',
        big: 'https://rescdn-crash.ssjj.cn/website/2023/05/16/054d9051ca6d9cd7662e202c03aa31cb.png'
      }
    ]
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '小图',
          target: 'small'
        },
        {
          title: '大图',
          target: 'big'
        }
      ]
    },
    {
      target: 'smallSize',
      label: '小图尺寸'
    },
    {
      target: 'bigSize',
      label: '大图尺寸'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
