import request from '@/utils/request'

export const getSourceList = (params) => {
  return request({
    url: '/api/home/nav',
    method: 'get',
    params
  })
}
export const addSource = (params) => {
  return request({
    url: '/api/home/nav',
    method: 'post',
    data: params
  })
}
export const delSource = (params) => {
  return request({
    url: '/api/home/nav',
    method: 'post',
    data: params
  })
}
