<template>
  <div class="part-center">
    <div class="render-header">
      <el-slider v-model="zoom"/>
    </div>
    <div class="render-body">
      <div class="content"
           id="pageContent"
           :style="{zoom: zoom/100}"
           tabindex="0"
           @keydown.delete="onDelComponent"
      >
        <div class="full-wrap" id="pageRenderParent">
          <PageRender id="page"
                      :request="request"
                      :data="page.tree"
                      :is-edit="page.isEdit"
                      :is-choose="page.isChoose"
                      :current-id="page.currentId"
          />
        </div>
      </div>
    </div>
    <div class="render-footer">
      <ControlBar></ControlBar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageRender from './PageRender/index.vue'
import ControlBar from './_components/ControlBar.vue'
import { getTreeNodeById } from '@/utils/tree'
import request from '@/utils/request'

let dx = 0
let dy = 0
export default {
  name: 'PartCenter',
  components: { PageRender, ControlBar },
  props: {
  },
  computed: {
    ...mapGetters(['page']),
    currentComponent () {
      return getTreeNodeById(this.page.tree, this.page.currentId)
    }
  },
  data () {
    return {
      zoom: 70,

      // drag config
      el: null,
      move: false,
      // 鼠标相对于推动元素的位置
      deltaLeft: 0,
      deltaTop: 0,

      posParent: null, // 向上查找第一个有定位的父级
      posParentLeft: 0,
      posParentTop: 0,
      posParentW: 0,
      posParentH: 0,

      childW: 0,
      childH: 0,

      dx: 0,
      dy: 0
    }
  },
  created () {

  },
  mounted () {
    this.initDrag()
  },
  methods: {
    request,
    initDrag () {
      const zoom = this.zoom / 100
      const content = document.querySelector('#pageContent')
      // 拖动开始事件，要绑定在被移动元素上
      // 当.is-draggable组件鼠标按下时 初始化子组件和父组件 位置&大小等信息
      content.addEventListener('mousedown', (e) => {
        this.el = e.target
        if (this.el.id) this.$store.commit('SET_CURRENT_ID', this.el.id)
        if (this.el.className.includes('is-draggable')) {
          this.childW = this.el.offsetWidth
          this.childH = this.el.offsetHeight

          this.posParent = this._findPosParent(this.el)
          this.posParentLeft = this.posParent.offsetLeft
          this.posParentTop = this.posParent.offsetTop
          this.posParentW = this.posParent.offsetWidth
          this.posParentH = this.posParent.offsetHeight
          console.log(`child: 宽${this.childW} 高${this.childH}`)
          console.log(`posParent: 左${this.posParentLeft} 上${this.posParentTop} 宽${this.posParentW} 高${this.posParentH}`)

          /*
           * @des deltaLeft 即移动过程中不变的值(鼠标在拖拽元素上的位置)
           */
          this.deltaLeft = e.clientX - this.el.offsetLeft * zoom
          this.deltaTop = e.clientY - this.el.offsetTop * zoom
          console.log(e.clientX, this.el.offsetLeft)
          console.log('delta:', this.deltaLeft, this.deltaTop)
          this.move = true
        }
      })

      // 移动触发事件要放在，区域控制元素上
      content.addEventListener('mousemove', (e) => {
        if (this.move) {
          const cx = e.clientX
          const cy = e.clientY
          /** 相减即可得到相对于父元素移动的位置 */
          dx = (cx - this.deltaLeft) / zoom
          dy = (cy - this.deltaTop) / zoom
          // dx = (cx - this.deltaLeft)
          // dy = (cy - this.deltaTop)

          /** 防止超出父元素范围 */
          if (dx < 0) dx = 0
          if (dy < 0) dy = 0
          if (dx > this.posParentW - this.childW) dx = (this.posParentW - this.childW)
          if (dy > this.posParentH - this.childH) dy = (this.posParentH - this.childH)
          // this.el.style.left = `${dx}px`
          // this.el.style.top = `${dy}px`
          console.log(dx)
          this.currentComponent.styles.left = `${dx.toFixed(0)}px`
          this.currentComponent.styles.top = `${dy.toFixed(0)}px`
        }
      })
      // 拖动结束触发要放在，区域控制元素上
      content.addEventListener('mouseleave', (e) => {
        this.endDrag()
        // console.log('mouseleave')
      })

      // 拖动结束触发要放在，区域控制元素上
      content.addEventListener('mouseup', (e) => {
        this.endDrag()
        // console.log('mouseup')
      })
    },
    endDrag () {
      if (!this.el) return
      this.move = false
    },
    _findPosParent (el) {
      if (el.nodeName === 'HTML') {
        return el
      }
      const posArr = ['relative', 'absolute', 'fixed', 'sticky']
      const parent = el.parentNode
      const pos = parent.style.position
      if (posArr.includes(pos)) {
        return parent
      } else {
        return this._findPosParent(parent)
      }
    },
    onDelComponent (e) {
      if (this.page.isEdit) {
        this.$confirm('确认删除?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('delComponent')
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.part-center{
  display: flex;
  overflow: auto;
  //flex: 1;
  height: 100%;
  flex-shrink: 0;
  padding: 0 10px;
  flex-direction: column;
  background-color: #efefef;
  max-width: calc(100vw - 210px);
  .render-header{

  }
  .render-body{
    flex: 1;
    overflow-y: hidden;
    .content{
      position: relative;
      transform: scale(1);
      width: 1920px;
      height: 1080px;
      //background-color: #fff;
      overflow-y: auto;
      background: url('@/assets/images/bg.jpeg');

      .full-wrap{
        overflow-y: overlay;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;

        &::-webkit-scrollbar
        {
          width:0;
        }
      }
    }
  }
  .render-footer{
    padding: 10px 0;
  }

}
</style>
