import _mixin from '../_mixin'

export default {
  name: 'SAwardComModel',
  cnName: '公共奖励弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/66ff37c9751d3b7b50f31fa4a5097b04.png',
      style: {
        width: '750px',
        height: '450px'
      }
    },
    modelClose: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/9d4318b1f4af8a822f0bda055005e4c7.png',
      style: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '-20px',
        right: '-30px',
        left: '-',
        bottom: '_',
        cursor: 'pointer'
      }
    },
    modelColor: '#C09248',
    awardTitle: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/eddd856a3fe7cf5bfaa08f333ad7fc5e.png',
      style: {
        width: '271px',
        height: '39px'
      }
    },
    awardOtherDesc: '请前往邮箱查收奖励',
    // 奖励框的背景和大小配置
    awardBoxBg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/208f60ae752f40ddbfeab59dd5b50fdf.png',
      style: {
        width: '151px',
        height: '126px'
      }
    },
    // 开心收下按钮
    btnHappy: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/05/18/fc38f8d82bb8022393c4fbfceaa21b6d.png',
      style: {
        width: '181px',
        height: '51px'
      }
    }
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '弹窗背景',
      formType: 'image'
    },
    {
      target: 'modelClose',
      label: '关闭icon',
      formType: 'image'
    },
    {
      target: 'modelColor',
      label: '弹窗文案颜色'
    },
    {
      target: 'awardTitle',
      label: '奖励标题',
      formType: 'image'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBoxBg',
      label: '奖励背景图',
      formType: 'image'
    },
    {
      target: 'btnHappy',
      label: '开心收下按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
