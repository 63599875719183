<template>
  <div class="table-list">
    <div class="table-box">
        <div class="select-bar mb16"
             v-if="selectable && selectArr.length>0">
          <el-row>
            <el-col :span="12">
              <i class="el-icon-warning color-blue"></i>
              已选择 <span class="color-red">{{selectArr.length}}</span> 条数据
            </el-col>
            <el-col :span="12"
                    class="text-right">
              <slot name="operationBtn"></slot>
              <el-button type="text"
                         class="ml10"
                         @click="cancelSelect"
                         :disabled="selectArr.length === 0">取消</el-button>
            </el-col>
          </el-row>
        </div>
        <div
          v-if="showTotalSize"
          class="color-666 mb16 fs-16"
        >
          总共{{ totalSize }}条数据
        </div>
        <el-table v-on="$listeners"
                  @selection-change="handleSelectionChange"
                  :highlight-current-row="true"
                  class="tableList mb24"
                  :data.sync="tableData"
                  v-loading="isLoading"
                  border
                  ref="table"
                  @cell-mouse-enter="handleMouseEnter"
                  @sort-change="sortChange">
          <el-table-column v-if="selectable"
                           type="selection"
                           width="55"></el-table-column>
          <el-table-column :label="orderLabel"
                           v-if="order"
                           width="60">
            <template slot-scope="scope">{{scope.$index + 1 + (page - 1) * size}}</template>
          </el-table-column>
          <slot></slot>
          <div slot="empty" class="text-center color-999">
            暂无数据
          </div>
        </el-table>
        <el-row v-show="totalPage > 1">
          <el-col :span="12">
            <p class="page-total-box fs-12"
               v-show="showTotalPage">
              共{{totalSize}}条，一页共展示
              <el-select v-model="size"
                         size="small"
                         class="wth80"
                         @change="changeSize">
                <el-option :label="10"
                           :value="10"></el-option>
                <el-option :label="20"
                           :value="20"></el-option>
                <el-option :label="30"
                           :value="30"></el-option>
                <el-option :label="50"
                           :value="50"></el-option>
              </el-select>
              条
            </p>
          </el-col>
          <el-col :span="12" :offset="showTotalPage ? 0 : 12" class="text-right">
            <el-pagination v-show="totalPage > 1"
                           :current-page.sync="page"
                           layout="prev, pager, next"
                           :page-count="totalPage"
                           @current-change="currentChange"></el-pagination>
          </el-col>
        </el-row>
      </div>
  </div>
</template>
<script>
import request from '@/utils/request'
export default {
  name: 'TableList',
  props: {
    keepAlive: {
      type: Boolean
    },
    nothing: {
      type: String
    },
    api: {
      type: String,
      required: true
    },
    params: {
      type: Object
    },
    isNoData: {
      type: Boolean
    },
    data: {
      type: Array
    },
    onUpdate: {
      type: Function
    },
    await: {
      type: Boolean,
      default: false
    },
    order: {
      type: Boolean,
      default: false
    },
    orderLabel: {
      type: String,
      default: '序号'
    },
    showTotalPage: {
      type: Boolean,
      default: false
    },
    showNoData: {
      // 展示无数据状态
      type: Boolean,
      default: true
    },
    selectable: {
      // 列表selection列
      type: Boolean,
      default: false
    },
    selectArr: {
      // selection绑定值
      type: Array
    },
    showTotalSize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      noData: true,
      tableData: [],
      totalPage: 1,
      totalSize: 0,
      page: 1,
      size: 10,
      isCreatedFetch: false // 是否通过created 获取数据
    }
  },
  watch: {
    noData (newVal, oldVal) {
      this.$emit('update:isNoData', newVal)
    },
    tableData (newVal, oldVal) {
      this.$emit('update:data', newVal)
    }
  },
  created () {
    this.fetchData(this.params, false)
    this.isCreatedFetch = true
  },
  activated () {
    if (this.isCreatedFetch) {
      this.isCreatedFetch = false
    } else if (this.keepAlive) {
      this.update()
    }
  },
  methods: {
    toggleRowExpansion (row) {
      this.$refs.table.toggleRowExpansion(this.tableData[row])
    },
    toggleRowSelection (row) {
      this.$refs.table.toggleRowSelection(row)
    },
    fetchData (params, isSearch) {
      this.isLoading = true
      if (params) {
        if (params.page) {
          this.page = params.page
        }
        if (params.size) {
          this.size = params.size
        }
      }
      return request({
        url: this.api,
        method: 'get',
        params: {
          ...this.params,
          ...params,
          page: this.page,
          page_size: this.size
        }
      })
        .then(data => {
          // 如果返回数据为条数为0 并且 当前页不是第一页 则向前请求一页
          if ((!data.items || data.items.length === 0) && data.page > 1) {
            console.warn('当前页无数据，向前请求一页')
            this.fetchData({ ...params, page: data.page - 1 }, isSearch)
            return
          }

          this.page = data.page

          this.tableData = data.items || []
          this.totalPage = data.total_page
          this.totalSize = data.total
          isSearch
            ? (this.noData = false)
            : this.tableData.length === 0
              ? (this.noData = true)
              : (this.noData = false)
          this.isLoading = false
          // this.onUpdate ? this.onUpdate() : null
          this.$nextTick(() => {
            this.$emit('list-done')
          })
        })
        .catch(() => {})
    },
    search (params) {
      this.page = 1
      this.fetchData(params || null, true)
    },
    changeSize () {
      // this.params.page = 1
      // this.params.size = this.size
      this.fetchData({ ...this.params, page: 1, size: this.size })
    },
    reload () {
      this.page = 1
      this.fetchData(null, false)
    },
    update () {
      return this.fetchData(this.params)
    },
    currentChange (currentPage) {
      this.page = currentPage
      this.fetchData(null)
      this.$emit('change-page-callback', currentPage)
    },
    handleSelectionChange (val) {
      this.$emit('section-change', val)
      this.$emit('update:selectArr', val)
    },
    handleMouseEnter (row) {
      this.$emit('mouse-enter', row)
    },
    getSearchParams () {
      return {
        ...this.params,
        page: this.page,
        size: this.size
      }
    },
    clearSelection () {
      this.$refs.table.clearSelection()
    },
    cancelSelect () {
      this.$refs.table.clearSelection()
    },
    sortChange (column, prop, order) {
      this.$emit('sort-change', column, prop, order)
    }
  }
}
</script>
<style lang="scss" type="text/scss">
.wth80 {
  width: 80px;
}
</style>
<style lang="scss" scoped>
.nothingOuterBox {
  padding: 100px 0px;
}
.select-bar {
  padding: 0 20px;
  height: 40px;
  background: #fffadc;
  line-height: 40px;
  color: #262626;
  i {
    margin-right: 10px;
  }
}
</style>
