import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopic2PvpAward',
  cnName: '奖励展示-轮播',
  type: 'activity_topic2',
  isTab: true,
  preview: '',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/77cea858f3d2d10614421ec0ac78d9c2.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    autoplay: false,
    itemBg: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/dfb410cbc0dca9458ea5b43a02b6dfcf.png',
    list: [
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/63f1c7668f9fbfc324a6c9690fadb10e.png',
        label: '暴戾甜心 夜色魅影',
        desc: '描述描述描述描述描述描述描述描述描述描述描述描述描述描述'
      },
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/fa3b0117f232a91599f9884449ffe875.png',
        label: 'RPK 夜色魅影',
        desc: '描述描述描述描述描述描述描述描述描述描述描述描述描述描述'
      },
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/e751d18ce4dc0c97c210c7ed6d2a7088.png',
        label: 'Vector绝对零度',
        desc: '描述描述描述描述描述描述描述描述描述描述描述描述描述描述'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/f80dd141367be97e8f330be8ec973682.png',
        label: 'M249绝对零度',
        desc: '描述描述描述描述描述描述描述描述描述描述描述描述描述描述'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png?',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png??',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png???',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png????',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png?????',
        label: '手斧绝对零度',
        desc: ''
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png???????',
        label: '手斧绝对零度',
        desc: ''
      }
    ],
    prevImg: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/e4abe26c47da4e34b59a2d7222f7f09c.png',
    nextImg: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/0b2553ee92c9b7c5afbcc581bc643d28.png',
    thumbItemStyle: '{}',
    thumbItemActiveStyle: '{}'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'autoplay',
      label: '自动播放',
      formType: 'switch'
    },
    {
      target: 'itemBg',
      label: '子项背景'
    },
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '名称',
          target: 'label'
        },
        {
          title: '描述',
          target: 'desc'
        }
      ]
    },
    {
      target: 'prevImg',
      label: '上一页按钮图片'
    },
    {
      target: 'nextImg',
      label: '下一页按钮图片'
    },
    // {
    //   target: 'thumbWrapStyle',
    //   label: '标签容器样式',
    //   formType: 'textarea'
    // },
    {
      target: 'thumbItemStyle',
      label: '标签样式',
      formType: 'textarea'
    },
    {
      target: 'thumbItemActiveStyle',
      label: '标签选中样式',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
