<!--战斗补给助力-->
<template>
  <div class="s-act" :style="{'background-image': `url(${mainBg.src})`}">
    <img :src="btnReceive.src" v-if="awardList.some(item => item.status === 2 )" :style="btnReceive.style" @click="receiveAll" alt="" class="btn-receive-all hv-highlight"/>
<!--    <img :src="btnReceiveDisabled.src" :style="btnReceive.style" alt="" class="btn-receive-all"/>-->
    <div class="left-box">
      <div class="item" :style="{color: textColor}" v-for="(item, index) in awardList.filter((item,index) => index <=6 )" :key="index" @click="receiveAward(item)">
        <div class="info">
          <div class="count">{{item.name}}</div>
          <img :src="item.materials[0].icon" alt="" class="award"/>
          <div class="name">{{item.materials[0].name}} x {{item.materials[0].amount}}</div>
        </div>
        <div v-if="[0,1,4].indexOf(item.status) > -1" class="lock"><i class="el-icon-lock"></i></div>
        <div v-else-if="item.status === 3" class="finish" :style="{color: textColor}"><i class="el-icon-check"></i></div>
      </div>
    </div>
    <div class="right-box">
      <div class="item" :style="{color: textColor}" v-for="(item, index) in awardList.filter((item,index) => index > 6 )" :key="index" @click="receiveAward(item)">
        <div class="info">
          <div class="count">{{item.name}}</div>
          <img :src="item.materials[0].icon" alt="" class="award"/>
          <div class="name">{{item.materials[0].name}} x {{item.materials[0].amount}}</div>
        </div>
        <div v-if="[0,1,4].indexOf(item.status) > -1" class="lock"><i class="el-icon-lock"></i></div>
        <div v-else-if="item.status === 3" class="finish" :style="{color: textColor}"><i class="el-icon-check"></i></div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { getLimit, getPosition, getSize } from '@/components/BuilderComponents/utils'
export default {
  name: 'SActBattleSupply',
  components: {
  },
  props: {
    /* 公共配置 */
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    mainBg: {
      type: Object,
      required: () => {
        return {
          src: '',
          style: {}
        }
      }
    },
    btnReceive: {
      type: Object,
      default: () => {
        return {
          src: ''
        }
      }
    },
    textColor: {
      type: String,
      default: '#fff'
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      // 后端数据
      awardList: [],
      postForm: {
        g_id: '',
        a_id: ''
      }
    }
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.getAct)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    $checkLogin()
    this.getAct()
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getAct()
        }
      },
      immediate: false
    }
  },
  methods: {
    getSize,
    getPosition,
    getLimit,
    // 获取初始化状态
    getAct () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((data) => {
        console.log('----- 战斗补给列表 -----', data)
        this.awardList = data.groups || []
        // console.log(this.awardList)
        // this.$forceUpdate()

        // 检查是否处于活动时间
        // console.log('> 限时补给解锁')
        this.$MainWrap.checkStartEnd(data.start_at, data.end_at)
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    // 领取奖励
    receiveAward (item) {
      if (item.status !== 2) return
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!window.$checkLogin()) return
      // // 判断当前状态
      // if (item.status !== 2) {
      //   return
      // }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: {
          g_id: item.id,
          a_id: this.activityId
        }
      }).then((res) => {
        console.log(res)
        this.$MainWrap.openReceiveModal(item.materials)
        this.getAct()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    receiveAll () {
      if (!window.$checkLogin()) return
      this.requestAPI({
        url: '/v1/uni/batchReceive',
        method: 'get',
        params: {
          a_id: this.activityId
        }
      }).then((data) => {
        console.log(data)
        const list = []
        if (data && data.length) {
          data.forEach(item => {
            list.push(item.gift.materials[0])
          })
        }
        this.$MainWrap.openReceiveModal(list)
        this.getAct()
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 重置
    reset () {
      // this.getAct()
      this.awardList.forEach((item) => {
        item.status = 1
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> SActUnlockLimitSupply  取消登录监听')
      this.eventBus.off('loginSuccess', this.getAct)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 内容样式
.s-act {
  width: 1920px;
  height: 966px;
  background-size: 100% 100%;
  padding-top: 136px;
  .btn-receive-all{
    position: absolute;
  }
  .left-box, .right-box{
    position: absolute;
    top: 496px;
    height: 334px;
    display: flex;
    justify-content: space-between;
    .item{
      position: relative;
      height: 334px;
      padding: 30px 10px 10px;
      .info{
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: .3s;
        .count{
          font-size: 22px;
          text-align: center;
        }
        .award{
          width: 85px;
          height: 150px;
          object-fit: contain;
        }
        .name{
          height: 60px;
          font-size: 18px;
          text-align: center;
        }
        &:hover{
          cursor: pointer;
          filter: brightness(1.1);
        }
      }
      .lock, .finish{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        font-weight: 700;
        color: #b2b2b2;
      }
      .lock{
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .left-box{
    left: 108px;
    width: 770px;
    .item{
      width: 105px;
    }
  }
  .right-box{
    left: 1055px;
    width: 752px;
    .item{
      width: 175px;
    }
  }
}
</style>
