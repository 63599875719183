<template>
  <transition name="slide-fade">
    <div class="s-anchor-nav"
         v-show="visible">
      <div class="s-anchor-nav-item"
           v-for="(item, index) in list"
           :key="index"
           @click="handleClick(item, index)"
           @mouseenter="hoverIndex = index"
           @mouseout="hoverIndex = null"
           :style="{
          ...itemStyle,
          margin: itemMargin,
          'background-image': `url(${(active === index || hoverIndex === index) ? item.active : item.default})`
        }">
        <!--            <img v-show="active!==index" :src="item.default" :style="itemStyle" alt="">-->
        <!--            <img v-show="active===index" :src="item.active" :style="itemStyle" alt="">-->
      </div>
      <img class="s-anchor-top"
           v-if="backTopImg"
           :style="{
            ...backTopImg.style,
            margin: backTopImgMargin
           } "
          :src="backTopImg.src" @click="scrollTo(0)"/>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SAnchorNav',
  props: {
    list: {
      type: Array,
      required: true
    },
    itemSize: {
      type: String,
      default: '300px 40px'
    },
    itemMargin: {
      type: String,
      default: '_ _ 5px _'
    },
    backTopImg: {
      type: Object
    },
    backTopImgMargin: {
      type: String,
      default: '20px _ _ _'
    }
  },
  data () {
    return {
      visible: false,
      active: 0,
      hoverIndex: null,
      container: null,
      targetOffsetTopList: []
    }
  },
  computed: {
    itemStyle () {
      const arr = this.itemSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || '',
        margin: this.itemMargin
      }
    }
  },
  mounted () {
    this.container = document.querySelector('#pageRenderParent') || window
    // this.container.addEventListener('scroll', this._debounce(this.onScroll, 50))
    this.container.addEventListener('scroll', this.onScroll)
    // window.addEventListener('resize', this._throttle(this.setTopList.bind(this, true), 1000))
  },
  methods: {
    setTopList (reset = false) {
      // console.log(reset)
      if (reset || !this.targetOffsetTopList.length) {
        // console.log(1111)
        if (this.list.some(item => !item.targetId)) { // 如果锚点ID未完整填入
          console.warn('[SAnchorNav]:请完善锚点ID')
          return
        }
        this.targetOffsetTopList = this.list.map(item => {
          const dom = document.querySelector('#' + item.targetId)
          return document.querySelector('#' + item.targetId).offsetTop
        })
      }
    },
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick (item, index) {
      // this.active = index
      this.setTopList(true)
      // console.log(this.container)
      // console.log(item.targetId)
      // const target = document.querySelector('#' + item.targetId)
      // console.log(target.offsetTop)
      // this.container.scrollTo({
      //   top: this.targetOffsetTopList[index],
      //   behavior: 'smooth'
      // })
      this.scrollTo(this.targetOffsetTopList[index])
    },
    scrollTo (top) {
      this.container.scrollTo({
        top,
        behavior: 'smooth'
      })
    },
    onScroll (e) {
      this.setTopList()
      const rangeList = []
      this.targetOffsetTopList.forEach((item, index) => {
        rangeList.push([item, this.targetOffsetTopList[index + 1] || +Infinity])
      })
      const scrollTop = this._getScrollTop(e.target)
      // console.log(scrollTop)
      if (scrollTop >= 800) {
        this.visible = true
      } else {
        this.visible = false
      }
      let getIt = false
      // console.log('====')
      rangeList.forEach((item, index) => {
        // console.log(scrollTop, item)
        if (getIt) return
        const tolerance = 200 // 误差（缩放情况下）
        if (item[0] <= scrollTop + tolerance && scrollTop + tolerance < item[1]) {
          this.active = index
          getIt = true
        } else {
          this.active = -1
        }
      })
    },
    _getScrollTop (e) {
      if (e.scrollTop !== undefined) {
        return e.scrollTop
      } else if (e.document) {
        return (
          e.document.documentElement.scrollTop ||
          window.pageYOffset ||
          e.document.body.scrollTop ||
          0
        )
      }
      return e.documentElement.scrollTop || e.body.scrollTop || 0
    },
    _debounce (fn, time) {
      let timeout = null
      return function () {
        const _arguments = arguments
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          timeout = null
          fn.apply(this, _arguments)
        }, time)
      }
    },
    // 节流方法
    _throttle (fn, time) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, time)
      }
    }

  },
  beforeDestroy () {
    this.container.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss" scoped>
.s-anchor-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .s-anchor-nav-item {
    transition: .3s;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      //filter: brightness(120%);
    }

    //img{
    //  transition: .3s;
    //  display: block;
    //}
  }

  .s-anchor-top {
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: .3s;
    &:hover {
      filter: brightness(110%);
    }
  }
}
</style>
