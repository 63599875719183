import _mixin from '../_mixin'

export default {
  name: 'SOfficialActiveActivityPayNew',
  cnName: '官服活跃活动[付费]-顺序修改',
  type: 'activity_other',
  preview: 'https://rescdn.ssjj.cn/website/2024/03/13/4a0ab36daf99bbcfb7efe4904dacce7c.png',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/e88f99d0fe264423e55add3e91de54c9.jpg'
    },
    actKeyName: '怒鳞',
    // ===== 活动 ID 配置 =====
    activityId1: '5MTNWZA65MI22ZZCSHLZ374Q4U',
    activityId2: 'LJNXDQJU7XRH6XFATC56D7ORPI',
    activityId3: 'QZBXYBZQXBYRGDRL3IBH53CKOM',
    activityId4: 'TFBBIHFQWOBMKAPJXGC3W7QTGU',
    activityId5: 'RSAQRBEQILHFZ5DVOLLXGZT6RQ',
    // ===== text color =====
    textColorLevel1: '#31303F',
    textColorLevel2: '#FFF',
    textColorLevel3: '#A7ABE3',
    textColorActive: '#FFD387',
    miniBtnColor: '#EA0037',

    // ====== 购买按钮 ======
    shopList: [
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/03/13/c86d4236cf69cd988d1b149d8edfacc5.png',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/03/14/356b8e82d4c2d057b64d019e5c0fa644.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/03/13/37a7c9aa4597488ce6ae9d09d6b48b6f.png',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/03/14/356b8e82d4c2d057b64d019e5c0fa644.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/03/13/77c1131f76c055bfb28d1ac41b4c2818.png',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/03/14/356b8e82d4c2d057b64d019e5c0fa644.png'
      },
      {
        awardImg: 'https://rescdn.ssjj.cn/website/2024/03/13/ae4a54fcbcbcc8c8873cd6f3e773b79b.png',
        btnImg: 'https://rescdn.ssjj.cn/website/2024/03/14/356b8e82d4c2d057b64d019e5c0fa644.png'
      }
    ],
    // ===== 领取按钮 =====
    // 未开始按钮
    receiveBtn0: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/f942d95bb979fd7d9faf2c044cd9291b.png'
    },
    // 未完成按钮
    receiveBtn1: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/153b0da143d99b23d5490a5bfc14f890.png'
    },
    // 领取按钮
    receiveBtn2: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/37d81fa125eece38b62fb5ae1f00a5ce.png'
    },
    // 已领取按钮
    receiveBtn3: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/155d52cefe01c1cca59c97efdcb0c9b3.png'
    },
    // 已过期按钮
    receiveBtn4: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/14/9a8c37e833af391932794e65869b5909.png'
    },
    // ===== 抽奖按钮 =====
    drawActiveItem: '1,2',
    drawBgColor: '#C4CCE6,#E8E9EC',
    drawBgColorActive: '#FFBE57,#FFE8BB',
    // 抽一次
    drawBtn1: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/13/551adb03e93b12fb49027c0680bf3471.png'
    },
    // 抽10次
    drawBtn10: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/13/557f91b2696eaf9764488d9ffe20dd57.png'
    },

    // 弹窗相关
    modalBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/13/40593dac29a781cdd8dcdf1018428a3a.png',
      style: {
        width: '750px',
        height: '650px'
      }
    },
    modalCloseIcon: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/13/f76e2285005d4f4cdc20b79f15a87f5b.png',
      style: {
        width: '54px',
        height: '42px',
        right: '-60px',
        top: '0px'
      }
    },
    modalTitleStyle: '{"color": "#A81548", "opacity": "0.8", "font-weight": "700"}',
    modalTextStyle: '{"color": "#31303F"}',
    modalConfirmBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/15/99b4684fb700b34aa5da97b1024be328.png)","color": "#FFF", "font-weight": "700"}',
    modalCancelBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/15/b4c489022fc32a83793352f2e4d29c78.png)","color": "#FFF", "font-weight": "700"}'
  },
  propsDesc: [
    {
      target: 'bg',
      label: '背景图',
      formType: 'image'
    },
    {
      target: 'actKeyName',
      label: '积分代称'
    },
    {
      target: '=== 活动 ID 配置 ===',
      formType: 'divider'
    },
    {
      target: 'activityId1',
      label: '补给站'
    },
    {
      target: 'activityId2',
      label: '登录领'
    },
    {
      target: 'activityId3',
      label: '免费抽奖'
    },
    {
      target: 'activityId4',
      label: '任务领取'
    },
    {
      target: 'activityId5',
      label: '邀请助力'
    },
    {
      target: '---公共文本颜色---',
      formType: 'divider'
    },
    {
      target: 'textColorLevel1',
      label: '一级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel2',
      label: '二级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel3',
      label: '三级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorActive',
      label: '活跃文本颜色',
      formType: 'color'
    },
    {
      target: 'miniBtnColor',
      label: '小按钮颜色',
      formType: 'color'
    },

    {
      target: '---补给商店列表---',
      formType: 'divider'
    },
    {
      target: 'shopList',
      label: '购买物品列表',
      formType: 'list',
      listConfig: [
        {
          title: '物品图',
          target: 'awardImg'
        },
        {
          title: '按钮图',
          target: 'btnImg'
        }
      ]
    },
    {
      target: '--抽奖相关--',
      formType: 'divider'
    },
    {
      target: 'drawActiveItem',
      label: '高亮显示项'
    },
    {
      target: 'drawBgColor',
      label: '默认背景过渡色'
    },
    {
      target: 'drawBgColorActive',
      label: '高亮背景过渡色'
    },
    {
      target: 'drawBtn1',
      label: '按钮抽1次',
      formType: 'image'
    },
    {
      target: 'drawBtn10',
      label: '按钮抽10次',
      formType: 'image'
    },
    {
      target: '-----领取按钮-----',
      formType: 'divider'
    },
    {
      target: 'receiveBtn0',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn1',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn2',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn3',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn4',
      label: '已过期按钮',
      formType: 'image'
    },

    {
      target: '===== 公共弹窗 ======',
      formType: 'divider'
    },
    {
      target: 'modalBg',
      label: '弹窗背景图',
      formType: 'image'
    },
    {
      target: 'modalCloseIcon',
      label: '关闭按钮',
      formType: 'image'
    },
    {
      target: 'modalTitleStyle',
      label: '标题样式',
      formType: 'textarea'
    },
    {
      target: 'modalTextStyle',
      label: '文本样式',
      formType: 'textarea'
    },
    {
      target: 'modalConfirmBtnStyle',
      label: '确认按钮样式',
      formType: 'textarea'
    },
    {
      target: 'modalCancelBtnStyle',
      label: '取消按钮样式',
      formType: 'textarea'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: [
    // {
    //   id: genId() + '_BaseGroup_0',
    //   ...BaseGroup
    // },
    // {
    //   id: genId() + '_BaseGroup_1',
    //   ...BaseGroup
    // }
  ]
}
