import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JsonViewer from 'vue-json-viewer'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'

// import SSJJ2UI from 'ssjj2-ui'
// import 'ssjj2-ui/lib/theme-chalk/index.css'
import './assets/style/normalize.css'
import './assets/style/common.scss'
import components from '@/components'

Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */)
Vue.use(ElementUI)
// Vue.use(SSJJ2UI)
Vue.use(JsonViewer)
Vue.use(VueClipboard)
Vue.config.productionTip = false

components.forEach(item => {
  Vue.component(item.name, item)
})

router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title;
  // }
  if (to.path) { // 加入百度统计
    // console.log(to)
    if (window._hmt) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
