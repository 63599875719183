import _mixin from '../_mixin'

export default {
  name: 'STopicFoot',
  cnName: '公共底部',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/31/b3137408d9f5fabe9266f5000869b67a.jpeg',
  // 属性相关
  props: {
    defaultBg: {
      src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/footbg.46b235f4.jpg',
      style: {
        width: '1920px',
        height: '220px'
      }
    }
  },
  propsDesc: [
    {
      target: 'defaultBg',
      label: '背景图',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
