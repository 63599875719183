<template>
  <div v-if="videoModelShow" class="lay">
    <div class="model" :style="modelStyle">
      <div class="close rotate img-bg" :style="closeStyle" @click="close"></div>
      <video class="video-box" controls autoplay :src="videoLink"></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SVideoModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    modelClose: {
      type: String,
      default: ''
    },
    modelSize: {
      type: String,
      default: ''
    },
    videoLink: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      videoModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.videoModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.modelSize.split(' ')
      const width = arr[0] || '1080px'
      const height = arr[1] || '607px'
      return {
        width,
        height
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.videoModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.videoModelShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 88px;
    height: 80px;
    position: absolute;
    right: -70px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .rotate:hover {
    animation: rotate 0.8s ease 0s 1;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .video-box{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
