import _mixin from './_mixin'

export default {
  name: 'SReceive',
  cnName: '领取奖励',
  type: 'complex',
  needLogin: true,
  // 属性相关
  props: {
    activityId: 'EKX47UL6Z43JKQEUQ2QLM55LEM',
    activityGroup: [
      { groupId: '1935', condition: '是否创角', bg: 'https://rescdn-crash.ssjj.cn/website/2023/03/27/8fd08bb2746986953a2036f63067b1c0.png' },
      { groupId: '1936', condition: '是否有登录', bg: 'https://rescdn-crash.ssjj.cn/website/2023/03/27/8fd08bb2746986953a2036f63067b1c0.png' },
      { groupId: '1937', condition: '是否创角', bg: 'https://rescdn-crash.ssjj.cn/website/2023/03/27/8fd08bb2746986953a2036f63067b1c0.png' }
    ],
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelBgSize: '750px 450px',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    awardTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/dd63755a299381588c4e41b75541c1d4.png',
    awardTitleSize: '446px 29px',
    awardOtherDesc: '请前往邮箱查收奖励',
    awardBgImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/b3d2ea367a040f0c4f8791b415007fc4.png',
    awardBoxBgSize: '201px 132px',
    btnStatus: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    btnColor: '#FFFFFF',
    btnSize: '220px 66px',
    btnNoBegin: '未开始',
    btnUnfinishedText: '未完成',
    btnReceiveText: '领取',
    btnReceivedText: '已领取',
    btnExpireText: '已过期',
    btnHappyText: '开心收下',
    btnCloseText: '关闭',
    /* 提示弹窗配置 */
    tipTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/93eceb592677154dfb973142578014a7.png',
    tipTitleSize: '419px 29px'
  },
  propsDesc: [
    {
      target: '领取配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动地址id'
    },
    {
      target: 'activityGroup',
      label: '活动礼包配置',
      formType: 'list',
      listConfig: [
        {
          title: '礼包id',
          target: 'groupId'
        },
        {
          title: '礼包条件',
          target: 'condition'
        },
        {
          title: '任务背景',
          target: 'bg'
        }
      ]
    },
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelBgSize',
      label: '公共弹窗背景尺寸'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'awardTitle',
      label: '奖励弹窗标题'
    },
    {
      target: 'awardTitleSize',
      label: '奖励标题尺寸',
      description: '宽 高'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗副标题'
    },
    {
      target: 'awardBgImg',
      label: '奖励背景icon'
    },
    {
      target: 'awardBoxBgSize',
      label: '奖励背景icon尺寸'
    },
    {
      target: 'btnStatus',
      label: '奖励按钮背景'
    },
    {
      target: 'btnColor',
      label: '按钮文案颜色'
    },
    {
      target: 'btnSize',
      label: '奖励按钮大小'
    },
    {
      target: 'btnNoBegin',
      label: '未开始文案'
    },
    {
      target: 'btnUnfinishedText',
      label: '未完成文案'
    },
    {
      target: 'btnReceiveText',
      label: '领取文案'
    },
    {
      target: 'btnReceivedText',
      label: '已领取文案'
    },
    {
      target: 'btnExpireText',
      label: '已过期文案'
    },
    {
      target: 'btnHappyText',
      label: '开心收下文案'
    },
    /* 提示弹窗配置 */
    {
      target: '提示弹窗配置',
      formType: 'divider'
    },
    {
      target: 'tipTitle',
      label: '提示弹窗标题'
    },
    {
      target: 'tipTitleSize',
      label: '提示标题尺寸',
      description: '宽 高'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
