<template>
  <div class="lottery">
    <div class="top">
      <div v-for="(item,index) in lotteryAwards.slice(0,3)" :key="index" class="one-three-box">
        <img :src="item.icon" alt="" :class="[index === lotteryId ? 'lottery-select' : '']"/>
      </div>
    </div>
    <div class="middle">
      <div v-for="(item,index) in lotteryAwards.slice(3,4)" :key="index+3" class="four">
        <img :src="item.icon" alt="" :class="[index+3 === lotteryId ? 'lottery-select' : '']"/>
      </div>
      <!--抽奖按钮-start-->
      <div :class="['lottery-box',lotteryNum === 0 ? 'lottery-box-gray' : 'lottery-box-light']" @click="lotteryFun">
        <img :src="lotteryImg" alt="" class="lottery-img"/>
        <div class="lottery-num">{{lotteryNum}}</div>
      </div>
      <!--抽奖按钮-end-->
      <div v-for="(item,index) in lotteryAwards.slice(4,5)" :key="index+4" class="five">
        <img :src="item.icon" alt="" :class="[index+4 === lotteryId ? 'lottery-select' : '']"/>
      </div>
    </div>
    <div class="bottom">
      <div v-for="(item,index) in lotteryAwards.slice(5,8)" :key="index+5" class="six-eight">
        <img :src="item.icon" alt="" :class="[index+5 === lotteryId ? 'lottery-select' : '']"/>
      </div>
    </div>
    <!--提示弹窗-->
    <TipModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipBgSize="modelBgSize"
      :tipTitle="tipTitle"
      :tipTitleSize="tipTitleSize"
      :btnStatusOne="btnStatus"
      :tipBtnColor="btnTextColor"
      :tipBtnText="btnCloseText"
      :tipBtnSize="btnSize"
      :contentText="tipModel.text"
    >
    </TipModel>
    <!--奖励弹窗-->
    <AwardModel
      :modelShow.sync="awardModel.show"
      :modelType="awardModel.type === 2 ? 3 : 0 "
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardBgSize="modelBgSize"
      :awardTitle="awardTitle"
      :awardTitleSize="awardTitleSize"
      :awardOtherDesc="awardModel.type === 1 ? awardOtherDesc : ''"
      :awardBgImg="awardBoxBg"
      :awardBoxBgSize="awardBoxBgSize"
      :btnStatusOne="btnStatus"
      :awardBtnText="btnText"
      :awardBtnColor="btnTextColor"
      :awardBtnSize="btnSize"
      :awardData="awardModel.award"
      @openAddress="openAddress"
    >
    </AwardModel>
    <!--填写地址弹窗-->
    <AddressModel
      :modelShow.sync="addressModel.show"
      :modelBg="addressBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :modelBgSize="addressBgSize"
      :addressTitle="addressTitle"
      :addressTitleSize="addressTitleSize"
      :btnStatus="btnStatus"
      :btnColor="btnTextColor"
      :btnSize="btnSize"
      :btnSubmitText="btnSubmitText"
      :btnReturnText="btnReturnText"
      :btnConfirmText="btnConfirmText"
      :addressStatus="addressModel.addressStatus"
      :addressFormData="addressModel.addressFormData"
      @saveAddress="saveAddress"
    >
    </AddressModel>
  </div>
</template>

<script>
import TipModel from '@/components/BuilderComponents/Dialog/TipModel.vue'
import AwardModel from '@/components/BuilderComponents/Dialog/AwardModel.vue'
import AddressModel from '@/components/BuilderComponents/Dialog/AddressModel.vue'

export default {
  name: 'SLottery',
  components: { TipModel, AwardModel, AddressModel },
  inject: {
    eventBus: {
      default: null
    }
  },
  props: {
    request: {
      type: Function
    },
    /* 抽奖页配置 */
    activityId: {
      type: String,
      default: ''
    },
    lotteryImg: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    },
    /* 公共弹窗配置 */
    modelBg: {
      type: String,
      default: ''
    },
    modelBgSize: {
      type: String,
      default: ''
    },
    modelClose: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: ''
    },
    btnStatus: {
      type: String,
      default: ''
    },
    btnTextColor: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: ''
    },
    btnCloseText: {
      type: String,
      default: ''
    },
    btnHappyText: {
      type: String,
      default: ''
    },
    btnAddressText: {
      type: String,
      default: ''
    },
    btnSubmitText: {
      type: String,
      default: ''
    },
    btnReturnText: {
      type: String,
      default: ''
    },
    btnConfirmText: {
      type: String,
      default: ''
    },
    /* 奖励弹窗配置 */
    awardTitle: {
      type: String,
      default: ''
    },
    awardTitleSize: {
      type: String,
      default: ''
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: String,
      default: ''
    },
    awardBoxBgSize: {
      type: String,
      default: ''
    },
    /* 提示弹窗配置 */
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: ''
    },
    /* 填写地址弹窗配置 */
    addressBg: {
      type: String,
      default: ''
    },
    addressBgSize: {
      type: String,
      default: ''
    },
    addressTitle: {
      type: String,
      default: ''
    },
    addressTitleSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 总积分
      integralAll: 0,
      // 抽奖消耗的积分
      lotteryPrice: 1,
      // 所有物品的id
      lotteryItemIdMap: {},
      // 所有奖励
      lotteryAwards: [],
      // 选中的奖励
      lotteryId: 0,
      // 真正抽中的奖励
      realLotteryId: 0,
      // 抽奖防抖
      lotteryFlag: true,
      // 打脸提示弹窗
      tipModel: {
        show: false,
        text: ''
      },
      // 奖励弹窗
      awardModel: {
        show: false,
        // 1: 不是实物  2: 是实物  3：谢谢参与
        type: 1,
        award: []
      },
      // 地址弹窗
      addressModel: {
        show: false,
        // 0:输入态 1：锁定态 2：终态
        addressStatus: 0,
        // 地址填写弹窗
        addressFormData: {
          name: '',
          phone: '',
          address: ''
        }
      }
    }
  },
  watch: {
    // 开心收下后，刷新页面数据
    'awardModel.show' (newVal) {
      if (!newVal) {
        // 获取当前用户活动总积分
        this.integralInfo()
        // 获取当前活动数据
        this.activityInfo()
      }
    },
    activityId: {
      handler: function () {
        // 获取当前用户活动总积分
        this.integralInfo()
        // 获取当前活动数据
        this.activityInfo()
      },
      immediate: false
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    // 剩余抽奖次数
    lotteryNum () {
      return this.integralAll === 0 ? 0 : Math.floor(this.integralAll / this.lotteryPrice)
    },
    // 奖励按钮类型
    btnText () {
      if (this.awardModel.type === 1) {
        return this.btnHappyText
      } else if (this.awardModel.type === 2) {
        return this.btnAddressText
      } else {
        return this.btnCloseText
      }
    }
  },
  mounted () {
    // 获取当前活动数据
    this.activityInfo()
    // 获取当前用户活动总积分
    this.integralInfo()
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        // 获取当前活动数据
        this.activityInfo()
        // 获取当前用户活动总积分
        this.integralInfo()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
  },
  methods: {
    openAddress (flag) {
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/address',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.addressModel.show = flag
        if (res.mobile && res.address) {
          this.addressModel.addressStatus = 2
          this.addressModel.addressFormData.name = res.real_name
          this.addressModel.addressFormData.phone = res.mobile
          this.addressModel.addressFormData.address = res.address
        } else {
          this.addressModel.addressStatus = 0
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 保存地址
    saveAddress (formData) {
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/address',
        method: 'post',
        data: { a_id: this.activityId, mobile: formData.phone, real_name: formData.name, address: formData.address }
      }).then((res) => {
        console.log('地址填写保存成功')
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 获取当前用户活动总积分
    integralInfo () {
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      this.requestAPI({
        url: '/v1/uni/integral',
        method: 'get',
        params: { a_id: this.activityId, history: false }
      }).then((res) => {
        this.integralAll = res
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 获取当前活动信息
    activityInfo () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.lotteryPrice = res.price
        this.lotteryAwards = res.groups ? res.groups : []
        res.groups.forEach((item, index) => {
          this.lotteryItemIdMap[index] = item.id
        })
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 点击抽奖
    lotteryFun () {
      if (this.lotteryNum === 0) return
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      if (!this.lotteryFlag) {
        return
      }
      this.lotteryFlag = false
      this.requestAPI({
        url: '/v1/uni/lottery',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.realLotteryId = res.group_id
        // 开启动画
        this.moveLottery(res)
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 开启抽奖动画
    moveLottery (res) {
      const indexArr = [0, 1, 2, 4, 7, 6, 5, 3]
      // 控制转圈动画
      let count = 0
      // 控制转圈次数
      let time = 0
      const timer = setInterval(() => {
        this.lotteryId = indexArr[count % 8]
        count++
        // 判断当前转到的id是否与后台传过来的id相等
        if (this.lotteryItemIdMap[this.lotteryId] === this.realLotteryId) {
          time++
          if (time >= 5) {
            clearInterval(timer)
            // 允许下次抽奖了
            this.lotteryFlag = true
            // 抽奖完之后出现的弹窗
            this.awardModel.show = true
            this.awardModel.award = [{ image: res.icon, name: res.name }]
            if (res.materials && res.materials.length > 0) {
              // 展示抽中的奖品,判断是否是实物，如果是，则需要填写收货地址
              const flag = res.materials.some((item) => {
                return item.type === 2
              })
              if (flag) {
                // 实物展示和地址填写
                this.awardModel.type = 2
              } else {
                // 不是实物奖励，直接弹奖励弹窗，开心收下按钮
                this.awardModel.type = 1
              }
            } else {
              // 谢谢参与
              this.awardModel.type = 3
            }
          }
        }
      }, 100)
    },
    // 数据重置
    reset () {
      // 获取当前活动数据
      this.activityInfo()
      // 当前积分为0
      this.integralAll = 0
    }
  }
}
</script>

<style scoped lang="scss">
  .lottery{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 625px;
    height: 472px;
    .top,.middle,.bottom{
      display: flex;
      justify-content: space-between;
      width: 100%;
      img{
        width: 183px;
        height: 130px;
      }
      .lottery-select{
        filter: brightness(120%);
      }
    }
    .middle{
      .lottery-box{
        position: relative;
        .lottery-num{
          font-size: 14px;
          position: absolute;
          left: 123px;
          bottom: 25px;
        }
      }
      .lottery-box-gray{
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
      }
      .lottery-box-light{
        &:hover{
          cursor: pointer;
          filter: brightness(110%);
        }
      }
    }
  }
</style>
