<template>
  <component :is="name"></component>
</template>

<script>
export default {
  name: 'SCustom',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
