<template>
  <div class="s-component">
    <div>
      <div class="img-bg"
           :class="{active: curIndex === index}"
           v-show="curIndex === index"
           v-for="(item,index) in list"
           :style="{
             backgroundImage: `url(${item.img}`
           }"
           :key="(item.label + index)">
        <template v-if="index === curIndex">
          <div class="">
            <slot :name="index + ''">
            </slot>
          </div>
        </template>
      </div>
    </div>

    <div class="thumb-bar" :style="thumbWrapStyleObj">
      <div v-for="(item,index) in list"
           class="thumb-item"
           :class="{active: curIndex === index}"
           @click="curIndex = index"
           :key="index"
           :style="{
              backgroundImage: `url(${item.thumb}`,
              ...thumbItemStyleObj
            }">
        <img :src="item.thumb" alt="">
        <div class="label">{{item.label}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'STopic2Activity',
  props: {
    thumbWrapStyle: {
      type: String,
      default: '{}'
    },
    thumbItemStyle: {
      type: String,
      default: '{}'
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
    // 地图样式
    thumbWrapStyleObj () {
      try {
        return JSON.parse(this.thumbWrapStyle)
      } catch (e) {
        console.error('thumbWrapStyle 格式错误')
        return {}
      }
    },
    thumbItemStyleObj () {
      try {
        return JSON.parse(this.thumbItemStyle)
      } catch (e) {
        console.error('thumbItemStyle 格式错误')
        return {}
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  width: 1920px;
  height: 1000px;
}

.img-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  //&.active {
  //  animation-name: zoom; // 动画名
  //  animation-duration: 10s; // 动画时长
  //  animation-iteration-count: 1; // 动画次数
  //  transition-timing-function: linear; // 动画过渡
  //  animation-fill-mode : forwards; // 动画结束后的状态
  //}
}

.thumb-bar {
  position: absolute;
  z-index: 99;
  top: 208px;
  left: 210px;
  overflow: auto;
  height: 600px;
  .thumb-item {
    display: block;
    position: relative;
    transition: .3s;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 302px;
    height: 107px;
    margin: 0 0 10px;
    z-index: 2;
    color: #fff;
    //border: 1PX solid transparent !important;
    border: none;
    &:hover{
      filter: brightness(1.1);
    }
    &.active {
      border: 2PX solid #BD1418;
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
    .label{
      position: absolute;
      padding: 0 20px;
      left: 0;
      bottom: 0;
      width: 100%;
      //background-color: rgba(0,0,0,.5);
      font-size: 20px;
      line-height: 1;
      margin-bottom: 16px;
    }
  }

}

@keyframes zoom { // 动画设置
  0% {
    background-size: 100% 100%;
  }

  100% {
    background-size: 110% 110%;
  }
}
</style>
