<template>
  <div class="s-appointment" :style="bgStyle">
    <div class="s-appointment--btn s-appointment--btn--disabled" v-if="status" :style="{...btnStyle, 'background-image': `url(${btnDisabled.src})`}"></div>
    <div class="s-appointment--btn cursor-pointer hv-highlight" v-else :style="btnStyle" @click="apply"></div>

    <el-dialog
               :modal-append-to-body="false"
               :visible.sync="dialogVisible"
               class="s-appointment--dialog"
               :width="dialogBg.style.width">
      <div class="s-appointment--close cursor-pointer' hv-highlight" :style="closeBtnStyle" @click="dialogVisible = false"></div>
      <div class="s-appointment--content">
        <img :src="dialogBg.src" :style="{width: dialogBg.style.width, height: dialogBg.style.height}" alt="">
        <div class="s-appointment--confirm cursor-pointer hv-highlight" :style="confirmBtnStyle" @click="dialogVisible = false"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SAppointment',
  props: {
    request: {
      type: Function
    },
    uniqueKey: {
      type: String,
      required: true,
      default: ''
    },
    bg: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/5c81bd411e125c2d8907f366f50ee28d.jpg',
          style: {
            width: '1920px',
            height: '1080px'
          }
        }
      }
    },
    btn: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/d7727332796838e69c63bf80ed4b8bf5.png',
          style: {
            width: '511px',
            height: '144px'
          }
        }
      }
    },
    btnDisabled: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/88cd16ff3921942639e5ef183a321936.png'
        }
      }
    },
    dialogBg: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/92f2d13257eab5bc493de18247b52365.png',
          style: {
            width: '1087px',
            height: '533px'
          }
        }
      }
    },
    confirmBtn: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/3741729c1293328e98ae185a279aa39d.png',
          style: {
            width: '391px',
            height: '124px'
          }
        }
      }
    },
    closeBtn: {
      type: Object,
      default: () => {
        return {
          src: 'https://rescdn-crash.ssjj.cn/website/2023/12/15/e42735a38c66f215ad391a02a40928cb.png',
          style: {
            width: '61px',
            height: '51px'
          }
        }
      }
    }
  },
  data () {
    return {
      visible: true,
      dialogVisible: false,
      status: false,
      lock: false
    }
  },

  inject: {
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      return this.request ? this.request : window.$request
    },
    bgStyle () {
      return {
        width: this.bg.style.width,
        height: this.bg.style.height,
        'background-image': `url(${this.bg.src})`
      }
    },
    btnStyle () {
      return {
        ...this.btn.style,
        'background-image': `url(${this.btn.src})`
      }
    },
    confirmBtnStyle () {
      return {
        width: this.confirmBtn.style.width,
        height: this.confirmBtn.style.height,
        'background-image': `url(${this.confirmBtn.src})`
      }
    },
    closeBtnStyle () {
      return {
        width: this.closeBtn.style.width,
        height: this.closeBtn.style.height,
        'background-image': `url(${this.closeBtn.src})`
      }
    }
  },
  created () {
  },
  mounted () {
    window.$checkLogin() && this.getInfo()

    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.getInfo()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
  },
  methods: {
    getInfo () {
      this.requestAPI({
        url: '/v1/hkir/info',
        method: 'get',
        params: {
          activity_sn: this.uniqueKey
        }
      }).then((res) => {
        this.status = res.status
      }).catch((err) => {
        console.error(err)
        err.msg && this.$message.error(err.msg)
      })
    },
    apply () {
      if (!window.$checkLogin()) return
      this.lock = true
      this.requestAPI({
        url: '/v1/hkir/join',
        method: 'post',
        data: {
          activity_sn: this.uniqueKey
        }
      }).then((res) => {
        this.lock = false
        this.dialogVisible = true
        this.getInfo()
      }).catch((err) => {
        this.lock = false
        console.error(err)
        err.msg && this.$message.error(err.msg)
      })
    },
    reset () {
      this.status = false
    },
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-common {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.s-appointment {
  position: relative;
  @extend .bg-common;
  &--btn {
    position: absolute;
    cursor: pointer;
    @extend .bg-common;
    &--disabled {
      cursor: not-allowed;
    }
  }
  &--dialog {
    position: relative;
    .s-appointment--close {
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 0;
      z-index: 1;
      @extend .bg-common;
      &:hover{
        filter: brightness(120%);
      }
    }
    .s-appointment--content {
      position: relative;
      img{
        display: block;
      }
      .s-appointment--confirm {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        @extend .bg-common;
      }
    }
  }
}
</style>
<style lang="scss">
.s-appointment{
  .el-dialog{
    background-color: transparent;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0;
      word-break: break-word !important;
    }
  }
}
</style>
