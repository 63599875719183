<template>
  <div class="s-component" :style="{color: textColorLevel1, 'background-image': `url(${bg.src})`}">
    <!--      补给商店-->
    <div class="s-part-1" >
      <div class="top">
        <div class="mini-color-btn hv-highlight" @click="openLog(1)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">购买记录</div>
      </div>
      <div class="content">
        <div class="item" v-for="(item,index) in shopAct.groups" :key="index">
          <div class="award-wrap">
            <img class="award" :src="shopList[index].awardImg" alt="">
            <p>{{item.name}}</p>
          </div>
          <div class="s-btn" :style="{'background-image': `url(${shopList[index].btnImg})`}" @click="buy(item)">
            {{item.price}} 点券
            <span v-if="getLimit(item.limit_single).limit > 0">限购: ({{item.acquired_amount}}/{{getLimit(item.limit_single).limit}})</span>
          </div>
<!--          <img class="s-btn hv-highlight" :src="shopList[index].btnImg" alt="" @click="buy(item)">-->
        </div>
      </div>
    </div>
    <!--      登录任务-->
    <div class="s-part-2" >
      <template v-if="loginAct.groups.length">
        <img :class="['s-btn']" v-if="loginAct.groups[0].status === 0" :src="receiveBtn0.src" alt=""/>
        <img :class="['s-btn']" v-if="loginAct.groups[0].status === 1" :src="receiveBtn1.src" alt=""/>
        <img :class="['s-btn hv-highlight']" v-if="loginAct.groups[0].status === 2" :src="receiveBtn2.src" alt="" @click="receiveAward(2, loginAct.groups[0])"/>
        <img :class="['s-btn']" v-if="loginAct.groups[0].status === 3" :src="receiveBtn3.src" alt=""/>
        <img :class="['s-btn']" v-if="loginAct.groups[0].status === 4" :src="receiveBtn4.src" alt=""/>
      </template>
    </div>
    <!--      抽奖-->
    <div class="s-part-3" >
      <div class="top">
        <div class="mini-color-btn hv-highlight" @click="openLog(3)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">抽奖记录</div>
      </div>
      <div class="swiper-box">
        <swiper ref="swiper"
                :options="lotteryOptions"
                v-if="lotteryAct.groups.length"
                class="swiper-box">
          <swiper-slide v-for="(item, index) in lotteryAct.groups" :key="item.id" class="swiper-no-swiping">
            <div class="slide-box" :style="{'background': drawActiveItemIndexList.indexOf(index) > -1 ? drawItemBgColorActive : drawItemBgColor}">
              <div class="label label-must"
                   v-if="item.lottery_times && item.lottery_times - lotteryTimes > 0">
                {{item.lottery_times - lotteryTimes}}次内必得
              </div>
              <div class="label label-left" v-else-if="item.left_amount < 100000">余：{{item.left_amount}}</div>
              <img v-if="item.materials[0]" class="slide-img" :src="item.materials[0].icon" alt="">
              <div class="slide-text">{{ item.desc }}</div>
<!--              如果 已抽中 && 有限购条件-->
              <div class="cover" v-if="item.acquired_amount > 0 && getLimit(item.limit_single).limit !== 0 && getLimit(item.limit_single).limit < 100">
                <div>已抽中 {{item.acquired_amount}} 次</div>
                <div>
                  限中 {{ getLimit(item.limit_single).limit }} 次
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="arrow arrow-left el-icon-arrow-left"></div>
        <div class="arrow arrow-right el-icon-arrow-right"></div>
      </div>
      <div class="bottom">
        <img class="s-btn hv-highlight" :src="drawBtn1.src" alt="" @click="draw(1)">
        <img class="s-btn hv-highlight" :src="drawBtn10.src" alt="" @click="draw(10)">
      </div>
    </div>
    <!--      任务-->
    <div class="s-part-4" >
      <div class="log mini-color-btn hv-highlight" @click="openPointLog()" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">{{actKeyName}}明细</div>
      <div class="point">我的{{actKeyName}}: <span :style="{color: textColorActive}">{{point}}</span></div>

      <div class="task-list">
        <div class="item" v-for="(item,index) in taskAct.groups" :key="index">
          <div class="title" :style="{color: textColorLevel3}">
            {{ item.name }}
            <!-- 某些任务类型条件机不支持进度显示需要隐藏-->
            <span v-if="item.condition_ret && ['DynamicReturningUser', 'RegAt'].indexOf(item.condition_ret.condition) === -1"
                  :style="{color: textColorLevel2}"
            >
                ({{ item.condition_ret.actual_val }}/{{ item.condition_ret.expect_val }})
            </span>
<!--            <SButtonShare text="去分享" size="0 0"></SButtonShare>-->
          </div>
          <div class="info">
            <div class="award" v-if="item.materials" :style="{color: textColorLevel2}">
<!--              <img :src="item.materials[0].icon" alt="">-->
              <span class="name" v-for="(_item, _index) in item.materials" :key="_index">
                {{ _item.name }}
                <span v-if="_item.amount > 1"> * {{ _item.amount }}</span>
              </span>
            </div>
          </div>
          <img class="s-btn" v-if="item.status === 0" :src="receiveBtn0.src" alt=""/>
          <img class="s-btn" v-if="item.status === 1" :src="receiveBtn1.src" alt=""/>
          <img class="s-btn hv-highlight" v-if="item.status === 2" :src="receiveBtn2.src" alt="" @click="receiveAward(4, item)"/>
          <img class="s-btn" v-if="item.status === 3" :src="receiveBtn3.src" alt=""/>
          <img class="s-btn" v-if="item.status === 4" :src="receiveBtn4.src" alt=""/>
        </div>
      </div>
    </div>
    <ModalReceive ref="ModalReceive"/>
    <ModalConfirm ref="ModalConfirm"/>
    <ModalReceiveLog ref="ModalReceiveLog"/>
    <ModalPointLog ref="ModalPointLog"/>
    <ModalAddress ref="ModalAddress"/>
    <slot></slot>
  </div>
</template>

<script>
import ModalReceive from '../_components/ModalReceive.vue'
import ModalConfirm from '../_components/ModalConfirm.vue'
import ModalReceiveLog from '../_components/ModalReceiveLog.vue'
import ModalPointLog from '../_components/ModalPointLog.vue'
import ModalAddress from '../_components/ModalAddress.vue'
import { getLimit } from '../utils'
// import SButtonShare from "@/components/BuilderComponents/ButtonShare.vue";

export default {
  name: 'SOfficialActiveActivityPayNew',
  components: {
    // SButtonShare,
    ModalReceiveLog,
    ModalPointLog,
    ModalReceive,
    ModalAddress,
    ModalConfirm
  },
  props: {
    bg: {
      type: Object,
      required: true
    },
    actKeyName: {
      type: String,
      default: '积分'
    },
    // ===== 任务 ID 配置 =====
    // 购买
    activityId1: {
      type: String,
      default: ''
    },
    // 登录领
    activityId2: {
      type: String,
      default: ''
    },
    // 抽奖
    activityId3: {
      type: String,
      default: ''
    },
    // 任务
    activityId4: {
      type: String,
      default: ''
    },
    // 邀请助力
    activityId5: {
      type: String,
      default: ''
    },
    // ===== text color =====
    textColorLevel1: {
      type: String,
      default: '#333'
    },
    textColorLevel2: {
      type: String,
      default: '#666'
    },
    textColorLevel3: {
      type: String,
      default: '#999'
    },
    textColorActive: {
      type: String,
      default: '#999'
    },
    miniBtnColor: {
      type: String,
      default: '#608351'
    },

    // ===== 补给站 list ====
    shopList: {
      type: Array,
      default: () => []
    },

    // ===== 抽奖Btn =====
    drawActiveItem: {
      type: String,
      default: ''
    },
    drawBgColor: {
      type: String,
      default: '#FFBE57'
    },
    drawBgColorActive: {
      type: String,
      default: '#FFE8BB'
    },
    drawBtn1: {
      type: Object,
      required: true
    },
    drawBtn10: {
      type: Object,
      required: true
    },

    // ===== 登录领取Btn =====
    receiveBtn0: {
      type: Object,
      required: true
    },
    receiveBtn1: {
      type: Object,
      required: true
    },
    receiveBtn2: {
      type: Object,
      required: true
    },
    receiveBtn3: {
      type: Object,
      required: true
    },
    receiveBtn4: {
      type: Object,
      required: true
    },
    // ===== modal =====
    modalBg: {
      type: Object,
      default: () => {
      }
    },
    modalCloseIcon: {
      type: Object,
      default: () => {
      }
    },
    modalTitleStyle: {
      type: String,
      default: '{}'
    },
    modalTextStyle: {
      type: String,
      default: '{}'
    },
    modalConfirmBtnStyle: {
      type: String,
      default: '{}'
    },
    modalCancelBtnStyle: {
      type: String,
      default: '{}'
    }

  },
  data () {
    return {
      wdFrom: '',
      from: '',
      point: 0,
      pageInfo: {
        status: false
      },
      shopAct: {
        groups: []
      },
      // 登录活动
      loginAct: {
        groups: [{ status: 0 }]
      },
      lotteryAct: {
        groups: []
      },
      taskAct: {
        groups: []
      },
      lotteryOptions: {
        slidesPerView: 5,
        // spaceBetween: 32,
        // autoplay: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 4000
        },
        loop: false,
        navigation: {
          prevEl: '.arrow-left',
          nextEl: '.arrow-right'
        },
        on: {
          // slideChange: () => {
          //   this.handleSwiper('swiper', 'swiperIndex', true, this.lotteryAct.groups);
          // }
        }
      },
      lotteryTimes: 0
    }
  },
  provide () {
    return {
      $MainWrap: this
    }
  },

  inject: {
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    drawItemBgColor () {
      if (!this.drawBgColor) return
      const arr = this.drawBgColor.split(',')
      console.log('---drawBgColor---', arr)
      return arr.length > 1 ? `linear-gradient(${arr[0]}, ${arr[1]})` : this.drawBgColor
    },
    drawItemBgColorActive () {
      if (!this.drawBgColorActive) return
      const arr = this.drawBgColorActive.split(',')
      return arr.length > 1 ? `linear-gradient(${arr[0]}, ${arr[1]})` : this.drawBgColorActive
    },
    drawActiveItemIndexList () {
      if (!this.drawBgColorActive) return []
      return this.drawActiveItem.split(',').map(item => parseInt(item))
    }
  },
  mounted () {
    const { from, wdFrom } = this.$route.query
    this.from = from
    this.wdFrom = wdFrom
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.init)
      // 登出操作添加
      this.eventBus.on('signOut', this.init)
    }
    this.init()
    console.log(this.bg)
  },
  methods: {
    getLimit,
    init () {
      if (window.$checkLogin({ showLogin: false })) {
        this.getIndexInfo()
      } else {
        this.pageInfo = { status: false }
      }
      this.getLotteryTimes()
      this.getPointInfo()
      this.getShopAct()
      this.getLoginAct()
      this.getLotteryAct()
      this.getTaskAct()
    },
    // 获取页面信息
    getIndexInfo () {
      this.requestAPI({
        url: '/v1/official/index',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('---页面信息---：', res)
        this.pageInfo = res
        // if (!res.role) {
        //   this.confirm({ content: '<div style="text-align: center">暂未查询到官方游戏角色信息！<br><br>请先进入游戏创角或使用官方游戏角色参与活动。</div>', cancelBtnText: '' })
        // }
      })
    },
    // 获取积分信息
    getPointInfo () {
      if (!window.$checkLogin({ showLogin: false })) {
        this.point = 0
      } else {
        this.requestAPI({
          url: '/v1/uni/integral',
          method: 'get',
          params: { a_id: this.activityId3 }
        }).then(point => {
          console.log('累计登录任务：', point)
          this.point = point
        })
      }
    },
    // 获取商店物品列表
    getShopAct () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId1 }
      }).then(res => {
        console.log('----购买----', res)
        this.shopAct = res
      })
    },
    // 获取登录活动
    getLoginAct () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('----登录活动----', res)
        this.loginAct = res
      })
    },
    // 获取抽奖任务
    getLotteryAct () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId3 }
      }).then(res => {
        console.log('----抽奖活动----', res)
        this.lotteryAct = res
        const swiper = this.$refs.swiper
        if (swiper) {
          this.$nextTick(() => {
            swiper.loopDestroy()
            swiper.loopCreate()
          })
        }
      })
    },
    // 获取抽奖次数
    getLotteryTimes () {
      if (!window.$checkLogin({ showLogin: false })) return
      this.requestAPI({
        url: '/v1/uni/receiveLog',
        method: 'get',
        params: {
          a_id: this.activityId3,
          page: 1,
          page_size: 10
        }
      }).then(data => {
        this.lotteryTimes = data.total
        console.log('----抽奖次数----', data.total)
        // 防止swiper 中 this.data 数据无效
        const swiper = this.$refs.swiper
        if (swiper) {
          this.$nextTick(() => {
            swiper.loopDestroy()
            swiper.loopCreate()
          })
        }
      })
    },
    // 获取任务列表
    getTaskAct () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId4 }
      }).then(res => {
        console.log('----任务列表----', res)
        this.taskAct = res
      })
    },
    /**
     * 抽奖
     * @param type 1: 单抽 10: 十连抽
     */
    draw (type) {
      if (!window.$checkLogin()) return
      this.requestAPI({
        url: '/v1/uni/multiLottery',
        method: 'get',
        params: { a_id: this.activityId3, count: type }
      }).then(res => {
        console.log('抽奖：', res)
        // const id = res.id
        // const item = this.lotteryAct.groups.find(item => item.id === id)
        let materials = []
        res.forEach(item => {
          materials = [...materials, ...item.materials]
        })
        this.openReceiveModal(materials)
        this.init()
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 领取奖励
     * @param actType 1: 购买 2: 登录 3: 抽奖 4: 任务
     * @param item
     */
    receiveAward (actType, item) {
      console.log('---receiveAward---', actType, item)
      // 判断登录
      if (!window.$checkLogin()) return
      const params = {
        a_id: this[`activityId${actType}`],
        g_id: item.id
      }

      // this.openReceiveModal(item.materials)

      return this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params
      }).then((data) => {
        console.log(item.id)
        if (data.success) {
          // if (actType === 4) {
          // this.confirm({ content: '领取成功!', cancelBtnText: '' })
          // } else {
          if (data.gift && data.gift.materials) {
            this.openReceiveModal(data.gift.materials)
          } else {
            this.openReceiveModal(item.materials)
          }
          // }
          this.init()
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 购买
     */
    buy (item) {
      if (!window.$checkLogin()) return

      this.confirm({
        content: `<div style="text-align: center"> 即将消耗 ${item.price}点券 <br>购买 【${item.desc}】, 是否确认？</div>`,
        onConfirm: () => {
          this.receiveAward(1, item)
        }
      })
    },
    /**
     * 打开领取弹窗
     * @param awardsList 奖励列表 [{name: '', icon: ''}]
     * @param showAddress 是否显示地址
     */
    openReceiveModal (awardsList, showAddress = false) {
      this.$refs.ModalReceive.open(awardsList, showAddress)
    },
    confirm (options) {
      this.$refs.ModalConfirm.open(options)
    },
    /**
     * 打开记录弹窗
     * @param type 2: 抽奖记录 3: 领取记录 4: 兑换记录
     */
    openLog (type) {
      if (!window.$checkLogin()) return
      const titleMap = {
        1: '购买记录',
        // 2: '抽奖记录',
        3: '抽奖记录'
        // 4: this.actKeyName + '明细'
      }
      const title = titleMap[type]
      // console.log('---openLog---', type, title)
      this.$refs.ModalReceiveLog.open({
        title,
        activityId: this[`activityId${type}`],
        needAddress: type === 3
      })
    },
    /**
     * 打开积分记录弹窗
     */
    openPointLog () {
      if (!window.$checkLogin()) return
      const options = {
        title: this.actKeyName + '明细',
        activityId: this.activityId4
      }
      this.$refs.ModalPointLog.open(options, this.activityId3)
    },

    openAddress () {
      this.$refs.ModalAddress.open(this.activityId3)
    }
  }
}
</script>
<style lang="scss" scoped>
.s-component {
  padding-top: 316px;
  position: relative;
  height: 3819px;
  background-color: rgba(0, 0, 0, .1);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.mini-color-btn{
  text-decoration: underline;
  display: inline-block;
  //width: 95px;
  padding: 0 5px;
  height: 30px;
  border: 1PX solid;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.s-part-1 {
  width: 1280px;
  position: absolute;
  top: 316px;
  left: 50%;
  transform: translateX(-50%);
  .top{
    position: absolute;
    top: -45px;
    right: 0px;
  }
  .content{
    width: 100%;
    height: 540px;
    position: relative;
    .item{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .award-wrap{
        position: relative;
        p{
          position: absolute;
          bottom: 4px;
          color: #fff;
          text-align: center;
          font-size: 18px;
          line-height: 28px;
          width: 100%;
        }
      }
      .award{
        display: block;
        width: 100%;
      }
      .s-btn{
        display: block;
        width: 100%;
        height: 68px;
        line-height: 68px;
        margin-top: 4px;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        background-size: 100% 100%;
        cursor: pointer;
        span{
          //color: #ececec;
          font-weight: 400;
          margin-left: 10px;
          font-size: 20px;
        }
      }
      &:nth-child(1){
        width: 672px;
        height: 540px;
        top: 0;
        left: 0;
        .award{
          height: 468px;
        }
      }
      &:nth-child(2){
        width: 277px;
        height: 250px;
        top: 0;
        left: 699px;
        .award{
          height: 180px;
        }
      }
      &:nth-child(3){
        width: 277px;
        height: 250px;
        top: 0;
        left: 1003px;
        .award{
          height: 180px;
        }
      }
      &:nth-child(4){
        width: 581px;
        height: 260px;
        top: 278px;
        left: 699px;
        .award{
          height: 190px;
        }
      }
    }
  }
}

.s-part-2 {
  position: absolute;
  top: 3080px;
  width: 100%;
  height: 630px;
  .s-btn {
    position: absolute;
    width: 259px;
    height: 78px;
    object-fit: contain;
    top: 520px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.s-part-3 {
  position: absolute;
  top: 1046px;
  width: 100%;
  height: 900px;
  padding-top: 525px;

  .top{
    position: absolute;
    top: 60px;
    left: 460px;
  }
  .swiper-box{
    position: relative;
    width: 1450px;
    margin: 0 auto;
    .slide-box {
      text-align: center;
      position: relative;
      margin: 0 auto;
      width: 272px;
      //background: linear-gradient(#C4CCE6, #E8E9EC);
      //background: linear-gradient(#FFBE57, #FFE8BB);

      .label{
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: rgba(0,0,0,.5);
        color: #fff;
        font-size: 18px;
        padding: 5px 10px;
        &.label-left{
          border-left: 3px solid #15C5FF;
        }
        &.label-must{
          border-left: 3px solid #FF5215;
        }
      }
      img {
        display: block;
        width: 272px;
        height: 218px;
        margin: 0 auto;
        object-fit: contain;
        padding: 30px 30px 50px;
      }
      .slide-text{
        position: absolute;
        background-color: rgba(0,0,0,.5);
        padding: 5px 0;
        left: 3px;
        right: 3px;
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 1;
        bottom: 6px;
      }
      .cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.8);
        color: #E6A23C;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .arrow {
      font-size: 80px;
      color: #fff;
      opacity: .6;
      text-shadow: 0 0 5px #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      font-weight: 700;
      transition: .3s;
      &:hover{
        opacity: .8;
      }

      &.arrow-left {
        left: -70px;
      }

      &.arrow-right {
        right: -70px;
      }
    }

  }

  .bottom{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    .s-btn{
      margin: 0 20px;
      width: 344px;
      height: 78px;
    }
  }
}

.s-part-4{
  position: absolute;
  top: 2273px;
  width: 100%;
  height: 390px;
  .log{
    position: absolute;
    top: -80px;
    left: 390px;
  }
  .point{
    position: absolute;
    top: 200px;
    left: 80px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    span{
      font-size: 40px;
      font-weight: 700;
    }
  }
  .task-list{
    position: absolute;
    left: 700px;
    width: 1150px;
    height: 350px;
    overflow-y: auto;
    top: 20px;
    font-size: 18px;
    padding-right: 20px;
    .item{
      display: flex;
      align-items: center;
      line-height: 70px;
      .title{
        width: 400px;
        flex-shrink: 0;
      }
      .info{
        flex: 1;
        text-align: center;
        .award{
          .name{
            position: relative;
            margin: 0 15px;
            &::after{
              content: '+';
              position: absolute;
              top: 3px;
              right: -22px;
              line-height: 1;
            }
            &:last-child{
              &::after{
                display: none;
              }
            }
          }
        }
      }
      .s-btn{
        flex-shrink: 0;
        width: 125px;
        height: 39px;
      }
    }
  }
}
</style>
