<template>
  <div class="page-builder-group"
       :class="[
         inAnimate ? `animate__animated animate__${inAnimate}` : '',
         hoverClass,
         autoAnimate
         ]"
       v-if="visible && channelVisible"
       @mouseover="hoverAnimate ? hoverClass =  hoverAnimate : ''"
       @mouseout="hoverClass = ''"
       :style="style"
       @click="handleClick">
    <slot>
      <div class="default-area">组-占位区</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BaseGroup',
  props: {
    hideInChannels: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '100%'
    },
    minHeight: {
      type: String,
      default: ''
    },
    isFlex: {
      type: Boolean,
      default: false
    },
    alignItems: {
      type: String,
      default: ''
    },
    justifyContent: {
      type: String,
      default: ''
    },
    flexDirection: {
      type: String,
      default: ''
    },
    // 进场动画
    inAnimate: {
      type: String,
      default: ''
    },
    // 移入动画
    hoverAnimate: {
      type: String,
      default: ''
    },
    // 自执行动画
    autoAnimate: {
      type: String,
      default: ''
    },
    eventName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: true,
      options: [],
      hoverClass: ''
    }
  },
  computed: {
    style () {
      return {
        display: this.isFlex ? 'flex' : '',
        width: this.width,
        'min-height': this.minHeight,
        'align-items': this.alignItems,
        'justify-content': this.justifyContent,
        'flex-direction': this.flexDirection
      }
    },
    channelVisible () {
      let { wdFrom, from } = this.$route.query
      if (!wdFrom && from) {
        wdFrom = 'lenovo'
      }
      if (!wdFrom && !from) {
        wdFrom = 'website'
      }
      return !this.hideInChannels.includes(wdFrom)
    }
  },
  created () {
    // console.log('================')
    // const wdFrom = this.$route.query.wdFrom
    // console.log(wdFrom)
    // console.log(this.hideInChannels)
    // console.log('================')
    // if (this.hideInChannels.includes(wdFrom)) {
    //   this.visible = false
    // }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      // 触发事件埋点
      if (this.eventName) {
        console.log('-----事件埋点-----', this.eventName)
        window._hmt && window._hmt.push([
          '_trackEvent',
          'CLICK',
          this.eventName
        ])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-builder-group {
  .default-area {
    width: 100%;
    height: 200px;
    font-size: 80px;
    text-align: center;
    line-height: 200px;
    color: #e8e9ea;
  }
}
</style>
