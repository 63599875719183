var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"s-anchor-nav"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"s-anchor-nav-item",style:({
        ..._vm.itemStyle,
        margin: _vm.itemMargin,
        'background-image': `url(${(_vm.active === index || _vm.hoverIndex === index) ? item.active : item.default})`
      }),on:{"click":function($event){return _vm.handleClick(item, index)},"mouseenter":function($event){_vm.hoverIndex = index},"mouseout":function($event){_vm.hoverIndex = null}}})}),(_vm.backTopImg)?_c('img',{staticClass:"s-anchor-top",style:({
          ..._vm.backTopImg.style,
          margin: _vm.backTopImgMargin
         }),attrs:{"src":_vm.backTopImg.src},on:{"click":function($event){return _vm.scrollTo(0)}}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }