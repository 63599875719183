import _mixin from './_mixin'
import enums from '@/utils/enums'
export default {
  name: 'SButtonShare',
  cnName: '分享按钮',
  type: 'base',
  draggable: true,
  // 属性相关
  props: {
    defaultBg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/join.png?v=1.04',
    hoverBg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/join-hover.png?v=1.04',
    disabled: false,
    size: '329px 94px',
    text: '',
    btnOtherStyle: '{}',
    activityId: '3WLFEHN6MV2YPIR23CWEDCQC5E',
    tipText: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> '
  },
  propsDesc: [
    {
      target: 'defaultBg',
      label: '默认背景'
    },
    {
      target: 'hoverBg',
      label: 'hover背景'
    },
    {
      target: 'size',
      label: '按钮尺寸',
      description: '宽 高'
    },
    {
      target: 'text',
      label: '按钮文本'
    },
    {
      target: 'btnOtherStyle',
      label: '按钮其他样式',
      description: 'JSON字符串',
      type: 'textarea'
    },
    {
      target: 'activityId',
      label: '活动 ID'
    },
    {
      target: 'tipText',
      label: '提示文案(前缀)',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    top: '0',
    left: '0',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
