<template>
  <BaseGroup v-bind="$attrs" v-on="$listeners" v-show="visible">
    <slot>
      <div class="default-area">组-占位区</div>
    </slot>
  </BaseGroup>
</template>

<script>
import BaseGroup from './BaseGroup'
export default {
  name: 'BaseMoveGroup',
  components: {
    BaseGroup
  },
  props: {
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {

  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    }
  }
}
</script>
