<template>
  <div class="s-component" :style="{color: textColorLevel2}">
    <!--      登录任务-->
    <div class="s-part-1" id="version-data-1">
      <div class="top">
        <div class="mini-color-btn hv-highlight" @click="openLog(2)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">抽奖记录</div>
      </div>
      <div class="flex">
        <div class="left">
          <div class="title" :style="{color: textColorLevel1}">
            您已累计登录游戏
            <span v-if="loginCountTask.groups && loginCountTask.groups.length" :style="{color: textColorActive}">
            {{ loginCountTask.groups[loginCountTask.groups.length - 1].condition_ret.actual_val }}
          </span> 次
          </div>
          <div class="table">
            <div class="tr">
              <div class="th count text-center">累计登录</div>
              <div class="th">可得</div>
              <div class="th">&nbsp;</div>
            </div>
            <div class="tbody">
              <div class="tr" v-for="(item,index) in loginCountTask.groups" :key="index">
                <div class="td count text-center">
                  <i :style="{'background-color': textColorActive}"><b>{{
                      item.condition_ret.expect_val
                    }}</b><span>次</span></i>
                </div>
                <div class="td award"><img :src="item.materials[0].icon" alt=""> <span>{{ item.name }}</span></div>
                <div class="td">
                  <img class="s-btn" v-if="item.status === 0" :src="receiveBtn0.src" alt=""/>
                  <img class="s-btn" v-if="item.status === 1" :src="receiveBtn1.src" alt=""/>
                  <img class="s-btn hv-highlight" v-if="item.status === 2" :src="receiveBtn2.src" alt=""
                       @click="receiveAward(1, item)"/>
                  <img class="s-btn" v-if="item.status === 3" :src="receiveBtn3.src" alt=""/>
                  <img class="s-btn" v-if="item.status === 4" :src="receiveBtn4.src" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <img v-if="loginCountTask.groups.some(item => item.status === 2)" :src="receiveAllBtn.src" alt="" class="btn hv-highlight"  @click="receiveAwardAll(1)">
          <img v-else :src="receiveAllBtnDisabled.src" alt="" class="btn">
        </div>
        <div class="right">
          <div class="swiper-bg">
            <swiper ref="dailySwiper" :options="loginLotteryOptions" v-if="loginLotteryTask.groups.length" class="swiper-box">
              <swiper-slide v-for="(item) in loginLotteryTask.groups" :key="item.id" class="swiper-no-swiping">
                <div class="slide-box">
                  <img v-if="item.materials[0]" class="slide-img" :src="item.materials[0].icon" alt="">
                  <div class="slide-text">{{ item.desc }}</div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="arrow arrow-left el-icon-arrow-left"></div>
            <div class="arrow arrow-right el-icon-arrow-right"></div>
          </div>
          <img v-if="!pageInfo.status" :src="receiveDayBtn.src" alt="" class="btn hv-highlight"  @click="loginDraw">
          <img v-else :src="receiveDayBtnDisabled.src" alt="" class="btn">
        </div>
      </div>
    </div>
    <!--      日常任务-->
    <div class="s-part-2" id="version-data-2">
      <div class="top">
<!--        <div class="mini-color-btn hv-highlight" @click="openLog(3)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">领取记录</div>-->
      </div>
      <img v-if="taskList.groups.some(item => item.status === 2)" :src="receiveAllBtn.src" alt="" class="btn-receive-all hv-highlight"  @click="receiveAwardAll(3)">
      <img v-else :src="receiveAllBtnDisabled.src" alt="" class="btn-receive-all">
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item,index) in taskList.groups" :key="index">
            <div class="title" :style="{color: textColorLevel1}">
              {{ item.name }}
            <!-- 某些任务类型条件机不支持进度显示需要隐藏-->
              <span v-if="item.condition_ret && ['DynamicReturningUser', 'RegAt'].indexOf(item.condition_ret.condition) === -1">
                ({{ item.condition_ret.actual_val }}/{{ item.condition_ret.expect_val }})
              </span>
            </div>
            <div class="info">
              <div class="award" v-if="item.materials[0]">
                <img :src="item.materials[0].icon" alt="">
                <span class="name">{{ item.materials[0].name }} <span
                  v-if="item.materials[0].amount > 1"> * {{ item.materials[0].amount }}</span></span>
              </div>
              <img class="s-btn" v-if="item.status === 0" :src="receiveBtn0.src" alt=""/>
              <img class="s-btn" v-if="item.status === 1" :src="receiveBtn1.src" alt=""/>
              <img class="s-btn hv-highlight" v-if="item.status === 2" :src="receiveBtn2.src" alt=""
                   @click="receiveAward(3, item)"/>
              <img class="s-btn" v-if="item.status === 3" :src="receiveBtn3.src" alt=""/>
              <img class="s-btn" v-if="item.status === 4" :src="receiveBtn4.src" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      兑换-->
    <div class="s-part-3" id="version-data-3">
      <div class="top">
<!--        <div class="mini-color-btn hv-highlight" @click="openLog(4)" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">兑换记录</div>-->
      </div>
      <div class="info">
        <div class="my" :style="{color: textColorLevel1}">我的{{actKeyName}}:  <span class="point" :style="{color: textColorActive}">{{ point }}</span></div>
        <div class="btn-group">
          <div class="mini-color-btn hv-highlight" @click="openPointLog()" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">{{actKeyName}}明细</div>
          <div class="mini-color-btn hv-highlight" @click="openAddress()" :style="{color: miniBtnColor, 'border-color': miniBtnColor}">填写收货地址</div>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div class="item"
               v-for="(item,index) in exchangeList.groups" :key="index">
            <div class="title" :style="{color: textColorLevel1}">{{ item.price }} {{actKeyName}}</div>
            <img v-if="item.materials && item.materials[0]" class="award" :src="item.materials[0].icon" alt="">
            <div v-if="item.materials && item.materials[0]" class="name" :style="{color: textColorLevel1}">
              {{ item.desc }}
            </div>
            <img class="s-btn hv-highlight" :src="exchangeBtn.src" @click="exchange(item)" alt=""/>

            <p>限购: ({{item.acquired_amount}}/{{getLimit(item.limit_single).limit}})</p>
            <p v-if="item.left_amount>0">库存: {{item.left_amount}}</p>
            <p v-else-if="item.amount > 0 && item.amount === item.exchange_amount">库存: {{item.left_amount}}</p>
          </div>
        </div>
      </div>
    </div>
    <ModalReceive ref="ModalReceive"/>
    <ModalConfirm ref="ModalConfirm"/>
    <ModalReceiveLog ref="ModalReceiveLog"/>
    <ModalPointLog ref="ModalPointLog"/>
    <ModalAddress ref="ModalAddress"/>

    <OfficialActiveSliderBar v-if="!(wdFrom || from)" ref="ModalCommon" />
    <slot></slot>
  </div>
</template>

<script>
import ModalReceive from '../_components/ModalReceive.vue'
import ModalConfirm from '../_components/ModalConfirm.vue'
import ModalReceiveLog from '../_components/ModalReceiveLog.vue'
import ModalPointLog from '../_components/ModalPointLog.vue'
import ModalAddress from '../_components/ModalAddress.vue'
import OfficialActiveSliderBar from './_components/OfficialActiveSliderBar.vue'
import { getLimit } from '../utils'

export default {
  name: 'SOfficialActiveActivity',
  components: {
    ModalReceiveLog,
    ModalPointLog,
    ModalReceive,
    ModalAddress,
    ModalConfirm,
    OfficialActiveSliderBar
  },
  props: {
    actKeyName: {
      type: String,
      default: '积分'
    },
    // ===== 任务 ID 配置 =====
    // 累计登录
    activityId1: {
      type: String,
      default: ''
    },
    // 登录抽奖
    activityId2: {
      type: String,
      default: ''
    },
    // 任务列表
    activityId3: {
      type: String,
      default: ''
    },
    // 兑换
    activityId4: {
      type: String,
      default: ''
    },
    // 邀请助力
    activityId5: {
      type: String,
      default: ''
    },
    // ===== text color =====
    textColorLevel1: {
      type: String,
      default: '#333'
    },
    textColorLevel2: {
      type: String,
      default: '#666'
    },
    textColorLevel3: {
      type: String,
      default: '#999'
    },
    textColorActive: {
      type: String,
      default: '#999'
    },
    miniBtnColor: {
      type: String,
      default: '#608351'
    },

    // ===== 领取Btn =====
    receiveBtn0: {
      type: Object,
      required: true
    },
    receiveBtn1: {
      type: Object,
      required: true
    },
    receiveBtn2: {
      type: Object,
      required: true
    },
    receiveBtn3: {
      type: Object,
      required: true
    },
    receiveBtn4: {
      type: Object,
      required: true
    },
    // ===== 一键领取Btn =====
    receiveAllBtn: {
      type: Object,
      required: true
    },
    receiveAllBtnDisabled: {
      type: Object,
      required: true
    },
    // ===== 每日领取Btn =====
    receiveDayBtn: {
      type: Object,
      required: true
    },
    receiveDayBtnDisabled: {
      type: Object,
      required: true
    },
    // ===== 兑换Btn =====
    exchangeBtn: {
      type: Object,
      required: true
    },
    // ===== 侧边栏 =====
    sideBarBg: {
      type: Object,
      default: () => {
      }
    },
    topTextColor: {
      type: String,
      default: '#FDEDC2'
    },
    sideBarNavText1: {
      type: String,
      default: '登录必得'
    },
    sideBarNavText2: {
      type: String,
      default: '探险任务'
    },
    sideBarNavText3: {
      type: String,
      default: '春日礼兑'
    },

    // ===== modal =====
    modalBg: {
      type: Object,
      default: () => {
      }
    },
    modalCloseIcon: {
      type: Object,
      default: () => {
      }
    },
    modalTitleStyle: {
      type: String,
      default: '{}'
    },
    modalTextStyle: {
      type: String,
      default: '{}'
    },
    modalConfirmBtnStyle: {
      type: String,
      default: '{}'
    },
    modalCancelBtnStyle: {
      type: String,
      default: '{}'
    }

  },
  data () {
    return {
      wdFrom: '',
      from: '',
      point: 0,
      pageInfo: {
        status: false
      },
      loginCountTask: {
        groups: []
      },
      loginLotteryTask: {
        groups: []
      },
      taskList: {
        groups: []
      },
      exchangeList: {
        groups: []
      },
      loginLotteryOptions: {
        slidesPerView: 4,
        spaceBetween: 32,
        // autoplay: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000
        },
        loop: true,
        navigation: {
          prevEl: '.arrow-left',
          nextEl: '.arrow-right'
        },
        on: {
          // slideChange: () => {
          //   this.handleSwiper('dailySwiper', 'dailySwiperIndex', true, this.loginLotteryTask.groups);
          // }
        }
      }
    }
  },
  provide () {
    return {
      $MainWrap: this
    }
  },

  inject: {
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  mounted () {
    const { from, wdFrom } = this.$route.query
    this.from = from
    this.wdFrom = wdFrom
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.init)
      // 登出操作添加
      this.eventBus.on('signOut', this.init)
    }
    this.init()
  },
  methods: {
    getLimit,
    init () {
      if (window.$checkLogin({ showLogin: false })) {
        this.getIndexInfo()
      } else {
        this.pageInfo = { status: false }
      }
      this.getPointInfo()
      this.getLoginCountTask()
      this.getLoginLotteryTask()
      this.getTaskList()
      this.getExchangeList()
    },
    // 获取页面信息
    getIndexInfo () {
      this.requestAPI({
        url: '/v1/official/index',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('---页面信息---：', res)
        this.pageInfo = res
        // if (!res.role) {
        //   this.confirm({ content: '<div style="text-align: center">暂未查询到官方游戏角色信息！<br><br>请先进入游戏创角或使用官方游戏角色参与活动。</div>', cancelBtnText: '' })
        // }
      })
    },
    // 获取积分信息
    getPointInfo () {
      if (!window.$checkLogin({ showLogin: false })) {
        this.point = 0
      } else {
        this.requestAPI({
          url: '/v1/uni/integral',
          method: 'get',
          params: { a_id: this.activityId3 }
        }).then(point => {
          console.log('累计登录任务：', point)
          this.point = point
        })
      }
    },
    // 获取累计登录任务
    getLoginCountTask () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId1 }
      }).then(res => {
        console.log('累计登录任务：', res)
        this.loginCountTask = res
      })
    },
    // 获取登录抽奖任务
    getLoginLotteryTask () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('登录抽奖任务：', res)
        this.loginLotteryTask = res
      })
    },
    // 获取任务列表
    getTaskList () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId3 }
      }).then(res => {
        console.log('任务列表：', res)
        this.taskList = res
      })
    },
    // 获取兑换列表
    getExchangeList () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId4 }
      }).then(res => {
        console.log('兑换列表：', res)
        this.exchangeList = res
      })
    },
    /**
     * 登录抽奖
     */
    loginDraw () {
      if (!window.$checkLogin()) return
      // const id = 2798
      // const item = this.loginLotteryTask.groups.find(item => item.id === id)
      // this.openReceiveModal(item.materials)
      this.requestAPI({
        url: '/v1/official/draw',
        method: 'get',
        params: { a_id: this.activityId2 }
      }).then(res => {
        console.log('登录抽奖：', res)
        const id = res.id
        const item = this.loginLotteryTask.groups.find(item => item.id === id)
        this.openReceiveModal(item.materials)
        this.init()
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },

    /**
     * 领取奖励
     * @param type 1: 累计登录 2: 登录抽奖 3: 任务列表 4: 兑换
     * @param item
     */
    receiveAward (type, item) {
      console.log('---receiveAward---', type, item)
      // 判断登录
      if (!window.$checkLogin()) return
      const params = {
        a_id: this[`activityId${type}`],
        g_id: item.id
      }

      // this.openReceiveModal(item.materials)

      return this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params
      }).then((data) => {
        console.log(item.id)
        if (data.success) {
          if (type === 1 || type === 3) {
            this.confirm({ content: '领取成功!', cancelBtnText: '' })
          } else {
            this.openReceiveModal(item.materials)
          }
          this.init()
        } else {
          this.$message.error(data.msg || '系统错误')
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    /**
     * 一键领取
     * @param type 1: 累计登录  3: 任务列表
     */
    async receiveAwardAll (type) {
      // 判断登录
      if (!window.$checkLogin()) return

      const target = type === 1 ? this.loginCountTask : this.taskList
      const arr = target.groups.filter(item => item.status === 2)
      for (let i = 0; i < arr.length; i += 1) {
        await this.receiveAward(type, arr[i])
      }
    },
    /**
     * 兑换
     */
    exchange (item) {
      if (!window.$checkLogin()) return

      this.confirm({
        content: `<div style="text-align: center"> 即将消耗 ${item.price} ${this.actKeyName} <br>兑换 【${item.desc}】, 是否确认？</div>`,
        onConfirm: () => {
          this.receiveAward(4, item)
        }
      })
    },
    /**
     * 打开领取弹窗
     * @param awardsList 奖励列表 [{name: '', icon: ''}]
     */
    openReceiveModal (awardsList) {
      this.$refs.ModalReceive.open(awardsList)
    },
    confirm (options) {
      this.$refs.ModalConfirm.open(options)
    },
    /**
     * 打开记录弹窗
     * @param type 2: 抽奖记录 3: 领取记录 4: 兑换记录
     */
    openLog (type) {
      if (!window.$checkLogin()) return
      const titleMap = {
        2: '抽奖记录',
        3: '领取记录',
        4: '兑换记录'
      }
      const title = titleMap[type]
      console.log('---openLog---', type, title)
      this.$refs.ModalReceiveLog.open({
        title,
        activityId: this[`activityId${type}`],
        needAddress: type === 4
      })
    },
    /**
     * 打开积分记录弹窗
     * @param
     */
    openPointLog () {
      if (!window.$checkLogin()) return
      const options = {
        title: this.actKeyName + '明细',
        activityId: this.activityId3
      }
      this.$refs.ModalPointLog.open(options, this.activityId3)
    },

    openAddress () {
      this.$refs.ModalAddress.open(this.activityId4)
    },
    // 检查开始/结束时间
    async checkStartEnd (start, end) {
      const time = await this.requestAPI({
        url: '/v1/uni/t',
        method: 'get'
      })
      // console.log('服务器时间：', time)
      // console.log('开始时间：', start * 1000)
      // console.log('结束时间：', end * 1000)
      if (time < start * 1000) {
        this.$message('当前活动未开始, 请关注《生死狙击2》其他活动~')
      } else if (time > end * 1000) {
        this.$message('当前活动已结束, 请关注《生死狙击2》其他活动~')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.s-component {
  padding-top: 260px;
  position: relative;
  height: 3650px;
  background-color: rgba(0, 0, 0, .1);
}
.mini-color-btn{
  display: inline-block;
  padding: 0 20px;
  border: 1PX solid;
  border-radius: 12px;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.s-part-1 {
  margin: 0 auto;
  width: 1600px;
  height: 800px;
  padding-left: 90px;
  position: relative;
  .top{
    position: absolute;
    top: -45px;
    right: 300px;
    .mini-color-btn{
      margin: 0 10px;
    }
  }

  .left {
    float: left;
    width: 710px;
    height: 800px;
    padding: 30px 40px;

    .title {
      font-size: 48px;
      margin-bottom: 20px;

      span {
        font-size: 60px;
        font-weight: 700;
      }
    }

    .table {
      width: 570px;
      display: block;
      margin: 0 auto;
      text-align: center;

      .tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        margin-bottom: 20px;
      }

      .th {
        font-size: 26px;

        &.count {
          width: 120px;
          flex-shrink: 0;
        }
      }

      .tbody {
        height: 430px;
        overflow-y: scroll;
      }

      .td {
        &.count {
          width: 120px;
          flex-shrink: 0;

          i {
            display: block;
            margin: 0 auto;
            font-style: normal;
            text-align: center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
            color: #fff;
            font-size: 20px;
            font-weight: 700;

            span {
              font-size: 12px;
            }
          }
        }

        &.award {
          width: 280px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            object-fit: contain;
            display: block;
          }

          span {
            font-size: 18px;
          }
        }

        .s-btn {
          width: 123px;
          height: 48px;
          cursor: pointer;
        }
      }
    }

    .btn {
      display: block;
      margin: 30px auto 0;
      width: 422px;
    }
  }

  .right {
    flex: 1;
    height: 800px;
    padding: 480px 105px 0 40px;

    .swiper-bg {
      position: relative;
      width: 620px;
      height: 140px;
      padding-top: 20px;
      margin: 0 auto;

      .slide-box {
        text-align: center;

        img {
          display: block;
          width: 78px;
          height: 78px;
          object-fit: contain;
          margin: 0 auto 10px;
        }
      }

      .arrow {
        font-size: 70px;
        color: #efefef;
        text-shadow: 0 0 5px #000;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-weight: 700;
        &:hover{
          color: #fff;
        }

        &.arrow-left {
          left: -55px;
        }

        &.arrow-right {
          right: -55px;
        }
      }

    }

    .btn {
      display: block;
      margin: 25px auto 0;
      width: 422px;
    }
  }
}

.s-part-2 {
  position: relative;
  margin: 310px auto 0;
  width: 1600px;
  height: 800px;
  padding-top: 200px;
  .top{
    position: absolute;
    top: -30px;
    right: 200px;
    .mini-color-btn{
      margin: 0 10px;
    }
  }
  .btn-receive-all {
    position: absolute;
    width: 422px;
    height: 147px;
    object-fit: contain;
    top: 35px;
    left: 940px;
  }

  .content {
    margin: 0 auto;
    width: 1400px;
    height: 550px;
    overflow-y: auto;

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 670px;
        margin: 10px;
        flex-shrink: 0;
        padding: 10px 0;

        .title {
          font-size: 24px;
          height: 60px;
          line-height: 30px;
          font-weight: 700;
          text-align: center;
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 120px;

          .award {
            display: flex;
            align-items: center;

            img {
              width: 70px;
              height: 70px;
              object-fit: contain;
              margin-right: 10px;
            }

            .name {
              flex: 1;
              font-size: 18px;
            }
          }

          .s-btn {
            width: 147px;
            height: 57px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.s-part-3 {
  position: relative;
  margin: 300px auto 0;
  width: 1600px;
  height: 800px;
  //padding-top: 140px;

  .top{
    position: absolute;
    top: -30px;
    right: 200px;
    .mini-color-btn{
      margin: 0 10px;
    }
  }
  .info{
    height: 140px;
    padding: 0 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .my{
      font-size: 36px;
      font-weight: 700;
    }
    .point{
      font-size: 60px;
    }
    .mini-color-btn{
      margin: 0 10px;
    }
  }
  .content {
    margin: 0 auto;
    width: 1400px;
    height: 620px;
    overflow-y: auto;

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 275px;
        height: 280px;
        flex-shrink: 0;
        text-align: center;
        margin-bottom: 30px;

        .title {
          font-size: 20px;
          font-weight: 700;
          text-align: center;
        }

        img.award {
          display: block;
          margin: 10px auto;
          width: 164px;
          height: 107px;
          object-fit: contain;
        }

        .name {
          flex: 1;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .s-btn {
          width: 151px;
          height: 46px;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
