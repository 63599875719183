import { randomString } from '@/utils/index'

/**
 * 获取节点
 * @param data
 * @param id
 * @returns {*}
 */
export function getTreeNodeById (data, id) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === id) {
      return data[i]
    }
    if (data[i].children) {
      const node = getTreeNodeById(data[i].children, id)
      if (node) return node
    }
  }
}

/**
 * 添加节点
 * @param data
 * @param id
 * @param node
 * @returns {boolean}
 */
export function addTreeNodeById (data, id, node) {
  if (!id) {
    data.push(node)
    return
  }
  for (let i = 0; i < data.length; i++) {
    const current = data[i]
    if (current.id === id) {
      const nodePlus = {
        // todo 记录父级路径 优化递归查找
        // parentId: current.id,
        // parentIdList: current.parentIdList ? [...current.parentIdList, current.id] : [current.id],
        ...node
      }
      if (current.children) { // 如果选中的节点有children 则将节点填入此节点
        // 增强节点属性，添加id,parentId, parentIdList
        current.children.push(nodePlus)
      } else { // 如果选中的节点没有children 则将节点填入父节点末尾
        data.push(nodePlus)
      }
      return false
    }
    if (current.children) {
      addTreeNodeById(current.children, id, node)
    }
  }
}

/**
 * 删除节点
 * @param data
 * @param id
 */
export function delTreeNodeById (data, id) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].id === id) {
      data.splice(i, 1)
      return
    }
    if (data[i].children) {
      delTreeNodeById(data[i].children, id)
    }
  }
}

/**
 * 重置节点&子节点Id
 * @param node
 * 对于子节点id会变成 组件名_timestamp_timestamp
 */
export function resetNodeId (node) {
  const idArr = node.id.split('_')
  console.log('当前nodeId:', node.id)
  node.id = idArr[0] + '_' + idArr[1] + '_' + randomString(8)
  console.log('重置nodeId:', node.id)
  console.log(node)
  if (node.children) {
    node.children.forEach(item => {
      resetNodeId(item)
    })
  }
}
//
// const id = 1
//
// const nodeImage = {
//   name: 'image'
// }
// const nodeGroup = {
//   id: 8888,
//   name: 'group',
//   children: []
// }
// const data = [{
//   id: 1,
//   name: 'page',
//   children: [{
//     id: 100,
//     name: '组',
//     // ...props
//     children: [{
//       id: 100100,
//       name: '图片'
//     },
//     {
//       id: 100101,
//       name: '图片2',
//       children: []
//     }
//     ]
//   },
//   {
//     id: 101,
//     name: '图片'
//   }
//   ]
// }]

// const node = getTreeNodeById(data, 100101)
// console.log(JSON.stringify(node))

// addTreeNodeById(data, 100101, nodeGroup)
// console.log(JSON.stringify(data))
