<template>
  <div v-if="awardModelShow" class="lay">
    <div class="award-model" :style="modelStyle">
      <div class="close img-bg" :style="closeStyle" @click="close"></div>
      <img :src="awardTitle" alt="" class="title-model" :style="titleStyle"/>
      <div class="mail-text" :style="{color: modelColor}">{{awardOtherDesc}}{{modelType === 2 ? energyValue : ''}}</div>
      <div class="text-model">
        <div v-for="(item,index) in awardData" :key="index" class="award-item">
          <div class="award-bg" :style="awardStyle">
            <img :src="item.image" alt="" class="award-img"/>
          </div>
          <div v-if="item.count && Number(item.count) > 0" class="award-title" :style="{color: modelColor}">{{item.name}}*{{item.count}}</div>
          <div v-else class="award-title" :style="{color: modelColor}">{{item.name}}</div>
        </div>
      </div>
      <div class="btn-close img-bg" :style="btnCloseStyle" @click="close">{{awardBtnText}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SAwardModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    // 0: 普通领取奖励  1：预约成功  2：助力成功 3: 按钮是填写地址点击会弹填写地址弹窗
    modelType: {
      type: Number,
      default: 0
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    awardBgSize: {
      type: String,
      default: ''
    },
    awardTitle: {
      type: String,
      default: ''
    },
    awardTitleSize: {
      type: String,
      default: '419px 29px'
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBgImg: {
      type: String,
      default: ''
    },
    awardBoxBgSize: {
      type: String,
      default: ''
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    awardBtnColor: {
      type: String,
      default: ''
    },
    awardBtnText: {
      type: String,
      default: ''
    },
    awardBtnSize: {
      type: String,
      default: '118px 55px'
    },
    // 奖励数据
    awardData: {
      type: Array,
      default: () => []
    },
    // 助力成功的能量值
    energyValue: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      awardModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.awardModelShow = newVal
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.awardBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '450px'
      return {
        width,
        height,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    titleStyle () {
      const arr = this.awardTitleSize.split(' ')
      const width = arr[0] || '358px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    awardStyle () {
      const arr = this.awardBoxBgSize.split(' ')
      const width = arr[0] || '201px'
      const height = arr[1] || '132px'
      return {
        width,
        height,
        'background-image': `url(${this.awardBgImg})`,
        'background-size': `${width} ${height} `
      }
    },
    btnCloseStyle () {
      const arr = this.awardBtnSize.split(' ')
      const width = arr[0] || '184px'
      const height = arr[1] || '55px'
      return {
        width,
        height,
        color: `${this.awardBtnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatusOne})`
      }
    }
  },
  methods: {
    close () {
      if (this.modelType === 3) {
        // 填写地址弹窗
        this.$emit('openAddress', true)
      }
      this.$emit('update:modelShow', false)
      this.awardModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.awardModelShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .award-model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .title-model{
    margin: 42px 0 20px;
  }
  .mail-text{
    font-size: 18px;
  }
  .text-model{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 230px;
    margin: 0 auto 20px;
    .award-bg{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 201px;
      height: 132px;
      .award-img{
        width: 80%;
        height: 80%;
      }
    }
    .award-title{
      font-size: 18px;
      margin-top: 5px;
    }
  }
  .btn-close{
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto;
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
</style>
