<template>
  <div class="s-act">
    <img :src="mainImg" alt="" class="main-img">
    <div class="btn-group">
<!--      左侧按钮-->
        <img class="s-btn" v-if="appointAwards.status === 0" :src="leftBtnNoBegin.src" alt=""  :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
        <img class="s-btn" v-if="appointAwards.status === 1" :src="leftBtnUnfinished.src" alt="" :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
        <img class="s-btn hv-highlight" v-if="appointAwards.status === 2" :src="leftBtnReceive.src" alt="" @click="receiveAward(1)" :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
        <img class="s-btn" v-if="appointAwards.status === 3" :src="leftBtnReceived.src" alt="" :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
        <img class="s-btn" v-if="appointAwards.status === 4" :src="leftBtnExpire.src" alt="" :style="{...getPosition(leftBtnPos), ...getSize(leftBtnSize)}"/>
<!--      右侧按钮-->
        <img class="s-btn" v-if="loginAwards.status === 0" :src="rightBtnNoBegin.src" alt="" :style="{...getPosition(rightBtnPos), ...getSize(rightBtnSize)}"/>
        <img class="s-btn" v-if="loginAwards.status === 1" :src="rightBtnUnfinished.src" alt="" :style="{...getPosition(rightBtnPos), ...getSize(rightBtnSize)}"/>
        <img class="s-btn hv-highlight" v-if="loginAwards.status === 2" :src="rightBtnReceive.src" alt="" @click="receiveAward(2)" :style="{...getPosition(rightBtnPos), ...getSize(rightBtnSize)}"/>
        <img class="s-btn" v-if="loginAwards.status === 3" :src="rightBtnReceived.src" alt="" :style="{...getPosition(rightBtnPos), ...getSize(rightBtnSize)}"/>
        <img class="s-btn" v-if="loginAwards.status === 4" :src="rightBtnExpire.src" alt="" :style="{...getPosition(rightBtnPos), ...getSize(rightBtnSize)}"/>
    </div>
  </div>
</template>

<script>
import { getPosition, getSize } from '@/components/BuilderComponents/utils'

export default {
  name: 'SAct1',
  props: {
    request: {
      type: Function
    },
    activityId: {
      type: String,
      default: ''
    },
    mainImg: {
      type: String,
      required: true
    },
    /* 左侧按钮 */
    leftBtnSize: {
      type: String,
      default: '200px 50px'
    },
    leftBtnPos: {
      type: String,
      default: ''
    },
    leftBtnNoBegin: {
      type: Object,
      required: true
    },
    leftBtnUnfinished: {
      type: Object,
      required: true
    },
    leftBtnReceive: {
      type: Object,
      required: true
    },
    leftBtnReceived: {
      type: Object,
      required: true
    },
    leftBtnExpire: {
      type: Object,
      required: true
    },
    /* 右侧按钮 */
    rightBtnSize: {
      type: String,
      default: '200px 50px'
    },
    rightBtnPos: {
      type: String,
      default: ''
    },
    rightBtnNoBegin: {
      type: Object,
      required: true
    },
    rightBtnUnfinished: {
      type: Object,
      required: true
    },
    rightBtnReceive: {
      type: Object,
      required: true
    },
    rightBtnReceived: {
      type: Object,
      required: true
    },
    rightBtnExpire: {
      type: Object,
      required: true
    }
  },
  inject: {
    eventBus: {
      default: null
    },
    $MainWrap: {
      default: null
    }
  },
  data () {
    return {
      appointAwards: { status: 2 },
      loginAwards: { status: 1 },
      // 提示弹窗
      tipModel: {
        show: false,
        text: ''
      },
      // 奖励弹窗
      awardModel: {
        show: false,
        award: []
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    }
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.getCheckStatus)
      // 登出操作添加
      this.eventBus.on('signOut', this.reset)
    }
    // 初始化登录判断
    // eslint-disable-next-line no-undef
    $checkLogin()
    this.getCheckStatus()
  },
  watch: {
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.getCheckStatus()
        }
      },
      immediate: false
    }
  },
  methods: {
    getSize,
    getPosition,
    // 获取初始化状态
    getCheckStatus () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        this.appointAwards = { ...res.groups[0] }
        this.loginAwards = { ...res.groups[1] }

        // 检查是否处于活动时间
        console.log('> 活动1 检查时间')
        this.$MainWrap.checkStartEnd(res.start_at, res.end_at)
      }).catch((err) => {
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 领取左边的奖励
    receiveAward (type) {
      console.log(this.loginAwards.materials)
      // 判断登录
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      const params = {
        a_id: this.activityId
      }
      if (type === 1) {
        params.g_id = this.appointAwards.id
      } else if (type === 2) {
        params.g_id = this.loginAwards.id
      }
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params
      }).then((res) => {
        console.log(res)
        if (res.success) {
          let awardList = []
          if (type === 1) {
            awardList = this.appointAwards.materials
          } else if (type === 2) {
            awardList = this.loginAwards.materials
          }
          this.$MainWrap.openReceiveModal(awardList)
          this.getCheckStatus()
        } else {
          this.$message.error(res.msg || '系统错误')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    // 重置
    reset () {
      this.appointAwards.status = 2
      this.loginAwards.status = 1
      // this.getCheckStatus()
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> act1 取消登录监听')
      this.eventBus.off('loginSuccess', this.getCheckStatus)
      this.eventBus.off('signOut', this.reset)
    }
  }
}
</script>

<style scoped lang="scss">
// 公共样式
.auto-bg{
  background-repeat: no-repeat;
  background-size: 100%;
}
// 内容样式
.s-act{
  width: 100%;
  height: 966px;
  .main-img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .btn-group{
    .s-btn{
      position: absolute;
    }
  }
}
</style>
