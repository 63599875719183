import _mixin from './_mixin'
export default {
  name: 'BaseGroup',
  cnName: '组',
  // 属性相关
  props: {
    isFlex: false,
    alignItems: '',
    justifyContent: '',
    flexDirection: ''
  },
  propsDesc: [
    {
      target: 'isFlex',
      label: 'flex',
      formType: 'select',
      options: [
        { label: '否', value: false },
        { label: '是', value: true }
      ]
    },
    {
      target: 'alignItems',
      label: 'alignItems',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'center', value: 'center' },
        { label: 'flex-start', value: 'flex-start' },
        { label: 'flex-end', value: 'flex-end' },
        { label: 'baseline', value: 'baseline' }
      ]
    },
    {
      target: 'justifyContent',
      label: 'justifyContent',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'center', value: 'center' },
        { label: 'flex-start', value: 'flex-start' },
        { label: 'flex-end', value: 'flex-end' },
        { label: 'space-between', value: 'space-between' },
        { label: 'space-around', value: 'space-around' }
      ]
    },
    {
      target: 'flexDirection',
      label: 'flexDirection',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'column', value: 'column' },
        { label: 'column-reverse', value: 'column-reverse' },
        { label: 'row', value: 'row' },
        { label: 'row-reverse', value: 'row-reverse' }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    width: '100%',
    'min-height': '300px',
    'background-color': 'rgba(158,158,158, .3)',
    'background-image': 'url(https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/common/industrial/pc/images/part-bg5.jpg?v=1.04)'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
