import _mixin from './_mixin'

export default {
  name: 'STags',
  cnName: '标签列表',
  type: 'base',
  // 属性相关
  props: {
    tags: '标签1,标签2,标签3',
    fontSize: '30px',
    color: '#fff',
    border: '2px solid #fff',
    backgroundColor: 'rgba(0,0,0,0.3)',
    padding: '5px 20px',
    textAlign: 'left',
    itemOtherStyle: ''
  },
  propsDesc: [
    {
      target: 'tags',
      label: '标签列表'
    },
    {
      target: 'fontSize',
      label: '字号'
    },
    {
      target: 'color',
      label: '文字颜色'
    },
    {
      target: 'borderColor',
      label: '边框颜色'
    },
    {
      target: 'backgroundColor',
      label: '背景颜色'
    },
    {
      target: 'padding',
      label: '内边距'
    },
    // {
    //   target: 'margin',
    //   label: '背景颜色'
    // },
    {
      target: 'textAlign',
      label: '对齐方式',
      formType: 'radio',
      options: [
        { label: '左对齐', value: 'left' },
        { label: '居中对齐', value: 'center' },
        { label: '右对齐', value: 'right' }
      ]

    },
    {
      target: 'itemStyle',
      label: '补充样式',
      formType: 'textarea'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
