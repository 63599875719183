import _mixin from '../_mixin'

export default {
  name: 'SHelpModel',
  cnName: '助力弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    helpBgSize: '750px 530px',
    helpTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/0ca0e1a303e653f0d9464dc03026ce75.png',
    helpTitleSize: '450px 29px',
    gunBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/b87f3ef9b909f57ef9cf898f370b1340.png',
    awardBoxBgSize: '401px 112px',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    helpBtnColor: '#FFFFFF',
    helpBtnText: '帮TA助力',
    helpBtnSize: '192px 57px',
    textIcon: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/8837dd8e3c10f93fcdcc6f952ff32b14.png',
    helpRuleTextList: [
      {
        ruleText: '每位狙友仅可助力一次；'
      },
      {
        ruleText: '2023年01月13日-2023年02月12日，每邀请一位好友完成助力，你将获得1能量，积攒5能量即可解锁奖励；'
      }
    ]
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'helpBgSize',
      label: '助力弹窗背景大小'
    },
    {
      target: 'helpTitle',
      label: '助力弹窗标题'
    },
    {
      target: 'helpTitleSize',
      label: '助力弹窗标题尺寸',
      description: '宽 高'
    },
    {
      target: 'gunBg',
      label: '助力奖励背景'
    },
    {
      target: 'awardBoxBgSize',
      label: '助力奖励背景大小'
    },
    {
      target: 'btnStatusOne',
      label: '公共按钮背景'
    },
    {
      target: 'helpBtnColor',
      label: '按钮颜色'
    },
    {
      target: 'helpBtnText',
      label: '按钮文案'
    },
    {
      target: 'helpBtnSize',
      label: '按钮大小'
    },
    {
      target: 'textIcon',
      label: '文案前面的icon'
    },
    {
      target: 'helpRuleTextList',
      label: '活动规则',
      formType: 'list',
      listConfig: [
        {
          title: '活动规则文案',
          target: 'ruleText'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
