<template>
  <div class="s-component">
    <div class="ss-button"
         v-show="visible"
         :style="{...style, ...textStyleObj}"
         @click="openModal"
         @mouseover="isHover = true"
         @mouseout="isHover = false">
      {{ text }}
    </div>
  </div>

</template>

<script>

export default {
  name: 'SButtonTip',
  components: {
  },
  props: {
    defaultBg: {
      type: String,
      default: ''
    },
    hoverBg: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '200px 150px' // 宽高
    },
    text: {
      type: String,
      default: ''
    },
    btnOtherStyle: {
      type: String,
      default: '{}'
    },
    title: {
      type: String,
      default: '活动规则'
    },
    html: {
      type: String,
      default: '活动龟规则活动龟规则活动龟规则活动龟规则活动龟规则活动龟规则活动龟规则活动龟规则活动龟规则 '
    }
  },
  data () {
    return {
      visible: true,
      isHover: false
    }
  },
  inject: {
    // 由 MainWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  computed: {
    style () {
      const arr = this.size.split(' ')
      return {
        width: arr[0] || '200px',
        height: arr[1] || '50px',
        'background-image': `url(${this.isHover ? (this.hoverBg || this.defaultBg) : this.defaultBg})`
      }
    },
    textStyleObj () {
      try {
        return JSON.parse(this.btnOtherStyle)
      } catch (e) {
        console.error('SButtonShare style 格式错误')
        return {}
      }
    }
  },
  methods: {
    openModal () {
      this.$MainWrap.confirm({
        content: this.html,
        title: this.title,
        cancelBtnText: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: .3s;
  text-decoration: none;
  color: inherit;
  user-select: none;
  &:hover{
    filter: brightness(110%)
  }
}
</style>
