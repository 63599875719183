import _mixin from '../_mixin'
// import BaseGroup from './BaseGroup'

const defaultImg1 = 'https://rescdn.ssjj.cn/website/2024/01/29/fc525e509ce4f2d8905095e20c87f078.png'
const activeImg1 = 'https://rescdn.ssjj.cn/website/2024/01/29/f69f974db12802076b0cd19ac7619901.png'
const defaultImg2 = 'https://rescdn.ssjj.cn/website/2024/01/29/44ab0fb5d9a926ad5706b7745642f2e1.png'
const activeImg2 = 'https://rescdn.ssjj.cn/website/2024/01/29/bf41f5f0de95ddf54324f808fabeb15f.png'
const defaultImg3 = 'https://rescdn.ssjj.cn/website/2024/01/29/a0c5461eb4ce8315efad1cd234d3a702.png'
const activeImg3 = 'https://rescdn.ssjj.cn/website/2024/01/29/ea725b179ce1b317f870c9dac742d26f.png'
const defaultImg4 = 'https://rescdn.ssjj.cn/website/2024/01/29/0bde8257a5e9cbc503a642dd3e211223.png'
const activeImg4 = 'https://rescdn.ssjj.cn/website/2024/01/29/30da138ed7dee4405682473d02cc1633.png'
const defaultImg5 = 'https://rescdn.ssjj.cn/website/2024/01/29/6d858194e3d321dc1e4c1de6bff8f535.png'
const activeImg5 = 'https://rescdn.ssjj.cn/website/2024/01/29/a38ba270442bf8262335b315548b1a30.png'
// function genId () {
//   return Date.now()
// }

export default {
  name: 'SFiveWrap',
  cnName: '五合一容器',
  type: 'activity_five',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/01/29/77eef94cd888645419997b5931fc8c8b.jpg',
  // 属性相关
  props: {
    list: [
      { img: defaultImg1, activeImg: activeImg1, isNeedLogin: 0 },
      { img: defaultImg2, activeImg: activeImg2, isNeedLogin: 0 },
      { img: defaultImg3, activeImg: activeImg3, isNeedLogin: 0 },
      { img: defaultImg4, activeImg: activeImg4, isNeedLogin: 0 },
      { img: defaultImg5, activeImg: activeImg5, isNeedLogin: 0 }
    ],
    bg: 'https://rescdn.ssjj.cn/website/2024/01/29/a2555e73a311e6f8d5212f0d7b196f43.jpg',
    modalBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/30/86c1aac7bc41c347173a1f7df26a39da.png',
      style: {
        width: '750px',
        height: '500px'
      }
    },
    modalCloseIcon: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/30/8a1698878335f98187a3b9fb1e60f6a8.png',
      style: {
        width: '40px',
        height: '40px',
        right: '10px',
        top: '10px'
      }
    },
    modalTitleStyle: '{}',
    modalTextStyle: '{"color": "#A4F1FF"}',
    modalConfirmBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/01/30/50471ebeffbabb3e7bfb7ba155f933bb.png)","color": "#50356D", "font-weight": "700"}',
    modalCancelBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/01/30/5f5077047474ed8a9fde19936a0dcd21.png)"}'
  },
  propsDesc: [
    {
      target: 'list',
      label: '按钮列表',
      formType: 'list',
      listConfig: [
        {
          title: '默认图',
          target: 'img'
        },
        {
          title: '选中图',
          target: 'activeImg'
        },
        {
          title: '是否需要登录',
          target: 'isNeedLogin'
        }
      ]
    },
    {
      target: 'bg',
      label: '主背景图'
    },

    {
      target: '公共弹窗',
      formType: 'divider'
    },
    {
      target: 'modalBg',
      label: '弹窗背景图',
      formType: 'image'
    },
    {
      target: 'modalCloseIcon',
      label: '关闭按钮',
      formType: 'image'
    },
    {
      target: 'modalTitleStyle',
      label: '标题样式',
      formType: 'textarea'
    },
    {
      target: 'modalTextStyle',
      label: '文本样式',
      formType: 'textarea'
    },
    {
      target: 'modalConfirmBtnStyle',
      label: '确认按钮样式',
      formType: 'textarea'
    },
    {
      target: 'modalCancelBtnStyle',
      label: '取消按钮样式',
      formType: 'textarea'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: [
    // {
    //   id: genId() + '_BaseGroup_0',
    //   ...BaseGroup
    // },
    // {
    //   id: genId() + '_BaseGroup_1',
    //   ...BaseGroup
    // }
  ]
}
