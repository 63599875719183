import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'

export default {
  name: 'STopic2Map',
  cnName: '场景地图',
  type: 'activity_topic2',
  isTab: true,
  preview: '',
  // 属性相关
  props: {
    // ..._mixinWrap.props,
    autoplay: false,
    list: [
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/1661320b553ba86d9cb5dff06f16eb87.jpg'
      },
      {
        img: 'https://rescdn-crash.ssjj.cn/website/2023/03/21/ea7f78ccc208b7c109865b3f0205e7ff.jpg'
      }
    ],
    title: '场景地图',
    tags: '爆破战,团队战',
    desc: 'lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, voluptate.'
  },
  propsDesc: [
    // ..._mixinWrap.propsDesc,
    {
      target: 'autoplay',
      label: '自动播放',
      formType: 'switch'
    },
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
