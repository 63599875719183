<template>
  <div class="page-builder">
<!--    <SResizeBox default-width="350px">-->
    <PartLeft/>
<!--    </SResizeBox>-->
    <SResizeBox default-width="70vw">
      <PartCenter/>
    </SResizeBox>
    <PartRight ref="partRight"/>
  </div>
</template>

<script>
import PartRight from './PartRight.vue'
import PartLeft from './PartLeft.vue'
import PartCenter from './PartCenter.vue'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import SResizeBox from '@/components/PageBuilder/_components/ResizeBox.vue'

export default {
  name: 'PageBuilder',
  components: {
    SResizeBox,
    PartRight,
    PartCenter,
    PartLeft
  },
  provide () {
    return {
      pageBuilder: this
    }
  },
  data () {
    return {
      // 拖拽
      // isMove: false,
      // deltaLeft: 0,
      // deltaTop: 0
      timer: null
    }
  },
  computed: {
    ...mapGetters(['page'])
  },
  mounted () {
    process.env.NODE_ENV !== 'development' && this.initUniqueEnv()
    // 初始化根节点
    setTimeout(() => {
      this.initRootComponent()
    }, 300)
    // 开启自动保存
    !this.timer && this.autoSave()
  },
  methods: {
    /**
     * 初始化运行环境监听
     * 同一个浏览器不允许打开多个此工具的页面（tab）
     * 因为自动保存功能使用了本地存储，多个tab, 会造成存储数据互相覆盖，造成预览页面错乱
     */
    initUniqueEnv () {
      const broadcastChannel = new BroadcastChannel('PageBuilder')
      broadcastChannel.onmessage = (e) => {
        console.log('message', e.data)
        console.log('message', e)
        if (e.data === 'page_builder_come_in') { // 检测到当前地址在另一个页面打开了
          broadcastChannel.postMessage('page_builder_exist') // 发送builder存在
        }
        if (e.data === 'page_builder_exist') { // 其他页面应答通知 builder 已存在
          this.$alert('模版编辑器已在此浏览器的其他窗口打开，同时存在多个编辑器会造成数据存储混乱。点击确认关闭当前窗口', '重要提示', {
            type: 'warning',
            callback: action => {
              window.opener = null
              window.open('', '_self')
              window.close()
            }
          })
        }
      }
      broadcastChannel.postMessage('page_builder_come_in')
    },
    /**
     * 初始化根节点
     */
    initRootComponent () {
      this.page.tree.length === 0 && this.$store.dispatch('addComponent', 'BasePage')
      // console.log(this.page.tree)
      this.$store.commit('SET_CURRENT_ID', this.$store.getters.page.tree[0].id)
    },
    /**
     * 自动保存tree 到本地存储
     */
    autoSave () {
      this.timer = setInterval(() => {
        this.$store.commit('SAVE_TREE_TO_LOCALSTORAGE')
        console.log('> 自动保存:' + dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss'))
      }, 60000)
    },
    onStartMove (e, comp, index) {
      this.switchCurrentComponent(index)
      /*
      * @des deltaLeft 即移动过程中不变的值
      */
      this.deltaLeft = e.clientX - e.target.offsetLeft
      this.deltaTop = e.clientY - e.target.offsetTop
      this.isMove = true
    },
    onMove (e) {
      if (this.isMove) {
        const cx = e.clientX
        const cy = e.clientY
        /** 相减即可得到相对于父元素移动的位置 */
        let dx = cx - this.deltaLeft
        let dy = cy - this.deltaTop

        /** 防止超出父元素范围 */
        if (dx < 0) dx = 0
        if (dy < 0) dy = 0
        if (dx > 375) dx = 375
        if (dy > 720) dy = 720
        this.componentRenderList[this.currentComponentIndex].styles.top = `${dy}px`
        this.componentRenderList[this.currentComponentIndex].styles.left = `${dx}px`
        // icon.setAttribute('style', `left:${dx}px;top:${dy}px`)
      }
    },
    onFinishMove () {
      this.isMove = false
    }

    // save () {
    //   this.componentRenderList.forEach(item => {
    //     item.eventsList && item.eventsList.forEach(_item => {
    //       item.events[_item.type] = () => {
    //         console.log(this.$refs[_item.target + ''])
    //         console.log(_item.fn)
    //         this.$refs[_item.target + ''][0][_item.fn]()
    //       }
    //     })
    //   })
    //   this.$forceUpdate()
    // }
  },
  beforeDestroy () {
    console.log('beforeDestroy')
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-builder{
  display: flex;
  height: 100vh;
}
</style>
