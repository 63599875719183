import _mixin from '../_mixin'

export default {
  name: 'SAct1',
  cnName: '活动1',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/01/29/0a5a072993df2756b42344d8deff972b.jpg',
  // 属性相关
  props: {
    activityId: 'MTQUJ3REIBWFM2WXWE4YGNRSIA',
    // 主图
    mainImg: 'https://rescdn.ssjj.cn/website/2024/01/29/0416b96c8256b2f0e87bf2b8abd2652f.png',
    // 左侧按钮
    leftBtnSize: '360px 100px',
    leftBtnPos: '814px _ _ 328px',
    leftBtnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/67b89552ab28e1234f938e8213e365ac.png'
    },
    leftBtnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/799310280c06dc45c7c7769ee95dde0f.png'
    },
    leftBtnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/28c9b20472c485f9b9948a8ec62a238c.png'
    },
    leftBtnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c37cf7d6d93de034025df9b81dbfee42.png'
    },
    leftBtnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/4302923e1bd3311edb392c03de82f896.png'
    },
    // 右侧按钮
    rightBtnSize: '360px 100px',
    rightBtnPos: '814px _ _ 1228px',
    rightBtnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/67b89552ab28e1234f938e8213e365ac.png'
    },
    rightBtnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/799310280c06dc45c7c7769ee95dde0f.png'
    },
    rightBtnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/35ee1c7fe12c538cacc1cb5ee8e0dda4.png'
    },
    rightBtnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c37cf7d6d93de034025df9b81dbfee42.png'
    },
    rightBtnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/4302923e1bd3311edb392c03de82f896.png'
    }
  },
  propsDesc: [
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'mainImg',
      label: '主图'
    },
    // ======== 左侧按钮 ========
    {
      target: '左侧按钮',
      formType: 'divider'
    },
    {
      target: 'leftBtnSize',
      label: '宽高',
      formType: 'size'
    },
    {
      target: 'leftBtnPos',
      label: '位置',
      formType: 'position'
    },
    {
      target: 'leftBtnNoBegin',
      label: '未开始',
      formType: 'image'
    },
    {
      target: 'leftBtnUnfinished',
      label: '未完成',
      formType: 'image'
    },
    {
      target: 'leftBtnReceive',
      label: '待领取',
      formType: 'image'
    },
    {
      target: 'leftBtnReceived',
      label: '已领取',
      formType: 'image'
    },
    {
      target: 'leftBtnExpire',
      label: '已过期',
      formType: 'image'
    },
    // ======== 右侧按钮 ========
    {
      target: '右侧按钮',
      formType: 'divider'
    },
    {
      target: 'rightBtnSize',
      label: '宽高',
      formType: 'size'
    },
    {
      target: 'rightBtnPos',
      label: '位置',
      formType: 'position'
    },
    {
      target: 'rightBtnNoBegin',
      label: '未开始',
      formType: 'image'
    },
    {
      target: 'rightBtnUnfinished',
      label: '未完成',
      formType: 'image'
    },
    {
      target: 'rightBtnReceive',
      label: '待领取',
      formType: 'image'
    },
    {
      target: 'rightBtnReceived',
      label: '已领取',
      formType: 'image'
    },
    {
      target: 'rightBtnExpire',
      label: '已过期',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
