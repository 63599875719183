<!--助力分享-->
<!--仅仅完成简单的分享&助力功能-->
<!--需要绑定活动Id 以获取分享码-->
<template>
  <ModalCommon ref="ModalCommon"
               :title="config.title">
    <div class="wrap">
      <div class="content">
        <div class="sub-title">复制链接分享给好友</div>
        <div class="link-wrap">
          <p class="bg-cv">{{shareLink}}</p>
          <div class="line"></div>
          <div class="btn-share hv-highlight flex-shrink-0" @click="copy">复制</div>
        </div>
      </div>
    </div>
  </ModalCommon>
</template>

<script>
import { copyContent } from '../utils'
import ModalCommon from './ModalCommon'
export default {
  name: 'ModalShare',
  components: {
    ModalCommon
  },
  props: {
    activityId: {
      type: String,
      default: '0'
    },
    tipText: {
      type: String,
      default: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> '
    }
  },
  data () {
    return {
      visible: false,
      shareLink: '',
      myCode: '',
      config: {
        title: '分享好友'
      }
    }
  },
  inject: {
    $MainWrap: {
      default: null
    },
    eventBus: {
      default: null
    }
  },
  computed: {
    requestAPI () {
      return this.request ? this.request : window.$request
    }
  },
  async created () {
    this.getShareCode()
  },
  mounted () {
    if (this.eventBus) {
      // 登录成功操作添加
      this.eventBus.on('loginSuccess', this.getShareCode)
      // 登出操作添加
      this.eventBus.on('signOut', this.getShareCode)
    }
  },
  methods: {
    open (options) {
      if (!window.$checkLogin()) return
      this.config = Object.assign({
        title: '分享好友'
      }, options)
      this.$refs.ModalCommon.open()
    },
    getShareCode () {
      if (!window.$checkLogin()) return
      const { code } = this.$route.query
      return this.requestAPI({
        url: '/v1/official/help',
        method: 'get',
        params: {
          a_id: this.activityId,
          code
        }
      }).then(data => {
        this.myCode = data.code
        this.initLink()
        if (data.help === 3) {
          this.$message.success('助力成功')
        } else if (data.help === 0) {
          // this.$message.success('助力失败')
        } else if (data.help === 4) {
          this.$message.error('助力失败,已为该账号助力')
        } else {
          this.$message.error('助力失败,已助力其他人或助力已满')
        }
        if (code) this.clearPath()
      }).catch(err => {
        console.error(err)
        this.$message.error(err.msg || '系统错误')
      })
    },
    initLink () {
      const link = window.location.origin + window.location.pathname
      const { from, wdFrom } = this.$route.query
      const fromQuery = `${from ? '&from=' + from : ''}`
      let wdFromQuery = `${wdFrom ? '&wdFrom=' + wdFrom : ''}`
      if (wdFrom === 'website' || wdFrom === 'lenovo') {
        wdFromQuery = ''
      }
      // this.role_name = teamInfo.role_name
      // this.shareLink = link + (link.indexOf('?') > -1 ? '&' : '?') + 'code=' + this.code + '&role_name=' + encodeURIComponent(this.role_name) + wdFromQuery + fromQuery
      this.shareLink = link + (link.indexOf('?') > -1 ? '&' : '?') + 'code=' + this.myCode + wdFromQuery + fromQuery
    },
    copy () {
      copyContent(
        `${this.tipText} ${this.shareLink}`
      ).then(() => {
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      })
    },
    // 检查是否是客态助力页面
    isGuideView () {
      const { code } = this.$route.query
      return !!code
    },
    // 好有助力
    // help (code, role_name) {
    //   const helpFn = this.$parent.phase === 1 ? secretTreasureHelp : secretTreasureV2Help
    //   helpFn({ code }).then(data => {
    //     console.log('----- help -----')
    //     console.log(data)
    //     const name = role_name ? `【${decodeURIComponent(role_name)}】` : ''
    //     this.tipFn({ msg: `成功助力好友${name}，快去告诉他吧~` })
    //     this.clearPath()
    //   }).catch(err => {
    //     this.tipFn(err)
    //     this.clearPath()
    //   })
    // },
    clearPath () {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      delete query.code
      delete query.role_name
      this.$router.replace({
        path: window.location.pathname,
        query
      })
    }
  },
  beforeDestroy () {
    if (this.eventBus) {
      // console.log('> act1 取消登录监听')
      this.eventBus.off('loginSuccess', this.getShareCode)
      this.eventBus.off('signOut', this.getShareCode)
    }
  }
}
</script>

<style scoped lang="scss">
.wrap{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 60px 100px;
  .sub-title{
    text-align: center;
    margin-bottom: 40px;
    font-size: 30px;
  }
  .link-wrap{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0,0,0, .6);
    border-radius: 4px;
    p{
      flex: 1;
      height: 65px;
      line-height: 65px;
      padding: 0 20px;
      color: #fff;
      //background-image: url("../../../assets/images/fungus/link_bg.png");
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: normal;
      font-size: 20px;
    }
    .line{
      width: 1px;
      height: 40px;
      background-color: #fff;
    }
    .btn-share{
      margin-left: 10px;
      width: 177px;
      font-size: 27px;
      height: 73px;
      line-height: 73px;
      text-align: center;
      color: #eee;
      &:hover{
        color: #fff;
      }
    }
  }
}
</style>
