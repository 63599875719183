import _mixin from '../_mixin'

export default {
  name: 'SAwardModel',
  cnName: '奖励弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    awardBgSize: '750px 450px',
    awardTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/dd63755a299381588c4e41b75541c1d4.png',
    awardTitleSize: '446px 29px',
    awardOtherDesc: '请前往邮箱查收奖励',
    awardBgImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/b3d2ea367a040f0c4f8791b415007fc4.png',
    awardBoxBgSize: '201px 132px',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    awardBtnColor: '#FEEACB',
    awardBtnText: '开心收下',
    awardBtnSize: '184px 55px'
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'awardBgSize',
      label: '奖励背景大小'
    },
    {
      target: 'awardTitle',
      label: '奖励标题'
    },
    {
      target: 'awardTitleSize',
      label: '奖励标题尺寸',
      description: '宽 高'
    },
    {
      target: 'awardOtherDesc',
      label: '奖励弹窗描述'
    },
    {
      target: 'awardBgImg',
      label: '奖励背景图'
    },
    {
      target: 'awardBoxBgSize',
      label: '奖励背景图大小'
    },
    {
      target: 'btnStatusOne',
      label: '奖励按钮背景'
    },
    {
      target: 'awardBtnColor',
      label: '按钮文案颜色'
    },
    {
      target: 'awardBtnText',
      label: '奖励按钮文案'
    },
    {
      target: 'awardBtnSize',
      label: '奖励按钮大小'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
