<template>
  <div class="part-left">

    <el-checkbox-group v-model="checkboxGroup" size="mini" class="mb5">
      <el-checkbox-button v-for="item in ['组件', '组件树']" :label="item" :key="item">{{ item }}</el-checkbox-button>
    </el-checkbox-group>

    <div class="modules-wrap">
      <el-card shadow="never" class="module" v-show="checkboxGroup.includes('组件')" :body-style="{padding: '0 5px'}">
        <el-collapse v-model="activeNames">
          <el-collapse-item :title="item.groupName"
                            :name="item.groupName"
                            v-for="(item,index) in componentDemoList"
                            :key="index">
            <div class="component-list">
              <!--              <div class="item" @click="addComponent(_item.component)" v-for="(_item,_index) in item.children" :key="_index">{{componentConfigs[_item.component].cnName}}</div>-->
              <div class="item"
                   @click="addComponent(_item)"
                   v-for="(_item,_index) in item.children"
                   :key="_index">{{ _item.name }}
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="※自定义组件" name="自定义组件">
            <div class="component-list">
              <el-tag size="small"
                      class="mr5 mb5 cursor-pointer"
                      closable
                      @close="delCustomComponent(index)"
                      @click="addCustomComponent(item.component)"
                      v-for="(item,index) in page.customComponents"
                      :key="item.name">
                {{ item.name }}
              </el-tag>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-card shadow="never" class="module ml5" v-show="checkboxGroup.includes('组件树')"
               :body-style="{padding: '0 5px'}">
        <el-tree :data="page.tree"
                 node-key="id"
                 :draggable="true"
                 :allow-drop="allowDrop"
                 :props="{label: 'cnName'}"
                 :current-node-key="page.currentId"
                 @node-click="chooseComponent"
                 :expand-on-click-node="false"
                 highlight-current
                 default-expand-all>
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <div class="icons" @click.stop="() => {}">
              <i class="icon el-icon-document-copy hv-blue"
                 v-clipboard:copy="data.id"
                 v-clipboard:success="onCopySuccess"></i>
              <i v-if="data.name !== 'BasePage'" class="icon el-icon-close hv-blue" @click="() => delComponent(node, data)"></i>
            </div>
          </div>
        </el-tree>
      </el-card>
    </div>
    <!--    <el-button size="small" @click="save">保存</el-button>-->
    <!--    <el-button size="small" @click="openStatus">查看页面数据</el-button>-->
    <!--    <el-button size="small" @click="isEdit = !isEdit">{{isEdit ? '预览模式' : '编辑模式'}}</el-button>-->

  </div>
</template>

<script>
import config from '@/components/BuilderComponentsConfig'
// import { addTreeNodeById } from './utils/tree'
import { mapGetters } from 'vuex'

const componentDemoList = [
  {
    groupName: '容器组件',
    children: [
      {
        name: 'Group 组',
        component: 'BaseGroup'
      },
      {
        name: 'Dialog 弹窗',
        component: 'SDialog'
      }
    ]
  },
  {
    groupName: '基础组件',
    children: [
      // { name: '按钮', component: 'ElButton' },
      {
        name: 'Button 按钮',
        component: 'SButton'
      },
      // { name: '星级', component: 'ElRate' },
      {
        name: 'Image 图片',
        component: 'BaseImage'
      },
      {
        name: 'Text 文本',
        component: 'SText'
      }
    ]
  },
  {
    groupName: '复合组件',
    children: [
      {
        name: 'Login 登录注册',
        component: 'SLogin'
      },
      {
        name: 'Banner 头图',
        component: 'SBanner'
      },
      {
        name: 'Pendant 挂件',
        component: 'SPendant'
      },
      {
        name: 'AnchorNav 锚点导航',
        component: 'SAnchorNav'
      },
      {
        name: 'Swiper 轮播',
        component: 'BaseSwiper'
      },
      {
        name: 'Tab 标签页',
        component: 'STab'
      },
      {
        name: 'Live 直播',
        component: 'SLive'
      },
      {
        name: 'Video 视频',
        component: 'SVideo'
      },
      {
        name: 'VideoList 视频列表',
        component: 'SVideoList'
      },
      {
        name: 'RoleList 角色列表',
        component: 'SRoleList'
      },
      {
        name: '图片列表',
        component: 'BaseImageList'
      },
      {
        name: 'x Footer 公共底部',
        component: 'SFooter'
      },
      {
        name: '收货地址',
        component: 'SAddress'
      }
    ]
  },
  {
    groupName: '通用弹窗模板组件',
    children: [
      {
        name: '提示弹窗',
        component: 'STipModel'
      },
      {
        name: '奖励弹窗',
        component: 'SAwardModel'
      },
      {
        name: '分享弹窗',
        component: 'SShareModel'
      },
      {
        name: '助力弹窗',
        component: 'SHelpModel'
      },
      {
        name: '记录弹窗',
        component: 'SRecordModel'
      },
      {
        name: '视频弹窗',
        component: 'SVideoModel'
      }
    ]
  },
  {
    groupName: '五合一模板组件',
    children: [
      {
        name: '新版本福利',
        needLogin: true,
        component: 'SActivityOne'
      },
      {
        name: '每周战斗任务',
        needLogin: true,
        component: 'SActivityThree'
      },
      {
        name: '每周末战斗任务',
        needLogin: true,
        component: 'SActivityFour'
      }
    ]
  },
  {
    groupName: '专题页模板组件',
    children: [
      {
        name: '头图',
        component: 'STopicKV'
      },
      {
        name: '转盘枪',
        component: 'STopicTurntableWeapon'
      },
      {
        name: '卡池枪',
        component: 'STopicCardPoolWeapon'
      },
      {
        name: '更多武器',
        component: 'STopicMoreWeapon'
      },
      {
        name: 'PVE模式',
        component: 'STopicPVE'
      },
      {
        name: '精彩活动',
        component: 'STopicActivity'
      },
      {
        name: '更多角色',
        component: 'STopicMoreRole'
      },
      {
        name: '底图',
        component: 'STopicFoot'
      }
    ]
  }
]
export default {
  name: 'part-left',
  props: {},
  watch: {
    // innerList (newVal) {
    //   this.$emit('update:list', newVal)
    // }
  },
  inject: ['pageBuilder'],
  computed: {
    ...mapGetters(['page'])
  },
  data () {
    return {
      statusVisible: false,
      isEdit: true,
      componentDemoList, // 左侧示例组件列表
      componentConfigs: config,

      // activeName: 'components',
      checkboxGroup: ['组件'],
      activeNames: ['容器组件', '基础组件', '复合组件', '通用弹窗模板组件', '五合一模板组件', '专题页模板组件', '自定义组件'],

      // 是否存在登录
      isLogin: false
    }
  },
  created () {
  },
  methods: {
    addComponent (_item) {
      if (this.page.isChoose) {
        this.$message.warning('当前处于选择联动组件状态，无法添加组件')
        return false
      }
      // // 判斷添加的組件是否需要登录，需要则判断添加的组件之前是否有登录组件添加，没有提示先引入登录
      // if (_item.needLogin) {
      //   if (!this.page.tree[0].children) {
      //     this.$message.warning('请先引入登录组件，再引入该组件')
      //     return
      //   }
      //   this.isIncludeLogin(this.page.tree[0].children)
      //   if (!this.isLogin) {
      //     this.$message.warning('请先引入登录组件，再引入该组件')
      //     return
      //   }
      // }
      this.$store.dispatch('addComponent', _item.component)
    },
    // 递归循环判断是否有登录组件
    // isIncludeLogin (children) {
    //   for (let i = 0; i < children.length; i++) {
    //     if (children[i].id.indexOf('SLogin_') !== -1) {
    //       this.isLogin = true
    //     }
    //     if (this.isLogin) {
    //       return
    //     }
    //     if (children[i].children) {
    //       this.isIncludeLogin(children[i].children)
    //     }
    //   }
    // },
    delComponent (node, data) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('delComponent', data.id)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    chooseComponent (data) {
      // console.log(data.id)
      // this.$store.commit('SET_CURRENT_ID', data.id)
      if (!this.isEdit) return
      if (this.page.isChoose) {
        this.pageBuilder.$refs.partRight.switchControlledComponent(data)
      } else {
        this.$store.commit('SET_CURRENT_ID', data.id)
      }
    },

    onCopySuccess () {
      this.$message.success('复制成功')
    },
    allowDrop (draggingNode, dropNode, type) {
      let flag = true
      console.log(dropNode)

      if (dropNode.data.name === 'BasePage' && type !== 'inner') { // 不允许移到根节点前后
        flag = false
      } else if (!dropNode.data.children && type === 'inner') { // 不允许移到没有子节点的元素内
        flag = false
      }
      return flag
    },
    /**
     * 添加自定义组件
     * @param component
     */
    addCustomComponent (component) {
      this.$store.dispatch('addCustomComponent', component)
    },
    /**
     * 删除自定义组件
     * @param index
     */
    delCustomComponent (index) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$store.commit('DEL_CUSTOM_COMPONENT', index)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.part-left {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
  background-color: #fff;

  .modules-wrap {
    display: flex;

    .module {
      width: 200px;
      //flex: 50%;
    }
  }
  ::v-deep(.el-tree-node__content){
    transition: .3s;
    .custom-tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      .icons {
        display: none;
        font-size: 14px;
        .icon {
          margin-left: 4px;
        }
      }

    }
    &:hover {
      .icons {
        display: block !important;
      }
    }
  }

  .component-list {
    .item {
      width: 100%;
      line-height: 28px;
      cursor: pointer;

      .el-tag {
      }

      &:hover {
        color: #409EFF;
      }
    }
  }
}
</style>
