<template>
  <div class="s-banner">
    <a :href="logoLink" class="s-banner--logo" target="_blank" v-if="hasLogo">
      <img :src="logoSrc" alt="">
    </a>
    <img
      class="s-banner--img"
      :src="bannerSrc"
      alt=""
      :style="bannerStyle"
      v-show="visible">
  </div>
</template>

<script>
export default {
  name: 'SBanner',
  props: {
    bannerSrc: {
      type: String,
      required: true
    },
    bannerSize: {
      type: String,
      default: '1920 987px' // 宽高
    },
    hasLogo: {
      type: Boolean,
      default: true
    },
    logoSrc: {
      type: String,
      required: 'https://h5.ssjj.cn/common/industrial/pc/images/logo.png?v=1667439096388'
    },
    logoLink: {
      type: String,
      default: 'https://www.ssjj.cn/'
    }
  },
  data () {
    return {
      visible: true
    }
  },

  computed: {
    bannerStyle () {
      const arr = this.bannerSize.split(' ')
      return {
        width: arr[0] || '1920px',
        height: arr[1] || '987px'
      }
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    handleClick () {
      window.open(this.link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.s-banner {
  position: relative;
  .s-banner--logo{
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
  }
  .s-banner--img{
    vertical-align: bottom;
  }

  &.pointer {
    cursor: pointer;
  }
}
</style>
