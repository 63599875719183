import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopicMoreRole',
  cnName: '角色展示',
  type: 'activity_topic',
  preview: 'https://rescdn-crash.ssjj.cn/website/2023/03/31/020a48a60eacea42686d651d9ab64a5a.jpeg',
  // 属性相关
  props: {
    ..._mixinWrap.props,
    roleList: [
      {
        contentBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/98ba8379fa8af1edf6140def5c1148c9.jpg',
        roleImg: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/role1.e460bdce.png',
        roleImgSize: '290px 490px',
        roleImgPosition: '74px - - 70px',
        roleDescImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/abb9ea2d7595e69880f03edd7088bea7.png',
        roleDescPosition: '- - 25px -35px'
      },
      {
        contentBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/9496f4550ea97cc8128bfc40101a3584.png',
        roleImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/11f02f29c63b6e6cc7cbc11263581e27.png',
        roleImgSize: '260px 680px',
        roleImgPosition: '-20px - - 80px',
        roleDescImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/abb9ea2d7595e69880f03edd7088bea7.png',
        roleDescPosition: '- - 25px -35px'
      }
    ],
    contentBgSize: '430px 620px',
    roleDescSize: '500px 70px',
    animateType: 'zoom'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'roleList',
      label: '角色列表',
      formType: 'list',
      listConfig: [
        {
          title: '背景图',
          target: 'contentBg'
        },
        {
          title: '角色图',
          target: 'roleImg'
        },
        {
          title: '角色尺寸',
          target: 'roleImgSize'
        },
        {
          title: '角色位置',
          target: 'roleImgPosition'
        },
        {
          title: '角色描述图',
          target: 'roleDescImg'
        },
        {
          title: '角色描述图位置',
          target: 'roleDescPosition'
        }
      ]
    },
    {
      label: '角色背景尺寸',
      target: 'contentBgSize'
    },
    {
      label: '角色描述尺寸',
      target: 'roleDescSize'
    },
    {
      target: 'animateType',
      label: '动画类型',
      formType: 'radio',
      options: [
        { label: 'zoom', value: 'zoom' },
        { label: 'shake', value: 'shake' }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
