<template>
  <div class="record-box">
    <div class="btn-record img-bg" :style="btnStyle" @click="recordFun">{{btnRecordText}}</div>
    <RecordModel
      :modelShow.sync="recordModel.show"
      :modelType = 0
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :modelBgSize="recordBgSize"
      :recordTitle="recordTitle"
      :recordTitleSize="recordTitleSize"
      :btnStatusOne="btnStatusOne"
      :btnStatusTwo="btnStatusTwo"
      :recordBtnColor="recordBtnColor"
      :recordBtnSize="recordBtnSize"
      :recordData="recordModel.data"
      :currentPage="currentPage"
      :totalPageSize="totalPageSize"
      :pageSize="pageSize"
      @upAndDown="upAndDown"
    ></RecordModel>
    <!--提示弹窗-->
    <TipModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipBgSize="recordBgSize"
      :tipTitle="tipTitle"
      :tipTitleSize="tipTitleSize"
      :btnStatusOne="btnStatusOne"
      :tipBtnColor="recordBtnColor"
      :tipBtnText="btnCloseText"
      :tipBtnSize="recordBtnSize"
      :contentText="tipModel.text"
    >
    </TipModel>
  </div>
</template>

<script>
import TipModel from '@/components/BuilderComponents/Dialog/TipModel.vue'
import RecordModel from '@/components/BuilderComponents/Dialog/RecordModel.vue'

export default {
  name: 'SRecord',
  components: { RecordModel, TipModel },
  props: {
    activityId: {
      type: String,
      default: ''
    },
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#AD272D'
    },
    modelClose: {
      type: String,
      default: ''
    },
    recordBgSize: {
      type: String,
      default: ''
    },
    recordTitle: {
      type: String,
      default: ''
    },
    recordTitleSize: {
      type: String,
      default: '419px 29px'
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    btnStatusTwo: {
      type: String,
      default: ''
    },
    recordBtnColor: {
      type: String,
      default: ''
    },
    recordBtnSize: {
      type: String,
      default: ''
    },
    btnCloseText: {
      type: String,
      default: ''
    },
    btnRecordText: {
      type: String,
      default: ''
    },
    recordData: {
      type: Array,
      default: () => []
    },
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      recordModel: {
        show: false,
        data: []
      },
      currentPage: 1,
      totalPageSize: 0,
      pageSize: 10,
      // 提示弹窗文案
      tipModel: {
        show: false,
        text: ''
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    totalPage () {
      return Math.ceil(this.totalPageSize / this.pageSize)
    },
    modelStyle () {
      const arr = this.recordBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '450px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    recordTitleStyle () {
      const arr = this.recordTitleSize.split(' ')
      const width = arr[0] || '419px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    upStyle () {
      const arr = this.recordBtnSize.split(' ')
      const width = arr[0] || '184px'
      const height = arr[1] || '55px'
      const backImg = this.currentPage === 1 ? this.btnStatusTwo : this.btnStatusOne
      return {
        width,
        height,
        'line-height': `${height}`,
        color: `${this.recordBtnColor}`,
        'background-image': `url(${backImg})`
      }
    },
    downStyle () {
      const arr = this.recordBtnSize.split(' ')
      const width = arr[0] || '184px'
      const height = arr[1] || '55px'
      const backImg = this.currentPage === this.totalPage ? this.btnStatusTwo : this.btnStatusOne
      return {
        width,
        height,
        'line-height': `${height}`,
        color: `${this.recordBtnColor}`,
        'background-image': `url(${backImg})`
      }
    },
    btnStyle () {
      const arr = this.recordBtnSize.split(' ')
      const width = arr[0] || '220px'
      const height = arr[1] || '66px'
      return {
        width,
        height,
        color: `${this.recordBtnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatusOne})`
      }
    }
  },
  methods: {
    // 查看奖品
    recordFun () {
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      this.requestAPI({
        url: '/v1/uni/receiveLog',
        method: 'get',
        params: { a_id: this.activityId, page: this.currentPage, page_size: this.pageSize }
      }).then((res) => {
        this.recordModel.show = true
        this.recordModel.data = res.list
        this.currentPage = res.page
        this.page_size = res.pageSize
        this.totalPageSize = res.total
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 上下翻页
    upAndDown (type) {
      if (type === 1) {
        // 上一页
        this.currentPage = this.currentPage - 1
        this.recordFun()
      } else {
        // 下一页
        this.currentPage = this.currentPage + 1
        this.recordFun()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .btn-record {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
      filter: brightness(120%);
    }
  }
</style>
