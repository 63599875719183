import { render, staticRenderFns } from "./AwardComModel.vue?vue&type=template&id=7122ea80&scoped=true"
import script from "./AwardComModel.vue?vue&type=script&lang=js"
export * from "./AwardComModel.vue?vue&type=script&lang=js"
import style0 from "./AwardComModel.vue?vue&type=style&index=0&id=7122ea80&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7122ea80",
  null
  
)

export default component.exports