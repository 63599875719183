import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopicSwiper',
  cnName: '轮播展示',
  type: 'activity_topic',
  preview: '',
  // 属性相关
  props: {
    /* 页面属性 */
    ..._mixinWrap.props,
    /* 轮播 */
    autoplay: false,
    swiperImgList: [
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png'
      },
      {
        src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/c3c3a7d5e7f0bb386d68161bfee8ed05.jpg',
        thumbDefault: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/448b997fdc1b0003b004118c9461e121.png',
        thumbActive: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/4ffaa9a21e1df96e61149fc50afdf24a.png'
      }
    ],
    swiperImgSize: '1416px 542px',
    swiperThumbImgSize: '270px 72px',
    /* 视频弹窗 */
    // coverImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/ae23d6b65b789186d048dc27441d70ca.jpg',
    // coverImgSize: '368px 208px',
    coverImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/ae23d6b65b789186d048dc27441d70ca.jpg',
      style: {
        width: '368px',
        height: '208px'
      }
    },
    videoSize: '1080px 607px',
    mainVideo: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/media/julyAo.4d0b2bb9.mp4',
    closeImg: 'https://rescdn-crash.ssjj.cn/website/2023/02/22/bbd1bb0232ef4e6c1f3092d36c435dd3.png'
  },
  propsDesc: [
    /* 页面属性 */
    {
      target: '页面配置',
      formType: 'divider'
    },
    ..._mixinWrap.propsDesc,
    /* ================================= 轮播图 =========================== */
    {
      target: '轮播图',
      formType: 'divider'
    },
    {
      target: 'autoplay',
      label: '是否自动轮播',
      formType: 'switch'
    },
    // 轮播
    {
      target: 'swiperImgList',
      label: '轮播图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '主图',
          target: 'src'
        },
        {
          title: '缩略(默认)',
          target: 'thumbDefault'
        },
        {
          title: '缩略(选中)',
          target: 'thumbActive'
        }
      ]
    },
    {
      target: 'swiperImgSize',
      label: '轮播图尺寸'
    },
    {
      target: 'swiperThumbImgSize',
      label: '缩略图尺寸'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
