import _mixin from '../_mixin'

export default {
  name: 'SShareModel',
  cnName: '分享弹窗',
  draggable: true,
  // 属性相关
  props: {
    modelBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/6bcaa9534a885606129ba5a15ed15f5d.png',
    modelColor: '#D54A51',
    modelClose: 'https://rescdn-crash.ssjj.cn/website/2023/01/30/3741729c1293328e98ae185a279aa39d.png',
    shareBgSize: '750px 530px',
    shareTitle: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/5a31129c12b40437b9677d86f1e7d1a9.png',
    shareTitleSize: '476px 29px',
    shareInputBg: 'https://rescdn-crash.ssjj.cn/website/2023/02/16/b80aca77e7501d973b1397759e2eb752.png',
    btnStatusOne: 'https://rescdn-crash.ssjj.cn/website/2023/02/17/111a6441054790b8ecfb0a711d665fa5.png',
    shareBtnColor: '#FEEACB',
    shareBtnText: '复制',
    shareBtnSize: '192px 57px',
    textIcon: 'https://rescdn-crash.ssjj.cn/website/2023/02/15/8837dd8e3c10f93fcdcc6f952ff32b14.png',
    shareRuleTextList: [
      {
        ruleText: '每位狙友仅可助力一次；'
      },
      {
        ruleText: '2023年01月13日-2023年02月12日，每邀请一位好友完成助力，你将获得1能量，积攒5能量即可解锁奖励；'
      }
    ]
  },
  propsDesc: [
    {
      target: 'modelBg',
      label: '公共弹窗背景'
    },
    {
      target: 'modelColor',
      label: '公共弹窗文本颜色'
    },
    {
      target: 'modelClose',
      label: '公共弹窗关闭按钮'
    },
    {
      target: 'shareBgSize',
      label: '分享弹窗背景大小'
    },
    {
      target: 'shareTitle',
      label: '分享弹窗标题'
    },
    {
      target: 'shareTitleSize',
      label: '分享弹窗标题尺寸',
      description: '宽 高'
    },
    {
      target: 'shareInputBg',
      label: '分享地址背景'
    },
    {
      target: 'btnStatusOne',
      label: '按钮背景'
    },
    {
      target: 'shareBtnColor',
      label: '按钮颜色'
    },
    {
      target: 'shareBtnText',
      label: '按钮文案'
    },
    {
      target: 'shareBtnSize',
      label: '按钮大小'
    },
    {
      target: 'textIcon',
      label: '文案前面的icon'
    },
    {
      target: 'shareRuleTextList',
      label: '活动规则',
      formType: 'list',
      listConfig: [
        {
          title: '活动规则文案',
          target: 'ruleText'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
