<template>
  <div v-if="awardModelShow" class="lay">
    <div class="model" :style="{...modelBg.style,color: modelColor,'background-image': `url(${modelBg.src})`,'background-size': `${this.modelBg.style.width} ${this.modelBg.style.height}`}">
      <div class="close img-bg" :style="{...modelClose.style,'background-image': `url(${modelClose.src})`}" @click="close"></div>
      <img :src="awardTitle.src" alt="" :style="{...awardTitle.style}" class="title"/>
      <div class="mail-text">{{awardOtherDesc}}</div>
      <div class="text-model">
        <div v-for="(item,index) in awardData" :key="index" class="award-item">
          <div class="award-bg img-bg" :style="{...awardBoxBg.style,'background-image': `url(${awardBoxBg.src})`}">
            <img :src="item.images" alt="" class="award-img"/>
          </div>
          <div v-if="item.count && Number(item.count) > 1" class="award-title">{{item.name}}*{{item.count}}</div>
          <div v-else class="award-title">{{item.name}}</div>
        </div>
      </div>
      <img class="btn-status img-bg" :src="btnHappy.src" alt='' :style="{...btnHappy.style}" @click="close"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SAwardComModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: Object,
      required: true
    },
    modelClose: {
      type: Object,
      required: true
    },
    modelColor: {
      type: String,
      required: true
    },
    awardTitle: {
      type: Object,
      required: true
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBoxBg: {
      type: Object,
      required: true
    },
    btnHappy: {
      type: Object,
      required: true
    },
    // 奖励数据
    awardData: {
      type: Array,
      default: () => [{ name: '汉娜之吻', count: 10, images: '' }, { name: '汉娜之吻*10', images: '' }, { name: '汉娜之吻*10', images: '' }]
    }
  },
  data () {
    return {
      awardModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.awardModelShow = newVal
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.awardModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.awardModelShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .title{
    margin: 42px 0 20px 0
  }
  .mail-text{
    font-size: 18px;
  }
  .text-model{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 230px;
    margin: 0 auto;
    .award-item{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .award-bg{
      display: flex;
      justify-content: center;
      align-items: center;
      .award-img{
        max-width: 90%;
        max-height: 90%;
      }
    }
    .award-title{
      font-size: 18px;
      margin-top: 10px;
    }
  }
  .btn-status{
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
</style>
