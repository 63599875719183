<template>
  <transition name="slide-fade">
    <div class="source-manage" v-show="visible">
      <SResizeBox default-width="800px">
        <el-card class="card">
          <div slot="header" class="flex justify-between">
            <span>资源管理</span>
            <i class="el-icon-close cursor-pointer hv-blue" @click="close"></i>
          </div>
          <!--        <div class="search-bar">-->
          <!--          <el-form label-width="80px" class="el-row">-->
          <!--            <el-form-item label="模糊搜索"-->
          <!--                          class="el-col-12">-->
          <!--              <el-input size="mini" v-model="searchParams.search"></el-input>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label=""-->
          <!--                          class="el-col-12">-->
          <!--              <el-button type="" size="mini">搜索</el-button>-->
          <!--              <el-button type="" size="mini">重置</el-button>-->
          <!--              <el-button type="success" size="mini" @click="uploadVisible = true">上传</el-button>-->
          <!--            </el-form-item>-->
          <!--          </el-form>-->
          <!--        </div>-->
          <div class="source-control-bar flex justify-between items-center mb16">
            <div class="list-style fs-24">
              <i class="el-icon-s-grid cursor-pointer hv-blue" v-show="isTable" @click="isTable = false"></i>
              <i class="el-icon-s-unfold cursor-pointer hv-blue" v-show="!isTable" @click="isTable = true"></i>
            </div>
            <el-button type="success" size="mini" @click="uploadVisible = true">上传</el-button>

          </div>
          <div class="source-box">
            <transition name="slide-fade" mode="out-in">
              <div class="source-table" v-show="isTable">
                <el-row key="1">
                  <el-col :span="7">文件</el-col>
                  <el-col :span="7">名称</el-col>
                  <el-col :span="4">大小</el-col>
                  <el-col :span="6">操作</el-col>
                </el-row>
                <draggable :value="page.sourceList" tag="div" @update="onListUpdate">
                  <el-row v-for="(item,index) in page.sourceList" :key="item.uid" class="row">
                    <el-col :span="7">
                      <el-image
                        class="img"
                        :src="item.url"
                        fit="contain"
                        :preview-src-list="[item.url]"></el-image>
                    </el-col>
                    <el-col :span="7">{{ item.name }}</el-col>
                    <el-col :span="4">{{ item.size }}</el-col>
                    <el-col :span="6">
                      <el-button type="primary"
                                 size="mini"
                                 icon="el-icon-share"
                                 v-clipboard:copy="item.url"
                                 v-clipboard:success="onCopySuccess"></el-button>
                      <el-button @click="del(index)" icon="el-icon-delete" type="danger" size="mini"></el-button>
                    </el-col>
                  </el-row>
                </draggable>
              </div>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <div class="source-list" v-show="!isTable">
                <draggable :value="page.sourceList" tag="div" @update="onListUpdate">
                  <el-card class="mr8 mb8 inline-block" :body-style="{padding: 0}"
                           v-for="(item,index) in page.sourceList"
                           :key="item.uid">
                    <el-image class="img block" fit="contain" :src="item.url" :preview-src-list="[item.url]"></el-image>
                    <el-button-group class="block w-100" size="mini">
                      <el-button class="w-1/2" type="primary" size="small" icon="el-icon-share"
                                 v-clipboard:copy="item.url"
                                 v-clipboard:success="onCopySuccess"></el-button>
                      <el-button class="w-1/2" type="danger" size="small" icon="el-icon-delete"
                                 @click="del(index)"></el-button>
                    </el-button-group>
                  </el-card>
                </draggable>
              </div>
            </transition>
          </div>

          <el-dialog :visible.sync="uploadVisible" title="上传" append-to-body width="600px">
            <div class="source-upload-wrap">
              <el-upload
                drag
                action="/manage/base/uploadV2"
                :before-upload="beforeUpload"
                :on-success="onUploadSuccess"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">图片文件不超过500kb，视频文件不超过10MB</div>
              </el-upload>
            </div>
          </el-dialog>
        </el-card>
      </SResizeBox>
    </div>
  </transition>
</template>

<script>
import { delSource, getSourceList } from '@/api/source'
import { mapGetters } from 'vuex'
import { getTreeNodeById } from '@/utils/tree'
import SResizeBox from '@/components/PageBuilder/_components/ResizeBox.vue'
import draggable from 'vuedraggable'
import { deepClone } from '@/utils'

export default {
  name: 'SourceManage',
  components: { SResizeBox, draggable },

  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      searchParams: {
        search: '',
        page: 1,
        size: 10
      },
      total: 0,
      isTable: true,
      uploadVisible: false
    }
  },
  computed: {
    ...mapGetters(['page'])
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    onCopySuccess () {
      this.$message.success('复制成功')
    },
    getList () {
      getSourceList().then(res => {
        this.list = res.data.list
        this.total = res.data.total
      })
    },
    del (index) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$store.commit('DEL_SOURCE', index)
      })
    },
    onListUpdate ({ to, from, item, clone, oldIndex, newIndex }) {
      const list = deepClone(this.page.sourceList)
      const moveItem = list.splice(oldIndex, 1)[0]
      list.splice(newIndex, 0, moveItem)
      this.$store.commit('UPDATE_SOURCE', list)
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.getList()
    },
    beforeUpload (file) {
      console.log(file)
      const isLt10M = file.size / (1024 * 1024) < 10
      const isLt500K = file.size / 1024 < 500
      const isImage = file.type.includes('image')
      const isVideo = file.type.includes('video')
      // const filename = this._substringFilename(file.name)
      const filename = file.name
      if (isImage) {
        if (!isLt500K) {
          this.$notify({
            title: '警告',
            message: `图片: ${filename} 大小不能超过 500KB!`,
            type: 'warning',
            duration: 10000
          })
        }
      } else if (isVideo) {
        if (!isLt10M) {
          this.$notify({
            title: '警告',
            message: `视频: ${filename}  大小不能超过 10MB!`,
            type: 'warning',
            duration: 10000
          })
        }
      } else {
        this.$notify({
          title: '警告',
          message: `文件: ${filename} 格式不符`,
          type: 'warning',
          duration: 10000
        })
      }
      return (isImage && isLt500K) || (isVideo && isLt10M)
    },
    onUploadSuccess (response, file) {
      console.log(response, file)
      const source = {
        name: file.name,
        size: file.size,
        url: file.response.data.value,
        uid: file.uid
      }
      this.$store.commit('ADD_SOURCE', source)
    },
    _substringFilename (name) {
      if (name.length > 15) {
        const suffix = name.split('.').splice(-1)[0]
        return name.substring(0, 10) + '…… .' + suffix
      } else {
        return name
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.source-manage {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1002;
  .card {
    height: 100%;

    .el-icon-close {
      font-size: 20px;
    }

    .source-box {
      position: relative;

    }

    .source-table {
      position: absolute;
      width: 100%;
      text-align: left;
      .el-row{
        display: flex;
        align-items: center;
        padding: 5px;
      }
      .el-col{
        align-items: center;
      }
      .row {
        font-size: 14px;
        transition: .3s;
        cursor: move;

        &:hover {
          background-color: #e9f0FD;
        }
      }
    }

    .source-list {
      position: absolute;

      .el-card {
      }
    }

    .img {
      background-image: url("@/assets/images/bg.jpeg");
      width: 120px;
      height: 100px;
      object-fit: contain;
    }

  }
}
</style>
<style lang="scss">

.source-upload-wrap {
  .el-upload {
    width: 100%;
    display: block;

    .el-upload-dragger {
      width: 100%;
    }
  }
}
</style>
