<template>
  <div class="topic-wrapper" :style="defaultStyle">
    <img class="topic-title" v-if="titleImg.src" :src="titleImg.src" alt="" :style="titleStyle"/>
    <slot></slot>
    <img class="topic-footer" v-if="footerArrow.src" :src="footerArrow.src" alt="" :style="footerArrowStyle"/>
  </div>
</template>

<script>

export default {
  name: 'STopicWrapper',
  props: {
    bg: {
      type: Object,
      required: false
    },
    titleImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    contentImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    footerArrow: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    defaultStyle () {
      return {
        width: this.bg?.style.width,
        height: this.bg?.style.height,
        'background-image': `url(${this.bg?.src})`
      }
    },
    // title样式
    titleStyle () {
      return {
        width: this.titleImg.style.width,
        height: this.titleImg.style.height
      }
    },
    footerArrowStyle () {
      return {
        width: this.footerArrow.style.width,
        height: this.footerArrow.style.height,
        position: 'absolute',
        top: this.footerArrow.style.top,
        right: this.footerArrow.style.right,
        bottom: this.footerArrow.style.bottom,
        left: this.footerArrow.style.left
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.topic-wrapper{
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 30px;
  .topic-title{
    display: block;
    margin: 0 auto 30px;
    object-fit: contain;
    position: relative;
    z-index: 1;
  }
  .topic-footer{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 50px;
    object-fit: contain;
  }
}
</style>
