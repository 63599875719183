<template>
  <div v-if="addressModelShow" class="lay">
    <div class="address-model img-bg" :style="modelStyle">
      <div class="close img-bg" :style="closeStyle" @click="close"></div>
      <img :src="addressTitle" alt="" class="title-model" :style="titleStyle"/>
      <div class="form-content">
        <div class="input-item">
          <div class="text">姓名</div>
          <input  v-if="status!==2" v-model="formData.name" placeholder="请输入您的姓名" class="input-box" :disabled="status===1" :style="colorStyle"/>
          <div v-else class="input-content">{{formData.name}}</div>
        </div>
        <div class="tip-text tip-text-one">{{formTipData.nameTip}}</div>
        <div class="input-item">
          <div class="text">手机号</div>
          <input  v-if="status!==2" v-model="formData.phone" placeholder="请输入您的手机号" class="input-box" :disabled="status===1" :style="colorStyle"/>
          <div v-else class="input-content">{{formData.phone}}</div>
        </div>
        <div class="tip-text tip-text-two">{{formTipData.phoneTip}}</div>
        <div class="input-item">
          <div class="text">地址</div>
          <input v-if="status!==2" v-model="formData.address" placeholder="请输入您的地址" class="input-box" :disabled="status===1" :style="colorStyle"/>
          <div v-else class="input-content">{{formData.address}}</div>
        </div>
        <div class="tip-text tip-text-three">{{formTipData.addressTip}}</div>
      </div>
      <div v-if="status!==2" class="desc">*每位用户仅可提交一次，请仔细核实您的中奖信息~</div>
      <div v-else class="desc">*您的信息已提交，如需修改可联系客服</div>
      <div v-if="status===0" class="btn" :style="btnStyle" @click="btnFun(0)">{{btnSubmitText}}</div>
      <div v-if="status===1" class="btn-items">
        <div class="btn" :style="btnStyle" @click="btnFun(1)">{{btnReturnText}}</div>
        <div class="btn" :style="btnStyle" @click="btnFun(2)">{{btnConfirmText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SAddressModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelBgSize: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    addressTitle: {
      type: String,
      default: ''
    },
    addressTitleSize: {
      type: String,
      default: '719px 39px'
    },
    btnStatus: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: '220px 66px'
    },
    btnWriteText: {
      type: String,
      default: ''
    },
    btnSubmitText: {
      type: String,
      default: ''
    },
    btnReturnText: {
      type: String,
      default: ''
    },
    btnConfirmText: {
      type: String,
      default: ''
    },
    addressStatus: {
      type: Number,
      default: 0
    },
    addressFormData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      addressModelShow: false,
      formTipData: {
        nameTip: '',
        phoneTip: '',
        addressTip: ''
      },
      formData: {
        name: '',
        phone: '',
        address: ''
      },
      // 0:输入态 1：锁定态 2：终态
      status: 0
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.addressModelShow = newVal
      },
      immediate: true
    },
    addressStatus: {
      handler: function (newVal) {
        this.status = newVal
      },
      immediate: true
    },
    addressFormData: {
      handler: function (newVal) {
        if (newVal) {
          this.formData = newVal
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.modelBgSize.split(' ')
      const width = arr[0] || '1000px'
      const height = arr[1] || '600px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    colorStyle () {
      const border = '2px solid' + `${this.modelColor}`
      return {
        border,
        color: `${this.modelColor}`
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    titleStyle () {
      const arr = this.addressTitleSize.split(' ')
      const width = arr[0] || '719px'
      const height = arr[1] || '39px'
      return {
        width,
        height
      }
    },
    btnStyle () {
      const arr = this.btnSize.split(' ')
      const width = arr[0] || '220px'
      const height = arr[1] || '66px'
      return {
        width,
        height,
        color: `${this.btnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatus})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.addressModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.addressModelShow = true
    },
    // flag: 0: 提交 1： 返回修改 2： 确认
    btnFun (flag) {
      if (flag === 0) {
        // 校验
        if (this.formData.name.length <= 0) {
          this.formTipData.nameTip = '姓名不能为空'
          this.formTipData.phoneTip = ''
          this.formTipData.addressTip = ''
          return
        }
        if (this.formData.phone.length <= 0) {
          this.formTipData.nameTip = ''
          this.formTipData.phoneTip = '手机号不能为空'
          this.formTipData.addressTip = ''
          return
        }
        if (!this.isPhone(this.formData.phone)) {
          this.formTipData.nameTip = ''
          this.formTipData.phoneTip = '手机号格式不正确'
          this.formTipData.addressTip = ''
          return
        }
        if (this.formData.address.length < 5) {
          this.formTipData.nameTip = ''
          this.formTipData.phoneTip = ''
          this.formTipData.addressTip = '地址最少5个汉字'
          return
        }
        this.formTipData.nameTip = ''
        this.formTipData.phoneTip = ''
        this.formTipData.addressTip = ''
        this.status = 1
      } else if (flag === 1) {
        this.status = 0
      } else {
        this.status = 2
        // 掉保存地址的接口
        this.$emit('saveAddress', this.formData)
      }
    },
    isPhone (account) {
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(account)
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .address-model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .title-model{
    margin: 56px auto;
  }
  .form-content{
    position: relative;
    margin: 0 auto 40px;
    .input-item{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      .text{
        width: 120px;
        font-size: 29px;
        font-weight: bold;
        margin-right: 40px;
        text-align: right;
      }
      .input-box{
        width: 549px;
        height: 55px;
        outline: none;
        font-size: 24px;
        font-weight: 500;
        padding-left: 20px;
        background-color: transparent;
      }
      .input-content{
        width: 549px;
        font-size: 24px;
        font-weight: 500;
        text-align: left;
      }
    }
    .tip-text{
      font-size: 20px;
      font-weight: bold;
    }
    .tip-text-one{
      position: absolute;
      top: 60px;
      left: 300px;
    }
    .tip-text-two{
      position: absolute;
      top: 145px;
      left: 300px;
    }
    .tip-text-three{
      position: absolute;
      top: 230px;
      left: 300px;
    }
  }
  .desc{
    width: 670px;
    font-size: 21px;
    font-weight: 400;
    margin:0 auto 30px;
  }
  .btn-items{
    display: flex;
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: .3s;
    text-decoration: none;
    font-size: 28px;
    font-weight: bold;
    margin: 0 auto;
  }
  *::-webkit-input-placeholder {
    color: #E69381;
  }
  *:-moz-placeholder {
    /* FF 4-18 */
    color: #E69381;
  }
  *::-moz-placeholder {
    /* FF 19+ */
    color: #E69381;
  }
  *:-ms-input-placeholder {
    /* IE 10+ */
    color: #E69381;
  }
</style>
