<template>
  <div v-if="shareModelShow" class="lay">
    <div class="share-model" :style="modelStyle">
      <div class="close img-bg" :style="closeStyle" @click="close"></div>
      <img :src="shareTitle" alt="" class="title-model" :style="titleStyle"/>
      <div class="copy-desc">复制链接邀请好友助力领大奖</div>
      <div class="input-model" >
        <input v-model="linkUrl" class="input-box img-bg" :style="inputStyle"/>
      </div>
      <div class="btn-share img-bg" :style="btnCopyStyle" @click="copyLink">{{shareBtnText}}</div>
      <div class="right-rule">
        <div v-for="(item,index) in shareRuleTextList" :key="index" class="rule-item">
          <p><span class="text-icon img-bg" :style="styleIcon"></span>{{item.ruleText}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SShareModel',
  props: {
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: ''
    },
    modelClose: {
      type: String,
      default: ''
    },
    shareBgSize: {
      type: String,
      default: '750px 530px'
    },
    shareTitle: {
      type: String,
      default: ''
    },
    shareTitleSize: {
      type: String,
      default: '476px 29px' // 宽高
    },
    shareInputBg: {
      type: String,
      default: ''
    },
    btnStatusOne: {
      type: String,
      default: ''
    },
    shareBtnColor: {
      type: String,
      default: ''
    },
    shareBtnText: {
      type: String,
      default: '复制'
    },
    shareBtnSize: {
      type: String,
      default: '192px 57px'
    },
    textIcon: {
      type: String,
      default: ''
    },
    shareRuleTextList: {
      type: Array,
      required: false
    },
    shareUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      linkUrl: window.location.href,
      shareModelShow: false
    }
  },
  watch: {
    modelShow: {
      handler: function (newVal) {
        this.shareModelShow = newVal
      },
      immediate: true
    },
    shareUrl: {
      handler: function (newVal) {
        if (newVal) {
          this.linkUrl = newVal
        } else {
          this.linkUrl = window.location.href
        }
      },
      immediate: true
    }
  },
  computed: {
    modelStyle () {
      const arr = this.shareBgSize.split(' ')
      const width = arr[0] || '750px'
      const height = arr[1] || '530px'
      return {
        width,
        height,
        color: `${this.modelColor}`,
        'background-image': `url(${this.modelBg})`,
        'background-size': `${width} ${height} `
      }
    },
    closeStyle () {
      return {
        'background-image': `url(${this.modelClose})`
      }
    },
    titleStyle () {
      const arr = this.shareTitleSize.split(' ')
      const width = arr[0] || '476px'
      const height = arr[1] || '29px'
      return {
        width,
        height
      }
    },
    styleIcon () {
      return {
        'background-image': `url(${this.textIcon})`
      }
    },
    inputStyle () {
      return {
        color: `${this.modelColor}`,
        'background-image': `url(${this.shareInputBg})`
      }
    },
    btnCopyStyle () {
      const arr = this.shareBtnSize.split(' ')
      const width = arr[0] || '192px'
      const height = arr[1] || '57px'
      return {
        width,
        height,
        color: `${this.shareBtnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatusOne})`
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:modelShow', false)
      this.shareModelShow = false
    },
    open () {
      this.$emit('update:modelShow', true)
      this.shareModelShow = true
    },
    // 复制链接
    copyLink () {
      this.copyContent(
        `快来帮我助力，一起领珍稀道具！点击参与>> ${this.linkUrl}`
      ).then(() => {
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      })
    },
    // 复制剪切功能
    copyContent (text) {
      let target = null
      target = document.createElement('div')
      target.id = 'tempTarget'
      target.style.opacity = '0'
      target.innerText = text
      document.body.appendChild(target)
      return new Promise((resolve, reject) => {
        try {
          const range = document.createRange()
          range.selectNode(target)
          window.getSelection().removeAllRanges()
          window.getSelection().addRange(range)
          document.execCommand('copy')
          window.getSelection().removeAllRanges()
          target.parentElement.removeChild(target)
          resolve()
        } catch (e) {
          target.parentElement.removeChild(target)
          reject(e)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  /*滚动条整体样式*/
  ::-webkit-scrollbar{
    width: 6px;
    height: 10px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #c6513d;
  }

  /*滚动条里面轨道*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #e1c480;
  }
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .lay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }
  .share-model{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: left top;
    text-align: center;
    font-size: 20px;
  }
  .close{
    width: 40px;
    height: 30px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .title-model{
    margin: 42px 0 20px;
  }
  .copy-desc{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .input-model{
    margin-bottom: 20px;
    .input-box{
      width: 492px;
      height: 61px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 20px;
    }
  }
  .btn-share{
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0 auto 20px;
    cursor: pointer;
    &:hover{
      filter: brightness(120%)
    }
  }
  .right-rule{
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 30px;
    padding: 0 70px;
    box-sizing: border-box;
    .rule-item{
      display: flex;
      align-items: center;
      line-height: 35px;
      .text-icon{
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

</style>
