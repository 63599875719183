import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopic2VersionWelfare',
  cnName: '版本福利',
  type: 'activity_topic2',
  preview: 'https://rescdn.ssjj.cn/website/2023/11/01/7cca8475396b4c03885123facfe09f43.jpg',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/c108b230e38a8aa6793a47ae98134a77.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    list: [
      {
        name: 'xxx',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/16/b0ee135685f3732bc3ce166073bca883.jpg',
        showInChannel: '',
        link: 'website@https://h5.ssjj.cn/pc2mobile/inviteGiftsOfficial,wegame@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=wegame&from=09invite_2048000064&z=z,swjoy@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=swjoy&from=09invite_819200045&z=z,yly@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=yly&from=09invite_2867200048&z=z,lenovo@https://h5.ssjj.cn/pc2mobile/inviteGifts?from=09invite_3276800055&z=z,douyu@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=douyu&from=09invite_3686400047&z=z,huya@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=huya&from=09invite_4915200041&z=z,4399@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=4399&from=09invite_2457600046&z=z,steam@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=steam&from=09invite_4096000036&z=z,zhengqi@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=zhengqi&from=09invite_4505600038&z=z,360@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=360&from=09invite_5734400034&z=z,bilibili@https://h5.ssjj.cn/pc2mobile/inviteGifts?wdFrom=bilibili&from=09invite_1638400040&z=z'
      },
      {
        name: 'xxx',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/16/34377b4007eb798d07a2308fb59eb2f3.jpg',
        showInChannel: '',
        link: 'website@https://h5.ssjj.cn/pc2mobile/newOld,wegame@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=wegame&from=1011newold_2048000067&z=z,swjoy@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=swjoy&from=1011newold_819200048&z=z,yly@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=yly&from=1011newold_2867200051&z=z,lenovo@https://h5.ssjj.cn/pc2mobile/newOld?from=1011newold_3276800058&z=z,douyu@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=douyu&from=1011newold_3686400050&z=z,huya@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=huya&from=1011newold_4915200044&z=z,4399@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=4399&from=1011newold_2457600049&z=z,steam@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=steam&from=1011newold_4096000039&z=z,zhengqi@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=zhengqi&from=1011newold_4505600041&z=z,360@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=360&from=1011newold_5734400037&z=z,bilibili@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=bilibili&from=1011newold_1638400043&z=z'
      },
      {
        name: 'xxx',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/16/d9c5f7b2b82b8d4b888d2896527b4364.jpg',
        showInChannel: '',
        link: 'website@https://h5.ssjj.cn/pc2mobile/newOld,wegame@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=wegame&from=1011newold_2048000067&z=z,swjoy@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=swjoy&from=1011newold_819200048&z=z,yly@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=yly&from=1011newold_2867200051&z=z,lenovo@https://h5.ssjj.cn/pc2mobile/newOld?from=1011newold_3276800058&z=z,douyu@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=douyu&from=1011newold_3686400050&z=z,huya@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=huya&from=1011newold_4915200044&z=z,4399@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=4399&from=1011newold_2457600049&z=z,steam@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=steam&from=1011newold_4096000039&z=z,zhengqi@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=zhengqi&from=1011newold_4505600041&z=z,360@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=360&from=1011newold_5734400037&z=z,bilibili@https://h5.ssjj.cn/pc2mobile/newOld?wdFrom=bilibili&from=1011newold_1638400043&z=z'

      },
      {
        name: 'xxx',
        img: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/361cf7b8edc3b139ce2857e090a56962.png',
        showInChannel: 'website,huya,douyu',
        link: 'website@http://xxx.cn,huya@http://xxx.cn,douyu@http://xxx.cn'
      }
    ],
    prevImg: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png',
    nextImg: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/33b1279f41a2b1a9d796e076f996d306.png',
    news: [
      {
        title: '[综合]《生死狙击2》游戏环境安全处罚公示（9月18日-101',
        showInChannel: 'huya,douyu',
        link: 'huya@http://huya.cn,douyu@http://douyu.cn'
      },
      {
        title: '[综合]《生死狙击2》游戏环境安全处罚公示（9月18日-102',
        showInChannel: '',
        link: 'xxxxx'
      },
      {
        title: '[综合]《生死狙击2》游戏环境安全处罚公示（9月18日-103',
        showInChannel: '',
        link: 'xxxxx'
      },
      {
        title: '[综合]《生死狙击2》游戏环境安全处罚公示（9月18日-104',
        showInChannel: '',
        link: 'xxxxx'
      }
    ],
    newsColor: '#fff',
    newsColorHover: '#FFA94F'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '备注名称',
          target: 'name',
          placeholder: '备注名称'
        },
        {
          title: '图片',
          target: 'img',
          placeholder: 'website@http://xxx.cn,huya@http://xxx.cn,douyu@http://xxx.cn'
        },
        {
          title: '在这些频道显示',
          target: 'showInChannel',
          placeholder: '默认在所有渠道显示'
        },
        {
          title: '跳链',
          target: 'link',
          placeholder: '可用@区分渠道'
        }
      ]
    },
    {
      target: 'prevImg',
      label: '上一页按钮图片'
    },
    {
      target: 'nextImg',
      label: '下一页按钮图片'
    },
    {
      formType: 'divider'
    },
    {
      target: 'news',
      label: '新闻列表',
      formType: 'list',
      listConfig: [
        {
          title: '标题',
          target: 'title'
        },
        {
          title: '在这些频道显示',
          target: 'showInChannel'
        },
        {
          title: '跳链',
          target: 'link'
        }
      ]
    },
    {
      target: 'newsColor',
      label: '新闻字色'
    },
    {
      target: 'newsColorHover',
      label: '新闻字色-hover'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ]
}
