export default {
  props: {
    hideInChannels: []
  },
  propsDesc: [
    {
      target: 'hideInChannels',
      label: '在这些渠道隐藏',
      formType: 'checkbox',
      options: [
        { value: 'website', label: '官服' },
        { value: 'wegame', label: 'WeGame' },
        { value: 'swjoy', label: '顺网' },
        { value: 'yly', label: '易乐游' },
        { value: 'lenovo', label: '联想' },
        { value: 'douyu', label: '斗鱼' },
        { value: 'huya', label: '虎牙' },
        { value: '4399', label: '4399' },
        { value: 'steam', label: 'Steam' },
        { value: 'zhengqi', label: 'Steam蒸汽' },
        { value: '360', label: '360' },
        { value: 'bilibili', label: 'bilibili' }
      ]
    }
  ]
}
