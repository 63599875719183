export default {
  projectStatus: [
    { value: 1, label: '线上' },
    { value: 2, label: '下线' },
    { value: 3, label: '编辑中' }
  ],
  templateStatus: [
    { value: 1, label: '线上' },
    { value: 2, label: '下线' },
    { value: 3, label: '编辑中' }
  ],
  historyStatus: [
    { value: 1, label: '存档' },
    { value: 2, label: '审批中' },
    { value: 3, label: '生效中' }
  ],
  // 渠道
  channel: [
    { value: 'wegame', label: 'WeGame' },
    { value: 'swjoy', label: '顺网' },
    { value: 'yly', label: '易乐游' },
    { value: 'lenovo', label: '联想' },
    { value: 'douyu', label: '斗鱼' },
    { value: 'huya', label: '虎牙' },
    { value: '4399', label: '4399' },
    { value: 'steam', label: 'Steam' },
    { value: 'zhengqi', label: 'Steam蒸汽' },
    { value: '360', label: '360' },
    { value: 'bilibili', label: 'bilibili' }
  ]
}
