import _mixin from '../_mixin'

export default {
  name: 'SActFreeLegendWeapon',
  cnName: '活动-免费传说武器',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/01/31/e5e5b15425bf8b0875b46d4b1b655838.jpg',
  // 属性相关
  props: {
    activityId1: 'MQK4AJCYMOEV4UJYE44EQBJSDY',
    activityId2: 'E2WBDTU5BJZUJHUQBGF3IG6BDA',
    activityId3: 'TL34QFIOKIKHEGHT7ICURXU6GA',
    activityId4: 'DSUAHDCU26NWFQ4W63FC2UU7AE',
    // 主图
    mainImg: 'https://rescdn.ssjj.cn/website/2024/01/29/48b30dfdfe152f2622a21e228fa7f24e.png',

    // 任务进度字色
    taskProgressColor: '#C3A3FF',
    // 任务列表字色
    taskListColor: '#D9C4FF',
    // 线索字体颜色
    clueColor: '#C3A3FF',
    // 小卡片图
    miniCardImg: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/f47862336fb25c47d36b01cb8b1306ad.png'
    },
    // 小领取按钮
    miniBtnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c62d6a8d73627d324270c79825d27d76.png'
    },
    miniBtnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/105cdfb1a0059606e936e649fdc6d200.png'
    },
    miniBtnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/72ae442f3a38e8814b9458b3c6ea5fa0.png'
    },
    miniBtnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/a9201d08d00a7528eee2eab114c98506.png'
    },
    miniBtnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c8730484423115bb44a04c43eb80bf6a.png'
    },
    // 左侧按钮
    leftBtnSize: '286px 79px',
    leftBtnPos: '760px _ _ 191px',
    leftBtnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/67b89552ab28e1234f938e8213e365ac.png'
    },
    leftBtnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/799310280c06dc45c7c7769ee95dde0f.png'
    },
    leftBtnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/792478216ac476ad236e50e2ab3ff6a8.png'
    },
    leftBtnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/c37cf7d6d93de034025df9b81dbfee42.png'
    },
    leftBtnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/01/4302923e1bd3311edb392c03de82f896.png'
    },
    // 右侧按钮
    rightBtn: {
      src: 'https://rescdn.ssjj.cn/website/2024/01/29/c5f18facb9fddefe7000fb67bf13d6c2.png',
      style: {
        width: '286px',
        height: '79px',
        top: '803px',
        left: '1474px',
        right: '',
        bottom: ''
      }
    }
  },
  propsDesc: [
    {
      target: '活动ID配置',
      formType: 'divider'
    },
    {
      target: 'activityId1',
      label: '限时枪ID'
    },
    {
      target: 'activityId2',
      label: '任务列表ID'
    },
    {
      target: 'activityId3',
      label: '领取卡片ID'
    },
    {
      target: 'activityId4',
      label: '购买线索ID'
    },
    {
      target: 'mainImg',
      label: '主图'
    },
    {
      target: 'taskProgressColor',
      label: '任务进度字色'
    },
    {
      target: 'taskListColor',
      label: '任务列表字色'
    },
    {
      target: 'clueColor',
      label: '线索字色'
    },
    {
      target: 'miniCardImg',
      label: '小卡片图',
      formType: 'image'
    },
    // ======== 小领取按钮 ========
    {
      target: '小领取按钮',
      formType: 'divider'
    },
    {
      target: 'miniBtnNoBegin',
      label: '未开始',
      formType: 'image'
    },
    {
      target: 'miniBtnUnfinished',
      label: '未完成',
      formType: 'image'
    },
    {
      target: 'miniBtnReceive',
      label: '待领取',
      formType: 'image'
    },
    {
      target: 'miniBtnReceived',
      label: '已领取',
      formType: 'image'
    },
    {
      target: 'miniBtnExpire',
      label: '已过期',
      formType: 'image'
    },
    // ======== 领取适用按钮 ========
    {
      target: '领取适用按钮',
      formType: 'divider'
    },
    {
      target: 'leftBtnSize',
      label: '宽高',
      formType: 'size'
    },
    {
      target: 'leftBtnPos',
      label: '位置',
      formType: 'position'
    },
    {
      target: 'leftBtnNoBegin',
      label: '未开始',
      formType: 'image'
    },
    {
      target: 'leftBtnUnfinished',
      label: '未完成',
      formType: 'image'
    },
    {
      target: 'leftBtnReceive',
      label: '待领取',
      formType: 'image'
    },
    {
      target: 'leftBtnReceived',
      label: '已领取',
      formType: 'image'
    },
    {
      target: 'leftBtnExpire',
      label: '已过期',
      formType: 'image'
    },
    // ======== 购买线索按钮 ========
    {
      target: '购买线索按钮',
      formType: 'divider'
    },
    {
      target: 'rightBtn',
      label: '购买线索按钮',
      formType: 'image'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
