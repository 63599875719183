// topic 专题活动组件公用配置项
export default {
  props: {
    bg: {
      src: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/activity-pc/img/fifth-bg.9ef1d6a2.png',
      style: {
        width: '1920px',
        height: '1000px'
      }
    }
  },
  propsDesc: [
    {
      target: 'bg',
      label: '背景图',
      formType: 'image'
    }
  ]
}
