<template>
  <STopicWrapper class="new-mode" v-bind="$attrs">
    <div class="content">
      <div class="left">
        <img
          :src="list[0].small"
          alt=""
          :class="{ active: active === 0 }"
          :style="smallStyle"
          @click="active = 0"
        />
        <img
          :src="list[2].small"
          alt=""
          :class="{ active: active === 2 }"
          :style="smallStyle"
          @click="active = 2"
        />
      </div>
      <div
        class="center"
        :style="{ ...bigStyle, backgroundImage: `url(${list[active].big})` }"
      ></div>
      <div class="right">
        <img
          :src="list[1].small"
          alt=""
          :class="{ active: active === 1 }"
          :style="smallStyle"
          @click="active = 1"
        />
        <img
          :src="list[3].small"
          alt=""
          :class="{ active: active === 3 }"
          :style="smallStyle"
          @click="active = 3"
        />
      </div>
    </div>
  </STopicWrapper>
</template>

<script>
export default {
  name: 'STopicNewMode',
  props: {
    list: {
      type: Array,
      required: true
    },
    smallSize: {
      type: String,
      default: ''
    },
    bigSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      active: 0
    }
  },
  computed: {
    smallStyle () {
      const arr = this.smallSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    },
    bigStyle () {
      const arr = this.bigSize.split(' ')
      return {
        width: arr[0] || '',
        height: arr[1] || ''
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.img-bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.new-mode {
  .content {
    width: 1416px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      img {
        opacity: 0.8;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
        margin: 5px;
      }
    }
    .center {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;
      transition: 0.3s;
      margin: 0 10px;
    }
  }
}
</style>
