import _mixin from './_mixin'

export default {
  name: 'SLogin',
  cnName: '登录组件',
  type: 'complex',
  draggable: true,
  // 属性相关
  props: {
    showChannelLogin: false,
    channelOptions: ['wooduan', 'lenovo', 'wegame', 'douyu', 'huya', 'steam', 'zhengqi', 'swjoy', 'yly', '4399', '360', 'bilibili'],
    textColor: '#ffffff'
  },
  propsDesc: [
    {
      target: 'showChannelLogin',
      label: '渠道登录',
      formType: 'switch'
    },
    {
      target: 'channelOptions',
      label: '展示渠道',
      formType: 'checkbox',
      options: [
        { value: 'wooduan', label: '无端登录' },
        { value: 'wegame', label: 'WeGame' },
        { value: 'swjoy', label: '顺网' },
        { value: 'yly', label: '易乐游' },
        { value: 'lenovo', label: '联想' },
        { value: 'douyu', label: '斗鱼' },
        { value: 'huya', label: '虎牙' },
        { value: '4399', label: '4399' },
        { value: 'steam', label: 'Steam' },
        { value: 'zhengqi', label: 'Steam蒸汽' },
        { value: '360', label: '360' },
        { value: 'bilibili', label: 'bilibili' }
      ],
      showOn: {
        target: 'showChannelLogin',
        value: true
      }
    },
    {
      target: 'textColor',
      label: '文字颜色'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'absolute',
    top: '112px',
    left: '1600px',
    'z-index': 10
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ]
}
