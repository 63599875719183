import _mixin from '../_mixin'
// import BaseGroup from './BaseGroup'

// function genId () {
//   return Date.now()
// }

export default {
  name: 'SOfficialActiveActivity',
  cnName: '官服活跃活动',
  type: 'activity_other',
  preview: 'https://rescdn.ssjj.cn/website/2024/01/29/77eef94cd888645419997b5931fc8c8b.jpg',
  // 属性相关
  props: {
    // ===== 活动 ID 配置 =====
    actKeyName: '春日信',
    // ===== 活动 ID 配置 =====
    activityId1: '45HLDOHMA5WPEIUWVH6PDJP5V4',
    activityId2: 'ZILN4I33JI3H5ZQGZ63WAZ2NJA',
    activityId3: 'MY637DU7CV5RD7D6R7ENUQZY6Y',
    activityId4: 'MGV3NL3MTGTG4YNWNRLRAMJLYE',
    activityId5: '3WLFEHN6MV2YPIR23CWEDCQC5E',
    // ===== text color =====
    textColorLevel1: '#333',
    textColorLevel2: '#666',
    textColorLevel3: '#999',
    textColorActive: '#C3636B',
    miniBtnColor: '#608351',
    // ===== 领取按钮 =====
    // 未开始按钮
    receiveBtn0: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/29/9734e8511c1fa32ca8ed8b36b1af5e58.png'
    },
    // 未完成按钮
    receiveBtn1: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/3fa927bcd614c5e5c8f53612378179ef.png'
    },
    // 领取按钮
    receiveBtn2: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/e5393d7de699699200d43fc8d8f94802.png'
    },
    // 已领取按钮
    receiveBtn3: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/5d6802d0300d39f6ab4f61c34595e27c.png'
    },
    // 已过期按钮
    receiveBtn4: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/29/a8189b5fbf78bb47558dce340823fccf.png'
    },
    // ===== 兑换按钮 =====
    // 未开始按钮
    exchangeBtn: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/1fa23746d05df6cf5ef93c4da5232f9e.png'
    },

    // ===== receiveAllBtn =====
    receiveAllBtn: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/5c189ff22e5cdab77b55b38fc8554947.png'
    },
    receiveAllBtnDisabled: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/29/7ddc179e17bb9652327540ffb2d067dd.png'
    },
    // ===== receiveAllBtn =====
    receiveDayBtn: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/1126301cbe881fdbfb71aa7fbd120669.png'
    },
    receiveDayBtnDisabled: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/29/d3d2e079e551247bc91771c90fed690c.png'
    },

    // ====== 侧边栏 ======
    sideBarBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/6e423cc75691c7dd5736a94fe34e3f1e.png'
    },
    topTextColor: '#FFECBB',
    sideBarNavText1: '登录必得',
    sideBarNavText2: '探险任务',
    sideBarNavText3: '春日礼兑',
    // 弹窗相关
    modalBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/29/e61a1b9de4c96cdf5cef2eef5a5dec8b.png',
      style: {
        width: '750px',
        height: '650px'
      }
    },
    modalCloseIcon: {
      src: 'https://rescdn.ssjj.cn/website/2024/02/28/e5a37c056c8faa677cacf381cc8520ab.png',
      style: {
        width: '80px',
        height: '80px',
        right: '-90px',
        top: '0px'
      }
    },
    modalTitleStyle: '{"color": "#333"}',
    modalTextStyle: '{"color": "#666"}',
    modalConfirmBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/01/bbfa5da4b19e25be0012d120346a1907.png)","color": "#FAEBCC", "font-weight": "700"}',
    modalCancelBtnStyle: '{"background-image":"url(https://rescdn.ssjj.cn/website/2024/03/01/4055c31f562e4e26cf374bfbbf57687b.png)","color": "#514230", "font-weight": "700"}'
  },
  propsDesc: [
    {
      target: 'actKeyName',
      label: '积分代称'
    },
    {
      target: '=== 活动 ID 配置 ===',
      formType: 'divider'
    },
    {
      target: 'activityId1',
      label: '累计登录'
    },
    {
      target: 'activityId2',
      label: '登录抽奖'
    },
    {
      target: 'activityId3',
      label: '任务列表'
    },
    {
      target: 'activityId4',
      label: '兑换'
    },
    {
      target: 'activityId5',
      label: '邀请助力'
    },
    {
      target: '公共文本颜色',
      formType: 'divider'
    },
    {
      target: 'textColorLevel1',
      label: '一级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel2',
      label: '二级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorLevel3',
      label: '三级文本颜色',
      formType: 'color'
    },
    {
      target: 'textColorActive',
      label: '活跃文本颜色',
      formType: 'color'
    },
    {
      target: 'miniBtnColor',
      label: '小按钮颜色',
      formType: 'color'
    },

    {
      target: '领取按钮',
      formType: 'divider'
    },
    {
      target: 'receiveBtn0',
      label: '未开始按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn1',
      label: '未完成按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn2',
      label: '领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn3',
      label: '已领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveBtn4',
      label: '已过期按钮',
      formType: 'image'
    },

    {
      target: '全部领取按钮',
      formType: 'divider'
    },
    {
      target: 'receiveAllBtn',
      label: '全部领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveAllBtnDisabled',
      label: '全部领取按钮-禁用',
      formType: 'image'
    },

    {
      target: '每日领取按钮',
      formType: 'divider'
    },
    {
      target: 'receiveDayBtn',
      label: '每日领取按钮',
      formType: 'image'
    },
    {
      target: 'receiveDayBtnDisabled',
      label: '每日领取按钮-禁用',
      formType: 'image'
    },

    {
      target: '兑换按钮',
      formType: 'divider'
    },
    {
      target: 'exchangeBtn',
      label: '兑换按钮',
      formType: 'image'
    },
    {
      target: '===== 侧边栏 ======',
      formType: 'divider'
    },
    {
      target: 'sideBarBg',
      label: '侧边栏背景',
      formType: 'image'
    },
    {
      target: 'topTextColor',
      label: '顶部文本颜色',
      formType: 'color'
    },
    {
      target: 'sideBarNavText1',
      label: '导航文本1'
    },
    {
      target: 'sideBarNavText2',
      label: '导航文本2'
    },
    {
      target: 'sideBarNavText3',
      label: '导航文本3'
    },
    {
      target: '===== 公共弹窗 ======',
      formType: 'divider'
    },
    {
      target: 'modalBg',
      label: '弹窗背景图',
      formType: 'image'
    },
    {
      target: 'modalCloseIcon',
      label: '关闭按钮',
      formType: 'image'
    },
    {
      target: 'modalTitleStyle',
      label: '标题样式',
      formType: 'textarea'
    },
    {
      target: 'modalTextStyle',
      label: '文本样式',
      formType: 'textarea'
    },
    {
      target: 'modalConfirmBtnStyle',
      label: '确认按钮样式',
      formType: 'textarea'
    },
    {
      target: 'modalCancelBtnStyle',
      label: '取消按钮样式',
      formType: 'textarea'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: [
    // {
    //   id: genId() + '_BaseGroup_0',
    //   ...BaseGroup
    // },
    // {
    //   id: genId() + '_BaseGroup_1',
    //   ...BaseGroup
    // }
  ]
}
