import _mixin from './_mixin'

export default {
  name: 'SDialog',
  cnName: '弹窗',
  type: 'box',
  // 属性相关
  props: {
    visible: true,
    width: '800px',
    height: '600px',
    padding: '30px',
    backgroundColor: 'transparent',
    backgroundImage: 'https://rescdn.ssjj.cn/ssjj2res/ssjj2-activity-fe/active/pc2mobile/img/modalbg.e7c9651f.png'

  },
  propsDesc: [
    {
      target: 'visible',
      label: '显隐',
      formType: 'switch'
    },
    {
      target: 'width',
      label: '宽'
    },
    {
      target: 'height',
      label: '高'
    },
    {
      target: 'padding',
      label: '内边距'
    },
    {
      target: 'backgroundColor',
      label: '弹窗背景色'
    },
    {
      target: 'backgroundImage',
      label: '弹窗背景图',
      description: '图片地址'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'fixed',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    'z-index': 998
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
