<template>
  <div class="page">
    <el-breadcrumb separator="/">
<!--      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
      <el-breadcrumb-item v-for="(item, index) in matched" :key="index" :to="item.path">
        {{item.meta.title}}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'BaseBreadcrumb',
  components: {},
  props: {},
  data () {
    return {
    }
  },
  watch: {},
  computed: {
    matched () {
      return this.$route.matched
    }
  },
  created () {
    console.log(this.$route)
  },
  mounted () {},
  methods: {},
  beforeDestroy () {}
}
</script>
<style scoped lang="scss">
.page{}
</style>
