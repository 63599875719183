import _mixin from '../_mixin'
export default {
  name: 'SActNewOld',
  cnName: '活动-拉新回归礼',
  type: 'activity_five',
  draggable: false,
  needLogin: true,
  preview: 'https://rescdn.ssjj.cn/website/2024/03/07/72023dac766617fbea6019cbe6aeecb5.jpg',
  // 属性相关
  props: {
    activityId: 'VDTKZHV73RBAJC3RSLTWQPJHCY',
    mainBg: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/8104cc855a8571ef6c39b2c148e66e8f.png'
    },
    /* 领取按钮 */
    // 尺寸
    btnSize: '251px 61px',
    // 左领取按钮位置
    leftBtnPos: '398px 0 0 606px',
    // 右领取按钮位置
    rightBtnPos: '398px 0 0 1541px',

    btnNoBegin: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/2ee878638f41a3079dc43a2c33dc1af4.png'
    },
    btnShare: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/18b73b7295f0534551217bb80750d23d.png'
    },
    btnUnfinished: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/20af0624d8560bc8df9152763d2d6293.png'
    },
    btnReceive: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/15c1cc375352ff4fbd5cd87672e86034.png'
    },
    btnReceived: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/11b2d8469b4f401aed568d46131ca09f.png'
    },
    btnExpire: {
      src: 'https://rescdn.ssjj.cn/website/2024/03/08/62e8e3fef8a72b9e6a7cb7a8095e3733.png'
    },
    textColor: '#FBE1C9',
    shareLinkDesc: '好兄弟，快来帮我助力！真的很想要！是兄弟就来《生死狙击2》！>> ',
    shareDesc: '活动规则： xxxx',
    shareTextColor: 'rgba(174, 64, 10, 1)',
    shareBgColor: 'rgba(255, 255, 255, 0.5)',
    shareLink4399: 'https://www.4399.com/xxxxxx',
    shareChannelList: [
    // const wdFromMap = {
    //   2: 'swjoy',
    //   4: 'bilibili',
    //   5: 'wegame',
    //   6: '4399',
    //   7: 'yly',
    //   9: 'douyu',
    //   10: 'steam',
    //   11: 'zhengqi',
    //   12: 'huya'
    // }
    // const fromMap = {
    //   2: '615activity_819200038',
    //   4: '615activity_1638400033',
    //   5: '615activity_2048000056',
    //   6: '615activity_2457600038',
    //   7: '615activity_2867200041',
    //   8: '615activity_3276800048',
    //   9: '615activity_3686400040',
    //   10: '615activity_4096000029',
    //   11: '615activity_4505600031',
    //   12: '615activity_4915200034'
    // }
      { channelId: 2, channelName: '顺网', wdFrom: 'swjoy', from: '615activity_819200038' },
      { channelId: 4, channelName: 'Bilibili', wdFrom: 'bilibili', from: '615activity_1638400033' },
      { channelId: 5, channelName: 'WeGame', wdFrom: 'wegame', from: '615activity_2048000056' },
      { channelId: 6, channelName: '4399', wdFrom: '4399', from: '615activity_2457600038' },
      { channelId: 7, channelName: '易乐游', wdFrom: 'yly', from: '615activity_2867200041' },
      { channelId: 8, channelName: '联想', wdFrom: '', from: '615activity_2867200041' },
      { channelId: 9, channelName: '斗鱼', wdFrom: 'douyu', from: '615activity_3686400040' },
      { channelId: 10, channelName: 'steam', wdFrom: 'steam', from: '615activity_4096000029' },
      { channelId: 11, channelName: '蒸汽', wdFrom: 'zhengqi', from: '615activity_4505600031' },
      { channelId: 12, channelName: '虎牙', wdFrom: 'huya', from: '615activity_4915200034' }
    ]
  },
  propsDesc: [
    {
      target: '基础配置',
      formType: 'divider'
    },
    {
      target: 'activityId',
      label: '活动id'
    },
    {
      target: 'mainBg',
      label: '主背景图',
      formType: 'image'
    },
    {
      target: 'textColor',
      label: '奖励文字颜色',
      formType: 'color'
    },
    {
      target: '== 领取按钮 ==',
      formType: 'divider'
    },
    {
      target: 'btnSize',
      label: '尺寸',
      formType: 'size'
    },
    {
      target: 'leftBtnPos',
      label: '左领取按钮位置',
      formType: 'position'
    },
    {
      target: 'rightBtnPos',
      label: '右领取按钮位置',
      formType: 'position'
    },
    {
      target: 'btnNoBegin',
      label: '未开始',
      formType: 'image'
    },
    {
      target: 'btnShare',
      label: '分享',
      formType: 'image'
    },
    {
      target: 'btnUnfinished',
      label: '未完成',
      formType: 'image'
    },
    {
      target: 'btnReceive',
      label: '待领取',
      formType: 'image'
    },
    {
      target: 'btnReceived',
      label: '已领取',
      formType: 'image'
    },
    {
      target: 'btnExpire',
      label: '已过期',
      formType: 'image'
    },
    // ===== 分享 =====

    {
      target: '====== 分享 ======',
      formType: 'divider'
    },
    {
      target: 'shareLinkDesc',
      label: '分享链接描述'
    },
    {
      target: 'shareDesc',
      label: '分享描述',
      formType: 'textarea'
    },
    {
      target: 'shareTextColor',
      label: '分享文字颜色',
      formType: 'color'
    },
    {
      target: 'shareBgColor',
      label: '分享背景颜色',
      formType: 'color'
    },
    {
      target: 'shareLink4399',
      label: '4399分享链接'
    },
    {
      target: 'shareChannelList',
      label: '渠道(勿增删/改from)',
      formType: 'list',
      listConfig: [
        {
          title: '渠道id',
          target: 'channelId'
        },
        {
          title: '渠道名称',
          target: 'channelName'
        },
        {
          title: 'wdFrom',
          target: 'wdFrom'
        },
        {
          title: 'from',
          target: 'from'
        }
      ]
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    position: 'relative',
    color: '#fff'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
