// topic 专题活动组件公用配置项
export default {
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/21/9cdb2c030010e9059baec333be06b047.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    titleImg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/02/23/3eca65c76c6c17a996857fa842549833.png',
      style: {
        width: '1280px',
        height: '170px'
      }
    },
    footerArrow: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/06/06/50e18c1a105049b8936aa1bbeecd6634.png',
      style: {
        width: '1920px',
        height: '50px',
        top: '',
        right: '',
        bottom: '30px',
        left: '0'
      }
    }
  },
  propsDesc: [
    {
      target: 'bg',
      label: '背景图',
      formType: 'image'
    },
    {
      target: 'titleImg',
      label: '标题',
      formType: 'image'
    },
    {
      target: 'footerArrow',
      label: '底部箭头',
      formType: 'image'
    }
  ]
}
