<template>
  <STopic2Wrapper v-bind="$attrs">
    <div class="s-component">
      <div class="role-item"
             :class="{active: curIndex === index}"
             v-show="curIndex === index"
             v-for="(item,index) in list"
             :key="item.img">
          <template v-if="index === curIndex">
            <div class="animate__animated animate__fadeInLeft">
              <slot :name="index + ''">
              </slot>
            </div>
          </template>
          <img class="role animate__animated animate__fadeInRight" v-if="item.img" :src="item.img" alt="">
      </div>
      <div class="thumb-bar" :style="thumbPos">
        <div v-for="(item,index) in list"
             class="thumb-item"
             :class="{active: curIndex === index}"
             @click="curIndex = index"
             :key="index"
             :style="{
                backgroundImage: `url(${item.thumb}`
              }">
          <img :src="item.thumb" alt="">
          <div class="label">{{item.label}}</div>
        </div>
      </div>
    </div>
  </STopic2Wrapper>
</template>

<script>

export default {
  name: 'STopic2Role',
  props: {
    imgSize: {
      type: String,
      default: ''
    },
    thumbSize: {
      type: String,
      default: ''
    },
    thumbPosition: {
      type: String,
      default: ''
    },
    thumbMargin: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
    thumbStyle () {
      const sizeArr = this.thumbSize.split(' ')
      return {
        width: sizeArr[0] || '',
        height: sizeArr[1] || '',
        margin: this.thumbMargin
      }
    },
    thumbPos () {
      const posArr = this.thumbPosition.split(' ')
      return {
        top: posArr[0] || '',
        right: posArr[1] || '',
        bottom: posArr[2] || '',
        left: posArr[3] || ''
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  width: 1920px;
  height: 1000px;
}

.role-item {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .role{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    object-fit: contain;
  }
}

.thumb-bar {
  position: absolute;
  padding-left: 100px;
  width: 100%;
  z-index: 99;
  bottom: 70px;
  align-items: center;

  .thumb-item {
    position: relative;
    transition: .3s;
    cursor: pointer;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 250px;
    height: 280px;
    margin: 0 20px;
    z-index: 2;
    filter: grayscale(100%);

    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .label{
      position: absolute;
      left: 0;
      bottom: 0;
      height: 40px;
      width: 100%;
      background-color: rgba(0,0,0,.5);
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 40px;
    }
    &.active{
      z-index: 3;
      filter: grayscale(0);;
    }
  }

}

</style>
