import _mixin from '../_mixin'
import _mixinWrap from './_mixinWrap'
export default {
  name: 'STopic2Weapon',
  cnName: '武器[军备]',
  type: 'activity_topic2',
  isTab: true,
  preview: 'https://rescdn.ssjj.cn/website/2023/11/01/9d26035d6d9ddd7cfc243509a6a35100.jpg',
  // 属性相关
  props: {
    bg: {
      src: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/5c81bd411e125c2d8907f366f50ee28d.jpg',
      style: {
        width: '1920px',
        height: '1000px'
      }
    },
    autoplay: false,
    list: [
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/f206430a802eb4ff311071807e55a1d7.png',
        label: 'QBZ192绝对零度'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/53922685a42aef6bf97e2f8c5c0344bb.png',
        label: 'TWS绝对零度'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/5388f76695f72c0b803f4dc9487be803.png',
        label: 'Vector绝对零度'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/f80dd141367be97e8f330be8ec973682.png',
        label: 'M249绝对零度'
      },
      {
        img: 'https://rescdn.ssjj.cn/website/2023/10/16/cdc76f69d05ebf6df1d06ba668aef3a9.png',
        label: '手斧绝对零度'
      }
    ],
    prevImg: 'https://rescdn-crash.ssjj.cn/website/2023/10/11/7b1f62d43ef1531106cf8b894de36a55.png',
    nextImg: 'https://rescdn-crash.ssjj.cn/website/2023/11/07/2c509c23beed288a73cc2605f4bd927a.png',
    thumbItemStyle: '{}',
    thumbItemActiveStyle: '{}'
  },
  propsDesc: [
    ..._mixinWrap.propsDesc,
    {
      target: 'autoplay',
      label: '自动播放',
      formType: 'switch'
    },
    {
      target: 'list',
      label: '图片列表',
      formType: 'list',
      listConfig: [
        {
          title: '大图',
          target: 'img'
        },
        {
          title: '缩略图',
          target: 'thumb'
        },
        {
          title: '名称',
          target: 'label'
        }
      ]
    },
    {
      target: 'prevImg',
      label: '上一页按钮图片'
    },
    {
      target: 'nextImg',
      label: '下一页按钮图片'
    },
    // {
    //   target: 'thumbWrapStyle',
    //   label: '标签容器样式',
    //   formType: 'textarea'
    // },
    {
      target: 'thumbItemStyle',
      label: '标签样式',
      formType: 'textarea'
    },
    {
      target: 'thumbItemActiveStyle',
      label: '标签选中样式',
      formType: 'textarea'
    }
  ],

  // 样式相关
  styles: {
    ..._mixin.styles
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
