<!--领取成功弹窗-->
<template>
  <SFiveModalCommon ref="SFiveModalCommon"
                    :title="config.title"
  >
    <div class="content" >
      <div class="counter">
        <div class="icon sub el-icon-minus" @click="sub"></div>
<!--        <div class="text">{{num}}</div>-->
        <input v-model="num" type="number'" class="text" @input="getNumPrice()">
        <div class="icon add el-icon-plus" @click="add"></div>
      </div>
      <div class="viewer">
        <span>|</span>
        <span class="num">{{ price }} 点券</span>
        <span>|</span>
      </div>
    </div>
    <div class="s-btn-wrap">
      <div v-if="config.confirmBtnText" :style="modalConfirmBtnStyleObj" class="s-btn s-btn-confirm" @click="confirm">{{config.confirmBtnText}}</div>
    </div>
  </SFiveModalCommon>

</template>

<script>
import { math } from '../../utils'
import SFiveModalCommon from './FiveModalCommon.vue'
export default {
  name: 'SFiveModalCounter',
  components: {
    SFiveModalCommon
  },
  props: {
    title: {
      type: String,
      default: '购买'
    },
    btnText: {
      type: String,
      default: '购买'
    }
  },
  inject: {
    // 由 FiveWrap 组件传入
    $MainWrap: {
      default: () => null
    }
  },
  data () {
    return {
      num: 0,
      config: {
        title: '提示',
        initNum: 1,
        min: '',
        max: '',
        step: 1,
        unitPrice: 25,
        confirmBtnText: '购买',
        onConfirm: () => {},
        onCancel: () => {}
      }
    }
  },
  computed: {
    price () {
      return math.mul(this.num, this.config.unitPrice)
    },
    modalConfirmBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalConfirmBtnStyle)
      } catch (e) {
        console.error('modalConfirmBtnStyle 格式错误')
        return {}
      }
    },
    modalCancelBtnStyleObj () {
      try {
        return JSON.parse(this.$MainWrap.modalCancelBtnStyle)
      } catch (e) {
        console.error('modalCancelBtnStyle 格式错误')
        return {}
      }
    }
  },
  async mounted () {
  },
  methods: {
    async open (options) {
      console.log(options)
      this.config = Object.assign(this.config, options)
      this.num = this.config.initNum
      this.$refs.SFiveModalCommon.open()
    },
    add () {
      if (this.num < this.config.max) {
        this.num += this.config.step
        if (this.num > this.config.max) this.num = this.config.max
      }
    },
    sub () {
      if (this.num > this.config.min) {
        this.num -= this.config.step
        if (this.num < this.config.min) this.num = this.config.min
      }
    },
    // 自定义数量
    getNumPrice () {
      // 保证输入的值是正整数
      this.num = Number(this.num.replace(/^(0+)|[^\d]+/g, ''))
      if (this.num > this.config.max) {
        this.num = this.config.max
        this.$message.warning('最大限购' + this.config.max)
      }
    },
    confirm () {
      if (this.num <= 0) {
        this.$message.warning('购买数量有误！')
        return
      }
      this.config.onConfirm(this.num, this.price)
      this.close()
    },
    cancel () {
      this.config.onCancel()
      this.close()
    },
    close () {
      this.$refs.SFiveModalCommon.close()
    }
  }
}
</script>

<style scoped lang="scss">
//@import "../common.scss";
.content{
  margin-bottom: 0px;
  height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding: 0 40px;
  .counter{
    color: #eee;
    display: flex;
    width: 350px;
    height: 60px;
    margin-bottom: 30px;
    margin-top: 30px;
    .icon{
      font-size: 32px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      transition: .3s;
      background-color: rgba(0,0,0, .5);
      border-radius: 4px;
      font-weight: 700;
      &:hover{
        background-color: rgba(55,55,55, .5);
      }
    }
    .text{
      width: 200px;
      margin: 0 2px;
      flex: 1;
      text-align: center;
      line-height: 60px;
      font-size: 30px;
      background-color: rgba(0,0,0, .5);
      color: #eee;
      border: none;
      outline: none;
    }
  }
  .viewer{
    width: 350px;
    height: 68px;
    text-align: center;
    background-color: rgba(0,0,0, .5);
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    span{
      display: block;
      margin: 0 5px;
    }
    .num{
      flex: 1;
    }
  }
}
.s-btn-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.s-btn{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  width: 222px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  margin: 0 20px;
  transition: .3s;
  cursor: pointer;
}
.s-btn-confirm{
  &:hover{
    filter: brightness(1.1);
  }
}
</style>
