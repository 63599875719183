<template>
  <div class="s-component">
    <div class="main-bg"
         :class="{active: curIndex === index}"
         v-show="curIndex === index"
         v-for="(item,index) in list"
         :key="item.img">
      <template v-if="index === curIndex">
        <video v-if="item.video" class="bg" :src="item.video" muted playsinline :autoplay="true"  loop/>
        <img v-else class="bg" :src="item.img" alt="">

        <div class="">
          <slot :name="index + ''">
          </slot>
        </div>
      </template>
    </div>

    <div class="thumb-bar" :style="thumbWrapStyleObj">
      <div v-for="(item,index) in list"
           class="thumb-item"
           :class="{active: curIndex === index}"
           @click="curIndex = index"
           :key="index"
           :style="curIndex === index ? thumbItemActiveStyleObj : thumbItemStyleObj">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'STopic2Core',
  props: {
    thumbWrapStyle: {
      type: String,
      default: '{}'
    },
    thumbItemStyle: {
      type: String,
      default: '{}'
    },
    thumbItemActiveStyle: {
      type: String,
      default: '{}'
    },
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      curIndex: 0
    }
  },
  computed: {
    // 地图样式
    thumbWrapStyleObj () {
      try {
        return JSON.parse(this.thumbWrapStyle)
      } catch (e) {
        console.error('thumbWrapStyle 格式错误')
        return {}
      }
    },
    thumbItemStyleObj () {
      try {
        return JSON.parse(this.thumbItemStyle)
      } catch (e) {
        console.error('thumbItemStyle 格式错误')
        return {}
      }
    },
    thumbItemActiveStyleObj () {
      try {
        return JSON.parse(this.thumbItemActiveStyle)
      } catch (e) {
        console.error('thumbItemActiveStyle 格式错误')
        return {}
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.s-component {
  position: relative;
  width: 1920px;
  height: 1000px;
}

.main-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .bg{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #000;
  }

}

.thumb-bar {
  position: absolute;
  z-index: 99;
  top: 230px;
  left: 210px;

  .thumb-item {
    display: block;
    position: relative;
    transition: .3s;
    cursor: pointer;
    width: 160px;
    height: 55px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 53px;
    font-size: 25px;
    border: 1px solid #969696;
    background-color: rgba(103, 103, 103,.2);
    z-index: 2;
    color: #fff;

    &.active {
      border: 1px solid #ce292d;
      background-color: rgba(189, 20, 24,.2);
    }
  }

}

.animate__animated{
  position: relative;
  z-index: 1;
}

</style>
