<template>
  <div class="receive-box">
    <div class="conditions">
      <div v-for="(item,index) in groupAward" :key="index" class="condition-item img-bg"
           :style="{backgroundImage: `url(${item.bg})`}">
        <div class="condition-desc">{{item.id}}</div>
        <div class="condition-desc">{{item.condition}}</div>
        <div v-if="Number(item.status) === 0 " class="btn-gray img-bg" :style="btnStyle">{{btnNoBegin}}</div>
        <div v-if="Number(item.status === 1) " class="btn-gray img-bg" :style="btnStyle">{{btnUnfinishedText}}</div>
        <div v-if="Number(item.status === 2) " class="btn-light img-bg" :style="btnStyle" @click="receiveAward(item)">{{btnReceiveText}}</div>
        <div v-if="Number(item.status === 3) " class="btn-gray img-bg" :style="btnStyle">{{btnReceivedText}}</div>
        <div v-if="Number(item.status === 4) " class="btn-gray img-bg" :style="btnStyle">{{btnExpireText}}</div>
      </div>
    </div>
    <!--奖励弹窗-->
    <AwardModel
      :modelShow.sync="awardModel.show"
      :modelType= 0
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :awardBgSize="modelBgSize"
      :awardTitle="awardTitle"
      :awardTitleSize="awardTitleSize"
      :awardOtherDesc="awardModel.type === 1 ? awardOtherDesc : ''"
      :awardBgImg="awardBgImg"
      :awardBoxBgSize="awardBoxBgSize"
      :btnStatusOne="btnStatus"
      :awardBtnText="btnHappyText"
      :awardBtnColor="btnColor"
      :awardBtnSize="btnSize"
      :awardData="awardModel.award"
    >
    </AwardModel>
    <!--提示弹窗-->
    <TipModel
      :modelShow.sync="tipModel.show"
      :modelBg="modelBg"
      :modelColor="modelColor"
      :modelClose="modelClose"
      :tipBgSize="modelBgSize"
      :tipTitle="tipTitle"
      :tipTitleSize="tipTitleSize"
      :btnStatusOne="btnStatus"
      :tipBtnColor="btnColor"
      :tipBtnText="btnCloseText"
      :tipBtnSize="btnSize"
      :contentText="tipModel.text"
    >
    </TipModel>
  </div>
</template>

<script>
import TipModel from '@/components/BuilderComponents/Dialog/TipModel.vue'
import AwardModel from '@/components/BuilderComponents/Dialog/AwardModel.vue'

export default {
  name: 'SReceive',
  components: { AwardModel, TipModel },
  inject: {
    eventBus: {
      default: null
    }
  },
  props: {
    activityId: {
      type: String,
      default: ''
    },
    activityGroup: {
      type: Array,
      default: () => []
    },
    modelShow: {
      type: Boolean,
      default: true
    },
    modelBg: {
      type: String,
      default: ''
    },
    modelBgSize: {
      type: String,
      default: ''
    },
    modelColor: {
      type: String,
      default: '#D54A51'
    },
    modelClose: {
      type: String,
      default: ''
    },
    awardTitle: {
      type: String,
      default: ''
    },
    awardTitleSize: {
      type: String,
      default: '719px 39px'
    },
    awardOtherDesc: {
      type: String,
      default: ''
    },
    awardBgImg: {
      type: String,
      default: ''
    },
    awardBoxBgSize: {
      type: String,
      default: ''
    },
    btnStatus: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    },
    btnSize: {
      type: String,
      default: '220px 66px'
    },
    btnNoBegin: {
      type: String,
      default: ''
    },
    btnUnfinishedText: {
      type: String,
      default: ''
    },
    btnReceiveText: {
      type: String,
      default: ''
    },
    btnReceivedText: {
      type: String,
      default: ''
    },
    btnExpireText: {
      type: String,
      default: ''
    },
    btnHappyText: {
      type: String,
      default: ''
    },
    btnCloseText: {
      type: String,
      default: ''
    },
    tipTitle: {
      type: String,
      default: ''
    },
    tipTitleSize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      groupAward: [],
      btnStatusMap: {
        0: '未开始',
        1: '未完成',
        2: '领取',
        3: '已领取',
        4: '已过期'
      },
      awardModel: {
        show: false,
        // 1: 不是实物  2: 是实物  3：谢谢参与
        type: 1,
        award: []
      },
      // 提示弹窗文案
      tipModel: {
        show: false,
        text: ''
      }
    }
  },
  computed: {
    requestAPI () {
      // eslint-disable-next-line no-undef
      return this.request ? this.request : $request
    },
    btnStyle () {
      const arr = this.btnSize.split(' ')
      const width = arr[0] || '220px'
      const height = arr[1] || '66px'
      return {
        width,
        height,
        color: `${this.btnColor}`,
        'line-height': `${height}`,
        'background-image': `url(${this.btnStatus})`
      }
    }
  },
  watch: {
    // 开心收下后，刷新页面数据
    'awardModel.show': {
      handler: function (newVal) {
        if (!newVal) {
          // 获取当前活动数据
          this.activityInfo()
        }
      },
      immediate: false
    },
    activityId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          // 获取当前活动数据
          this.activityInfo()
        }
      },
      immediate: false
    },
    activityGroup: {
      handler: function (newVal) {
        this.groupAward = newVal
        // 获取活动信息
        this.activityInfo()
      },
      immediate: true
    }
  },
  mounted () {
    // 获取活动信息
    this.activityInfo()
    // 登录成功操作添加
    if (this.eventBus) {
      this.eventBus.on('loginSuccess', () => {
        this.activityInfo()
      })
    }
    // 登出操作添加
    if (this.eventBus) {
      this.eventBus.on('signOut', () => {
        this.reset()
      })
    }
  },
  methods: {
    // 获取活动信息
    activityInfo () {
      this.requestAPI({
        url: '/v1/uni/info',
        method: 'get',
        params: { a_id: this.activityId }
      }).then((res) => {
        if (res.groups) {
          const newGroupAward = []
          this.groupAward.forEach((k) => {
            res.groups.forEach((item) => {
              if (Number(k.groupId) === Number(item.id)) {
                k.id = item.id
                k.status = item.status
                k.award = [{ image: item.icon, name: item.name, count: item.amount }]
                newGroupAward.push(k)
              }
            })
          })
          this.groupAward = newGroupAward
        }
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    },
    // 领取奖品
    receiveAward (item) {
      // eslint-disable-next-line no-undef
      if (!$checkLogin()) return
      // 判断是打开详情，还是新增
      this.requestAPI({
        url: '/v1/uni/receive',
        method: 'get',
        params: { a_id: this.activityId, g_id: item.id }
      }).then((res) => {
        if (!res.success) {
          this.tipModel.show = true
          this.tipModel.text = res.msg
          return
        }
        this.awardModel.show = true
        this.awardModel.type = 1
        this.awardModel.award = item.award
      }).catch((err) => {
        this.tipModel.show = true
        this.tipModel.text = err.msg || '系统错误'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .img-bg{
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
  }
  .conditions{
    .condition-item{
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 700px;
      height: 200px;
      .condition-desc{
        font-size: 28px;
        font-weight: bold;
      }
      .btn-gray{
        font-size: 28px;
        font-weight: bold;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
      }
      .btn-light {
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
          filter: brightness(120%);
        }
      }
    }
  }
</style>
