import _mixin from './_mixin'
import channel from './_mixin/channel'
export default {
  name: 'BaseGroup',
  cnName: '组',
  type: 'box',
  draggable: true,
  // 属性相关
  props: {
    ...channel.props,
    width: '100%',
    // minHeight: '500px',
    isFlex: false,
    alignItems: '',
    justifyContent: '',
    flexDirection: '',
    inAnimate: '',
    hoverAnimate: '',
    autoAnimate: '',
    // 数据埋点 名称
    eventName: ''
  },
  propsDesc: [
    ...channel.propsDesc,
    {
      target: 'width',
      label: '宽'
    },
    {
      target: 'minHeight',
      label: '最小高度'
    },
    {
      target: 'isFlex',
      label: 'flex',
      formType: 'select',
      options: [
        { label: '否', value: false },
        { label: '是', value: true }
      ]
    },
    {
      target: 'alignItems',
      label: 'alignItems',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'center', value: 'center' },
        { label: 'flex-start', value: 'flex-start' },
        { label: 'flex-end', value: 'flex-end' },
        { label: 'baseline', value: 'baseline' }
      ]
    },
    {
      target: 'justifyContent',
      label: 'justifyContent',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'center', value: 'center' },
        { label: 'flex-start', value: 'flex-start' },
        { label: 'flex-end', value: 'flex-end' },
        { label: 'space-between', value: 'space-between' },
        { label: 'space-around', value: 'space-around' }
      ]
    },
    {
      target: 'flexDirection',
      label: 'flexDirection',
      description: '',
      formType: 'select',
      options: [
        { label: '', value: '' },
        { label: 'column', value: 'column' },
        { label: 'column-reverse', value: 'column-reverse' },
        { label: 'row', value: 'row' },
        { label: 'row-reverse', value: 'row-reverse' }
      ]
    },
    // 分隔线
    {
      label: '动画-只可配置一种',
      formType: 'divider'
    },
    {
      target: 'inAnimate',
      label: '进场动画',
      placeholder: 'animate.css 中的动画类名',
      formType: 'select',
      options: [
        { label: 'Attention seekers', value: 'Attention seekers', disabled: true },
        { label: 'bounce', value: 'bounce' },
        { label: 'flash', value: 'flash' },
        { label: 'pulse', value: 'pulse' },
        { label: 'rubberBand', value: 'rubberBand' },
        { label: 'shakeX', value: 'shakeX' },
        { label: 'shakeY', value: 'shakeY' },
        { label: 'headShake', value: 'headShake' },
        { label: 'swing', value: 'swing' },
        { label: 'tada', value: 'tada' },
        { label: 'wobble', value: 'wobble' },
        { label: 'jello', value: 'jello' },
        { label: 'heartBeat', value: 'heartBeat' },
        { label: 'Back entrances', value: 'Back entrances', disabled: true },
        { label: 'backInDown', value: 'backInDown' },
        { label: 'backInLeft', value: 'backInLeft' },
        { label: 'backInRight', value: 'backInRight' },
        { label: 'backInUp', value: 'backInUp' },
        { label: 'Back exits', value: 'Back exits', disabled: true },
        { label: 'backOutDown', value: 'backOutDown' },
        { label: 'backOutLeft', value: 'backOutLeft' },
        { label: 'backOutRight', value: 'backOutRight' },
        { label: 'backOutUp', value: 'backOutUp' },
        { label: 'Bouncing entrances', value: 'Bouncing entrances', disabled: true },
        { label: 'bounceIn', value: 'bounceIn' },
        { label: 'bounceInDown', value: 'bounceInDown' },
        { label: 'bounceInLeft', value: 'bounceInLeft' },
        { label: 'bounceInRight', value: 'bounceInRight' },
        { label: 'bounceInUp', value: 'bounceInUp' },
        { label: 'Bouncing exits', value: 'Bouncing exits', disabled: true },
        { label: 'bounceOut', value: 'bounceOut' },
        { label: 'bounceOutDown', value: 'bounceOutDown' },
        { label: 'bounceOutLeft', value: 'bounceOutLeft' },
        { label: 'bounceOutRight', value: 'bounceOutRight' },
        { label: 'bounceOutUp', value: 'bounceOutUp' },
        { label: 'Fading entrances', value: 'Fading entrances', disabled: true },
        { label: 'fadeIn', value: 'fadeIn' },
        { label: 'fadeInDown', value: 'fadeInDown' },
        { label: 'fadeInDownBig', value: 'fadeInDownBig' },
        { label: 'fadeInLeft', value: 'fadeInLeft' },
        { label: 'fadeInLeftBig', value: 'fadeInLeftBig' },
        { label: 'fadeInRight', value: 'fadeInRight' },
        { label: 'fadeInRightBig', value: 'fadeInRightBig' },
        { label: 'fadeInUp', value: 'fadeInUp' },
        { label: 'fadeInUpBig', value: 'fadeInUpBig' },
        { label: 'fadeInTopLeft', value: 'fadeInTopLeft' },
        { label: 'fadeInTopRight', value: 'fadeInTopRight' },
        { label: 'fadeInBottomLeft', value: 'fadeInBottomLeft' },
        { label: 'fadeInBottomRight', value: 'fadeInBottomRight' },
        { label: 'Fading exits', value: 'Fading exits', disabled: true },
        { label: 'fadeOut', value: 'fadeOut' },
        { label: 'fadeOutDown', value: 'fadeOutDown' },
        { label: 'fadeOutDownBig', value: 'fadeOutDownBig' },
        { label: 'fadeOutLeft', value: 'fadeOutLeft' },
        { label: 'fadeOutLeftBig', value: 'fadeOutLeftBig' },
        { label: 'fadeOutRight', value: 'fadeOutRight' },
        { label: 'fadeOutRightBig', value: 'fadeOutRightBig' },
        { label: 'fadeOutUp', value: 'fadeOutUp' },
        { label: 'fadeOutUpBig', value: 'fadeOutUpBig' },
        { label: 'fadeOutTopLeft', value: 'fadeOutTopLeft' },
        { label: 'fadeOutTopRight', value: 'fadeOutTopRight' },
        { label: 'fadeOutBottomRight', value: 'fadeOutBottomRight' },
        { label: 'fadeOutBottomLeft', value: 'fadeOutBottomLeft' },
        { label: 'Flippers', value: 'Flippers', disabled: true },
        { label: 'flip', value: 'flip' },
        { label: 'flipInX', value: 'flipInX' },
        { label: 'flipInY', value: 'flipInY' },
        { label: 'flipOutX', value: 'flipOutX' },
        { label: 'flipOutY', value: 'flipOutY' },
        { label: 'Lightspeed', value: 'Lightspeed', disabled: true },
        { label: 'lightSpeedInRight', value: 'lightSpeedInRight' },
        { label: 'lightSpeedInLeft', value: 'lightSpeedInLeft' },
        { label: 'lightSpeedOutRight', value: 'lightSpeedOutRight' },
        { label: 'lightSpeedOutLeft', value: 'lightSpeedOutLeft' },
        { label: 'Rotating entrances', value: 'Rotating entrances', disabled: true },
        { label: 'rotateIn', value: 'rotateIn' },
        { label: 'rotateInDownLeft', value: 'rotateInDownLeft' },
        { label: 'rotateInDownRight', value: 'rotateInDownRight' },
        { label: 'rotateInUpLeft', value: 'rotateInUpLeft' },
        { label: 'rotateInUpRight', value: 'rotateInUpRight' },
        { label: 'Rotating exits', value: 'Rotating exits', disabled: true },
        { label: 'rotateOut', value: 'rotateOut' },
        { label: 'rotateOutDownLeft', value: 'rotateOutDownLeft' },
        { label: 'rotateOutDownRight', value: 'rotateOutDownRight' },
        { label: 'rotateOutUpLeft', value: 'rotateOutUpLeft' },
        { label: 'rotateOutUpRight', value: 'rotateOutUpRight' },
        { label: 'Specials', value: 'Specials', disabled: true },
        { label: 'hinge', value: 'hinge' },
        { label: 'jackInTheBox', value: 'jackInTheBox' },
        { label: 'rollIn', value: 'rollIn' },
        { label: 'rollOut', value: 'rollOut' },
        { label: 'Zooming entrances', value: 'Zooming entrances', disabled: true },
        { label: 'zoomIn', value: 'zoomIn' },
        { label: 'zoomInDown', value: 'zoomInDown' },
        { label: 'zoomInLeft', value: 'zoomInLeft' },
        { label: 'zoomInRight', value: 'zoomInRight' },
        { label: 'zoomInUp', value: 'zoomInUp' },
        { label: 'Zooming exits', value: 'Zooming exits', disabled: true },
        { label: 'zoomOut', value: 'zoomOut' },
        { label: 'zoomOutDown', value: 'zoomOutDown' },
        { label: 'zoomOutLeft', value: 'zoomOutLeft' },
        { label: 'zoomOutRight', value: 'zoomOutRight' },
        { label: 'zoomOutUp', value: 'zoomOutUp' },
        { label: 'Sliding entrances', value: 'Sliding entrances', disabled: true },
        { label: 'slideInDown', value: 'slideInDown' },
        { label: 'slideInLeft', value: 'slideInLeft' },
        { label: 'slideInRight', value: 'slideInRight' },
        { label: 'slideInUp', value: 'slideInUp' },
        { label: 'Sliding exits', value: 'Sliding exits', disabled: true },
        { label: 'slideOutDown', value: 'slideOutDown' },
        { label: 'slideOutLeft', value: 'slideOutLeft' },
        { label: 'slideOutRight', value: 'slideOutRight' },
        { label: 'slideOutUp', value: 'slideOutUp' }
      ]
    },
    {
      target: 'hoverAnimate',
      label: '鼠标移入动画',
      formType: 'select',
      options: [
        { label: 'Grow', value: 'hvr-grow' },
        { label: 'Shrink', value: 'hvr-shrink' },
        { label: 'Pulse', value: 'hvr-pulse' },
        { label: 'Bounce In', value: 'hvr-bounce-in' },
        { label: 'Float', value: 'hvr-float' },
        { label: 'Bob', value: 'hvr-bob' },
        { label: 'Forward', value: 'hvr-forward' },
        { label: 'Backward', value: 'hvr-backward' }
      ]
    },
    {
      target: 'autoAnimate',
      label: '自执行动画',
      formType: 'select',
      options: [
        { label: 'Bob', value: 'auto-animate-bob' }
      ]
    },
    {
      target: '埋点配置',
      formType: 'divider'
    },
    {
      target: 'eventName',
      label: '事件名称'
    }

  ],

  // 样式相关
  styles: {
    ..._mixin.styles,
    'background-image': 'url()'
  },
  stylesDesc: [
    ..._mixin.stylesDesc
  ],

  // 事件相关
  events: {
    // click: () => {} 无需配置
  },
  eventsList: [
    // eventTpl 无需配置
  ],
  eventsDesc: [
    ..._mixin.eventsDesc,
    {
      target: 'mouseover',
      label: '鼠标移入'
    }
  ],

  methods: [
    ..._mixin.methods
  ],
  children: []
}
