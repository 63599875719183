<template>
  <div class="list-editor" v-show="visible">
    <table style="width: 100%">
      <tr>
        <th v-for="(item,index) in config" :key="index">{{item.title}}</th>
        <th width="50px">操作</th>
      </tr>
      <tr v-for="(_item,_index) in innerList" :key="_index">
        <td v-for="(item,index) in config" :key="index">
          <el-input size="mini" v-model="_item[item.target]"></el-input>
        </td>
        <td>
          <el-button type="text" @click="add"><i class="el-icon-circle-plus-outline"></i></el-button>
          <el-button type="text" @click="del(_index)"><i class="el-icon-circle-close"></i></el-button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'list-editor',
  props: {
    config: {
      type: Array,
      required: true,
      default: () => [
        // {
        //   title: '图片地址',
        //   target: 'src'
        // },
        // {
        //   title: '图片标题',
        //   target: 'label'
        // }
      ]
    },
    list: {
      type: Array,
      required: true,
      default: () => [
        // {label: 'xxx', src: 'xxx'}
      ]
    }
  },
  watch: {
    innerList: {
      handler (newVal) {
        this.$emit('update:list', JSON.parse(JSON.stringify(newVal)))
      },
      deep: true
    }
  },
  data () {
    return {
      visible: true,
      innerList: [],
      tpl: {}
    }
  },
  created () {
    this.innerList = this.list
    this.tpl = JSON.parse(JSON.stringify(this.list))[0] || {}
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    add () {
      this.innerList.push(this.tpl)
    },
    del (index) {
      this.innerList.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.list-editor{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  ::v-deep(.el-input__inner) {
    padding: 0 5px;
  }
}
</style>
