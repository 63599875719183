<template>
  <div class="component-wrap"
       :id="component.id"
       :class="{
         active: currentId === component.id,
         hover: isHover,
         'is-edit': isEdit,
         'is-choose': isChoose,
         'is-draggable': component.draggable && !['relative', 'static'].includes(component.styles.position)
       }"
       :style="component.styles"
       @click="chooseComponent($event,component)"
       @mousemove="isHover = true"
       @mouseout="isHover = false"
  >
    <!--    操作边框线-->
    <div v-if="isEdit" class="border border-top"></div>
    <div v-if="isEdit" class="border border-right"></div>
    <div v-if="isEdit" class="border border-bottom"></div>
    <div v-if="isEdit" class="border border-left"></div>
    <!-- 没有children的组件 直接渲染-->
    <component
      v-if="!component.children"
      :ref="component.id"
      :is="component.name"
      v-bind="component.props"
    >
      {{ component.props.slotText }}
    </component>
    <!-- 有children 的组件 将子组件渲染到当前组件的 slot 中  -->
    <template v-else>

      <!-- Tab 组件这种有多个插槽的需要单独处理  -->
      <component
        v-if="component.isTab"
        :ref="component.id"
        :is="component.name"
        v-bind="component.props"
      >
        <template
          v-for="(_component, _index) in component.children"
          v-slot:[getSlotName(_index)]
        >
          <ComponentRender
            :component="_component"
            :key="_component.id"
            :is-edit="isEdit"
            :is-choose="isChoose"
            :current-id="currentId"/>
        </template>
      </component>
      <component
        v-else
        :ref="component.id"
        :is="component.name"
        v-bind="component.props"
      >
        <ComponentRender
          v-for="_component in component.children"
          :component="_component"
          :key="_component.id"
          :is-edit="isEdit"
          :is-choose="isChoose"
          :current-id="currentId"/>
      </component>
    </template>

  </div>
</template>

<script>

export default {
  name: 'ComponentRender',
  props: {
    component: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    currentId: {
      type: String,
      default: ''
    },
    isChoose: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    pageBuilder: {
      default: null
    }
  },
  data () {
    return {
      isHover: ''
    }
  },
  computed: {},
  created () {
    // console.log(this.component)
  },
  mounted () {
    // this.component.methods.forEach(item => {
    this.$root.$on(`${this.component.id}-click`, methodName => {
      // 执行组件内对应的方法
      console.log(`${this.component.id}-click`)
      console.log(methodName)
      this.$refs[this.component.id][methodName]()
    })
    // })
  },
  beforeDestroy () {
    this.component.methods.forEach(item => {
      this.$root.$off(`${this.component.id}-${item.fn}`)
    })
  },
  methods: {
    chooseComponent (e, comp) {
      if (this.isEdit) {
        e.stopPropagation()
        if (this.isChoose) {
          this.pageBuilder.$refs.partRight.switchControlledComponent(comp)
        } else {
          this.$store.commit('SET_CURRENT_ID', comp.id)
        }
      } else {
        comp.events.click && this.doFn(comp.events.click)
      }
    },
    getSlotName (index) {
      return index + ''
    },
    doFn (eventStr) {
      if (!eventStr) return
      console.log(eventStr)
      const arr = eventStr.split('--')
      const event = arr[0]
      const method = arr[1]
      this.$root.$emit(event, method)
    },
    onMousedown () {

    }
  }
}
</script>
<style lang="scss" scoped>
.component-wrap {
  position: relative;

  &::before {
    display: none;
    content: '\e6d1';
    position: absolute;
    //width: 100%;
    //height: 100%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // background-color: rgba(0,0,0, .1);
    z-index: 9;
    box-sizing: content-box;
    cursor: pointer;
    font-family: element-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 36px;
    text-align: center;
    color: #00FF00;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    font-size: 30px;
  }

  .border {
    //display: none;
    position: absolute;
    width: 4px;
    height: 4px;
    z-index: 99;
    background-color: transparent;
    transition: .2s;
    &.border-left {
      left: 0;
      top: 0;
      height: 100%;
    }

    &.border-right {
      right: 0;
      top: 0;
      height: 100%;
    }

    &.border-top {
      left: 0;
      top: 0;
      width: 100%;
    }

    &.border-bottom {
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  // 编辑模式下
  &.is-edit {
    &.active {
      & > .border {
        background-color: #00FF00 !important;
      }
    }

    &.hover {
      cursor: pointer;
      & > .border {
        display: block;
        background-color: #E6A23C;
      }
      user-select: none; // 方式拖拽时选中文字
      &.is-draggable{
        &::before {
          display: block;
          border: 2px solid #00FF00;
          //box-shadow: 0 0 10px #00FF00;
          background-color: rgba(255, 155, 0, .01);
        }
      }
    }
  }

  &.is-choose {
    &.hover {
      > .border{
        background-color: #4ddbff !important;
      }
      //&::before {
      //  border: 4px solid #4ddbff;
      //  background-color: rgba(77, 219, 255, .1);
      //}
    }
  }

}
</style>
