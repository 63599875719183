import { render, staticRenderFns } from "./TopicNewMode.vue?vue&type=template&id=68cd0274&scoped=true"
import script from "./TopicNewMode.vue?vue&type=script&lang=js"
export * from "./TopicNewMode.vue?vue&type=script&lang=js"
import style0 from "./TopicNewMode.vue?vue&type=style&index=0&id=68cd0274&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cd0274",
  null
  
)

export default component.exports